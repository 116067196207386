<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div *ngIf="question.type === 'checkBox'">
  <div class="question-container">
    <div class="question-title-skip">
      <div class="dx-field-label georama-font-subtitle">
        {{ localizeQuestionnaireQuestionText(question) }}
      </div>
      <div class="questionnaire-question-buttons">
        <dx-button
          *ngIf="!noteShown"
          icon="fi fi-rr-add-document"
          class="button-rounded-regular-capitalized button-rounded-regular-margin text-button-focus-hover"
          stylingMode="text"
          [text]="_('button-add-notes-text')"
          type="normal"
          width="auto"
          (onClick)="addQuestionNote(question)"
          [disabled]="isAuditFinished"
        >
        </dx-button>
        <dx-button
          *ngIf="noteShown"
          icon="fi fi-rr-delete-document"
          class="button-rounded-regular-capitalized button-rounded-regular-margin text-button-focus-hover"
          stylingMode="text"
          [text]="_('button-remove-notes-text')"
          type="normal"
          width="auto"
          (onClick)="removeQuestionNote(question)"
          [disabled]="isAuditFinished"
        >
        </dx-button>
        <dx-button
          class="button-rounded-regular-capitalized button-rounded-regular-margin text-button-focus-hover"
          icon="fi fi-rr-arrow-down"
          *ngIf="skipQuestionButtonShow"
          stylingMode="text"
          [text]="skipButtonText"
          [disabled]="skipQuestionButtonDisabled || isAuditFinished"
          type="normal"
          width="auto"
          (onClick)="skipQuestion($event)"
        >
        </dx-button>
      </div>
    </div>
    <div
      *ngIf="question.multiplechoice"
      class="answer-container"
    >
      <dx-check-box
        #checkBoxes
        [ngClass]="{ 'error-checkbox': error }"
        *ngFor="let answer of question.answer; let i = index"
        iconSize="1.5rem"
        class="answer-checkbox"
        [text]="localizeQuestionnaireAnswerText(answer)"
        [value]="answer.booleanValue"
        [hoverStateEnabled]="false"
        [activeStateEnabled]="false"
        (onValueChanged)="saveAnswers($event, answer)"
        [disabled]="isAuditFinished"
      ></dx-check-box>
    </div>
    <div
      *ngIf="!question.multiplechoice"
      class="answer-container"
    >
      <dx-radio-group
        #radioGroup
        [items]="translatedRadioAnswers"
        displayExpr="text"
        class="answer-checkbox"
        [activeStateEnabled]="false"
        [value]="radioAnswer"
        (onValueChanged)="checkIfAnswerSelected($event.value)"
        [disabled]="isAuditFinished"
      ></dx-radio-group>
    </div>
    <div
      *ngIf="error"
      class="error-container"
    >
      <p>{{ _('multiple-answer-validation') }}</p>
    </div>
    <div class="questionnaire-notes-container">
      <dx-text-area
        height="100"
        [(value)]="question.note"
        stylingMode="outlined"
        [visible]="noteShown"
        (onFocusOut)="saveQuestionNote(question)"
      ></dx-text-area>
    </div>
  </div>
</div>

<app-change-answer-popup
  *ngIf="confirmPopupVisible"
  [popupVisible]="confirmPopupVisible"
  (answerChanged)="setNewAnswer($event)"
></app-change-answer-popup>
