import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  DxComponent,
  DxNumberBoxComponent,
  DxSelectBoxComponent,
  DxTextBoxComponent,
  DxValidatorComponent,
} from 'devextreme-angular';
import { OpenAnswer, Unit } from '../models/answers.models';
import { OpenQuestion } from '../models/questions.models';
import { QuestionnaireService } from '../questionnaire.service';
import { Localizable } from '../../../../locale/localizable';
import { SkippableQuestion } from '../models/skippable-question';

@Component({
  selector: 'app-open-question',
  templateUrl: './open-question.component.html',
  styleUrls: ['./open-question.component.scss'],
})
export class OpenQuestionComponent extends SkippableQuestion implements OnInit, AfterViewInit {
  @ViewChild('validator', { static: false }) validator: DxValidatorComponent;
  @ViewChild('unitValidator', { static: false })
  unitValidator: DxValidatorComponent;
  @ViewChild('inputElement', { static: false }) textBox: DxComponent;

  @Input() question: OpenQuestion;
  @Input() isAuditFinished: boolean = false;
  @Output() validationError = new EventEmitter<boolean>();

  public selectedUnit: string;
  public skipQuestionButtonDisabled = false;
  public skipQuestionButtonShow = true;
  public skipButtonText: string;

  constructor(private questionnaireService: QuestionnaireService) {
    super(questionnaireService, 'Questionnaire');
  }

  ngOnInit(): void {
    if (this.answerUnits) {
      this.selectedUnit = this.answerUnits[0];
    }
    if (this.question.answer.validation?.rule) {
      if (!Array.isArray(this.question.answer.validation.rule)) {
        this.question.answer.validation.rule = [this.question.answer.validation.rule];
      }
    }
    this.skipQuestionButtonText();
    this.fillAnswers();
    this.fillNotes(this.question);
  }

  ngAfterViewInit(): void {
    this.textBox.instance.focus();
  }

  public skipQuestion(e: any) {
    super.skipQuestion(this.question);
  }

  public skipQuestionButtonText() {
    super.skipQuestionButtonText(this.question);
  }

  public get answerUnits() {
    if (Array.isArray(this.question.answer.units)) {
      return this.question.answer.units;
    } else {
      return [this.question.answer.units];
    }
  }
  private fillAnswers(): void {
    const questionNode = this._questionnaireService.findNode(this.question.guid);
    const answerNode = questionNode.model.answer;
    if (answerNode != null) {
      this.skipQuestionButtonShow = false;
      this.selectedUnit = answerNode.unit;
      this.question.answer.stringValue = answerNode.stringValue;
      this.question.answer.numberValue = answerNode.numberValue;
    }
  }

  public filterNewValue(): void {
    this._questionnaireService.isDirty = true;
    if (!this.answerUnits || this.selectedUnit) {
      if (this.validator?.instance?.validate().isValid) {
        this.addAnswer(this.question.answer);
      } else {
        this.validationError.next(true);
      }

      if (!!this.validator?.instance === false) {
        this.addAnswer(this.question.answer);
      }
    }
  }

  public addAnswer(answer: OpenAnswer): void {
    this.skipQuestionButtonShow = false;
    if (!!this.selectedUnit !== false) {
      answer.unit = this.selectedUnit;
    }
    answer._questionId = this.question._id;
    answer.questionGuid = this.question.guid;
    answer.isText = !!answer.stringValue !== false;
    if (answer.numberValue !== undefined || answer.stringValue !== undefined) {
      this._questionnaireService.setUserAnswer(answer, false);
    }
    this.validationError.next(false);
  }

  public textBoxFilterNewValue(): void {
    if (this.rulesValidIfExist()) {
      this.addAnswer(this.question.answer);
    }
  }

  public rulesValidIfExist(): boolean {
    if (this.validator) {
      if (this.validator.instance.validate().isValid) {
        return true;
      } else {
        this.validationError.next(true);
        return false;
      }
    }
    return true;
  }

  public keyDownOnlyNumbers(e: any): void {
    const event = e.event;
    const str = event.key || String.fromCharCode(event.which);
    if (/^[e]$/.test(str)) {
      event.preventDefault();
    }
  }
}
