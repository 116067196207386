<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-popup
  height="auto"
  width="auto"
  [fullScreen]="false"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  [visible]="popupVisible"
  contentTemplate="popupContent"
  titleTemplate="popupTitle"
  (onHiding)="exitCancel()"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <div
    *dxTemplate="let data of 'popupTitle'"
    class="dxPopup-confirmation"
  >
    <h3 class="exitPopupTitle">{{ title }}</h3>
  </div>
  <div
    *dxTemplate="let data of 'popupContent'"
    class="dxPopup-confirmation"
  >
    <p
      *ngIf="message !== null"
      class="exitPopupMessage"
    >
      {{ message }}
    </p>
    <div
      *ngIf="customContent"
      class="exitPopupMessage"
    >
      <ng-container [ngTemplateOutlet]="content.templateRef"></ng-container>
    </div>
    <div class="confirm-buttons-container">
      <dx-button
        class="outlined-button-focus-hover"
        stylingMode="outlined"
        [text]="confirmationSpecial"
        type="normal"
        [visible]="specialButtonVisible"
        (onClick)="exitSpecial()"
      ></dx-button>
      <dx-button
        class="outlined-button-focus-hover"
        stylingMode="outlined"
        [text]="confirmationYes"
        type="normal"
        (onClick)="exitConfirm()"
      ></dx-button>
      <dx-button
        class="contained-button-focus-hover active-button button dxPopupConfirmButton"
        stylingMode="contained"
        [text]="confirmationNo"
        type="normal"
        (onClick)="exitCancel()"
      ></dx-button>
    </div>
  </div>
</dx-popup>
