<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="container">
  <!-- Left Section -->
  <div class="left-section">
    <dx-accordion
      #accordion
      [dataSource]="versions"
      [collapsible]="false"
      [multiple]="false"
      [animationDuration]="300"
      [selectedItems]="[selectedVersion]"
      id="accordion-container"
    >
      <div *dxTemplate="let version of 'title'">
        <div class="header">
          {{ _('version-overview-version-keyword') }}
          {{ version.versionNumber }} - {{ version.versionName }}
        </div>
      </div>
      <div *dxTemplate="let version of 'item'">
        <div *ngFor="let filegroup of version.fileGroups">
          <strong
            >{{ _('version-overview-file-keyword') }}
            {{ filegroup.fileName }}
          </strong>
          <dx-button
            *ngIf="isUserAdmin()"
            (onClick)="onOpenFileGroup(filegroup.fileName)"
            >Open group
          </dx-button>
          <ul>
            <li *ngFor="let group of filegroup.groups">{{ _('version-overview-group-keyword') }} {{ group }}</li>
          </ul>
        </div>
      </div>
    </dx-accordion>
  </div>
  <!-- Right Section -->
  <div class="right-section">
    <dx-button
      [text]="_('version-overview-add-version')"
      stylingMode="outlined"
      mode="outlined"
      class="outlined-button-focus-hover"
      (onClick)="addVersion()"
    ></dx-button>
  </div>
</div>

<app-version-creator
  *ngIf="versionCreatorVisible"
  [popupWidth]="popupWidth"
  [popupHeight]="popupHeight"
  [(versionCreatorPopupVisible)]="versionCreatorVisible"
  (versionCreatorPopupVisibleChange)="reloadVersions()"
>
</app-version-creator>
