export enum FormMenuActions {
  Save = 0,
  Add = 1,
  Gallery = 2,
  Report = 3,
  Delete = 4,
  Sankey = 5,
  Questionnaire = 6,
  ToggleFinishedAudit = 7,
  OpenDrawer = 8,
  CloseDrawer = 9,
}
