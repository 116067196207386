<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div *ngIf="question.type === 'open'">
  <div class="question-container">
    <div class="question-title-skip">
      <div class="dx-field-label georama-font-subtitle">
        {{ localizeQuestionnaireQuestionText(question) }}
      </div>
      <div class="questionnaire-question-buttons">
        <dx-button
          *ngIf="!noteShown"
          icon="fi fi-rr-add-document"
          class="button-rounded-regular-capitalized button-rounded-regular-margin text-button-focus-hover"
          stylingMode="text"
          [text]="_('button-add-notes-text')"
          type="normal"
          width="auto"
          (onClick)="addQuestionNote(question)"
          [disabled]="isAuditFinished"
        >
        </dx-button>
        <dx-button
          *ngIf="noteShown"
          icon="fi fi-rr-delete-document"
          class="button-rounded-regular-capitalized button-rounded-regular-margin text-button-focus-hover"
          stylingMode="text"
          [text]="_('button-remove-notes-text')"
          type="normal"
          width="auto"
          (onClick)="removeQuestionNote(question)"
          [disabled]="isAuditFinished"
        >
        </dx-button>
        <dx-button
          class="button-rounded-regular-capitalized button-rounded-regular-margin text-button-focus-hover"
          icon="fi fi-rr-arrow-down"
          *ngIf="skipQuestionButtonShow"
          stylingMode="text"
          [text]="skipButtonText"
          [disabled]="skipQuestionButtonDisabled || isAuditFinished"
          type="normal"
          width="auto"
          (onClick)="skipQuestion($event)"
        >
        </dx-button>
      </div>
    </div>
    <div class="answer-container">
      <dx-text-box
        stylingMode="outlined"
        #inputElement
        *ngIf="question.answer.type === 'TextBox'"
        class="answer-text-box wider-answer-box open-focus-border"
        [(value)]="question.answer.stringValue"
        (onValueChanged)="textBoxFilterNewValue()"
        [inputAttr]="{ autocomplete: 'DBEnergy-questionnaire-text' }"
        [disabled]="isAuditFinished"
      >
        <dx-validator
          #validator
          *ngIf="question.answer.validation"
        >
          <dxi-validation-rule
            *ngFor="let rule of question.answer.validation.rule"
            [type]="rule._type"
            [message]="rule.message"
            [min]="rule.min"
            [max]="rule.max"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>

      <dx-number-box
        stylingMode="outlined"
        [step]="0"
        #inputElement
        *ngIf="question.answer.type === 'NumberBox'"
        class="answer-text-box open-focus-border"
        [(value)]="question.answer.numberValue"
        (onValueChanged)="filterNewValue()"
        (onKeyDown)="keyDownOnlyNumbers($event)"
        [format]="fullNumberFormat"
        [inputAttr]="{ autocomplete: 'DBEnergy-questionnaire-number' }"
        [disabled]="isAuditFinished"
      >
        <dx-validator
          #validator
          *ngIf="question.answer.validation"
        >
          <dxi-validation-rule
            *ngFor="let rule of question.answer.validation.rule"
            [type]="rule._type"
            format="#"
            [message]="rule.message"
            [min]="rule.min"
            [max]="rule.max"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-number-box>

      <dx-select-box
        stylingMode="outlined"
        #inputElement
        *ngIf="question.answer.units && question.answer.type === 'NumberBox'"
        class="unit-select-box open-focus-border"
        placeholder="{{ _('choose-unit-placeholder') }}"
        [(value)]="selectedUnit"
        [items]="answerUnits"
        (onValueChanged)="filterNewValue($event)"
        [disabled]="isAuditFinished"
      >
        <dx-validator #unitValidator>
          <dxi-validation-rule
            type="required"
            message="{{ _('unit-required-message') }}"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>
    </div>
    <div class="questionnaire-notes-container">
      <dx-text-area
        height="100"
        [(value)]="question.note"
        stylingMode="outlined"
        [visible]="noteShown"
        (onFocusOut)="saveQuestionNote(question)"
      ></dx-text-area>
    </div>
  </div>
</div>
