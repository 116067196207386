import { Injectable, inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { Localizable } from '../../locale/localizable';
import { CanDeactivateGuardService } from '../../shared/services/can-deactivate-guard.service';

export interface CanComponentDeactivate {
  canBeDeactivated: () => boolean;
}

@Injectable({
  providedIn: 'root',
})
class CanDeactivateGuard extends Localizable {
  constructor(private _canDeactivateGuardService: CanDeactivateGuardService) {
    super();
  }

  canDeactivate(component: CanComponentDeactivate): Observable<boolean> | Promise<boolean> | boolean {
    if (component != null && !component.canBeDeactivated()) {
      this._canDeactivateGuardService.openConfirmationDialog();
      return this._canDeactivateGuardService.getConfirmation();
    }
    return true;
  }
}

export const CanDeactivateFnGuard: CanDeactivateFn<CanComponentDeactivate> = (
  component: CanComponentDeactivate
): any => {
  return inject(CanDeactivateGuard).canDeactivate(component);
};
