import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Medium } from 'src/app/shared/models/area/types/boiler.model';
import {
  OperatingPointVolatility,
  RegulationType,
  TechnicalCondition,
  WaterPump,
} from 'src/app/shared/models/area/types/water-pump.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { Localizable } from '../../../../../../../locale/localizable';

@Component({
  selector: 'app-water-pump',
  templateUrl: './water-pump.component.html',
  styleUrls: ['./water-pump.component.scss'],
})
export class WaterPumpComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  waterPump: WaterPump = null;

  mediumsList: ArrayStore;
  technicalConditions: ArrayStore;
  regulationTypesList: ArrayStore;
  operatinPointsVolatilityList: ArrayStore;

  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getBoilerMediumList().subscribe((res: Array<Medium>) => {
      this.mediumsList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getAreaTechnicalConditions().subscribe((res: Array<TechnicalCondition>) => {
      this.technicalConditions = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getRegulationTypes().subscribe((res: Array<RegulationType>) => {
      this.regulationTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getOperatingPointVolatilities().subscribe((res: Array<OperatingPointVolatility>) => {
      this.operatinPointsVolatilityList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
