import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import {
  Deaerator,
  DeaeratorSupplyType,
  DegassingType,
  WaterSupplyType,
} from 'src/app/shared/models/area/types/deaerator.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-deaerator',
  templateUrl: './deaerator.component.html',
  styleUrls: ['./deaerator.component.scss'],
})
export class DeaeratorComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  deaerator: Deaerator = null;

  currentYear: number;
  degassingTypes: ArrayStore;
  deaeratorSupplyTypes: ArrayStore;
  waterSupplyTypes: ArrayStore;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getDeaeratorDegassingTypes().subscribe((res: Array<DegassingType>) => {
      this.degassingTypes = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getDeaeratorSupplyTypes().subscribe((res: Array<DeaeratorSupplyType>) => {
      this.deaeratorSupplyTypes = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getDeaeratorWaterSupplyTypes().subscribe((res: Array<WaterSupplyType>) => {
      this.waterSupplyTypes = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
