<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="content-block">
  <div class="reset_password_dialog credentials">
    <h3>{{ _('reset-password-title') }}</h3>
    <form (keyup.enter)="onSendResetMailClick()">
      <div class="field">
        <div class="field-label">{{ _('resetPassword-enterEmail') }}</div>
        <dx-text-box
          (onValueChanged)="onEmailValueChanged($event)"
          [showClearButton]="true"
          width="100%"
          [stylingMode]="stylingMode"
          autocomplete="off"
        >
          <dx-validator>
            <dxi-validation-rule
              type="required"
              message="{{ _('login-emailRequiredMessage') }}"
            ></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>

      <div class="center_button button_bottom_margin">
        <dx-button
          id="buttonLogin"
          text="{{ _('resetPassword-sendButtonText') }}"
          (onClick)="onSendResetMailClick()"
          stylingMode="outlined"
          class="outlined-button-focus-hover"
        >
        </dx-button>
      </div>

      <div class="center_button">
        <dx-button
          class="text-button-focus-hover forget_password_button"
          text="{{ _('login-loginButton') }}"
          stylingMode="text"
          [hoverStateEnabled]="false"
          (onClick)="onLoginClick()"
        >
        </dx-button>
      </div>
    </form>
  </div>
</div>
