<!-- Copyright C Alfa-Net. All Rights Reserved. -->

<dx-popup
  id="versionCreatorPopup"
  *ngIf="versionCreatorPopupVisible"
  [fullScreen]="false"
  [showTitle]="true"
  [width]="popupWidth + '%'"
  [height]="popupHeight + '%'"
  [dragEnabled]="false"
  [(visible)]="currentVersionCreatorPopupVisible"
  titleTemplate="title"
  (onHiding)="onHiding($event)"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <div *dxTemplate="let data of 'title'">
    <div
      id="questionnaireTitle"
      class="dx-widget dx-visibility-change-handler dx-collection dx-popup-title dx-has-close-button"
      role="toolbar"
    >
      <div>
        <div class="toolbar-before-questionnaire-padding">
          <h1 class="h1-no-margin">{{ _('version-creator-title') }}</h1>
        </div>
        <div class="dx-toolbar-after toolbar-after-padding">
          <dx-button
            id="close-questionnaire-button"
            icon="fi fi-rr-cross-small"
            (onClick)="showVersionCreator(false)"
          ></dx-button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <!-- Left Section -->
    <div class="left-section">
      <div class="version-files">
        <h2>{{ _('version-creator-uploaded-files') }}</h2>
        <dx-list
          [dataSource]="questionnaireVersionInfo.fileGroups"
          [allowItemDeleting]="true"
          [itemDeleteMode]="'static'"
          (onItemDeleted)="onFileDeleted($event)"
          itemTemplate="listItem"
        >
          >
          <div *dxTemplate="let item of 'listItem'">
            <strong>{{ _('version-creator-file-keyword') }} {{ item.fileName }}</strong>
            <br />
            <ul>
              <li *ngFor="let group of item.groups">{{ _('version-creator-group-keyword') }} {{ group }}</li>
            </ul>
          </div>
        </dx-list>
      </div>
    </div>
    <!-- Right Section -->
    <div class="right-section">
      <div class="version-details">
        <dx-text-box
          [(value)]="versionText"
          (onValueChanged)="versionTextChanged($event)"
          [placeholder]="_('version-creator-new-version-placeholder')"
        ></dx-text-box>

        <div class="fileuploader-container">
          <dx-file-uploader
            class="fileuploader"
            [uploadUrl]="uploadUrl"
            [selectButtonText]="_('select-file')"
            [labelText]="_('drop-zone')"
            [uploadFailedMessage]="_('upload-failed')"
            [uploadedMessage]="_('upload-success')"
            uploadMode="instantly"
            [showFileList]="false"
            [allowedFileExtensions]="['.xml']"
            [maxFileSize]="5000000"
            [name]="fileName"
            [multiple]="true"
            (onUploadError)="handleUploadError($event)"
            (onFilesUploaded)="handleFilesUploaded($event)"
            [uploadHeaders]="{
              Authorization: token,
            }"
          >
          </dx-file-uploader>
        </div>
      </div>
    </div>
  </div>

  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    cssClass="button-contained button-case-sensitive"
    [options]="acceptButtonOptions"
    [disabled]="acceptVersionButtonDisabled"
  >
  </dxi-toolbar-item>
</dx-popup>
