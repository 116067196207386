import { enumFromStringValue } from '../questionnaire.service';
import { Answer, OpenAnswer, CheckBoxAnswer } from './answers.models';
import { Question } from './questions.models';
import { v4 as uuidv4 } from 'uuid';

export class QuestionnairePathNode {
  id: string;
  type: QuestionType;
  guid: string;
  multiplechoice?: boolean;
  pathChildGuid?: string;
  root?: boolean;
  answer?: QuestionnaireAnswer | Array<QuestionnaireAnswer>;
  skipToId?: string;
  note?: string;

  constructor(question: Question) {
    this.id = question._id;
    this.skipToId = question._skipToId;
    this.type = enumFromStringValue(QuestionType, question.type);
    this.guid = question.guid;
    this.multiplechoice = question.multiplechoice;
    if (this.type === QuestionType.CheckBox) {
      this.answer = new Array<QuestionnaireAnswer>();
    }
  }

  public setAnswer(questionAnswer: QuestionnaireAnswer, multiplechoice?: boolean): void {
    let multipleChoiceQuestion = false;
    if (typeof multiplechoice !== 'undefined' && multiplechoice === true) {
      multipleChoiceQuestion = true;
    }

    if (Array.isArray(this.answer)) {
      if (questionAnswer.id.toLocaleUpperCase() !== QuestionnaireTags.SkipTag) {
        this.removeAnswerFromList(QuestionnaireTags.SkipTag);
      }
      if (this.answer.find((a) => a.id === questionAnswer.id)) {
        this.removeAnswerFromList(questionAnswer.id);
      } else {
        if (multipleChoiceQuestion === false) {
          // Radiobutton - checkbox type but without multiple answers.
          this.answer.splice(0);
        }

        this.answer.push(questionAnswer);
      }
    } else {
      this.answer = questionAnswer;
    }
  }

  public setPathGuid(guid: string): void {
    this.pathChildGuid = guid;
  }

  private removeAnswerFromList(id: string): void {
    if (Array.isArray(this.answer)) {
      const indexToRemove = this.answer.findIndex((a) => a.id.toLocaleUpperCase() === id.toLocaleUpperCase());
      if (indexToRemove !== -1) {
        this.answer.splice(indexToRemove, 1);
      }
    }
  }
}

export class QuestionnairePathNodeCheckBox extends QuestionnairePathNode {
  answer?: Array<QuestionnaireAnswer>;

  constructor(question: Question) {
    super(question);
    this.answer = new Array<QuestionnaireAnswer>();
  }
}

export class QuestionnaireAnswer {
  id: string;
  questionId: string;
  stringValue: string;
  numberValue: number;
  booleanValue: boolean;
  questionGuid: string;
  nextQuestionId: string;
  guid: string;
  unit?: string;
  isText: boolean = false;

  constructor(answer: Answer | OpenAnswer | CheckBoxAnswer) {
    this.id = answer._id;
    if ((answer as OpenAnswer).type) {
      this.numberValue = (answer as OpenAnswer).numberValue;
      this.stringValue = (answer as OpenAnswer).stringValue;
      this.isText = (answer as OpenAnswer).isText;
      this.unit = (answer as OpenAnswer).unit;
    } else if ((answer as CheckBoxAnswer).type) {
      this.booleanValue = (answer as CheckBoxAnswer).booleanValue;
    }

    this.questionId = answer._questionId;
    this.questionGuid = answer.questionGuid;
    this.nextQuestionId = answer._next;
    if (answer.guid !== '' && answer.guid != null) {
      this.guid = answer.guid;
    } else {
      this.guid = uuidv4();
    }
  }
}

export enum QuestionType {
  Closed = 'closed',
  Open = 'open',
  CheckBox = 'checkBox',
}

export class QuestionnaireTags {
  static RootTag: string = 'ROOT';
  static EndTag: string = 'END';
  static SkipTag: string = 'SKIP';
}

export class QuestionGroup {
  _id: string;
  _next: string;
  _prev?: string;
  guid: string;
  question?: Array<Question>;
  done?: boolean;
  _name: string;
  _icon?: string;
}
