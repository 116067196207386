<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div [ngSwitch]="requestDialog">
  <div *ngSwitchCase="0">
    <h2>{{ _('login-title') }}</h2>
    <div class="credentials">
      <form
        validate
        (ngSubmit)="onLoginClick()"
        autocomplete="off"
      >
        <div class="field">
          <div class="field-label">{{ _('login-emailLabel') }}</div>
          <dx-text-box
            data-testid="login"
            [(value)]="email"
            [showClearButton]="true"
            width="100%"
            [stylingMode]="stylingMode"
            autocomplete="off"
            validationMessageMode="always"
          >
            <dx-validator>
              <dxi-validation-rule
                type="required"
                message="{{ _('login-emailRequiredMessage') }}"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>
        <div class="field">
          <div class="field-label">{{ _('login-passwordLabel') }}</div>
          <dx-text-box
            data-testid="password"
            [(mode)]="passwordMode"
            [(value)]="password"
            autocomplete="false"
            [stylingMode]="stylingMode"
            width="100%"
            validationMessageMode="always"
          >
            <dx-validator>
              <dxi-validation-rule
                type="required"
                message="{{ _('login-paswordRequiredMessage') }}"
              ></dxi-validation-rule>
            </dx-validator>
            <dxi-button name="clear"></dxi-button>
            <dxi-button
              name="password-visibility"
              location="after"
              [options]="passwordButton"
            ></dxi-button>
          </dx-text-box>
        </div>
        <div class="center_button button_login">
          <dx-button
            data-testid="button-login"
            id="buttonLogin"
            text="{{ _('login-loginButton') }}"
            type="ngSubmit"
            stylingMode="outlined"
            class="outlined-button-focus-hover"
            (ngSubmit)="onLoginClick()"
            [useSubmitBehavior]="true"
          >
          </dx-button>
        </div>
        <div class="center_button forget_password">
          <dx-button
            data-testid="button-forget-password"
            class="forget_password_button"
            text="{{ _('login-forgetPasswordButton') }}"
            stylingMode="text"
            class="text-button-focus-hover"
            [hoverStateEnabled]="true"
            (onClick)="onForgetpasswordClick(event)"
          >
          </dx-button>
        </div>
        <div></div>
      </form>
    </div>
  </div>
  <div *ngSwitchCase="1">
    <app-password-confirmation></app-password-confirmation>
  </div>
  <div *ngSwitchCase="2">
    <app-password-reset></app-password-reset>
  </div>
</div>
