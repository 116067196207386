import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { AirEnhancer, AirEnhancerType } from 'src/app/shared/models/area/types/air-enhancer.model';
import { TechnicalCondition } from 'src/app/shared/models/area/types/water-pump.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-air-enhancer',
  templateUrl: './air-enhancer.component.html',
  styleUrls: ['./air-enhancer.component.scss'],
})
export class AirEnhancerComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  airEnhancer: AirEnhancer = null;

  airEnhancerTypesList: ArrayStore;
  technicalConditionsList: ArrayStore;

  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getAreaTypeAirEnhancerTypes().subscribe((res: Array<AirEnhancerType>) => {
      this.airEnhancerTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getAreaTechnicalConditions().subscribe((res: Array<TechnicalCondition>) => {
      this.technicalConditionsList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
