<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div
  *ngIf="selectedFormType"
  class="content-block"
>
  <div class="dx-card responsive-paddings">
    <div [ngSwitch]="selectedFormType">
      <div *ngSwitchCase="'Audyt'">
        <app-audit-form [templateMode]="true"></app-audit-form>
      </div>
      <div *ngSwitchCase="'Przedsiębiorstwo'">
        <app-enterprise-form [templateMode]="true"></app-enterprise-form>
      </div>
      <div *ngSwitchCase="'Dział'">
        <app-department-form [templateMode]="true"></app-department-form>
      </div>
      <div *ngSwitchCase="AreaTypesEnum.Transport">
        <app-area-template-form
          [areaType]="AreaTypesEnum.Transport"
          [areaCode]="'Db99Transport'"
        ></app-area-template-form>
        <hr class="line" />
        <p>{{ _('template-file-additional-variables') }}</p>
        <app-doc-variables-list></app-doc-variables-list>
        <hr class="line" />
        <app-add-template
          [docVariablesListVisible]="false"
          [areaTypeCode]="'Db99Transport'"
        ></app-add-template>
      </div>
      <div *ngSwitchDefault>{{ _('template-file-form-not-supported') }}</div>
    </div>
  </div>
</div>
