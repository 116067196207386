<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form
  [showColonAfterLabel]="true"
  *ngIf="blower"
>
  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="blower.producer"
        stylingMode="outlined"
        [disabled]="templateMode"
      >
      </dx-text-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'Producer'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="blower.model"
        stylingMode="outlined"
        [disabled]="templateMode"
      >
      </dx-text-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'Model'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="blower.serialNumber"
        stylingMode="outlined"
        [disabled]="templateMode"
      >
      </dx-text-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'SerialNumber'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="blower.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'ProductionYear'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-select-box
        [(value)]="blower.blowerType"
        [dataSource]="blowerTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('blower-type-placeholder')"
        [disabled]="templateMode"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'Type'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="blower.ratedPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'RatedPower'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-rated-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³/min'"
        [(value)]="blower.ratedCapacity"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'RatedCapacity'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-rated-capacity')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' mbar'"
        [(value)]="blower.ratedSpring"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'RatedSpring'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-rated-spring')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-select-box
        [(value)]="blower.blowerRegulationType"
        [dataSource]="blowerRegulationTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('blower-regulation-type-placeholder')"
        [disabled]="templateMode"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'RegulationType'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-regulation-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="blower.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'WorkingHoursInYear'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-working-hours-in-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³/min'"
        [(value)]="blower.averageEfficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'AverageEfficiency'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-average-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="blower.averageLoad"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'AverageLoad'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-average-load')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-select-box
        [(value)]="blower.blowerOperatingPointVolatility"
        [dataSource]="blowerOperatingPointVolatilitiesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('blower-operating-point-volatility-placeholder')"
        [disabled]="templateMode"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'OperatingPointVolatility'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-operating-point-volatility')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-select-box
        [(value)]="blower.technicalCondition"
        [dataSource]="technicalConditionsList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('blower-technical-condition-placeholder')"
        [disabled]="templateMode"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'TechnicalCondition'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('blower-technical-condition')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="templateMode">
    <div *dxTemplate>
      <app-add-template [areaTypeCode]="this.docVariableCode"></app-add-template>
    </div>
  </dxi-item>
</dx-form>
