import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { retry } from 'rxjs/operators';
import { AppConfig } from '../../../../../app/app.config';
import { TemplateType } from '../../../../shared/models/template';
import { ApiRequestService } from '../../../../shared/services/api-request.service';
import { TemplateService } from '../../../../shared/services/template.service';
import { AppSettings } from '../../../../AppSettings';
import { UploadErrorEvent } from 'devextreme/ui/file_uploader';
import { ErrorHandlerService } from '../../../../shared/services/errorHandlerService';
import { Localizable } from '../../../../locale/localizable';
import { AuthenticationService } from '../../../../shared/services/authentication.service';

@Component({
  selector: 'app-template-file-list',
  templateUrl: './template-file-list.component.html',
  styleUrls: ['./template-file-list.component.scss'],
})
export class TemplateFileListComponent extends Localizable implements OnInit {
  templatesList: Array<string> = null;
  uploadTemplateUrl: string = '';
  urlToDatabase: string = '';
  templateTypes: Array<TemplateType>;
  fileListVisible: boolean = false;
  formTypeCode: string = null;
  auditTypeId: string = null;

  constructor(
    private _apiRequestService: ApiRequestService,
    private _config: AppConfig,
    private _templateService: TemplateService,
    private _httpClient: HttpClient,
    private _errorHandlerService: ErrorHandlerService,
    private _authenticationService: AuthenticationService
  ) {
    super();
    this.urlToDatabase = this._config.getConfig('DbEnergyDatabaseUrl');
  }

  ngOnInit(): void {
    this.formTypeCode = this._templateService.getFormCode();
    this.auditTypeId = this._templateService.getAuditType().id;
    this.uploadTemplateUrl = this.urlToDatabase + `api/templates/${this.formTypeCode}/${this.auditTypeId}`;

    this.getTemplateList();
    this.getTemplateTypeList();
  }

  onTemplateDeleted(item: any): void {
    this._apiRequestService.deleteTemplate(item.itemData.id).subscribe();
  }

  getTemplateList() {
    this._apiRequestService
      .getTemplateList(this.formTypeCode, this.auditTypeId)
      .subscribe((res) => (this.templatesList = res));
  }

  getTemplateTypeList() {
    this._httpClient
      .get<Array<TemplateType>>(this.urlToDatabase + 'api/templates/types')
      .pipe(retry(3))
      .subscribe(
        (res) => (this.templateTypes = res),
        () => notify(this._('template-file-load-error'), 'error', AppSettings.NOTIFY_DURATION)
      );
  }

  public get token() {
    return this._authenticationService.getBearerTokenHeader();
  }

  public handleUploadError(error: UploadErrorEvent) {
    error.message = `${this._('template-upload-failed-message')}. ${error.request.responseText}`;
    this._errorHandlerService.handleError(error.error);
  }
}
