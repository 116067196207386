<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form
  id="audit-form"
  [showColonAfterLabel]="true"
  [colCount]="3"
>
  <dxi-item
    itemType="group"
    caption="Dane podstawowe"
    [colSpan]="3"
    [colCount]="3"
  >
    <dxi-item dataField="Tutuł naukowy">
      <div *dxTemplate>
        <dx-select-box
          [items]="academicTitles"
          [(value)]="user.academicTitle"
          placeholder="Wybierz Twój tytuł naukowy"
          stylingMode="outlined"
          [searchEnabled]="true"
          searchMode="contains"
        ></dx-select-box>
      </div>
    </dxi-item>
    <dxi-item dataField="Imie">
      <div *dxTemplate>
        <dx-text-box
          [(value)]="user.firstName"
          placeholder="Twoje imię"
          stylingMode="outlined"
        >
          <dx-validator>
            <dxi-validation-rule
              type="required"
              message="Pole Imię nie może być puste."
            ></dxi-validation-rule>
            <dxi-validation-rule
              type="stringLength"
              [min]="1"
              message="Pole Imię musi zawierać przynajmniej 1 znak."
            ></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </dxi-item>
    <dxi-item dataField="Nazwisko">
      <div *dxTemplate>
        <dx-text-box
          [(value)]="user.lastName"
          placeholder="Twoje nazwisko"
          stylingMode="outlined"
        >
          <dx-validator>
            <dxi-validation-rule
              type="required"
              message="Pole Nazwisko nie może być puste."
            ></dxi-validation-rule>
            <dxi-validation-rule
              type="stringLength"
              [min]="1"
              message="Pole Nazwisko musi zawierać przynajmniej 1 znak."
            ></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </dxi-item>
  </dxi-item>

  <dxi-item
    itemType="group"
    caption="Dane kontatkowe"
    [colSpan]="3"
    [colCount]="2"
  >
    <dxi-item dataField="Numer telefonu">
      <div *dxTemplate>
        <dx-text-box
          mask="+(00) 000-000-000"
          maskInvalidMessage="Niepoprawny numer telefonu."
          [(value)]="user.phoneNumber"
          stylingMode="outlined"
        >
        </dx-text-box>
      </div>
    </dxi-item>
    <dxi-item dataField="E-mail">
      <div *dxTemplate>
        <dx-text-box
          [(value)]="user.email"
          placeholder="jannowak@przyklad.pl"
          stylingMode="outlined"
        >
          <dx-validator>
            <dxi-validation-rule
              type="required"
              message="Pole E-mail nie może być puste."
            ></dxi-validation-rule>
            <dxi-validation-rule
              type="email"
              message="Zawartość pola E-mail nie jest poprawnym adresem e-mail."
            ></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </dxi-item>
  </dxi-item>

  <dxi-item
    itemType="group"
    caption="Szczegóły"
    [colSpan]="3"
    [colCount]="2"
  >
    <dxi-item dataField="Stanowisko">
      <div *dxTemplate>
        <dx-select-box
          [items]="positionsTypes"
          [(value)]="user.position"
          placeholder="Wybierz stanowisko"
          stylingMode="outlined"
          [searchEnabled]="true"
          searchMode="contains"
        ></dx-select-box>
      </div>
    </dxi-item>
    <dxi-item
      dataField="Poziom uprawnień"
      [colSpan]="2"
    >
      <div *dxTemplate>
        <dx-select-box
          [items]="permissionLevels"
          [(value)]="user.permissionLevel"
          placeholder="Wybierz poziom uprawnień"
          stylingMode="outlined"
          [searchEnabled]="true"
          searchMode="contains"
        >
          <dx-validator>
            <dxi-validation-rule
              type="required"
              message="Pole Poziom uprawnień nie może być puste."
            ></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>
      </div>
    </dxi-item>
  </dxi-item>

  <dxi-item [colSpan]="3">
    <div *dxTemplate>
      <dx-button
        id="button"
        text="Dodaj użytkownika"
        type="normal"
        icon="plus"
        (onClick)="save($event, user)"
      >
      </dx-button>
    </div>
  </dxi-item>
</dx-form>
