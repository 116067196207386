import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Localizable } from '../../../../../locale/localizable';
import ArrayStore from 'devextreme/data/array_store';
import { QuestionnaireDiagramDataService } from '../questionnaire-diagram-data.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { retry, switchMap } from 'rxjs/operators';
import { AppSettings } from '../../../../../AppSettings';
import { AppConfig } from '../../../../../app.config';

@Component({
  selector: 'app-questionnaire-diagram',
  templateUrl: './questionnaire-diagram.component.html',
  styleUrls: ['./questionnaire-diagram.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [QuestionnaireDiagramDataService],
  preserveWhitespaces: true,
})
export class QuestionnaireDiagramComponent extends Localizable {
  @Input() xml: any;
  flowNodesDataSource: ArrayStore;
  flowEdgesDataSource: ArrayStore;
  fullscreen: boolean;
  currentQuestion: any;
  popupVisible: boolean;
  lastAddedType: string;
  generatedID = 100;
  metadataId: string;

  apiUrl: any;

  constructor(
    private diagramDataService: QuestionnaireDiagramDataService,
    private route: ActivatedRoute,
    private _config: AppConfig,
    private _httpClient: HttpClient
  ) {
    super('Questionnaire');

    this.apiUrl = this._config.getConfig('DbEnergyDatabaseUrl');
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          this.metadataId = params.get('id');
          return this.getFileByMetaDataId(this.metadataId);
        })
      )
      .subscribe((response) => {
        this.xml = response;
        this.diagramDataService.parseXmlToArrays(this.xml);
        this.flowNodesDataSource = new ArrayStore({
          key: 'id',
          data: this.diagramDataService.getFlowNodes(),
          onInserting: (values) => {
            this.generatedID++;
            values.id = values.id || this.generatedID.toString();
            values.textt = values.textt || this.lastAddedType === 'question' ? 'Question text' : 'Answer text';
            values.type = values.type || this.lastAddedType;
          },
        });
        this.flowEdgesDataSource = new ArrayStore({
          key: 'id',
          data: this.diagramDataService.getFlowEdges(),
        });
      });
  }

  private getFileByMetaDataId(id: any): Observable<HttpResponse<any> | string> {
    return this._httpClient
      .get(`${this.apiUrl}api/questionnaires/file/metadata/${id}`, { responseType: 'text' })
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  requestLayoutUpdateHandler(e) {}

  editQuestion(question) {
    this.currentQuestion = question;
    this.popupVisible = true;
  }

  deleteQuestion(question) {}

  cancelEditQuestion() {
    this.popupVisible = false;
  }

  itemCustomDataExpr(obj, value) {
    if (value === undefined) {
      return {
        text: obj.text,
      };
    }
    obj.text = value.text;
  }

  itemTypeExpr(obj) {
    let sth = `${obj.type}`;
    if (sth === 'undefined') {
      sth = 'question';
    }
    return sth;
  }

  onOptionChanged(arg0: any) {
    if (arg0.operation === 'addShape') {
      this.lastAddedType = arg0.args.shape.type;
    }

    const questionElements = document.getElementsByClassName('customQuestion');
    if (questionElements != null) {
      // add question class
      for (let i = 0; i < questionElements.length; i++) {
        const questionElement = questionElements[i];
        const path = [].filter.call(questionElement.parentNode.parentNode.childNodes, this.byTag('path'));

        path.forEach((item) => {
          item.classList.add('template-question-node');
        });

        // remove text nod?
        const text = [].filter.call(questionElement.parentNode.parentNode.childNodes, this.byTag('text'));

        text.forEach((item) => {
          item.textContent = '';
        });
      }
    }

    const answerElements = document.getElementsByClassName('customAnswer');
    if (answerElements != null) {
      for (let i = 0; i < answerElements.length; i++) {
        const answerElement = answerElements[i];

        // add question class
        const path = [].filter.call(answerElement.parentNode.parentNode.childNodes, this.byTag('path'));

        path.forEach((item) => {
          item.classList.add('template-answer-node');
        });

        // remove text nod?
        const text = [].filter.call(answerElement.parentNode.parentNode.childNodes, this.byTag('text'));

        text.forEach((item) => {
          item.textContent = '';
        });
      }
    }
  }

  byTag(name) {
    name = name.toLowerCase();
    return function (el) {
      return el.nodeName.toLowerCase() === name;
    };
  }

  customShapes = [
    {
      category: 'question',
      type: 'question',
      title: 'Question',
      baseType: 'terminator',
      defaultWidth: 1.5,
      defaultHeight: 1,
      template: 'customQuestionShapeTemplate',
      toolboxTemplate: 'customQuestionShapeToolboxTemplate',
    },
    {
      category: 'answer',
      type: 'answer',
      title: 'Answer',
      baseType: 'terminator',
      defaultWidth: 1.5,
      defaultHeight: 1,
      template: 'customAnswerShapeTemplate',
      toolboxTemplate: 'customAnswerShapeToolboxTemplate',
    },
  ];
}
