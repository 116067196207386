import { Component, OnInit } from '@angular/core';
import globalConfig from 'devextreme/core/config';
import { AppSettings } from '../../../AppSettings';
import { FormType } from 'src/app/shared/models/audit/audit-tree-navigation.model';
import { TreeNavigationService } from 'src/app/shared/services/tree-navigation.service';
import { Localizable } from '../../../locale/localizable';
@Component({
  selector: 'app-audits-list',
  templateUrl: './audits-list.component.html',
  styleUrls: ['./audits-list.component.scss'],
})
export class AuditsListComponent extends Localizable implements OnInit {
  constructor(private _treeNavigationService: TreeNavigationService) {
    super();
    globalConfig({
      floatingActionButtonConfig: {
        position: { at: 'right bottom', my: 'right bottom', offset: '-10 -10' },
      },
    });
  }

  ngOnInit(): void {
    this._treeNavigationService.clearNavigationTree();
  }
}
