<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div *ngIf="question.type == 'closed'">
  <div class="question-container">
    <div class="question-title-skip">
      <div class="dx-field-label georama-font-subtitle">
        {{ localizeQuestionnaireQuestionText(question) }}
      </div>
      <div class="questionnaire-question-buttons">
        <dx-button
          *ngIf="!noteShown"
          icon="fi fi-rr-add-document"
          class="button-rounded-regular-capitalized button-rounded-regular-margin text-button-focus-hover"
          stylingMode="text"
          [text]="_('button-add-notes-text')"
          type="normal"
          width="auto"
          (onClick)="addQuestionNote(question)"
          [disabled]="isAuditFinished"
        >
        </dx-button>
        <dx-button
          *ngIf="noteShown"
          icon="fi fi-rr-delete-document"
          class="button-rounded-regular-capitalized button-rounded-regular-margin text-button-focus-hover"
          stylingMode="text"
          [text]="_('button-remove-notes-text')"
          type="normal"
          width="auto"
          (onClick)="removeQuestionNote(question)"
          [disabled]="isAuditFinished"
        >
        </dx-button>
        <dx-button
          class="button-rounded-regular-capitalized button-rounded-regular-margin text-button-focus-hover"
          icon="fi fi-rr-arrow-down"
          *ngIf="skipQuestionButtonShow"
          stylingMode="text"
          [text]="skipButtonText"
          [disabled]="skipQuestionButtonDisabled"
          type="normal"
          width="auto"
          (onClick)="skipQuestion($event)"
          [disabled]="skipQuestionButtonDisabled || isAuditFinished"
        >
        </dx-button>
      </div>
    </div>
    <div class="answers-container">
      <dx-button
        #buttons
        *ngFor="let answer of question.answer; let i = index"
        class="answer-button button-case-sensitive button-focus-border"
        stylingMode="outlined"
        type="normal"
        [text]="localizeQuestionnaireAnswerText(answer)"
        [ngClass]="{ 'active-button': i === activeButtonIndex }"
        (onClick)="answerSelect(answer, question._id, i)"
        [disabled]="isAuditFinished"
      >
      </dx-button>
    </div>
    <div class="questionnaire-notes-container">
      <dx-text-area
        height="100"
        [(value)]="question.note"
        stylingMode="outlined"
        [visible]="noteShown"
        (onFocusOut)="saveQuestionNote(question)"
      ></dx-text-area>
    </div>
  </div>
</div>

<app-change-answer-popup
  *ngIf="confirmPopupVisible"
  [popupVisible]="confirmPopupVisible"
  (answerChanged)="setNewAnswer($event, true)"
></app-change-answer-popup>
