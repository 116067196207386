import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { map, takeUntil } from 'rxjs/operators';
import { AppConfig } from '../../../app.config';
import { AppSettings } from '../../../AppSettings';
import { AuditTimeRange } from '../../models/audit/audit-time-range.model';
import { Currency } from '../../models/audit/currency.model';
import {
  EnergyBalanceSource,
  EnergyBalanceType,
  EnergyBalanceReceiver,
  DtoEnergyBalanceSource,
  DtoEnergyBalanceReceiver,
} from '../../models/energies/energy.model';
import { Unit } from '../../models/unit.model';
import { ApiRequestService } from '../../services/api-request.service';
import { EnergyBalanceService } from './energy-balance.service';
import { DxTagBoxComponent } from 'devextreme-angular';
import { Localizable } from '../../../locale/localizable';
import { Subject, Subscription } from 'rxjs';
import { EnergyBalanceSankey } from '../energy-balance-chart/energy-balance-chart/energy-balance-sankey.service';
@Component({
  selector: 'app-energy-balance',
  templateUrl: './energy-balance.component.html',
  styleUrls: ['./energy-balance.component.scss'],
})
export class EnergyBalanceComponent extends Localizable implements OnInit {
  @ViewChild('tagBox') tagBox: DxTagBoxComponent;

  @Input()
  auditId: string;

  @Input()
  energyBalanceId: string;

  @Input()
  assignedToFormId: string;

  @Input()
  isEnergyBalanceDisabled: boolean = true;

  public energyBalanceTypes: Array<EnergyBalanceType>;
  public chosenTypes: Array<EnergyBalanceType>;
  public chosenTypesIds: Array<string>;
  public usedTypes: Array<EnergyBalanceType>;
  public tagBoxValueChangeInProgress: boolean = false;
  public currencyFormat: string;
  public energySourceToDelete: EnergyBalanceSource = null;
  public sourcePopupVisible: boolean = false;
  public receiverPopupVisible: boolean = false;
  public deleteSourcePopupVisible: boolean = false;
  public editSource: boolean = false;
  public editReceiver: boolean = true;
  public energyBalanceSource: DtoEnergyBalanceSource;
  public energyBalanceReceiver: DtoEnergyBalanceReceiver;
  public editEnergyBalanceSource: EnergyBalanceSource;
  public editEnergyBalanceReceiver: EnergyBalanceReceiver;
  public newEnergySource: EnergyBalanceSource;
  public newReceiver: EnergyBalanceReceiver;
  public energySourceId: string;
  public unitsList: Array<Unit> = null;
  public energySourcesList: Array<EnergyBalanceType>;
  public isDropDownBoxOpened: boolean = false;
  public selectedEnergyTypes: string = null;
  public _urlToDatabase: string;
  public fromDate: string;
  public toDate: string;

  screen(width): string {
    return width < 800 ? 'sm' : 'lg';
  }

  constructor(
    private _apiRequestService: ApiRequestService,
    private _energyBalanceService: EnergyBalanceService,
    private _appConfig: AppConfig,
    private datePipe: DatePipe,
    private _energyBalanceSankeyService: EnergyBalanceSankey
  ) {
    super();
    this._urlToDatabase = this._appConfig.getConfig('DbEnergyDatabaseUrl');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchAuditTimeRange(this.auditId);
    this.fetchCurrency(this.auditId);
    this.fetchEnergyBalanceTypes();
    this._energyBalanceService.chosenInputs
      .pipe(takeUntil(this.onDestroy))
      .subscribe((selectedTypes: Array<EnergyBalanceType>) => {
        if (selectedTypes) {
          this.chosenTypes = selectedTypes;
          this.chosenTypesIds = this.chosenTypes.map((type) => type.id);
          this.fetchEnergyBalanceSources(this.chosenTypes);
        }
      });
  }

  private fetchEnergyBalanceTypes(): void {
    this._apiRequestService.getEnergyBalanceTypes().subscribe((energyTypesList: Array<EnergyBalanceType>) => {
      this.energyBalanceTypes = energyTypesList;
      this.initialFetchEnergyBalance();
    });
  }

  private fetchEnergyBalanceSources(energyTypesList: Array<EnergyBalanceType>): void {
    this._apiRequestService
      .getEnergyBalanceSourcesForAudit(this.auditId)
      .subscribe((energySources: Array<EnergyBalanceSource>) => {
        this.processEnergyData(energyTypesList, energySources);
      });
  }

  private initialFetchEnergyBalance() {
    this._apiRequestService
      .getEnergyBalanceSourcesForAudit(this.auditId)
      .subscribe((energySources: Array<EnergyBalanceSource>) => {
        let energyTypesList = energySources.map((s) => {
          return s.energyBalanceType;
        });
        energyTypesList = this.uniqueEnergyTypes(energyTypesList);
        const aviableEnergyTypes = energyTypesList.map((energyType) =>
          this.energyBalanceTypes.find((type) => type.id === energyType.id)
        );

        this.chosenTypesIds = this.sortEnergyTypesIds(aviableEnergyTypes.map((type) => type.id));
        this.chosenTypes = this.sortEnergyTypes(aviableEnergyTypes);

        this.usedTypes = aviableEnergyTypes;
        this.processEnergyData(this.chosenTypes, energySources);
      });
  }

  private uniqueEnergyTypes(data): EnergyBalanceType[] {
    const unique = [];
    data.forEach((element) => {
      if (!unique.some((e) => e.id === element.id)) {
        unique.push(element);
      }
    });
    return unique;
  }

  private processEnergyData(
    energyTypesList: Array<EnergyBalanceType>,
    energySources: Array<EnergyBalanceSource>
  ): void {
    energyTypesList.forEach((energyType) => {
      energyType.energySources = new Array<EnergyBalanceSource>();
      energySources.forEach((source) => {
        const energySource = this.copyEnergySource(source);
        if (source.energyBalanceType.id === energyType.id) {
          source.energyReceivers.forEach((receiver) => {
            const entityId = this.assignedToFormId.toUpperCase();
            if (
              receiver.baseFormEntity.id.toUpperCase() === entityId ||
              source.baseFormEntity.id.toUpperCase() === entityId
            ) {
              energySource.energyReceivers.push(receiver);
            }
          });
          energyType.energySources.push(energySource);
        }
      });
    });
    this.energySourcesList = energyTypesList;
  }

  private fetchAuditTimeRange(id: string): void {
    this._apiRequestService.getAuditTimeRange(id).subscribe((res: AuditTimeRange) => {
      this.fromDate = this.datePipe.transform(res.fromDate, 'yyyy-MM-dd');
      this.toDate = this.datePipe.transform(res.toDate, 'yyyy-MM-dd');
    });
  }

  private fetchCurrency(id: string): void {
    this._energyBalanceService
      .getAuditCurrency(id)
      .subscribe((currency: Currency) => (this.currencyFormat = `0# ${currency.short}`));
  }

  private copyEnergySource(source: EnergyBalanceSource): EnergyBalanceSource {
    const energySource: EnergyBalanceSource = {
      id: source.id,
      name: source.name,
      energyAmount: source.energyAmount,
      energyCost: source.energyCost,
      unit: source.unit,
      energyBalanceType: source.energyBalanceType,
      baseFormEntity: source.baseFormEntity,
      energyReceivers: [],
    };

    return energySource;
  }

  openSourceEditForm(energySource: EnergyBalanceSource): void {
    this.editEnergyBalanceSource = energySource;
    this.editSource = true;
    this.sourcePopupVisible = true;
  }

  openReceiverForm(energySource: EnergyBalanceSource): void {
    this.energyBalanceReceiver = this.createNewReceiver(energySource.id, this.assignedToFormId);
    this.receiverPopupVisible = true;
    this.editReceiver = false;
  }

  openReceiverEditForm(energyReceiver: EnergyBalanceReceiver): void {
    this.editEnergyBalanceReceiver = energyReceiver;
    this.editReceiver = true;
    this.receiverPopupVisible = true;
  }

  openSourceForm(energyType: EnergyBalanceType): void {
    this.energyBalanceSource = this.createNewSource(energyType);
    this.editSource = false;
    this.sourcePopupVisible = true;
  }

  createNewSource(energyBalanceType: EnergyBalanceType): DtoEnergyBalanceSource {
    const source: DtoEnergyBalanceSource = {
      energyBalanceId: this.energyBalanceId,
      auditId: this.auditId,
      energyTypeId: energyBalanceType.id,
      unitId: '',
      energyAmount: 0,
      energyCost: 0,
      assignedToFormId: this.assignedToFormId,
      name: '',
      id: null,
    };

    return source;
  }

  createNewReceiver(sourceId: string, assignToFormId: string): DtoEnergyBalanceReceiver {
    const receiver: DtoEnergyBalanceReceiver = {
      name: '',
      energyAmount: 0,
      energyCost: 0,
      unitId: '',
      energySourceId: sourceId,
      assignedToFormId: assignToFormId,
      id: null,
    };

    return receiver;
  }

  closedDeleteSourcePopup(closed: boolean): void {
    this.deleteSourcePopupVisible = !closed;
  }

  updatedEnergyReceiver(updatedEnergyReceiver: EnergyBalanceReceiver): void {
    this.energySourcesList.forEach((energyBalanceType) => {
      energyBalanceType.energySources.forEach((source) => {
        if (source.id === updatedEnergyReceiver?.energySourceId) {
          source.energyReceivers.forEach((receiver) => {
            if (receiver.id === updatedEnergyReceiver?.id) {
              receiver.name = updatedEnergyReceiver.name;
              receiver.energyAmount = updatedEnergyReceiver.energyAmount;
              receiver.unit = updatedEnergyReceiver.unit;
              receiver.energyCost = updatedEnergyReceiver.energyCost;
            }
          });
        }
      });
    });
    this._energyBalanceSankeyService.updateEnergyBalanceSankey(this.auditId);
    this.receiverPopupVisible = false;
  }

  updatedEnergySourced(updatedEnergySource: EnergyBalanceSource): void {
    this.energySourcesList.forEach((energyBalanceType) => {
      energyBalanceType.energySources.forEach((source) => {
        if (source.id === updatedEnergySource?.id) {
          source.name = updatedEnergySource.name;
          source.energyAmount = updatedEnergySource.energyAmount;
          source.unit = updatedEnergySource.unit;
          source.energyCost = updatedEnergySource.energyCost;
        }
      });
    });
    this._energyBalanceSankeyService.updateEnergyBalanceSankey(this.auditId);

    this.sourcePopupVisible = false;
  }

  addedEnergyReceiver(addedEnergyReceiver: EnergyBalanceReceiver): void {
    this.energySourcesList.forEach((energyBalanceType) => {
      energyBalanceType.energySources.forEach((energySource) => {
        if (energySource.id === addedEnergyReceiver?.energySourceId) {
          energySource.energyReceivers.push(addedEnergyReceiver);
        }
      });
    });
    this._energyBalanceSankeyService.updateEnergyBalanceSankey(this.auditId);

    this.receiverPopupVisible = false;
  }

  addedEnergySource(addedEnergySource: EnergyBalanceSource): void {
    this.energySourcesList.forEach((energyBalanceType) => {
      if (energyBalanceType.id === addedEnergySource?.energyBalanceType.id) {
        addedEnergySource.energyReceivers = [];
        energyBalanceType.energySources.push(addedEnergySource);
      }
    });
    this._energyBalanceSankeyService.updateEnergyBalanceSankey(this.auditId);
    this.initialFetchEnergyBalance();
    this.sourcePopupVisible = false;
  }

  deleteReceiver(energyReceiver: EnergyBalanceReceiver): void {
    this._apiRequestService
      .deleteEnergyBalanceReceiver(energyReceiver.id)
      .pipe(
        map((res) => {
          this.energySourcesList.forEach((energyBalanceType) => {
            energyBalanceType.energySources.forEach((source) => {
              source.energyReceivers.forEach((receiver) => {
                if (receiver.id === energyReceiver.id) {
                  const removeIndex = source.energyReceivers.map((item) => item.id).indexOf(energyReceiver.id);
                  source.energyReceivers.splice(removeIndex, 1);
                }
              });
            });
          });
          notify({
            message: this._('energy-balance-receiver-deleted'),
            type: 'success',
            displayTime: AppSettings.NOTIFY_DURATION,
            position: 'top center',
          });
          this._energyBalanceSankeyService.updateEnergyBalanceSankey(this.auditId);
        })
      )
      .subscribe();
  }

  deleteSource(energySourceDetails: EnergyBalanceSource): void {
    this._energyBalanceService.getEnergyBalanceSource(energySourceDetails.id).subscribe((res) => {
      if (res.energyReceivers) {
        this.deleteSourcePopupVisible = true;
        this.energySourceToDelete = res;
        this._energyBalanceSankeyService.updateEnergyBalanceSankey(this.auditId);
      } else {
        this.deleteEnergySource(energySourceDetails);
      }
    });
  }

  deleteEnergySource(energySource: EnergyBalanceSource): void {
    this._apiRequestService
      .deleteEnergyBalanceSource(energySource.id)
      .pipe(
        map(() => {
          this.energySourcesList.forEach((energyBalanceType) => {
            if (energyBalanceType.id === energySource?.energyBalanceType.id) {
              const removeIndex = energyBalanceType.energySources.map((item) => item.id).indexOf(energySource.id);

              energyBalanceType.energySources.splice(removeIndex, 1);
              notify({
                message: this._('energy-balance-source-deleted'),
                type: 'success',
                displayTime: AppSettings.NOTIFY_DURATION,
                position: 'top center',
              });
              this._energyBalanceSankeyService.updateEnergyBalanceSankey(this.auditId);
              this.initialFetchEnergyBalance();
              this.deleteSourcePopupVisible = false;
            }
          });
        })
      )
      .subscribe();
  }

  public onInputEnergySelectionChanged(selectedTypes: any): void {
    if (this.tagBoxValueChangeInProgress) {
      this.tagBoxValueChangeInProgress = false;
      return;
    }
    const types = selectedTypes.value;
    const previousValue = selectedTypes.previousValue;
    const deletedValues = previousValue.filter((type) => !types.includes(type));
    const intersection = this.usedTypes.filter((type) => deletedValues.includes(type.id));

    let typesToUpdate = [];

    if (intersection.length > 0) {
      selectedTypes.component.option('value', selectedTypes.previousValue);
      typesToUpdate = this.energyBalanceTypes.filter((type) => selectedTypes.previousValue.includes(type.id));
    } else {
      typesToUpdate = this.energyBalanceTypes.filter((type) => selectedTypes.value.includes(type.id));
    }
    this.tagBoxValueChangeInProgress = true;
    typesToUpdate = this.sortEnergyTypes(typesToUpdate);
    this._energyBalanceService.selectedEnergyTypesChanged(typesToUpdate);
  }

  public isTypeInChosen(type: any): boolean {
    if (!this.usedTypes) {
      return false;
    }
    return this.usedTypes.includes(type);
  }

  public sortEnergyTypesIds(energyTypesIds: Array<string>) {
    return energyTypesIds.sort((a, b) => a.localeCompare(b));
  }

  public sortEnergyTypes(energyTypes: Array<EnergyBalanceType>) {
    return energyTypes.sort((a, b) => a.id.localeCompare(b.id));
  }
}
