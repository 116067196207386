import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ScreenService {
  public isMobile = new BehaviorSubject<boolean>(null);
  public changed = new BehaviorSubject<BreakpointState>(null);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((res: BreakpointState) => {
        this.changed.next(res);
        this.isMobile.next(this.mobileDevice());
      });
  }

  public get sizes() {
    return {
      'screen-x-small': this.breakpointObserver.isMatched(Breakpoints.XSmall),
      'screen-small': this.breakpointObserver.isMatched(Breakpoints.Small),
      'screen-medium': this.breakpointObserver.isMatched(Breakpoints.Medium),
      'screen-large': this.breakpointObserver.isMatched(Breakpoints.Large),
      'screen-x-large': this.breakpointObserver.isMatched(Breakpoints.XLarge),
    };
  }

  public mobileDevice(): boolean {
    if (this.sizes['screen-x-small'] || this.sizes['screen-small'] || this.sizes['screen-medium']) {
      return true;
    } else {
      return false;
    }
  }
}
