import { Component, OnInit, NgModule } from '@angular/core';
import { Localizable } from '../../../locale/localizable';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import notify from 'devextreme/ui/notify';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { AppSettings } from '../../../AppSettings';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent extends Localizable implements OnInit {
  constructor(private authenticationService: AuthenticationService) {
    super();
  }

  private centerTop = 'center top';
  public noWhiteSpaces = /^\S*$/;
  private email: string;

  stylingMode = 'filled';

  ngOnInit(): void {}

  onSendResetMailClick() {
    if (this.email && this.email.replace(/\s/g, '').length) {
      this.email = this.email.trim();
      this.sendResetMailClick();
    } else {
      this.onResetPasswordNotCorrectEmailLog();
    }
  }

  sendResetMailClick() {
    if (this.email) {
      this.authenticationService.resetPasswordByEmail(this.email).subscribe(
        (result) => {
          this.onResetPasswordSuccessLog();
          this.authenticationService.setReturnToSignInpage(true);
        },
        (error) => {
          switch (error) {
            case AppSettings.HTTP_STATUS_CODE_NOT_FOUND:
              this.onResetPasswordNoUserLog();
              break;
            case AppSettings.HTTP_STATUS_CODE_CONFLICT:
              this.onResetPasswordCouldNotSetEmailLog();
              break;
            default:
              this.onResetPasswordFailedLog();
              break;
          }
        }
      );
    }
  }

  onLoginClick(): void {
    this.authenticationService.setReturnToSignInpage(true);
  }

  onEmailValueChanged(event) {
    this.email = event.value;
  }

  onResetPasswordSuccessLog() {
    notify(
      {
        message: this._('resetPassword-onSendRequestEmailSuccessMessage'),
        width: 'auto',
        position: {
          my: this.centerTop,
          at: this.centerTop,
        },
      },
      'success',
      AppSettings.NOTIFY_DURATION
    );
  }

  onResetPasswordFailedLog() {
    notify(
      {
        message: this._('resetPassword-onSendRequestEmailFailedMessage'),
        width: 'auto',
        position: {
          my: this.centerTop,
          at: this.centerTop,
        },
      },
      'warning',
      AppSettings.NOTIFY_DURATION
    );
  }

  onResetPasswordNoUserLog() {
    notify(
      {
        message: this._('resetPassword-onSendResetEmailNoUserMessage'),
        width: 'auto',
        position: {
          my: this.centerTop,
          at: this.centerTop,
        },
      },
      'warning',
      AppSettings.NOTIFY_DURATION
    );
  }

  onResetPasswordNotCorrectEmailLog() {
    notify(
      {
        message: this._('resetPassword-onSendResetEmailNotCorrectEmailMessage'),
        width: 'auto',
        position: {
          my: this.centerTop,
          at: this.centerTop,
        },
      },
      'warning',
      AppSettings.NOTIFY_DURATION
    );
  }

  onResetPasswordCouldNotSetEmailLog() {
    notify(
      {
        message: this._('resetPassword-onResetPasswordCouldNotSetEmailLog'),
        width: 'auto',
        position: {
          my: this.centerTop,
          at: this.centerTop,
        },
      },
      'warning',
      AppSettings.NOTIFY_DURATION
    );
  }
}

@NgModule({
  imports: [DxButtonModule, DxTextBoxModule, DxValidatorModule, CommonModule, FormsModule],
  declarations: [PasswordResetComponent],
  exports: [PasswordResetComponent],
})
export class PasswordResetModule {}
