import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { Medium } from 'src/app/shared/models/area/types/boiler.model';
import {
  HeatRecovery,
  HeatRecoveryPlace,
  HeatRecoveryType,
} from 'src/app/shared/models/area/types/heat-recovery.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-heat-recovery',
  templateUrl: './heat-recovery.component.html',
  styleUrls: ['./heat-recovery.component.scss'],
})
export class HeatRecoveryComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  heatRecovery: HeatRecovery = null;

  places: ArrayStore;
  heatRecoveryMediumTypes: ArrayStore;
  types: ArrayStore;
  mediumTypes: ArrayStore;

  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getHeatRecoveryPlaces().subscribe((res: Array<HeatRecoveryPlace>) => {
      this.places = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getHeatRecoveryTypes().subscribe((res: Array<HeatRecoveryType>) => {
      this.types = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getHeatRecoveryMediumTypes().subscribe((res: Array<HeatRecoveryPlace>) => {
      this.heatRecoveryMediumTypes = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getBoilerMediumList().subscribe((res: Array<Medium>) => {
      this.mediumTypes = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
