import { QuestionnaireService } from '../questionnaire.service';
import { NoteQuestion } from './note-question';
import { Question } from './questions.models';

export class SkippableQuestion extends NoteQuestion {
  skipQuestionButtonShow: boolean;
  skipQuestionButtonDisabled: boolean;
  skipButtonText: string;

  constructor(
    protected _questionnaireService: QuestionnaireService,
    dictionary: string
  ) {
    super(_questionnaireService, dictionary);
  }

  public skipQuestion(question: Question) {
    this.skipQuestionButtonShow = false;
    this._questionnaireService.skipQuestion(question);
  }

  public skipQuestionButtonText(question: Question) {
    if (this._questionnaireService.canQuestionBeSkipped(question)) {
      this.skipQuestionButtonDisabled = false;
      this.skipQuestionButtonShow = true;
      this.skipButtonText = this._('skip-question-button');
      return;
    }
    if (this._questionnaireService.isLastQuestion(question)) {
      this.skipQuestionButtonDisabled = true;
      this.skipQuestionButtonShow = true;
      this.skipButtonText = this._('skip-question-button-last-question');
      return;
    }
    if (this._questionnaireService.isSkippingToEndQuestion(question)) {
      this.skipQuestionButtonDisabled = true;
      this.skipQuestionButtonShow = true;
      this.skipButtonText = this._('skip-question-button-unskippable');
      return;
    }

    this.skipQuestionButtonShow = false;
    this.skipButtonText = '';
    return;
  }
}
