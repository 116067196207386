import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, NgModule, OnDestroy, OnInit } from '@angular/core';
import {
  DxButtonModule,
  DxToolbarModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxMenuModule,
  DxPopupModule,
} from 'devextreme-angular';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { Localizable } from '../../../locale/localizable';
import { ScreenService } from '../../services';
import { AppSettings } from '../../../AppSettings';
import { LanguageService } from '../../services/language.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent extends Localizable implements OnInit, OnDestroy {
  public showDropdown: boolean = false;

  public accountDropdownIcon: string = AppSettings.ACCOUNT_DROPDOWN_MENU_ICON_DOWN;

  public isMobile: boolean = false;

  public currentUser;

  public menuDataSource = [
    {
      id: '1',
      name: 'languages',
      icon: 'fi fi-rr-language',
      items: [
        {
          id: '1_1',
          name: AppSettings.LANG_PL,
          image: 'assets/img/poland.svg',
          selected: false,
        },
        {
          id: '1_2',
          name: AppSettings.LANG_EN,
          image: 'assets/img/united-kingdom.svg',
          selected: false,
        },
      ],
    },
    {
      id: '2',
      name: 'logout',
      icon: 'fi fi-rr-exit',
    },
  ];

  public selectedLanguage = null;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private screenService: ScreenService,
    private elementRef: ElementRef,
    private languageService: LanguageService
  ) {
    super('dictionary');
    this.currentUser = authenticationService.getDecodedCurrentUser();
    this.languageService.setLanguage();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const dropdownMenu = this.elementRef.nativeElement.querySelector('.dropdown-menu');
    const dropdownButton = this.elementRef.nativeElement.querySelector('.button');

    if (dropdownButton && dropdownButton.contains(event.target)) {
      return;
    }

    if (dropdownMenu && !dropdownMenu.contains(event.target)) {
      this.hideDropdownMenu();
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.screenService.changed.pipe(takeUntil(this.onDestroy)).subscribe(() => this.updateComponentView());
    this.languageService.languageIdSubject.pipe(takeUntil(this.onDestroy)).subscribe((languageId) => {
      this.selectedLanguage = this.languageService.languages.find((item) => item.id === languageId);
      this.changeLanguageSelection();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public changeLanguageSelection(): void {
    this.menuDataSource[0].items.forEach((item) => {
      if (item.name === this.selectedLanguage.name) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
  }

  private updateComponentView(): void {
    this.isMobile = this.screenService.sizes['screen-small'] || this.screenService.sizes['screen-x-small'];
  }

  toggleDropdown(event: any) {
    this.showDropdown = !this.showDropdown;
    if (this.showDropdown) {
      this.showDropdownMenu();
    } else {
      this.hideDropdownMenu();
    }
  }

  showDropdownMenu() {
    this.showDropdown = true;
    this.accountDropdownIcon = AppSettings.ACCOUNT_DROPDOWN_MENU_ICON_UP;
  }

  hideDropdownMenu() {
    this.showDropdown = false;
    this.accountDropdownIcon = AppSettings.ACCOUNT_DROPDOWN_MENU_ICON_DOWN;
  }

  logout(): void {
    this.authenticationService.logout();
    this.router.navigateByUrl('/login');
  }

  itemClick(data: any) {
    switch (data.itemData.name) {
      case 'logout':
        this.logout();
        break;
      case AppSettings.LANG_EN: {
        const chosenLanguage = this.languageService.languages.find((item) => item.name === AppSettings.LANG_EN);
        this.languageService.onLanguageChange({ itemData: chosenLanguage });
        break;
      }
      case AppSettings.LANG_PL: {
        const chosenLanguage = this.languageService.languages.find((item) => item.name === AppSettings.LANG_PL);
        this.languageService.onLanguageChange({ itemData: chosenLanguage });
        break;
      }
      default:
        break;
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxToolbarModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxMenuModule,
    DxPopupModule,
  ],
  declarations: [AccountComponent],
  exports: [AccountComponent],
})
export class AccountModule {}
