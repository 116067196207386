import { BuiltinType } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { Building, BuildingType, ErectingBuildingTechnology } from 'src/app/shared/models/area/types/building.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss'],
})
export class BuildingComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  building: Building = null;

  @Input()
  templateMode: boolean = false;

  docVariableCode: string = null;

  public typesList: ArrayStore;
  public erectingTechnologiesList: ArrayStore;
  public currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    if (this.templateMode) {
      this._apiRequestService.getBuildingDocVariableCode().subscribe((res) => {
        this.docVariableCode = res;
        this.building = this.getNewEmptyBuilding();
      });
    } else {
      this.currentYear = new Date().getFullYear();

      this._apiRequestService
        .getAreaTypeBuildingErectingTechnologies()
        .subscribe((res: Array<ErectingBuildingTechnology>) => {
          this.erectingTechnologiesList = new ArrayStore({
            key: 'id',
            data: res,
          });
        });

      this._apiRequestService.getAreaTypeBuildingTypes().subscribe((res: Array<BuildingType>) => {
        this.typesList = new ArrayStore({
          key: 'id',
          data: res,
        });
      });
    }
  }

  private getNewEmptyBuilding(): Building {
    return {
      name: '',
      description: '',
      buildingType: null,
      yearOfConstruction: 1950,
      area: 0,
      cubature: 0,
      erectingBuildingTechnologie: null,
      energyConsumptionIndicator: 0,
      isolated: false,
      windowsExchangable: false,
      energyCertificate: false,
      conservatorProtection: false,
      useTime: false,
      conclusion: '',
    };
  }
}
