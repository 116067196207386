<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="row">
  <div class="column-left">
    <p>{{ _('template-file-list-title') }}</p>
    <dx-list
      [dataSource]="templatesList"
      keyExpr="id"
      displayExpr="name"
      noDataText="{{ _('template-file-no-data-text') }}"
      width="100%"
      (onItemDeleted)="onTemplateDeleted($event)"
      allowItemDeleting="true"
    ></dx-list>
  </div>

  <div
    *ngIf="formTypeCode && templateTypes"
    class="column-right"
  >
    <div class="variables-names-container">
      <p>{{ _('template-file-list-variables') }}</p>
      <div class="variables">
        <ul *ngFor="let type of templateTypes | keyvalue">
          <li>{{ formTypeCode }}{{ type.value.name }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="upload-button">
  <dx-file-uploader
    class="contained-uploader-focus-hover"
    [(uploadUrl)]="uploadTemplateUrl"
    accept=".docx,.doc"
    labelText=""
    [selectButtonText]="_('load-template-button-text')"
    uploadMode="instantly"
    [multiple]="false"
    [invalidFileExtensionMessage]="_('template-upload-invalid-extension-message')"
    [invalidMaxFileSizeMessage]="_('template-upload-invalid-max-size-message')"
    [uploadAbortedMessage]="_('template-upload-aborted-message')"
    [uploadFailedMessage]="_('template-upload-failed-message')"
    [uploadedMessage]="_('template-upload-success-message')"
    maxFileSize="10485760"
    (onUploaded)="getTemplateList()"
    (onUploadError)="handleUploadError($event)"
    [uploadHeaders]="{
      Authorization: token,
    }"
  >
  </dx-file-uploader>
</div>
