<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-popup
  title=" Usunięcie tego źródła spowoduje usunięcie przypisanych mu odbiorników"
  [fullScreen]="false"
  [width]="popupWidth + '%'"
  [height]="popupHeight + '%'"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [visible]="true"
  [showCloseButton]="true"
  (onHidden)="formClosed()"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <dx-responsive-box>
    <dxi-row [ratio]="1"></dxi-row>
    <dxi-row [ratio]="1"></dxi-row>
    <dxi-row [ratio]="1"></dxi-row>
    <dxi-row [ratio]="1"></dxi-row>
    <dxi-row [ratio]="1"></dxi-row>

    <dxi-col [ratio]="1"></dxi-col>
    <dxi-col [ratio]="1"></dxi-col>

    <dxi-item>
      <dxi-location
        [row]="0"
        [col]="0"
      ></dxi-location>
      <dx-responsive-box>
        <dxi-row [ratio]="1"></dxi-row>
        <dxi-row [ratio]="1"></dxi-row>
        <dxi-row [ratio]="1"></dxi-row>
        <dxi-row [ratio]="1"></dxi-row>
        <dxi-item>
          <dxi-location [row]="0"></dxi-location>
          <div *dxTemplate>
            <div>Nazwa:</div>
            <dx-text-box
              [(value)]="energyBalanceSource.name"
              stylingMode="outlined"
              [readOnly]="true"
            ></dx-text-box>
          </div>
        </dxi-item>
        <dxi-item>
          <dxi-location [row]="1"></dxi-location>

          <div>Ilość:</div>
          <dx-text-box
            [(value)]="energyBalanceSource.energyAmount"
            stylingMode="outlined"
            [readOnly]="true"
          ></dx-text-box>
        </dxi-item>
        <dxi-item>
          <dxi-location [row]="2"></dxi-location>
          <div *dxTemplate>
            <div>Jednostka:</div>
            <dx-text-box
              [(value)]="energyBalanceSource.unit.shortName"
              stylingMode="outlined"
              [readOnly]="true"
            ></dx-text-box>
          </div>
        </dxi-item>
        <dxi-item>
          <dxi-location [row]="3"></dxi-location>
          <div *dxTemplate>
            <div class="item-top-empty-space">
              <div>Poziom: {{ energyBalanceSource.baseFormEntity.name }}</div>
            </div>
          </div>
        </dxi-item>
      </dx-responsive-box>
    </dxi-item>
    <dxi-item style="margin-left: 1em">
      <dxi-location
        [row]="0"
        [rowspan]="3"
        [col]="1"
        screen="lg"
      ></dxi-location>
      <dxi-location
        [row]="0"
        [rowspan]="3"
        [col]="1"
        screen="sm"
      ></dxi-location>
      <div
        class="responsive-paddings"
        style="border-left: 10px solid #c3d500; border-radius: 10px; margin-top: 20px"
        *ngFor="let energyReceiversDetails of energyBalanceSource?.energyReceivers"
      >
        <dx-responsive-box>
          <dxi-row [ratio]="1"></dxi-row>
          <dxi-row [ratio]="1"></dxi-row>
          <dxi-row [ratio]="1"></dxi-row>
          <dxi-row [ratio]="1"></dxi-row>

          <dxi-item>
            <dxi-location [row]="0"></dxi-location>
            <div *dxTemplate>
              <div>Nazwa:</div>
              <dx-text-box
                [(value)]="energyReceiversDetails.name"
                stylingMode="outlined"
                [readOnly]="true"
              ></dx-text-box>
            </div>
          </dxi-item>
          <dxi-item>
            <dxi-location [row]="1"></dxi-location>
            <div *dxTemplate>
              <div>Ilość:</div>
              <dx-text-box
                [(value)]="energyReceiversDetails.energyAmount"
                stylingMode="outlined"
                [readOnly]="true"
              ></dx-text-box>
            </div>
          </dxi-item>
          <dxi-item>
            <dxi-location [row]="2"></dxi-location>
            <div *dxTemplate>
              <div>Jednostka:</div>
              <dx-text-box
                [(value)]="energyReceiversDetails.unit.shortName"
                stylingMode="outlined"
                [readOnly]="true"
              ></dx-text-box>
            </div>
          </dxi-item>
          {{ energyReceiversDetails.name }}
          <dxi-item>
            <dxi-location [row]="3"></dxi-location>
            <div *dxTemplate>
              <div>Poziom: {{ energyReceiversDetails.baseFormEntity.name }}</div>
            </div>
          </dxi-item>
        </dx-responsive-box>
      </div>
    </dxi-item>

    <dxi-item>
      <dxi-location
        [row]="4"
        [col]="1"
      ></dxi-location>
      <dx-button
        text="Usuń źródło wraz z odbiornikami"
        icon="fi fi-rr-trash"
        stylingMode="outlined"
        type="danger"
        (onClick)="deleteSource()"
        class="outlined-button-focus-hover"
      ></dx-button>
    </dxi-item>
  </dx-responsive-box>
</dx-popup>
