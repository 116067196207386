import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditTreeNavigation, FormType } from '../../shared/models/audit/audit-tree-navigation.model';
import { MobileTabPanelOptionType, Tab } from '../../shared/models/mobile-tab-panel/mobile-tab-panel-option.model';
import { ScrollService } from '../../shared/services/scroll.service';
import { TreeNavigationService } from '../../shared/services/tree-navigation.service';
import { AppSettings } from '../../AppSettings';
import { FormDetectionChangesService } from '../../shared/services/form-detection-changes.service';
import { CanComponentDeactivate } from '../../core/guards/can-deactivate.guard';
import { EnterpriseFormComponent } from '../../shared/components/forms/enterprise-form/enterprise-form.component';
import { DepartmentFormComponent } from '../../shared/components/forms/department-form/department-form/department-form.component';
import { AreaFormComponent } from '../../shared/components/forms/area-form/area-form/area-form.component';
import { Localizable } from '../../locale/localizable';
import { FormMenuActions } from '../../shared/models/FormMenuActions.enum';
import { ScreenService } from '../../shared/services';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CloseResult } from '../../shared/components/questionnaire/exit-confirmation-popup/exit-confirmation-popup.component';
import { CanDeactivateGuardService } from '../../shared/services/can-deactivate-guard.service';
import { DxDrawerComponent } from 'devextreme-angular';
import { takeUntil } from 'rxjs/operators';
import { AuditService } from '../../shared/components/forms/audit-form/audit.service';
import { EnterpriseFormService } from '../../shared/components/forms/enterprise-form/enterprise-form.service';
import { AreaFormService } from '../../shared/components/forms/area-form/area-form/area-form.service';
import { DepartmentFormService } from '../../shared/components/forms/department-form/department-form/department-form.service';
import { EnergyBalanceSankey } from '../../shared/components/energy-balance-chart/energy-balance-chart/energy-balance-sankey.service';
import { Audit } from '../../shared/models/audit/audit.model';

export enum AuditCreatorVersion {
  Hide,
  Default,
  NoDepartments,
  FewDepartments,
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends Localizable implements OnInit, OnDestroy, CanComponentDeactivate {
  public isAuditFinishedFlag = false;
  @ViewChild('drawer', { static: false }) drawer: DxDrawerComponent;

  public actions: typeof FormMenuActions = FormMenuActions;
  public showCreator: boolean;
  public creatorTitle: string = this._('audit-creator-title');
  public creatorMessage: string;
  public creatorMainButtonText: string;
  public creatorSecondaryButtonText: string = this._('audit-creator-secondary-button-text');
  public creatorMainButtonFunction: () => void;
  displayQuestionnairePopup: boolean;
  displayCreateDepartmentPopup: boolean;
  public departmentRoot: string;
  private _auditCreatorSet: boolean;
  private _lastCreatorValue: AuditCreatorVersion;

  private setCreatorNoDepartments() {
    this.creatorMessage = this._('audit-creator-no-department-message');
    this.creatorMainButtonText = this._('audit-creator-no-department-main-button-text');
    this.showCreator = true;
  }

  private setCreatorFewDeparments() {
    this.creatorMessage = this._('audit-creator-few-department-message');
    this.creatorMainButtonText = this._('audit-creator-few-department-main-button-text');
    this.showCreator = true;
  }

  private setCreatorDefault() {
    this.creatorMessage = this._('audit-creator-default-message');
    this.creatorMainButtonText = this._('audit-creator-questionnaire-main-button-text');
    this.showCreator = true;
  }

  canBeDeactivated(): boolean {
    return true;
  }

  saveForm(formtype: FormType) {
    const saveAction = FormMenuActions.Save;
    switch (formtype) {
      case FormType.Audit:
        this._auditService.selectedAction({ itemData: { key: saveAction } });
        break;
      case FormType.Enterprise:
        this._enterpriseService.selectedAction({
          itemData: { key: saveAction },
        });
        break;
      case FormType.Department:
        this._departmentService.selectedAction({
          itemData: { key: saveAction },
        });
        break;
      case FormType.Area:
        this._areaService.selectedAction({ itemData: { key: saveAction } });
        break;
      default:
        break;
    }
  }

  public screenWidth: string;
  public mobileTabs: Array<Tab>;
  public currentAudit: Audit;
  public showButtons: boolean = false;
  public treeNavigationVisible: boolean = true;
  public formChanged: boolean = false;
  public formVisible: boolean = true;
  public formType: FormType;
  public formTypeBefore: FormType;
  public auditId: string;
  public enterpriseId: string;
  public departmentId: string;
  public areaId: string;
  public formId: string;
  public formIdBefore: string;
  public formName: string;
  public navigationDrawerOpened: boolean = true;
  public isMobile: boolean = false;
  public showFooter = false;
  public drawerViewMode: string = 'desktop';
  public expandTreeIcon: string = 'fi fi-rr-angle-double-small-right';
  public isLiteUser = false;
  public formExitPopupVisible = false;
  public toggleAuditClosePopupVisible = false;
  public formSelectorVisible = false;
  public isArealastLeaf = true;

  constructor(
    private _treeNavigationService: TreeNavigationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _scrollService: ScrollService,
    private _changeDetecetor: ChangeDetectorRef,
    private _formDetectionChangesService: FormDetectionChangesService,
    private _screenService: ScreenService,
    private _authenticationService: AuthenticationService,
    private _canDeactivateGuardService: CanDeactivateGuardService,
    private _auditService: AuditService,
    private _enterpriseService: EnterpriseFormService,
    private _departmentService: DepartmentFormService,
    private _areaService: AreaFormService,
    private _ref: ChangeDetectorRef,
    private _sankeyService: EnergyBalanceSankey
  ) {
    super('dictionary');
    this.updateComponentView();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._scrollService.updateBlockScroll(false);
  }

  private updateComponentView(): void {
    this.isMobile = this._screenService.sizes['screen-small'] || this._screenService.sizes['screen-x-small'];
    this._scrollService.updateBlockScroll(this.isMobile);
    this.setDrawerClassMode();
    this.changeChevronDirection();
  }

  async initNavigation(): Promise<boolean> {
    this.enterpriseId = this._route.snapshot.firstChild.params['enterpriseId'];
    this.departmentId = this._route.snapshot.firstChild.params['departmentId'];
    this.areaId = this._route.snapshot.firstChild.params['areaId'];
    this.auditId = this._route.snapshot.params['auditId'];

    this._auditService.getCurrentAudit(this.auditId).subscribe((res) => {
      this.currentAudit = res;
      this.isAuditFinishedFlag = this.currentAudit.isFinished;
    });

    await this._treeNavigationService.getAuditNavigationTree(this.auditId).toPromise();
    const navigationTree = this._treeNavigationService._navigationTreeData.getValue();
    const urlIds = [this.auditId];
    if (this.enterpriseId) {
      urlIds.push(this.enterpriseId);
    } else if (this.departmentId) {
      urlIds.push(this.departmentId);
    } else if (this.areaId) {
      urlIds.push(this.areaId);
    }

    const areUrlsConsitent = urlIds.every((urlFormId) =>
      navigationTree.some((navigationFrom) => navigationFrom.id === urlFormId.toUpperCase())
    );
    if (!areUrlsConsitent) {
      this._router.navigate(['pages/audits-list']);
      return false;
    } else {
      if (this.enterpriseId) {
        this.formType = FormType.Enterprise;
        this.formId = this.enterpriseId;
        this._treeNavigationService.setDisplayedRowKey(this.enterpriseId);
        this._treeNavigationService.setSelectedRowKey(this.enterpriseId);
      } else if (this.departmentId) {
        this.formType = FormType.Department;
        this.formId = this.departmentId;
        this._treeNavigationService.setDisplayedRowKey(this.departmentId);
        this._treeNavigationService.setSelectedRowKey(this.departmentId);
      } else if (this.areaId) {
        this.formType = FormType.Area;
        this.formId = this.areaId;
        this._treeNavigationService.setDisplayedRowKey(this.areaId);
        this._treeNavigationService.setSelectedRowKey(this.areaId);
        this._treeNavigationService.expandAllNodesFrom(this.areaId);
      } else {
        this.formType = FormType.Audit;
      }
      this.formTypeBefore = this.formType;
      this.formIdBefore = this.formId;
      return true;
    }
  }

  initNavigationHandle(): void {
    this._treeNavigationService.navigateToFormId
      .pipe(takeUntil(this.onDestroy))
      .subscribe((selectedForm: AuditTreeNavigation) => {
        if (selectedForm) {
          const lastUrlSegment = this._router.url.split('?')[0].split('/').pop();

          let wasSuccessRouted;
          if (selectedForm.formType === FormType.Audit) {
            this.auditId = selectedForm.id;
            wasSuccessRouted = this._router.navigate(['..', this.auditId, lastUrlSegment], {
              relativeTo: this._route,
            });
          } else if (selectedForm.formType === FormType.Enterprise) {
            this.enterpriseId = selectedForm.id;

            wasSuccessRouted = this._router.navigate(['enterprise', this.enterpriseId, lastUrlSegment], {
              relativeTo: this._route,
            });
          } else if (selectedForm.formType === FormType.Department) {
            this.departmentId = selectedForm.id;
            wasSuccessRouted = this._router.navigate(['department', this.departmentId, lastUrlSegment], {
              relativeTo: this._route,
            });
          } else if (selectedForm.formType === FormType.Area) {
            this.areaId = selectedForm.id;
            wasSuccessRouted = this._router.navigate(['area', this.areaId, lastUrlSegment], {
              relativeTo: this._route,
            });
          }
          wasSuccessRouted.then((result) => {
            if (result) {
              this.formTypeBefore = this.formType;
              this.formIdBefore = this.formId;
              this.formId = selectedForm.id;
              this.formType = selectedForm.formType;
              this.formName = selectedForm.name;
            }
            this._treeNavigationService.setDisplayedRowKey(this.formId);
            this._treeNavigationService.setSelectedRowKey(this.formId);
          });
        }
      });
  }

  initLiteUser(): void {
    const user = this._authenticationService.getDecodedUser();
    if (user != null && user.role === AppSettings.USER_LITE_ROLE_NAME) {
      this.isLiteUser = true;
    }
  }

  initCollapseNavSideMenuHandle(): void {
    this._treeNavigationService.collapseNavSideMenu$.pipe(takeUntil(this.onDestroy)).subscribe((response) => {
      if (response) {
        this.navigationDrawerOpened = false;
        this.changeChevronDirection();
      }
    });
  }

  initComponentRenderedHandle(): void {
    this._treeNavigationService.componentRendered.pipe(takeUntil(this.onDestroy)).subscribe((form: any) => {
      if (form instanceof AreaFormComponent && !form.currentArea?.type.lastLeaf) {
        this.isArealastLeaf = false;
        this._ref.detectChanges();
      } else {
        this.isArealastLeaf = true;
        this._ref.detectChanges();
      }
      if (form instanceof DepartmentFormComponent && this.displayQuestionnairePopup) {
        // department component open questionnaire
        form.showQuestionnaire(true);
        this.displayQuestionnairePopup = false;
      } else if (form instanceof EnterpriseFormComponent && this.displayCreateDepartmentPopup) {
        // department component open questionnaire
        form.createDepartmentPopupVisible = true;
        this.displayCreateDepartmentPopup = false;
      }
    });
  }

  // Load properly form and tree from url.
  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    if (this.isMobile) {
      this.navigationDrawerOpened = false;
      this.changeChevronDirection();
    }

    const properUrl = await this.initNavigation();
    if (!properUrl) {
      return;
    }
    this.initNavigationHandle();

    this._screenService.changed.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.updateComponentView();
    });

    this.initLiteUser();

    this.initCollapseNavSideMenuHandle();

    this._formDetectionChangesService.formChanged.pipe(takeUntil(this.onDestroy)).subscribe((changed) => {
      this.formChanged = changed;
    });

    this._treeNavigationService.exitFormVisible.pipe(takeUntil(this.onDestroy)).subscribe((visible) => {
      this.formExitPopupVisible = visible;
    });
    this._treeNavigationService.exitGuardFormVisible.pipe(takeUntil(this.onDestroy)).subscribe((visible) => {
      this.formExitPopupVisible = visible;
    });
    this._treeNavigationService.toggleAuditClosePopupVisible.pipe(takeUntil(this.onDestroy)).subscribe((visible) => {
      this.toggleAuditClosePopupVisible = visible;
    });

    this._treeNavigationService.exitFormSaveComponents
      .pipe(takeUntil(this.onDestroy))
      .subscribe((formtype: FormType) => this.saveForm(formtype));
    this._treeNavigationService.showCreator.pipe(takeUntil(this.onDestroy)).subscribe((value: AuditCreatorVersion) => {
      this.creatorProcess(value);
    });

    this.initComponentRenderedHandle();

    this._auditService.isLoaded.pipe(takeUntil(this.onDestroy)).subscribe((isLoaded) => {
      this.childFormLoaded(isLoaded);
    });

    this._auditService.isFinished.pipe(takeUntil(this.onDestroy)).subscribe((isAuditFinished) => {
      this.isAuditFinishedFlag = isAuditFinished;
    });

    this._enterpriseService.isLoaded.pipe(takeUntil(this.onDestroy)).subscribe((isLoaded) => {
      this.childFormLoaded(isLoaded);
    });

    this._enterpriseService.isFinished.pipe(takeUntil(this.onDestroy)).subscribe((isAuditFinished) => {
      this.isAuditFinishedFlag = isAuditFinished;
    });

    this._departmentService.isLoaded.pipe(takeUntil(this.onDestroy)).subscribe((isLoaded) => {
      this.childFormLoaded(isLoaded);
    });

    this._departmentService.isFinished.pipe(takeUntil(this.onDestroy)).subscribe((isAuditFinished) => {
      this.isAuditFinishedFlag = isAuditFinished;
    });

    this._areaService.isLoaded.pipe(takeUntil(this.onDestroy)).subscribe((isLoaded) => {
      this.childFormLoaded(isLoaded);
    });

    this._areaService.isFinished.pipe(takeUntil(this.onDestroy)).subscribe((isAuditFinished) => {
      this.isAuditFinishedFlag = isAuditFinished;
    });
  }

  private creatorProcess(value: AuditCreatorVersion) {
    this._lastCreatorValue = value;
    if (value === AuditCreatorVersion.Default) {
      this.setCreatorDefault();
      this.creatorMainButtonFunction = () => {
        // check changes
        if (this.canBeDeactivated() === false) {
          // active changes - show dialog
          this._auditCreatorSet = true;
          this._treeNavigationService.setExitFormVisible(true);
          return;
        }

        // no changes
        this.creatorProcessOneDepartment();
      };
    } else if (value === AuditCreatorVersion.NoDepartments) {
      this.setCreatorNoDepartments();
      this.creatorMainButtonFunction = () => {
        // check changes
        if (this.canBeDeactivated() === false) {
          // active changes - show dialog
          this._auditCreatorSet = true;
          this._treeNavigationService.setExitFormVisible(true);
          return;
        }

        // no changes
        this.creatorProcessNoDepartments();
      };
    } else if (value === AuditCreatorVersion.FewDepartments) {
      this.setCreatorFewDeparments();
      const enterprise = this._treeNavigationService.getNavigationItems(FormType.Enterprise);
      this.departmentRoot = enterprise[0].id;

      this.creatorMainButtonFunction = () => {
        // check changes
        if (this.canBeDeactivated() === false) {
          // active changes - show dialog
          this._auditCreatorSet = true;
          this._treeNavigationService.setExitFormVisible(true);
          return;
        }

        // no changes
        this.creatorProcessMultipleDeparments();
      };
    } else {
      this.showCreator = false;
    }
  }

  private creatorProcessMultipleDeparments() {
    // open popup and ask for department to go
    this.formSelectorVisible = true;
  }

  private creatorProcessNoDepartments() {
    const enterprise = this._treeNavigationService.getNavigationItems(FormType.Enterprise);
    // tree navigation go to single department

    if (this.formType !== FormType.Enterprise) {
      this._treeNavigationService.setNavigationToFormId(enterprise[0]);
      this.displayCreateDepartmentPopup = true;
    } else {
      this.performFormAction(FormMenuActions.Add);
      this.displayCreateDepartmentPopup = false;
    }
  }

  private creatorProcessOneDepartment() {
    const departments = this._treeNavigationService.getNavigationItems(FormType.Department);
    // tree navigation go to single department
    this.displayQuestionnairePopup = true;
    this._treeNavigationService.setNavigationToFormId(departments[0]);
  }

  public creatorSecondaryButtonFunction() {
    this._treeNavigationService.setHideCreatorHint(true);
    this.showCreator = false;
  }

  public onFormSelectorPopupHiding() {
    this.formSelectorVisible = false;
  }

  public onDepartmentFormSelected(department: AuditTreeNavigation) {
    this.displayQuestionnairePopup = true;
    this.formSelectorVisible = false;
    this._treeNavigationService.setNavigationToFormId(department);
  }

  public childFormLoaded(isloaded: { value: boolean; formtype: FormType }): void {
    if (this.formType === isloaded.formtype && isloaded.value) {
      this.showFooter = isloaded.value;
      this.showButtons = isloaded.value;
      this._changeDetecetor.detectChanges();
    }
  }

  public showHideTreeNavigation(): void {
    this.treeNavigationVisible = !this.treeNavigationVisible;
  }

  public performFormAction(action: FormMenuActions): void {
    switch (this.formType) {
      case FormType.Audit:
        this._auditService.selectedAction({ itemData: { key: action } });
        break;
      case FormType.Enterprise:
        this._enterpriseService.selectedAction({ itemData: { key: action } });
        break;
      case FormType.Department:
        this._departmentService.selectedAction({ itemData: { key: action } });
        break;
      case FormType.Area:
        this._areaService.selectedAction({ itemData: { key: action } });
        break;
      default:
        break;
    }
  }

  public changeMobilePanelToForm(): void {
    this.formVisible = true;
    this.treeNavigationVisible = false;
  }

  public changeMobilePanelToTreeNavigation(): void {
    this.formVisible = false;
    this.treeNavigationVisible = true;
  }

  public selectTab(selectedTabOption): void {
    if (selectedTabOption.itemData.type === MobileTabPanelOptionType.TreeNavigation) {
      this.changeMobilePanelToTreeNavigation();
    }

    if (selectedTabOption.itemData.type === MobileTabPanelOptionType.FormContent) {
      this.changeMobilePanelToForm();
    }
  }

  public changeDrawerOpenedState(): void {
    this.navigationDrawerOpened = true;
  }

  public navigationMenuButton(): void {
    if (!this.navigationDrawerOpened) {
      this._sankeyService.performDrawerAction({ itemData: { key: FormMenuActions.OpenDrawer } });
    } else {
      this._sankeyService.performDrawerAction({
        itemData: { key: FormMenuActions.CloseDrawer },
      });
    }
    this.navigationDrawerOpened = !this.navigationDrawerOpened;
    this.changeChevronDirection();
  }

  private setDrawerClassMode(): void {
    this.drawerViewMode = this.isMobile ? 'mobile' : 'desktop';
  }

  private changeChevronDirection(): void {
    if (this.navigationDrawerOpened) {
      if (this.isMobile) {
        this.expandTreeIcon = AppSettings.NAVIGATION_TREE_ICON_DOWN;
      } else {
        this.expandTreeIcon = AppSettings.NAVIGATION_TREE_ICON_RIGHT;
      }
    } else {
      if (this.isMobile) {
        this.expandTreeIcon = AppSettings.NAVIGATION_TREE_ICON_UP;
      } else {
        this.expandTreeIcon = AppSettings.NAVIGATION_TREE_ICON_LEFT;
      }
    }
  }

  public setFormName(name: string): void {
    this.formName = name;
    this._changeDetecetor.detectChanges();
  }

  private exitPopupHandleWithoutSave(): boolean {
    // return true if it shall return
    if (this._canDeactivateGuardService.guardSet === true) {
      this._canDeactivateGuardService.confirmationSubject.next(true);
      this._canDeactivateGuardService.guardSet = false;
      return false;
    } else if (this._auditCreatorSet === true) {
      if (this._lastCreatorValue === AuditCreatorVersion.Default) {
        this.creatorProcessOneDepartment();
      } else if (this._lastCreatorValue === AuditCreatorVersion.NoDepartments) {
        this.creatorProcessNoDepartments();
      } else if (this._lastCreatorValue === AuditCreatorVersion.FewDepartments) {
        this.creatorProcessMultipleDeparments();
      }

      this._auditCreatorSet = false;
      this._treeNavigationService.setExitFormVisible(false);
      return true;
    } else {
      return false;
    }
  }

  public onCloseFormExitPopup(e: CloseResult) {
    if (e === CloseResult.Confirm) {
      if (this.exitPopupHandleWithoutSave()) {
        return;
      }
    }
    if (e === CloseResult.Special && (this._canDeactivateGuardService.guardSet || this._auditCreatorSet)) {
      this.saveForm(this.formType);
      if (this.exitPopupHandleWithoutSave()) {
        return;
      }
    }
    if (e === CloseResult.Cancel) {
      if (this._canDeactivateGuardService.guardSet === true) {
        this._canDeactivateGuardService.confirmationSubject.next(false);
        this._canDeactivateGuardService.guardSet = false;
      } else if (this._auditCreatorSet === true) {
        this._treeNavigationService.setExitFormVisible(false);
        return;
      }
    }

    this._treeNavigationService.setExitFormReturn(e);
    this._treeNavigationService.setExitFormVisible(false);
  }

  public onToggleAuditClosePopup(e: CloseResult) {
    this._treeNavigationService.setAuditCloseFormReturn(e);
    this._treeNavigationService.setAuditCloseFormVisible(false);
  }
}
