<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="common_content_properties">
  <div class="flex_space_between_property">
    <dx-data-grid
      id="gridContainer"
      [showBorders]="true"
      [allowColumnResizing]="false"
      [allowColumnReordering]="false"
      [dataSource]="UsersData"
      [columnMinWidth]="150"
      (onCellPrepared)="onCellPrepared($event)"
      (onEditorPreparing)="onEditorPreparing($event)"
    >
      <dxi-column
        dataField="email"
        caption="{{ _('adminUsersManagement-email') }}"
        [minWidth]="250"
      >
        <dxi-validation-rule
          type="email"
          message="{{ _('adminUsersManagement-emailEmailMessage') }}"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="required"
          message="{{ _('adminUsersManagement-emailRequiredMessage') }}"
        ></dxi-validation-rule>
      </dxi-column>

      <dxi-column
        dataField="firstName"
        caption="{{ _('adminUsersManagement-firstName') }}"
      >
        <dxi-validation-rule
          type="required"
          message="{{ _('adminUsersManagement-firstNameColumnRequiredMessage') }}"
        ></dxi-validation-rule>
        <dxi-validation-rule
          type="stringLength"
          [min]="1"
          [max]="256"
          message="{{ _('adminUsersManagement-firstNameColumnRangeMessage') }}"
        ></dxi-validation-rule>
      </dxi-column>

      <dxi-column
        dataField="lastName"
        caption="{{ _('adminUsersManagement-lastName') }}"
      >
        <dxi-validation-rule
          type="required"
          message="{{ _('adminUsersManagement-lastNameColumnRequiredMessage') }}"
        >
        </dxi-validation-rule>
        <dxi-validation-rule
          type="stringLength"
          [min]="1"
          [max]="256"
          message="{{ _('adminUsersManagement-lastNameColumnRangeMessage') }}"
        >
        </dxi-validation-rule>
      </dxi-column>
      <dxi-column
        dataField="phoneNumber"
        caption="{{ _('adminUsersManagement-phoneNumber') }}"
        [minWidth]="200"
      >
        <dxi-validation-rule
          type="required"
          message="{{ _('adminUsersManagement-phoneNumberColumnRequiredMessage') }}"
        >
        </dxi-validation-rule>
      </dxi-column>
      <dxi-column
        dataField="position"
        caption="{{ _('adminUsersManagement-position') }}"
        [minWidth]="200"
      >
        <dxi-validation-rule
          type="required"
          message="{{ _('adminUsersManagement-positionColumnRequiredMessage') }}"
        >
        </dxi-validation-rule>
        <dxi-validation-rule
          type="stringLength"
          [min]="1"
          [max]="256"
          message="{{ _('adminUsersManagement-positionColumnRangeMessage') }}"
        ></dxi-validation-rule>
      </dxi-column>
      <dxi-column
        dataField="roleName"
        caption="{{ _('adminUsersManagement-role') }}"
      >
        <dxi-validation-rule
          type="required"
          message="{{ _('adminUsersManagement-roleColumnRequiredMessage') }}"
        >
        </dxi-validation-rule>
        <dxo-lookup [dataSource]="roles"> </dxo-lookup>
      </dxi-column>
      <dxi-column
        dataField="languageId"
        caption="{{ _('adminUsersManagement-language') }}"
      >
        <dxi-validation-rule
          type="required"
          message="{{ _('adminUsersManagement-languageColumnRequiredMessage') }}"
        >
        </dxi-validation-rule>
        <dxo-lookup
          [dataSource]="languages"
          displayExpr="name"
          valueExpr="id"
        >
        </dxo-lookup>
      </dxi-column>
      <dxo-grouping
        #expand
        [autoExpandAll]="true"
      ></dxo-grouping>

      <dxo-sorting mode="multiple"> </dxo-sorting>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-paging
        [pageSize]="10"
        [enabled]="false"
      ></dxo-paging>
      <dxo-pager
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[10, 20, 50, 'all']"
        [showInfo]="true"
      >
      </dxo-pager>
      <dxo-editing
        [allowUpdating]="true"
        [allowDeleting]="true"
        [allowAdding]="true"
      >
      </dxo-editing>
    </dx-data-grid>
  </div>
</div>
