<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item *ngIf="connectionGroupes">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="electricityConnection.connectionGroupe"
        stylingMode="outlined"
        [dataSource]="connectionGroupes"
        displayExpr="name"
        [placeholder]="_('electricity-connection-connection-groupe-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('electricity-connection-connection-groupe')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="measuringSystems">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="electricityConnection.measuringSystem"
        stylingMode="outlined"
        [dataSource]="measuringSystems"
        displayExpr="name"
        [placeholder]="_('electricity-connection-measuring-system-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('electricity-connection-measuring-system')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="electricityConnection.connectionPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('electricity-connection-connection-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="electricityConnection.orderedPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('electricity-connection-ordered-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat"
        [(value)]="electricityConnection.powerFactor"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('electricity-connection-power-factor')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="energySellers">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="electricityConnection.energySeller"
        stylingMode="outlined"
        [dataSource]="energySellers"
        displayExpr="name"
        [placeholder]="_('electricity-connection-energy-seller-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('electricity-connection-energy-seller')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="energyDistributors">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="electricityConnection.energyDistributor"
        stylingMode="outlined"
        [dataSource]="energyDistributors"
        displayExpr="name"
        [placeholder]="_('electricity-connection-energy-distributor-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('electricity-connection-energy-distributor')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="tariffs">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="electricityConnection.tariff"
        stylingMode="outlined"
        [dataSource]="tariffs"
        displayExpr="name"
        [placeholder]="_('electricity-connection-tariff-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('electricity-connection-tariff')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-area
        height="100"
        [(value)]="electricityConnection.exceedingPowerFine"
        stylingMode="outlined"
      >
      </dx-text-area>
    </div>
    <dxo-label [text]="_('electricity-connection-exceeding-power-fine')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-area
        height="100"
        [(value)]="electricityConnection.inductivePowerFine"
        stylingMode="outlined"
      >
      </dx-text-area>
    </div>
    <dxo-label [text]="_('electricity-connection-inductive-power-fine')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-area
        height="100"
        [(value)]="electricityConnection.capacitivePowerFine"
        stylingMode="outlined"
      >
      </dx-text-area>
    </div>
    <dxo-label [text]="_('electricity-connection-capacitive-power-fine')"></dxo-label>
  </dxi-item>
</dx-form>
