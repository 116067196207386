import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { AirConditioner } from 'src/app/shared/models/area/types/air-conditioner.model';
import { RefrigerantType } from 'src/app/shared/models/area/types/cooling-system.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-air-conditioner',
  templateUrl: './air-conditioner.component.html',
  styleUrls: ['./air-conditioner.component.scss'],
})
export class AirConditionerComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  airConditioner: AirConditioner = null;

  refrigerantTypes: ArrayStore;

  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getRefrigerantTypes().subscribe((res: Array<RefrigerantType>) => {
      this.refrigerantTypes = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
