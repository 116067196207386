<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-diagram
  id="diagram"
  #diagram
  [fullScreen]="false"
  height="100%"
  width="inherit"
  [simpleView]="true"
  [showGrid]="false"
  [units]="'cm'"
  viewUnits="cm"
  autoZoomMode="fitWidth"
  (onRequestEditOperation)="onOptionChanged($event)"
>
  <dxi-custom-shape
    *ngFor="let shape of customShapes"
    [type]="shape.type"
    [baseType]="shape.baseType"
    [category]="shape.category"
    [title]="shape.title"
    [toolboxTemplate]="shape.toolboxTemplate"
    [template]="shape.template"
    [defaultWidth]="6"
    [defaultHeight]="2"
    [toolboxWidthToHeightRatio]="2"
    [minWidth]="3"
    [minHeight]="0.75"
    [maxWidth]="9"
    [maxHeight]="4"
    [allowEditText]="false"
  >
  </dxi-custom-shape>
  <svg
    *dxTemplate="let item of 'customQuestionShapeTemplate'"
    class="customQuestion"
  >
    <foreignObject
      width="100%"
      height="100%"
      requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
    >
      <p xmlns="http://www.w3.org/1999/xhtml">
        <text class="template-text">
          {{ item.dataItem !== undefined ? item.dataItem.textt : 'Question Text' }}
        </text>
      </p>
    </foreignObject>

    <text
      class="template-text"
      x="30%"
      y="85%"
      (click)="editQuestion(item.dataItem)"
    >
      Edit
    </text>
    <text
      class="template-text"
      x="70%"
      y="85%"
      (click)="deleteQuestion(item.dataItem)"
    >
      Delete
    </text>
  </svg>
  <svg
    *dxTemplate="let item of 'customQuestionShapeToolboxTemplate'"
    class="customQuestion template-question-node"
  >
    <text
      class="template-text"
      x="50%"
      y="40%"
    >
      New
    </text>
    <text
      class="template-text"
      x="50%"
      y="70%"
    >
      Question
    </text>
  </svg>

  <svg
    *dxTemplate="let item of 'customAnswerShapeTemplate'"
    class="customAnswer"
  >
    <text
      class="template-text"
      x="50%"
      y="40%"
    >
      {{ item.dataItem !== undefined ? item.dataItem.textt : 'Answer Text' }}
    </text>

    <text
      class="template-text"
      x="30%"
      y="85%"
      (click)="editQuestion(item.dataItem)"
    >
      Edit
    </text>
    <text
      class="template-text"
      x="70%"
      y="85%"
      (click)="deleteQuestion(item.dataItem)"
    >
      Delete
    </text>
  </svg>
  <svg
    *dxTemplate="let item of 'customAnswerShapeToolboxTemplate'"
    class="customAnswer template-answer-node"
  >
    <text
      class="template-text"
      x="50%"
      y="40%"
    >
      New
    </text>
    <text
      class="template-text"
      x="50%"
      y="70%"
    >
      Answer
    </text>
  </svg>

  <dxo-context-toolbox [enabled]="false"> </dxo-context-toolbox>
  <dxo-toolbox
    [showSearch]="false"
    [shapeIconsPerRow]="1"
  >
    <dxi-group
      category="question"
      title="Question"
      [expanded]="true"
    ></dxi-group>
    <dxi-group
      category="answer"
      title="Answer"
      [expanded]="true"
    ></dxi-group>
  </dxo-toolbox>
  <dxo-properties-panel>
    <dxi-tab>
      <dxi-group
        title="Page Properties"
        [commands]="['pageSize', 'pageOrientation', 'pageColor']"
      ></dxi-group>
    </dxi-tab>
  </dxo-properties-panel>

  <dxo-nodes
    keyExpr="id"
    [dataSource]="flowNodesDataSource"
    [customDataExpr]="itemCustomDataExpr"
    leftExpr="left"
    topExpr="top"
    [typeExpr]="itemTypeExpr"
    textStyleExpr="textStyle"
    styleExpr="style"
  >
  </dxo-nodes>
  <dxo-edges
    [dataSource]="flowEdgesDataSource"
    keyExpr="id"
    fromExpr="from"
    toExpr="to"
    textExpr=""
    lineTypeExpr="lineType"
    fromPointIndexExpr="fromPoint"
    toPointIndexExpr="toPoint"
    styleExpr="style"
  ></dxo-edges>
</dx-diagram>

<dx-popup
  [width]="400"
  [height]="480"
  [showTitle]="true"
  title="Edit Question"
  [dragEnabled]="false"
  [(visible)]="popupVisible"
>
  <div *dxTemplate="let data of 'content'">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">Text</div>
        <div class="dx-field-value">
          <dx-text-box
            [(value)]="currentQuestion.textt"
            [inputAttr]="{ 'aria-label': 'Text' }"
          ></dx-text-box>
        </div>
      </div>
    </div>
    <div class="dx-fieldset buttons">
      <!-- <dx-button text="Update" type="default" (click)="this.updateEmployee()">
      </dx-button> -->
      <dx-button
        text="Cancel"
        (click)="this.cancelEditQuestion()"
      >
      </dx-button>
    </div>
  </div>
</dx-popup>
