import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public deepCopy(obj: any): any {
    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' != typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      return this.copyDate(obj);
    }

    // Handle Array
    if (obj instanceof Array) {
      return this.copyArray(obj);
    }

    // Handle Object
    if (obj instanceof Object) {
      return this.copyObject(obj);
    }

    return null;
  }

  public areObjectsEqual<T>(obj1: T, obj2: T): boolean {
    const propertiesToCheck: (keyof T)[] = Object.keys(obj1) as (keyof T)[];

    for (const property of propertiesToCheck) {
      if (JSON.stringify(obj1[property]) !== JSON.stringify(obj2[property])) {
        return false;
      }
    }

    return true;
  }

  public triggerParentDeactivateCheck<T>(first: T, second: T): boolean {
    if (this.areObjectsEqual(first, second)) {
      return true;
    } else {
      return false;
    }
  }

  private copyDate(obj: Date): any {
    let copy;

    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  private copyArray(obj: Array<any>): any {
    const copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = this.deepCopy(obj[i]);
    }
    return copy;
  }

  private copyObject(obj: Object): any {
    const copy = {};
    for (const attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = this.deepCopy(obj[attr]);
      }
    }
    return copy;
  }
}
