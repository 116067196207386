import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiRequestService } from './api-request.service';

@Injectable()
export class AuditsCountService {
  private _auditsCount$ = new BehaviorSubject<number>(0);
  public auditsCount$ = this._auditsCount$.asObservable();

  constructor(private apiRequestService: ApiRequestService) {}

  public updateAuditCount() {
    this.apiRequestService.getNumberOfAudits().subscribe((count: number) => {
      this._auditsCount$.next(count);
    });
  }
}
