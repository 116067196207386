<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="waterPump.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('water-pump-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="waterPump.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('water-pump-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="waterPump.serialNumber"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('water-pump-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [(value)]="waterPump.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
        [format]="integerNumberFormat"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('water-pump-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [(value)]="waterPump.ratedPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [format]="fullNumberFormat + ' kW'"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('water-pump-rated-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [(value)]="waterPump.ratedEfficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
        [format]="fullNumberFormat + ' %'"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('water-pump-rated-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [(value)]="waterPump.ratedCapacity"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
        [format]="fullNumberFormat + ' %'"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('water-pump-rated-capacity')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [(value)]="waterPump.rasingHeight"
        stylingMode="outlined"
        [min]="0"
        [max]="100000"
        [format]="fullNumberFormat + ' m'"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('water-pump-rasing-height')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="mediumsList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="waterPump.medium"
        [dataSource]="mediumsList"
        displayExpr="name"
        stylingMode="outlined"
        [searchEnabled]="true"
        searchMode="contains"
        [placeholder]="_('water-pump-medium-placeholder')"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('water-pump-medium')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="operatinPointsVolatilityList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="waterPump.operatingPointVolatility"
        [dataSource]="operatinPointsVolatilityList"
        displayExpr="name"
        stylingMode="outlined"
        [searchEnabled]="true"
        searchMode="contains"
        [placeholder]="_('water-pump-operating-point-volatility-placeholder')"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('water-pump-operating-point-volatility')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="regulationTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="waterPump.regulationType"
        [dataSource]="regulationTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [searchEnabled]="true"
        searchMode="contains"
        [placeholder]="_('water-pump-regulation-type-placeholder')"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('water-pump-regulation-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [(value)]="waterPump.mediumTemp"
        stylingMode="outlined"
        [min]="0"
        [max]="1000000"
        [format]="fullNumberFormat + ' °C'"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('water-pump-medium-temp')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [(value)]="waterPump.suctionPressure"
        stylingMode="outlined"
        [min]="0"
        [max]="1000000"
        [format]="fullNumberFormat + ' kPa'"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('water-pump-suction-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [(value)]="waterPump.dischargePressure"
        stylingMode="outlined"
        [min]="0"
        [max]="1000000"
        [format]="fullNumberFormat + ' kPa'"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('water-pump-discharge-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [(value)]="waterPump.averageLoad"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
        [format]="fullNumberFormat + ' %'"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('water-pump-average-load')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [(value)]="waterPump.averageEfficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
        [format]="fullNumberFormat + ' %'"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('water-pump-average-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [(value)]="waterPump.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
        [format]="integerNumberFormat + ' h'"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('water-pump-working-hours-in-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="waterPump.sampleCardAvailable"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('water-pump-sample-card-available')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="technicalConditions">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="waterPump.technicalCondition"
        [dataSource]="technicalConditions"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('water-pump-technical-condition-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('water-pump-technical-condition')"></dxo-label>
  </dxi-item>
</dx-form>
