<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item *ngIf="fleetOwnershipsList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="transport.fleetOwnership"
        [dataSource]="fleetOwnershipsList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('transport-select-box-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('transport-select-box-label')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="vehiclesList">
    <div *dxTemplate>
      <div id="transportDataGridDiv">
        <dx-data-grid
          id="transportGridContainer"
          [dataSource]="vehiclesList"
          [remoteOperations]="true"
          [columnAutoWidth]="true"
          [showBorders]="true"
          [allowColumnResizing]="false"
          [dataRowTemplate]="templateMode ? 'dataRowTemplateName' : null"
        >
          <dxo-paging [enabled]="true"></dxo-paging>
          <dxo-editing
            *ngIf="!templateMode"
            [allowUpdating]="true"
            [allowDeleting]="true"
            [allowAdding]="true"
          >
            <dxo-texts
              [deleteRow]="_('transport-vehicle-delete-action')"
              [addRow]="_('transport-vehicle-add-action')"
              [editRow]="_('transport-vehicle-edit-action')"
              [confirmDeleteMessage]="_('transport-vehicle-confirm-delete')"
              [saveRowChanges]="_('transport-vehicle-confirm-save')"
              [cancelRowChanges]="_('transport-vehicle-cancel')"
            >
            </dxo-texts>
          </dxo-editing>

          <dxi-column
            dataField="vehicleType.id"
            [caption]="_('transport-column-vehicle-type')"
            [editorOptions]="{ placeholder: this._('transport-column-vehicle-type-placeholder') }"
          >
            <dxo-lookup
              *ngIf="!templateMode"
              [dataSource]="vehiclesTypesList"
              displayExpr="name"
              valueExpr="id"
            >
            </dxo-lookup>
            <dxi-validation-rule
              type="required"
              [message]="_('transport-vehicle-type-error')"
            ></dxi-validation-rule>
          </dxi-column>

          <dxi-column
            dataField="producer"
            [caption]="_('transport-column-producer')"
          >
            <dxi-validation-rule
              type="stringLength"
              max="100"
              [message]="_('transport-vehicle-producer-error', 100)"
            ></dxi-validation-rule>
          </dxi-column>

          <dxi-column
            dataField="model"
            [caption]="_('transport-column-model')"
          >
            <dxi-validation-rule
              type="stringLength"
              max="100"
              [message]="_('transport-vehicle-model-error', 100)"
            ></dxi-validation-rule>
          </dxi-column>

          <dxi-column
            dataField="registrationNumber"
            [caption]="_('transport-column-registration-number')"
          >
            <dxi-validation-rule
              type="pattern"
              pattern="[A-Za-z0-9]{4,9}"
              [message]="_('transport-vehicle-registration-plate-error')"
            ></dxi-validation-rule>
          </dxi-column>

          <dxi-column
            dataField="vehicleFuelType.id"
            [caption]="_('transport-column-fuel-type')"
            [editorOptions]="{ placeholder: this._('transport-column-fuel-type-placeholder') }"
          >
            <dxo-lookup
              *ngIf="!templateMode"
              [dataSource]="fuelTypesList"
              displayExpr="name"
              valueExpr="id"
            >
            </dxo-lookup>
          </dxi-column>

          <dxi-column
            dataField="power"
            [caption]="_('transport-column-power')"
            format="#0.## kW"
          ></dxi-column>

          <dxi-column
            dataField="engineCapacity"
            [caption]="_('transport-column-engine-capacity')"
            format="#0 cm³"
          ></dxi-column>

          <dxi-column
            dataField="annualKm"
            [caption]="_('transport-column-annual-km')"
            format="#0 km"
          >
            ></dxi-column
          >

          <dxi-column
            dataField="annualFuelConsumption"
            [caption]="_('transport-column-annual-fuel-consumption')"
            format="#0 dm³"
          >
            ></dxi-column
          >

          <ng-container *dxTemplate="let vehicle of 'dataRowTemplateName'">
            <tr class="main-row">
              <td rowspan="2">
                <app-field-doc-variable
                  *ngIf="templateMode"
                  [text]="this.docVariableCode + 'Type'"
                ></app-field-doc-variable>
              </td>
              <td>
                <app-field-doc-variable
                  *ngIf="templateMode"
                  [text]="this.docVariableCode + 'Producer'"
                ></app-field-doc-variable>
              </td>
              <td>
                <app-field-doc-variable
                  *ngIf="templateMode"
                  [text]="this.docVariableCode + 'Model'"
                ></app-field-doc-variable>
              </td>
              <td>
                <app-field-doc-variable
                  *ngIf="templateMode"
                  [text]="this.docVariableCode + 'RegisterNumber'"
                ></app-field-doc-variable>
              </td>
              <td>
                <app-field-doc-variable
                  *ngIf="templateMode"
                  [text]="this.docVariableCode + 'FuelType'"
                ></app-field-doc-variable>
              </td>
              <td>
                <app-field-doc-variable
                  *ngIf="templateMode"
                  [text]="this.docVariableCode + 'Power'"
                ></app-field-doc-variable>
              </td>
              <td>
                <app-field-doc-variable
                  *ngIf="templateMode"
                  [text]="this.docVariableCode + 'Engine'"
                ></app-field-doc-variable>
              </td>
              <td>
                <app-field-doc-variable
                  *ngIf="templateMode"
                  [text]="this.docVariableCode + 'AnnualKm'"
                ></app-field-doc-variable>
              </td>
              <td>
                <app-field-doc-variable
                  *ngIf="templateMode"
                  [text]="this.docVariableCode + 'AnnualFuelConsumption'"
                ></app-field-doc-variable>
              </td>
            </tr>
          </ng-container>
        </dx-data-grid>
      </div>
    </div>
    <dxo-label [text]="_('transport-overview-of-vehicles')"></dxo-label>
  </dxi-item>

  <dxi-item dataField="System monitorowania pojazdów">
    <div *dxTemplate>
      <dx-switch
        [(value)]="transport.vehicleTrackingSystem"
        [disabled]="templateMode"
        switchedOffText="Nie"
        switchedOnText="Tak"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('transport-switch-vehicle-tracking-system')"></dxo-label>
  </dxi-item>

  <dxi-item dataField="Szkolenia z eko-drivingu">
    <div *dxTemplate>
      <dx-switch
        [(value)]="transport.ecoDrivingTraining"
        [disabled]="templateMode"
        switchedOffText="Nie"
        switchedOnText="Tak"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('transport-switch-eco-driving-training')"></dxo-label>
  </dxi-item>

  <dxi-item dataField="Kontrola i promowanie stosowania zasad eko-drivingu">
    <div *dxTemplate>
      <dx-switch
        [(value)]="transport.ecoDrivingPrinciples"
        [disabled]="templateMode"
        switchedOffText="Nie"
        switchedOnText="Tak"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('transport-switch-eco-driving-principles')"></dxo-label>
  </dxi-item>
</dx-form>
