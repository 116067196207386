<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="responsive-paddings-questionaire">
  <dx-scroll-view
    width="100%"
    height="100%"
  >
    <div class="container">
      <div
        #questions
        *ngFor="let question of questionsList"
      >
        <app-closed-question
          *ngIf="question.type === 'closed'"
          [question]="question"
          [isAuditFinished]="isAuditFinished"
        ></app-closed-question>
        <app-open-question
          *ngIf="question.type === 'open'"
          [question]="question"
          (validationError)="disableSaveQuestionGroup($event)"
          [isAuditFinished]="isAuditFinished"
        ></app-open-question>
        <app-multiple-choice-question
          *ngIf="question.type === 'checkBox'"
          [question]="question"
          (validationError)="disableSaveQuestionGroup($event)"
          [isAuditFinished]="isAuditFinished"
        ></app-multiple-choice-question>
      </div>
    </div>
  </dx-scroll-view>
</div>
