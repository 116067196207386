<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="container">
  <dx-drawer
    #drawer
    template="template"
    [(opened)]="navigationDrawerOpened"
    class="drawer drawer-{{ drawerViewMode }}"
    [openedStateMode]="isMobile ? 'overlap' : 'shrink'"
    revealMode="slide"
    [position]="isMobile ? 'bottom' : 'right'"
  >
    <div
      *dxTemplate="let data of 'template'"
      class="drawer-navigation drawer-navigation-{{ drawerViewMode }}"
    >
      <div class="navigation-drawer">
        <div
          id="tree-margin"
          *ngIf="isMobile === false"
        ></div>
        <dx-button
          id="expand-button"
          *ngIf="isMobile === false"
          (onClick)="navigationMenuButton()"
          class="white-icon"
          [icon]="expandTreeIcon"
        >
        </dx-button>
        <dx-button
          *ngIf="isMobile && !navigationDrawerOpened"
          id="expand-button-mobile"
          (onClick)="navigationMenuButton()"
          class="white-icon"
          [icon]="expandTreeIcon"
        >
        </dx-button>
        <dx-button
          *ngIf="isMobile"
          id="close-button"
          (onClick)="navigationMenuButton()"
          class="white-icon"
          icon="fi fi-rr-cross"
        >
        </dx-button>
        <h3 class="navigation-tree-header">{{ _('navigation-tree-title') }}</h3>
        <div class="static-height-for-action-buttons">
          <div
            [ngSwitch]="formType"
            class="form-actions-panel"
            *ngIf="showButtons"
          >
            <div
              *ngSwitchCase="'Audit'"
              class="form-actions"
            >
              <dx-button
                class="text-button-focus-hover button-rounded-regular"
                icon="fi fi-rr-trash"
                stylingMode="text"
                [text]="_('button-delete-text')"
                (onClick)="performFormAction(actions.Delete)"
                [disabled]="isAuditFinishedFlag"
              ></dx-button>
            </div>
            <div
              *ngSwitchCase="'Enterprise'"
              class="form-actions"
            >
              <dx-button
                class="text-button-focus-hover button-rounded-regular"
                icon="fi fi-rr-plus"
                stylingMode="text"
                [text]="_('button-add-department')"
                (onClick)="performFormAction(actions.Add)"
                [disabled]="isAuditFinishedFlag"
              ></dx-button>
            </div>
            <div
              *ngSwitchCase="'Department'"
              class="form-actions"
            >
              <dx-button
                *ngIf="!isLiteUser"
                class="text-button-focus-hover button-rounded-regular"
                icon="fi fi-rr-plus"
                stylingMode="text"
                [text]="_('button-add-area-text')"
                (onClick)="performFormAction(actions.Add)"
                [disabled]="isAuditFinishedFlag"
              ></dx-button>
              <dx-button
                class="text-button-focus-hover button-rounded-regular"
                icon="fi fi-rr-trash"
                stylingMode="text"
                [text]="_('button-delete-department-text')"
                (onClick)="performFormAction(actions.Delete)"
                [disabled]="isAuditFinishedFlag"
              ></dx-button>
            </div>
            <div
              *ngSwitchCase="'Area'"
              class="form-actions"
            >
              <dx-button
                *ngIf="!isArealastLeaf && !isLiteUser"
                class="text-button-focus-hover button-rounded-regular"
                icon="fi fi-rr-plus"
                stylingMode="text"
                [text]="_('button-add-area-text')"
                (onClick)="performFormAction(actions.Add)"
                [disabled]="isAuditFinishedFlag"
              ></dx-button>
              <dx-button
                class="text-button-focus-hover button-rounded-regular"
                icon="fi fi-rr-trash"
                stylingMode="text"
                [text]="_('button-delete-area')"
                (onClick)="performFormAction(actions.Delete)"
                [disabled]="isAuditFinishedFlag"
              ></dx-button>
            </div>
          </div>
        </div>
        <app-tree-navigation
          [auditId]="auditId"
          [isAuditFinished]="isAuditFinishedFlag"
        >
        </app-tree-navigation>
      </div>
    </div>
    <dx-scroll-view
      [useNative]="false"
      [bounceEnabled]="false"
      [disabled]="false"
      class="scroll responsive-paddings"
      showScrollbar="never"
    >
      <app-hint
        *ngIf="showFooter && isAuditFinishedFlag"
        [title]="_('audit-finished-title')"
        [message]="_('audit-finished-message')"
        [mainButtonText]="_('button-open-audit-text')"
        (mainButtonClick)="performFormAction(actions.ToggleFinishedAudit)"
      >
      </app-hint>
      <app-hint
        *ngIf="showCreator && !(showFooter && isAuditFinishedFlag)"
        [title]="creatorTitle"
        [message]="creatorMessage"
        [mainButtonText]="creatorMainButtonText"
        (mainButtonClick)="creatorMainButtonFunction()"
        [secondaryButton]="true"
        [secondaryButtonText]="creatorSecondaryButtonText"
        (secondaryButtonClick)="creatorSecondaryButtonFunction()"
      >
      </app-hint>
      <ng-template [ngTemplateOutlet]="formTypeSwitcher"></ng-template>
    </dx-scroll-view>
  </dx-drawer>

  <dx-toolbar class="bottom-toolbar">
    <dxi-item
      *ngIf="formType === 'Audit'"
      location="before"
      widget="dxButton"
    >
      <div *dxTemplate>
        <dx-button
          *ngIf="!isAuditFinishedFlag"
          class="outlined-button-focus-hover button-rounded-regular"
          stylingMode="outlined"
          [text]="isAuditFinishedFlag ? _('button-open-audit-text') : _('button-close-audit-text')"
          icon="fi fi-rr-cross-small"
          (onClick)="performFormAction(actions.ToggleFinishedAudit)"
        ></dx-button>
      </div>
    </dxi-item>

    <dxi-item
      *ngIf="formType === 'Audit'"
      location="after"
      widget="dxButton"
    >
      <div *dxTemplate>
        <dx-button
          class="outlined-button-focus-hover button-rounded-regular"
          stylingMode="outlined"
          [text]="_('button-generate-raport-text')"
          icon="fi fi-rr-document"
          (onClick)="performFormAction(actions.Report)"
        ></dx-button>
      </div>
    </dxi-item>

    <dxi-item
      *ngIf="formType === 'Department'"
      location="after"
      widget="dxButton"
    >
      <div *dxTemplate>
        <dx-button
          class="outlined-button-focus-hover button-rounded-regular"
          stylingMode="outlined"
          [text]="_('button-questionnaire-text')"
          icon="fi fi-rr-memo-pad"
          (onClick)="performFormAction(actions.Questionnaire)"
        ></dx-button>
      </div>
    </dxi-item>

    <dxi-item
      location="after"
      widget="dxButton"
    >
      <div *dxTemplate>
        <dx-button
          class="contained-button-focus-hover button-contained button-case-sensitive"
          type="normal"
          stylingMode="contained"
          [text]="_('button-save-text')"
          icon="fi fi-rr-check"
          (onClick)="performFormAction(actions.Save)"
          [disabled]="isAuditFinishedFlag"
        ></dx-button>
      </div>
    </dxi-item>
  </dx-toolbar>
</div>

<app-exit-confirmation-popup
  [popupVisible]="formExitPopupVisible"
  [confirmationNo]="_('exit-form-confirmation-no')"
  [confirmationYes]="_('exit-form-confirmation-yes')"
  [confirmationSpecial]="_('exit-form-confirmation-save-yes')"
  [specialButtonVisible]="true"
  [title]="_('exit-form-confirmation-title')"
  [message]="_('exit-form-confirmation-message')"
  (closeEvent)="onCloseFormExitPopup($event)"
></app-exit-confirmation-popup>

<app-exit-confirmation-popup
  [popupVisible]="toggleAuditClosePopupVisible"
  [confirmationNo]="_('Cancel')"
  [confirmationYes]="_('unsaved-changes-ignore-and-finish')"
  [confirmationSpecial]="_('unsaved-changes-save-and-finish')"
  [specialButtonVisible]="true"
  [title]="_('unsaved-changes-title')"
  [message]="_('unsaved-changes-message-before-finish')"
  (closeEvent)="onToggleAuditClosePopup($event)"
></app-exit-confirmation-popup>

<app-images-form-selector
  *ngIf="formSelectorVisible"
  [showMoveImagePopup]="formSelectorVisible"
  (onPopupHiding)="onFormSelectorPopupHiding()"
  [selectFormOnly]="true"
  [formRootId]="departmentRoot"
  (onFormSelected)="onDepartmentFormSelected($event)"
  [titleText]="_('audit-department-select-form-title')"
  [messageText]="_('audit-department-select-form-caption')"
  [searchText]="_('audit-department-select-form-placeholder')"
></app-images-form-selector>

<ng-template #formTypeSwitcher>
  <div class="forms">
    <router-outlet></router-outlet>
    <app-footer *ngIf="showFooter"></app-footer>
  </div>
</ng-template>
