import { AppSettings } from './AppSettings';

export const navigation = [
  {
    text: '',
    textKey: AppSettings.NAVIGATION_DASHBOARD,
    icon: 'fi fi-rr-home',
  },
  {
    text: '',
    textKey: AppSettings.NAVIGATION_AUDITS,
    path: '/pages/audits-list',
    icon: 'fi fi-rr-books',
  },
  {
    text: '',
    textKey: 'navigation-templates',
    path: '/pages/templates-list',
    icon: 'fi fi-rr-file-edit',
  },
  {
    text: '',
    icon: 'fi fi-rr-minus',
  },
  {
    text: '',
    textKey: 'navigation-preferences-constatns',
    path: '/pages/admin-panel',
    icon: 'fi fi-rr-apps',
  },
  {
    text: '',
    textKey: 'navigation-preferences-users',
    path: '/pages/users-management',
    icon: 'fi fi-rr-users-gear',
  },
  {
    text: '',
    textKey: 'navigation-version-creator',
    path: '/pages/questionnaire-version-overview',
    icon: 'fi fi-rr-upload',
  },
];
