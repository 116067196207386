import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { AppSettings } from '../../../../AppSettings';
import { Image } from '../../../models/image.model';
import { GalleryService } from '../../../services/gallery.service';
import { UtilsService } from '../../../services/utils.service';
import { Localizable } from '../../../../locale/localizable';
import { AuditGalleryComponent } from '../audit-gallery/audit-gallery.component';
import { DxScrollViewComponent } from 'devextreme-angular';
@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss'],
})
export class PictureComponent extends Localizable implements OnInit, OnDestroy {
  @Input() public isAuditFinished: boolean = false;
  @ViewChild('scrollView', { static: false }) scrollView: DxScrollViewComponent;
  @Input()
  public image: Image;
  loadingVisible = true;

  @Output()
  public deletedImageId: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public editedImageId: EventEmitter<Image> = new EventEmitter<Image>();

  public imageUrl: string;
  public apiUrl: string;

  private loadedFormData: Image;
  private imageDeleted: boolean = false;

  public imgWidth: number;
  public imgHeight: number;

  public imgConstWidth: number;
  public imgConstHeight: number;

  public loadIndicatorWidth = AppSettings.LOAD_INDICATOR_WIDTH;
  public loadIndicatorHeight = AppSettings.LOAD_INDICATOR_HEIGHT;
  public canZoomOut: boolean = false;

  public imageUnavailable: boolean = false;

  constructor(private _galleryService: GalleryService, private utilsService: UtilsService) {
    super(AuditGalleryComponent.localFile);
  }

  ngOnInit(): void {
    this.imageUrl = this._galleryService.createImageUrlById(this.image.id);

    this.getImageThumbnail(this.image.id);
    this._galleryService.fetchImage(this.image.id).subscribe((res: Image) => {
      this.image = res;
      this.loadedFormData = this.utilsService.deepCopy(res);
    });
  }

  public delete(id: string): void {
    this._galleryService.deleteImage(id).subscribe((res: string) => {
      this.deletedImageId.emit(res);
      this.imageDeleted = true;
      notify({
        message: this._('gallery-picture-successfully-deleted-message'),
        type: 'error',
        displayTime: AppSettings.NOTIFY_DURATION,
        position: 'top center',
      });
    });
  }

  public getImageThumbnail(id: string) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === AppSettings.HTTP_STATUS_CODE_200_OK) {
        const img = <HTMLImageElement>document.getElementById(`thumbnailElement`);
        img.onload = () => {
          const imgRatio = img.naturalWidth / img.naturalHeight;
          const containerRatio = this.scrollView.instance.clientWidth() / this.scrollView.instance.clientHeight();

          if (imgRatio > containerRatio) {
            this.imgHeight = this.scrollView.instance.clientWidth() / imgRatio;
            this.imgWidth = this.scrollView.instance.clientWidth();
          } else {
            this.imgWidth = this.scrollView.instance.clientHeight() * imgRatio;
            this.imgHeight = this.scrollView.instance.clientHeight();
          }
          this.imgConstHeight = this.imgHeight;
          this.imgConstWidth = this.imgWidth;
          this.loadingVisible = false;
        };
        img.src = URL.createObjectURL(xhr.response);
      }
      if (
        xhr.readyState === XMLHttpRequest.HEADERS_RECEIVED &&
        (xhr.status === AppSettings.HTTP_STATUS_CODE_NOT_FOUND ||
          xhr.status === AppSettings.HTTP_STATUS_CODE_BAD_REQUEST)
      ) {
        this.loadingVisible = false;
        this.imageUnavailable = true;
      }
      if (
        xhr.readyState === XMLHttpRequest.HEADERS_RECEIVED &&
        (xhr.status === AppSettings.HTTP_STATUS_CODE_NOT_FOUND ||
          xhr.status === AppSettings.HTTP_STATUS_CODE_BAD_REQUEST)
      ) {
        this.loadingVisible = false;
        this.imageUnavailable = true;
        notify({
          message: this._('gallery-image-unavailable'),
          type: 'error',
          displayTime: AppSettings.NOTIFY_DURATION,
          position: 'top center',
        });
      }
    };
    xhr.open('GET', this._galleryService.getImageThumbnailById(id), true);
    xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem(AppSettings.AUTH_TOKEN)}`);
    xhr.send();
  }

  public downloadOrginal(image: Image): void {
    this._galleryService.downloadOrginal(image);
  }

  public ngOnDestroy(): void {
    if (this.imageDeleted || Object.entries(this.loadedFormData).toString() === Object.entries(this.image).toString()) {
      return;
    }

    this.editedImageId.emit(this.image);
  }

  public zoomIn(): void {
    this.imgWidth *= 1.1;
    this.imgHeight *= 1.1;
    this.updateCanZoomOut();
  }

  public zoomOut(): void {
    this.imgWidth /= 1.1;
    this.imgHeight /= 1.1;
    this.updateCanZoomOut();
  }

  public updateCanZoomOut(): void {
    if (this.imgWidth > this.imgConstWidth || this.imgHeight > this.imgConstHeight) {
      this.canZoomOut = true;
    } else {
      this.canZoomOut = false;
    }
  }
}
