import { Component, Input } from '@angular/core';
import { Localizable } from '../../../../locale/localizable';

@Component({
  selector: 'app-field-doc-variable',
  templateUrl: './field-doc-variable.component.html',
  styleUrls: ['./field-doc-variable.component.scss'],
})
export class FieldDocVariableComponent extends Localizable {
  constructor() {
    super('TemplateFileListComponent');
  }
  @Input() text: string = '';

  @Input() tooltipText: string = this._('template-file-copy-variable');

  @Input() position: string = 'right';

  @Input() specialVariable: boolean = false;
}
