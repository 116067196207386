<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div *ngIf="currentArea">
  <h1
    *ngIf="currentArea && existingForm"
    class="form-header-margin-top form-header-margin-bottom"
  >
    {{ currentArea.name }}
  </h1>
  <p-tabView
    id="tabview"
    [activeIndex]="activeIndex"
    (onChange)="handleChange($event)"
    *ngIf="currentArea && existingForm"
  >
    <p-tabPanel [header]="_('button-form-text')">
      <ng-template [ngTemplateOutlet]="form"></ng-template>
    </p-tabPanel>
    <p-tabPanel header="{{ _('button-gallery-text') }} ({{ getImagesNumber() }})">
      <app-audit-gallery
        [formId]="areaId"
        [auditId]="currentAuditId"
        *ngIf="existingForm && isGalleryVisible"
        [isAuditFinished]="isAuditFinishedFlag"
      ></app-audit-gallery>
    </p-tabPanel>
    <p-tabPanel [header]="_('button-sankey-text')">
      <app-energy-balance-chart
        *ngIf="energyBalanceChart"
        [auditId]="currentAuditId"
      ></app-energy-balance-chart>
      <app-energy-balance
        *ngIf="currentArea && existingForm"
        [auditId]="currentAuditId"
        [energyBalanceId]="this.currentArea.energyBalance.id"
        [assignedToFormId]="this.currentArea.id"
        [isEnergyBalanceDisabled]="isAuditFinishedFlag"
      >
      </app-energy-balance>
    </p-tabPanel>
  </p-tabView>
  <app-custom-popup
    *ngIf="deleteAreaPopup"
    [message]="deleteAreaMessage"
    [name]="deleteAreaMessage.name"
    [type]="deleteAreaMessage.type"
    [receiversToDeleteList]="deleteAreaMessage.energyReceiversToDelete"
    [receiversBlockingDeletionList]="deleteAreaMessage.energyReceiversBlockingDeletion"
    [formsList]="deleteAreaMessage.childrenForms"
    (popupClose)="popupClosed($event)"
  >
  </app-custom-popup>
</div>

<ng-template
  *ngIf="currentArea && !existingForm"
  [ngTemplateOutlet]="form"
></ng-template>

<dx-popup
  *ngIf="currentArea && existingForm && areaPopupVisible"
  [title]="_('create-area')"
  [fullScreen]="false"
  maxWidth="70rem"
  [height]="popupHeight + '%'"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="areaPopupVisible"
  (onHiding)="onAreaCreationPopupHiding($event)"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <dx-scroll-view
    width="100%"
    height="100%"
  >
    <div class="forms">
      <app-area-form
        #areaFormPopup
        [parentAreaType]="currentArea.type"
        [existingForm]="false"
        [parentAreaId]="this.currentArea.id"
        [departmentId]="this.currentArea.departmentId"
        [areaChangesIsSaved]="isChangesSaved()"
        (areaAdded)="areaAddedToParentArea($event)"
      ></app-area-form>
    </div>
  </dx-scroll-view>

  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    cssClass="button-rounded-regular toolbar-button-focus-border"
    [options]="createButtonOptions"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    cssClass="toolbar-contained-button-focus-border button-contained button-case-sensitive"
    [options]="createAndDisplayButtonOptions"
  >
  </dxi-toolbar-item>
</dx-popup>

<app-exit-confirmation-popup
  [popupVisible]="createAreaExitPopupVisible"
  [confirmationNo]="_('No')"
  [confirmationYes]="_('Yes')"
  [title]="_('unsaved-changes-title')"
  [message]="_('unsaved-changes-message')"
  (closeEvent)="onCloseAreaCreation($event)"
></app-exit-confirmation-popup>

<app-exit-confirmation-popup
  [popupVisible]="deleteAreaPopupVisible"
  [confirmationNo]="_('No')"
  [confirmationYes]="_('Yes')"
  [title]="_('delete-area-confirm-title')"
  (closeEvent)="onDeleteAreaPopupClose($event)"
  customContent="true"
>
  <ng-template appExitConfirmationPopupContent>
    <div [innerHTML]="deleteAreaPopupMessage"></div>
  </ng-template>
</app-exit-confirmation-popup>

<ng-template #form>
  <dx-form
    *ngIf="currentArea"
    [showColonAfterLabel]="true"
    id="area-form"
    [disabled]="isChangingDisabled || isAuditFinished()"
  >
    <dxi-item>
      <h3 class="form-header-margin">{{ _('general') }}</h3>
    </dxi-item>
    <dxi-item [dataField]="_('area-name')">
      <dxi-validation-rule
        type="required"
        [message]="_('area-name-required-message')"
      >
        <div *dxTemplate>
          <dx-text-box
            [(value)]="currentArea.name"
            stylingMode="outlined"
          >
          </dx-text-box>
        </div>
      </dxi-validation-rule>
    </dxi-item>

    <dxi-item
      *ngIf="!existingForm && !parentAreaType"
      [dataField]="_('area-type')"
    >
      <div *dxTemplate>
        <dx-tree-view
          #treeView
          [dataSource]="areaCategories"
          [searchEnabled]="true"
          [rootValue]="'-1'"
          (onItemSelectionChanged)="selectItem($event)"
          displayExpr="name"
          keyExpr="id"
          parentIdExpr="parentId"
          dataStructure="plain"
          searchMode="contains"
          selectionMode="single"
          selectByClick="true"
        >
        </dx-tree-view>
      </div>
    </dxi-item>

    <dxi-item
      [dataField]="_('area-type')"
      *ngIf="existingForm"
    >
      <div *dxTemplate>
        <dx-text-box
          [(value)]="currentArea.type.name"
          stylingMode="outlined"
          [readOnly]="existingForm"
        >
        </dx-text-box>
      </div>
    </dxi-item>

    <dxi-item
      [dataField]="_('area-type')"
      *ngIf="(currentArea?.category || parentAreaType) && !existingForm"
    >
      <div *dxTemplate>
        <dx-select-box
          [placeholder]="_('area-type-select-placeholder')"
          [dataSource]="areaTypes"
          [searchEnabled]="true"
          [searchMode]="'contains'"
          [searchExpr]="'name'"
          [searchTimeout]="200"
          [minSearchLength]="0"
          displayExpr="name"
          stylingMode="outlined"
          (onSelectionChanged)="selectedItemChanged($event)"
        >
        </dx-select-box>
      </div>
    </dxi-item>

    <dxi-item [dataField]="_('area-description')">
      <div *dxTemplate>
        <dx-text-area
          height="100"
          [(value)]="currentArea.description"
          stylingMode="outlined"
        >
        </dx-text-area>
      </div>
    </dxi-item>

    <dxi-item *ngIf="existingForm">
      <app-boiler-room-form
        *ngIf="currentArea.type.name === AreaTypesEnum.BoilerRoom"
        [auditId]="currentAuditId"
        [boilerRoom]="currentArea"
      ></app-boiler-room-form>

      <app-boiler-form
        *ngIf="currentArea.type.name === AreaTypesEnum.Boiler"
        [auditId]="currentAuditId"
        [boiler]="currentArea"
      ></app-boiler-form>

      <app-cooling-system
        *ngIf="currentArea.type.name === AreaTypesEnum.CoolingSysten"
        [auditId]="currentAuditId"
        [coolingSystem]="currentArea"
      ></app-cooling-system>

      <app-water-pump
        *ngIf="currentArea.type.name === AreaTypesEnum.WaterPump"
        [auditId]="currentAuditId"
        [waterPump]="currentArea"
      ></app-water-pump>

      <app-fan
        *ngIf="currentArea.type.name === AreaTypesEnum.Fan"
        [auditId]="currentAuditId"
        [fan]="currentArea"
      ></app-fan>

      <app-heat-recovery
        *ngIf="currentArea.type.name === AreaTypesEnum.HeatRecovery"
        [auditId]="currentAuditId"
        [heatRecovery]="currentArea"
      ></app-heat-recovery>

      <app-electricity-connection
        *ngIf="currentArea.type.name === AreaTypesEnum.ElectricityConnection"
        [auditId]="currentAuditId"
        [electricityConnection]="currentArea"
      ></app-electricity-connection>

      <app-photovoltaics
        *ngIf="currentArea.type.name === AreaTypesEnum.Photovoltaics"
        [auditId]="currentAuditId"
        [photovoltaics]="currentArea"
      ></app-photovoltaics>

      <app-air-conditioner
        *ngIf="currentArea.type.name === AreaTypesEnum.AirConditioner"
        [auditId]="currentAuditId"
        [airConditioner]="currentArea"
      ></app-air-conditioner>

      <app-transformer
        *ngIf="currentArea.type.name === AreaTypesEnum.Transformer"
        [auditId]="currentAuditId"
        [transformer]="currentArea"
      ></app-transformer>

      <app-deaerator
        *ngIf="currentArea.type.name === AreaTypesEnum.Deaerator"
        [auditId]="currentAuditId"
        [deaerator]="currentArea"
      ></app-deaerator>

      <app-cold-compressor
        *ngIf="currentArea.type.name === AreaTypesEnum.ColdCompressor"
        [auditId]="currentAuditId"
        [coldCompressor]="currentArea"
      ></app-cold-compressor>

      <app-gass-cogeneration
        *ngIf="currentArea.type.name === AreaTypesEnum.GassCogeneration"
        [auditId]="currentAuditId"
        [gassCogeneration]="currentArea"
      ></app-gass-cogeneration>

      <app-absorption-aggregate
        *ngIf="currentArea.type.name === AreaTypesEnum.AbsorptionAggregate"
        [auditId]="currentAuditId"
        [absorptionAggregate]="currentArea"
      ></app-absorption-aggregate>

      <app-compressed-air-generator
        *ngIf="currentArea.type.name === AreaTypesEnum.CompressedAirGenerator"
        [auditId]="currentAuditId"
        [compressedAirGenerator]="currentArea"
      ></app-compressed-air-generator>

      <app-blower
        *ngIf="currentArea.type.name === AreaTypesEnum.Blower"
        [auditId]="currentAuditId"
        [blower]="currentArea"
      ></app-blower>

      <app-air-compressor
        *ngIf="currentArea.type.name === AreaTypesEnum.AirCompressor"
        [auditId]="currentAuditId"
        [airCompressor]="currentArea"
      ></app-air-compressor>

      <app-fan-cooler
        *ngIf="currentArea.type.name === AreaTypesEnum.FanCooler"
        [auditId]="currentAuditId"
        [fanCooler]="currentArea"
      ></app-fan-cooler>
      <app-dehumidifier
        *ngIf="currentArea.type.name === AreaTypesEnum.Dehumidifier"
        [auditId]="currentAuditId"
        [dehumidifier]="currentArea"
      ></app-dehumidifier>

      <app-air-curtain
        *ngIf="currentArea.type.name === AreaTypesEnum.AirCurtain"
        [auditId]="currentAuditId"
        [airCurtain]="currentArea"
      ></app-air-curtain>
      <app-electric-heater
        *ngIf="currentArea.type.name === AreaTypesEnum.ElectricHeater"
        [auditId]="currentAuditId"
        [electricHeater]="currentArea"
      ></app-electric-heater>

      <app-heat-pump
        *ngIf="currentArea.type.name === AreaTypesEnum.HeatPump"
        [auditId]="currentAuditId"
        [heatPump]="currentArea"
      ></app-heat-pump>

      <app-air-enhancer
        *ngIf="currentArea.type.name === AreaTypesEnum.AirEnhancer"
        [auditId]="currentAuditId"
        [airEnhancer]="currentArea"
      ></app-air-enhancer>

      <app-building
        *ngIf="currentArea.type.name === AreaTypesEnum.Building"
        [auditId]="currentAuditId"
        [building]="currentArea"
      ></app-building>

      <app-water-heater
        *ngIf="currentArea.type.name === AreaTypesEnum.WaterHeater"
        [auditId]="currentAuditId"
        [waterHeater]="currentArea"
      ></app-water-heater>

      <app-steam-generator
        *ngIf="currentArea.type.name === AreaTypesEnum.SteamGenerator"
        [auditId]="currentAuditId"
        [steamGenerator]="currentArea"
      ></app-steam-generator>

      <app-lighting
        *ngIf="currentArea.type.name === AreaTypesEnum.Lighting"
        [auditId]="currentAuditId"
        [lighting]="currentArea"
      ></app-lighting>

      <app-radiant-heater
        *ngIf="currentArea.type.name === AreaTypesEnum.RadiantHeater"
        [auditId]="currentAuditId"
        [radiantHeater]="currentArea"
      ></app-radiant-heater>

      <app-armature
        *ngIf="currentArea.type.name === AreaTypesEnum.Armature"
        [auditId]="currentAuditId"
        [armature]="currentArea"
      ></app-armature>

      <app-ventilation-central
        *ngIf="currentArea.type.name === AreaTypesEnum.VentilationCentral"
        [auditId]="currentAuditId"
        [ventilationCentral]="currentArea"
      ></app-ventilation-central>

      <app-heater
        *ngIf="currentArea.type.name === AreaTypesEnum.Heater"
        [auditId]="currentAuditId"
        [heater]="currentArea"
      ></app-heater>

      <app-injection-machine
        *ngIf="currentArea.type.name === AreaTypesEnum.InjectionMachine"
        [auditId]="currentAuditId"
        [injectionMachine]="currentArea"
      ></app-injection-machine>

      <app-press
        *ngIf="currentArea.type.name === AreaTypesEnum.Press"
        [auditId]="currentAuditId"
        [press]="currentArea"
      ></app-press>

      <app-transport
        *ngIf="currentArea.type.name === AreaTypesEnum.Transport"
        [auditId]="currentAuditId"
        [transport]="currentArea"
      ></app-transport>

      <app-electric-drive
        *ngIf="currentArea.type.name === AreaTypesEnum.ElectricDrive"
        [auditId]="currentAuditId"
        [electricDrive]="currentArea"
      ></app-electric-drive>

      <app-vacuum-pump
        *ngIf="currentArea.type.name === AreaTypesEnum.VacuumPump"
        [auditId]="currentAuditId"
        [vacuumPump]="currentArea"
      ></app-vacuum-pump>

      <app-power-generator
        *ngIf="currentArea.type.name === AreaTypesEnum.PowerGenerator"
        [auditId]="currentAuditId"
        [powerGenerator]="currentArea"
      ></app-power-generator>
    </dxi-item>

    <dxi-item
      *ngIf="existingForm"
      itemType="group"
      cssClass="georama-font"
      [dataField]="_('area-action-list')"
    >
      <div *dxTemplate>
        <div id="dataGridDiv">
          <dx-data-grid
            #actionDataGrid
            id="gridContainer"
            [dataSource]="dataSource"
            [remoteOperations]="true"
            [allowColumnReordering]="true"
            [columnAutoWidth]="true"
            [allowColumnResizing]="false"
            [showBorders]="true"
          >
            <dxo-paging [enabled]="true"></dxo-paging>
            <dxo-editing
              mode="popup"
              [allowUpdating]="true"
              [allowDeleting]="true"
              [allowAdding]="true"
            >
              <dxo-popup
                [title]="_('area-action-details')"
                [fullScreen]="false"
                [width]="popupWidth + '%'"
                [height]="popupHeight + '%'"
                [showTitle]="true"
                showCloseButton="true"
                [hideOnOutsideClick]="true"
                [onShowing]="onDxoPopupShowing"
              >
                <dxi-toolbar-item
                  widget="dxButton"
                  toolbar="bottom"
                  location="after"
                  cssClass="item-contained-button-focus-hover"
                  [options]="saveActionButtonOptions"
                >
                </dxi-toolbar-item>
              </dxo-popup>
              <dxo-form
                [showColonAfterLabel]="true"
                [colCount]="1"
                labelLocation="top"
              >
                <dxi-item
                  dataField="name"
                  editorType="dxTextBox"
                  [editorOptions]="{
                    stylingMode: 'outlined',
                  }"
                >
                  <dxo-label [text]="_('area-action-name')"></dxo-label>
                </dxi-item>

                <dxi-item
                  dataField="actionTypeId"
                  editorType="dxSelectBox"
                  [editorOptions]="{
                    stylingMode: 'outlined',
                    placeholder: this._('area-action-type-select-placeholder'),
                  }"
                >
                </dxi-item>

                <dxi-item
                  dataField="description"
                  editorType="dxTextArea"
                  [editorOptions]="{
                    height: '100px',
                    stylingMode: 'outlined',
                  }"
                >
                  <dxo-label [text]="_('area-action-description')"></dxo-label>
                </dxi-item>

                <dxi-item
                  itemType="group"
                  cssClass="georama-font"
                  [caption]="_('area-action-energy-saving')"
                >
                  <dxi-item
                    dataField="energySavingName"
                    editorType="dxTextBox"
                    [editorOptions]="{ stylingMode: 'outlined' }"
                  >
                    <dxo-label [text]="_('area-action-energy-saving-name')"></dxo-label>
                  </dxi-item>
                  <dxi-item
                    dataField="energySavingTypeId"
                    editorType="dxSelectBox"
                    [editorOptions]="{ stylingMode: 'outlined' }"
                  >
                    <dxo-label [text]="_('area-action-energy-saving-type')"></dxo-label>
                  </dxi-item>
                  <dxi-item
                    dataField="energySavingAmount"
                    editorType="dxNumberBox"
                    [editorOptions]="{ stylingMode: 'outlined' }"
                  >
                    <dxo-label [text]="_('area-action-energy-saving-amount')"></dxo-label>
                  </dxi-item>
                  <dxi-item
                    dataField="unitId"
                    editorType="dxSelectBox"
                    [editorOptions]="{
                      stylingMode: 'outlined',
                      placeholder: 'Wybierz jednostke',
                    }"
                  >
                  </dxi-item>
                </dxi-item>

                <dxi-item
                  itemType="group"
                  cssClass="georama-font"
                  [caption]="_('area-action-financial-saving')"
                >
                  <dxi-item
                    dataField="financialSavingName"
                    editorType="dxTextBox"
                    [editorOptions]="{ stylingMode: 'outlined' }"
                  >
                    <dxo-label [text]="_('area-action-financial-saving-name')"></dxo-label>
                  </dxi-item>

                  <dxi-item
                    dataField="financialSavingAmount"
                    editorType="dxNumberBox"
                    [editorOptions]="{ stylingMode: 'outlined' }"
                  >
                    <dxo-label [text]="_('area-action-financial-saving-amount')"></dxo-label>
                  </dxi-item>

                  <dxi-item
                    dataField="currencyId"
                    editorType="dxSelectBox"
                    [editorOptions]="{
                      stylingMode: 'outlined',
                      placeholder: 'Wybierz jednostke',
                    }"
                  ></dxi-item>
                </dxi-item>
              </dxo-form>
              <dxo-texts
                [deleteRow]="_('area-acton-delete-hint')"
                [addRow]="_('area-acton-add-hint')"
                [editRow]="_('area-acton-edit-hint')"
                [confirmDeleteMessage]="_('area-acton-delete-confirm-message')"
                [saveRowChanges]="_('area-acton-save')"
                [cancelRowChanges]="_('area-acton-cancel')"
              >
              </dxo-texts>
            </dxo-editing>

            <dxi-column
              dataField="name"
              [caption]="_('area-action-name')"
            >
              <dxi-validation-rule
                type="required"
                [message]="_('area-action-name-required-messsage')"
              ></dxi-validation-rule>
              <dxi-validation-rule
                type="stringLength"
                [min]="1"
                [message]="_('area-action-name-validation-messsage')"
              ></dxi-validation-rule>
            </dxi-column>

            <dxi-column
              dataField="description"
              [caption]="_('area-action-description')"
            ></dxi-column>
            <dxi-column
              dataField="actionTypeId"
              [caption]="_('area-action-type')"
            >
              <dxo-lookup
                [dataSource]="actionTypes"
                displayExpr="name"
                valueExpr="id"
              >
              </dxo-lookup>
            </dxi-column>
            <dxi-column
              dataField="energySavingName"
              [caption]="_('area-action-energy-saving-name')"
            ></dxi-column>
            <dxi-column
              dataField="energySavingTypeId"
              [caption]="_('area-action-energy-saving-type')"
            >
              <dxo-lookup
                [dataSource]="energySavingTypes"
                displayExpr="name"
                valueExpr="id"
              >
              </dxo-lookup>
            </dxi-column>
            <dxi-column
              dataField="energySavingAmount"
              [caption]="_('area-action-energy-saving')"
            ></dxi-column>
            <dxi-column
              dataField="unitId"
              [caption]="_('unit')"
            >
              <dxo-lookup
                [dataSource]="units"
                displayExpr="name"
                valueExpr="id"
              ></dxo-lookup>
            </dxi-column>
            <dxi-column
              dataField="financialSavingName"
              [caption]="_('area-action-financial-saving-name')"
            ></dxi-column>
            <dxi-column
              dataField="financialSavingAmount"
              [caption]="_('area-action-financial-saving-amount')"
            ></dxi-column>
            <dxi-column
              dataField="currencyId"
              [caption]="_('currency')"
            >
              <dxo-lookup
                [dataSource]="currencies"
                displayExpr="name"
                valueExpr="id"
              ></dxo-lookup>
            </dxi-column>
          </dx-data-grid>
        </div>
      </div>
    </dxi-item>
    <dxi-item dataField="{{ _('conclusions') }}">
      <div *dxTemplate>
        <dx-text-area
          height="140"
          [(value)]="currentArea.conclusion"
          stylingMode="outlined"
          [disabled]="templateMode"
        ></dx-text-area>
        <div *ngIf="templateMode">
          <app-field-doc-variable [text]="this.docVariableCode + 'Conclusion'"></app-field-doc-variable>
        </div>
      </div>
    </dxi-item>
  </dx-form>
</ng-template>
