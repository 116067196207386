import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { ElectricHeater, ElectricHeaterType } from 'src/app/shared/models/area/types/electric-heater.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-electric-heater',
  templateUrl: './electric-heater.component.html',
  styleUrls: ['./electric-heater.component.scss'],
})
export class ElectricHeaterComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  electricHeater: ElectricHeater = null;

  currentYear: number;
  electricHeaterTypesLIst: ArrayStore;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getElectricHeaterTypes().subscribe((res: Array<ElectricHeaterType>) => {
      this.electricHeaterTypesLIst = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
