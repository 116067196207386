import { Component, OnInit, inject } from '@angular/core';
import { Localizable } from '../../../../locale/localizable';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { AppSettings } from '../../../../AppSettings';
import { AppConfig } from '../../../../app.config';
import { ErrorHandlerService } from '../../../services/errorHandlerService';
import { QuestionnaireVersionDto } from '../version-creator/version-creator.component';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRoleGuard } from '../../../../core/guards/user-role.guard';

@Component({
  selector: 'app-version-overview',
  templateUrl: './version-overview.component.html',
  styleUrls: ['./version-overview.component.scss'],
})
export class VersionOverviewComponent extends Localizable implements OnInit {
  public versions: Array<QuestionnaireVersionDto>;
  public selectedVersion: QuestionnaireVersionDto;

  questionnaireVersionInfos: { filegroups: [] };
  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;
  public versionCreatorVisible = false;
  apiUrl: string;
  private userRoleGuard: UserRoleGuard;

  constructor(
    private _appConfig: AppConfig,
    private _httpClient: HttpClient,
    private _errorHandlerService: ErrorHandlerService,
    private _config: AppConfig,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
    this.apiUrl = this._config.getConfig('DbEnergyDatabaseUrl');
    this.userRoleGuard = inject(UserRoleGuard);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.versions = [];
    this.requestVersionFileList();
  }

  isUserAdmin() {
    return this.userRoleGuard.isAdmin();
  }
  reloadVersions() {
    this.requestVersionFileList();
  }

  addVersion() {
    this.versionCreatorVisible = true;
  }

  onOpenFileGroup(name: string) {
    this.getFileByMetaDataName(name).subscribe((response: string) => {
      response = response.replace(/['"]/g, '');
      this.navigateToComponent(response);
    });
  }

  navigateToComponent(id: string): void {
    this.router.navigate(['/pages/questionnaire-diagram', id]);
  }

  private requestVersionFileList() {
    this._httpClient
      .get(this._appConfig.getConfig('DbEnergyDatabaseUrl') + 'api/questionnaires/files', { responseType: 'json' })
      .pipe(retry(AppSettings.REQUEST_TRIES))
      .subscribe(
        (data: Array<QuestionnaireVersionDto>) => {
          this.versions = data;
          setTimeout(() => {
            // otherwise console error
            // https://supportcenter.devexpress.com/ticket/details/t946156/accordion-cannot-read-property-resolved-of-undefined
            this.selectedVersion = this.versions[0];
          }, AppSettings.REASONABLE_DELAY);
        },
        (error) => {
          console.log(error);
          this._errorHandlerService.handleErrorAdvanced(error, AppSettings.NOTIFY_DURATION, true);
        }
      );
  }

  private getFileByMetaDataName(name: string): Observable<HttpResponse<any> | string> {
    return this._httpClient
      .get(`${this.apiUrl}api/questionnaires/file/metadata/name/${name}`, { responseType: 'text' })
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }
}
