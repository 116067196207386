import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { Template } from '../../models/template';

@Component({
  selector: 'app-missing-template-files',
  templateUrl: './missing-template-files.component.html',
})
export class MissingTemplateFilesComponent implements OnInit {
  public missingFiles: Array<Template> = null;
  public showPopup: boolean = false;
  private apiUrl: string = null;

  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppConfig
  ) {
    this.apiUrl = this._appConfig.getConfig('DbEnergyDatabaseUrl');
  }

  ngOnInit(): void {
    this._httpClient.get<Array<Template>>(this.apiUrl + `api/templates/verify`).subscribe((res) => {
      if (res.length > 0) {
        this.missingFiles = res;
        this.showPopup = true;
      }
    });
  }
}
