<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="dehumidifier.numberOfDevices"
        stylingMode="outlined"
        [min]="0"
        [max]="100"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('dehumidifier-number-of-devices')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="dehumidifier.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('dehumidifier-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="dehumidifier.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('dehumidifier-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="dehumidifier.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('dehumidifier-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="dehumidifierTypes">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="dehumidifier.dehumidifierType"
        [dataSource]="dehumidifierTypes"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('dehumidifier-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('dehumidifier-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="dehumidifier.ratedPower"
        stylingMode="outlined"
        [min]="0"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('dehumidifier-rated-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="dehumidifier.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('dehumidifier-working-hours-in-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³/h'"
        [(value)]="dehumidifier.efficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('dehumidifier-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="refrigerantTypes">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="dehumidifier.refrigerantType"
        [dataSource]="refrigerantTypes"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('Wybierz czynnik chłodniczy')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('dehumidifier-refrigerant-type')"></dxo-label>
  </dxi-item>
</dx-form>
