import { Component, Input, OnInit } from '@angular/core';
import { Localizable } from '../../../../../../../locale/localizable';
import { CompressedAirGenerator } from 'src/app/shared/models/area/types/compressed-air-generator';

@Component({
  selector: 'app-compressed-air-generator',
  templateUrl: './compressed-air-generator.component.html',
  styleUrls: ['./compressed-air-generator.component.scss'],
})
export class CompressedAirGeneratorComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  compressedAirGenerator: CompressedAirGenerator = null;

  currentYear: number;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
}
