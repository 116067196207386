import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { AbsorptionAggregate, WorkFactor } from 'src/app/shared/models/area/types/absorption-aggregate';
import { TechnicalCondition } from 'src/app/shared/models/area/types/water-pump.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-absorption-aggregate',
  templateUrl: './absorption-aggregate.component.html',
  styleUrls: ['./absorption-aggregate.component.scss'],
})
export class AbsorptionAggregateComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  absorptionAggregate: AbsorptionAggregate = null;

  @Input()
  templateMode: boolean = false;

  docVariableCode: string = null;

  workFactorTypesList: ArrayStore;
  absorptionTypesList: ArrayStore;
  technicalConditions: ArrayStore;
  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    if (this.templateMode) {
      this._apiRequestService.getAbsorptionAggregateDocVariableCode().subscribe((res) => {
        this.docVariableCode = res;
        this.absorptionAggregate = this.getNewEmptyAbsorptionAggregat();
      });
    } else {
      this.currentYear = new Date().getFullYear();

      this._apiRequestService.getWorkFactorTypes().subscribe((res: Array<WorkFactor>) => {
        this.workFactorTypesList = new ArrayStore({
          key: 'id',
          data: res,
        });
      });

      this._apiRequestService.getAbsorptionTypes().subscribe((res: Array<WorkFactor>) => {
        this.absorptionTypesList = new ArrayStore({
          key: 'id',
          data: res,
        });
      });

      this._apiRequestService.getAreaTechnicalConditions().subscribe((res: Array<TechnicalCondition>) => {
        this.technicalConditions = new ArrayStore({
          key: 'id',
          data: res,
        });
      });
    }
  }

  private getNewEmptyAbsorptionAggregat(): AbsorptionAggregate {
    return {
      producer: '',
      model: '',
      serialNumber: '',
      productionYear: 0,
      absorptionType: null,
      workFactor: null,
      ratedEfficiency: 0,
      workingHoursInYear: 0,
      ratedCoolingCapacity: 0,
      ratedHeatDemand: 0,
      inputChilledWaterTemp: 0,
      outputChilledWaterTemp: 0,
      chilledWaterFlow: 0,
      inputHeatSourceTemp: 0,
      outputHeatSourceTemp: 0,
      heatSourceFlow: 0,
      workingHoursInAnalyzedPeriod: 0,
      coolingEnergyInAnalyzedPeriod: 0,
      electricEnergyConsumptionInAnalyzedPeriod: 0,
      heatEnergyConsumptionInAnalyzedPeriod: 0,
      technicalCondition: null,
    };
  }
}
