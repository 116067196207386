export enum FormType {
  Audit = 'Audit',
  Enterprise = 'Enterprise',
  Department = 'Department',
  Area = 'Area',
}

export interface AuditTreeNavigation {
  id: string;
  parentId: string;
  name: string;
  formType: FormType;
  expanded: boolean;
  selectable: boolean;
}
