import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { AppSettings } from '../../AppSettings';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  public urlDBEnergyDatabase: any;

  constructor(
    private httpClient: HttpClient,
    private config: AppConfig
  ) {
    this.urlDBEnergyDatabase = this.config.getConfig('DbEnergyDatabaseUrl');
  }
  generateReport(auditId: string): void {
    this.httpClient
      .get<Blob>(this.urlDBEnergyDatabase + `api/audits/${auditId}/report`)
      .pipe(retry(AppSettings.REQUEST_TRIES))
      .subscribe();
  }
}
