import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Localizable } from '../../../../locale/localizable';
import { QuestionnaireGroupService } from '../questionnaireGroup.service';
import { QuestionnaireService } from '../questionnaire.service';
import { ApiRequestService } from '../../../services/api-request.service';
import { ScrollService } from '../../../services/scroll.service';
import { CloseResult } from '../exit-confirmation-popup/exit-confirmation-popup.component';
import { ProgressPoints, QuestionnaireMetaInfo } from '../questionnaire.model';
import { delay, takeUntil } from 'rxjs/operators';
import { AppSettings } from '../../../../AppSettings';
import { QuestionGroup, QuestionnaireTags } from '../models/questionnaire.models';
import { isAdmin } from '../../../../core/guards/user-role.guard';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartmentFormService } from '../../forms/department-form/department-form/department-form.service';

@Component({
  selector: 'app-questionnaire-popup',
  templateUrl: './questionnaire-popup.component.html',
  styleUrls: ['./questionnaire-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionnairePopupComponent extends Localizable {
  @Input() currentDepartmentId = '';
  @Input() enterpriseId = '';
  @Input() currentDepartmentName = '';
  @Input() popupWidth: any;
  @Input() popupHeight: any;
  @Input() isAuditFinished: boolean = false;
  @Input() currentAuditId = '';
  disabledNextButton: boolean;
  disabledPrevButton: boolean;
  entepriseName = '';
  updateMessageTemplate: any;
  groupsToBeDiscarded: Array<QuestionGroup>;
  orphanedNotesPopupVisible: boolean = false;

  @Input() set questionnairePopupVisible(value) {
    this._popupVisible = value;
    if (value === false) {
      this.questionnairePopupVisibleChange.emit(value);
    }
  }
  get questionnairePopupVisible() {
    return this._popupVisible;
  }

  public currentQuestionnairePopupVisible = false;
  public updateQuestionnairePopupVisible = false;

  public loadIndicatorWidth = AppSettings.LOAD_INDICATOR_WIDTH;
  public loadIndicatorHeight = AppSettings.LOAD_INDICATOR_HEIGHT;

  @Output() questionnairePopupVisibleChange = new EventEmitter<boolean>();

  private _popupVisible: boolean;

  constructor(
    private _groupService: QuestionnaireGroupService,
    private _questionnaireService: QuestionnaireService,
    private _apiRequestService: ApiRequestService,
    private _scrollService: ScrollService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _departmentFormService: DepartmentFormService
  ) {
    super('Questionnaire');
    this.currentlySelectedGroup = { _id: '', _next: '', _name: '', guid: '' };
    this.currentlyAvailableGroups = [];

    this._groupService.selectedGroupSubject
      .asObservable()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((group) => {
        if (group !== undefined) {
          this.currentlySelectedGroup = group;
          if (this._questionnaireService.questionnaireGroupDto.metaInfo.totalProgress > 0) {
            this.totalProgress = this._questionnaireService.questionnaireGroupDto.metaInfo.totalProgress;
          } else {
            this.totalProgress = this.currentlyAvailableGroups.length * ProgressPoints.Completed;
          }
          // questionnaire can be updated - at least one group can be updated.
          this.updateButtonVisible = this._questionnaireService.questionnaireGroupDto.metaInfo.updateReady;
          const updateInfos = this._questionnaireService.questionnaireGroupDto.metaInfo.groupUpdateInfos;
          if (updateInfos !== null) {
            this.groupsToBeDiscarded = this.translatedCurrentlyAvailableGroups.filter((translatedGroup) =>
              updateInfos.some((info) => info.groupId === translatedGroup._id && info.updateable === false)
            );
          }
          this._questionnaireService.fillTreeGraph();
          this._questionnaireService.updateProgressGroup();
        }
      });

    this._groupService.availableGroupsSubject
      .asObservable()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((groups) => {
        if (groups !== undefined) {
          this.currentlyAvailableGroups = groups;
          this.translatedCurrentlyAvailableGroups = this.translateAvailableGroups();
        }
      });

    this._groupService.isNextGroupButtonDisabledSubject
      .asObservable()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((disabled) => {
        if (disabled !== undefined) {
          this.disabledNextButton = disabled;
          this.nextGroupButtonOptions = {
            icon: 'fi fi-rr-angle-small-right',
            text: this._('next-group-text'),
            rtlEnabled: true,
            disabled: this.disabledNextButton,
            stylingMode: 'outlined',
            onClick: () => {
              const nodesWithNotesWithoutAnswers =
                this._questionnaireService.getNodesWithNotesWithoutHavingAnswerProvided();
              if (nodesWithNotesWithoutAnswers.length > 0) {
                // inform user that he has some questions with notes but without answers
                this.orphanedNotesConfirmFn = () => this._groupService.handleNextGroupButton(this.isAuditFinished);
                this.orphanedNotesPopupVisible = true;
              } else {
                this._groupService.handleNextGroupButton(this.isAuditFinished);
              }
            },
          };
        }
      });
    this._groupService.isPreviousGroupButtonDisabledSubject
      .asObservable()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((disabled) => {
        if (disabled !== undefined) {
          this.disabledPrevButton = disabled;
          this.prevGroupButtonOptions = {
            icon: 'fi fi-rr-angle-small-left',
            text: this._('previous-group-text'),
            disabled: this.disabledPrevButton,
            stylingMode: 'outlined',
            onClick: () => {
              const nodesWithNotesWithoutAnswers =
                this._questionnaireService.getNodesWithNotesWithoutHavingAnswerProvided();
              if (nodesWithNotesWithoutAnswers.length > 0) {
                // inform user that he has some questions with notes but without answers
                this.orphanedNotesConfirmFn = () => this._groupService.handlePreviousGroupButton(this.isAuditFinished);
                this.orphanedNotesPopupVisible = true;
              } else {
                this._groupService.handlePreviousGroupButton(this.isAuditFinished);
              }
            },
          };
        }
      });

    this._groupService.progressInfoSubject
      .asObservable()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((metaInfo: QuestionnaireMetaInfo) => {
        this.currentProgress = metaInfo.currentProgress;
        this.groupProgressInfos = metaInfo.groupProgressInfos;
        if (
          this.translatedCurrentlyAvailableGroups !== undefined &&
          Array.isArray(this.translatedCurrentlyAvailableGroups)
        ) {
          this.translatedCurrentlyAvailableGroups.forEach((group) => {
            this.setGroupIcons(group);
          });
        }
      });
  }

  public totalProgress: number;
  public totalGroupProgress: number = 1;
  public currentGroupProgress: number = 0;
  public currentProgress: number;
  public groupProgressInfos: Array<{
    groupId: string;
    progress: number;
  }>;
  public questionnaireLoading: boolean = true;

  ngOnInit(): void {
    super.ngOnInit();
    this._groupService.xmlLoadedSubject
      .pipe(takeUntil(this.onDestroy), delay(AppSettings.QUESTIONNAIRE_LOADING_DURATION))
      .subscribe((data) => {
        this.questionnaireLoading = !data.loaded;
        this.currentQuestionnairePopupVisible = true;
      });

    this.isHiddenFromDialog = false;
    this._apiRequestService.getEnterprise(this.enterpriseId).subscribe((result) => {
      this.entepriseName = result.name;
    });

    this.nextGroupButtonOptions = {
      icon: 'fi fi-rr-angle-small-right',
      text: this._('next-group-text'),
      disabled: this.disabledNextButton,
      rtlEnabled: true,
      stylingMode: 'outlined',
      type: 'normal',
      onClick: () => {
        const nodesWithNotesWithoutAnswers = this._questionnaireService.getNodesWithNotesWithoutHavingAnswerProvided();
        if (nodesWithNotesWithoutAnswers.length > 0) {
          // inform user that he has some questions with notes but without answers
          this.orphanedNotesConfirmFn = () => this._groupService.handleNextGroupButton(this.isAuditFinished);
          this.orphanedNotesPopupVisible = true;
        } else {
          this._groupService.handleNextGroupButton(this.isAuditFinished);
        }
      },
    };
    this.prevGroupButtonOptions = {
      icon: 'fi fi-rr-angle-small-left',
      text: this._('previous-group-text'),
      disabled: this.disabledPrevButton,
      stylingMode: 'outlined',
      type: 'normal',
      onClick: () => {
        const nodesWithNotesWithoutAnswers = this._questionnaireService.getNodesWithNotesWithoutHavingAnswerProvided();
        if (nodesWithNotesWithoutAnswers.length > 0) {
          // inform user that he has some questions with notes but without answers
          this.orphanedNotesConfirmFn = () => this._groupService.handlePreviousGroupButton(this.isAuditFinished);
          this.orphanedNotesPopupVisible = true;
        } else {
          this._groupService.handlePreviousGroupButton(this.isAuditFinished);
        }
      },
    };

    this.saveButtonOptions = {
      icon: 'fi fi-rr-check',
      type: 'normal',
      text: this._('save-question-button'),
      onClick: () => {
        const nodesWithNotesWithoutAnswers = this._questionnaireService.getNodesWithNotesWithoutHavingAnswerProvided();
        if (nodesWithNotesWithoutAnswers.length > 0) {
          // inform user that he has some questions with notes but without answers
          this.orphanedNotesConfirmFn = () => this.saveGroupAndReload();
          this.orphanedNotesPopupVisible = true;
        } else {
          this.saveGroupAndReload();
        }
      },
    };

    this.downloadCSVButtonOptions = {
      stylingMode: 'outlined',
      text: this._('download-questionnaire-button'),
      onClick: () => {
        this._questionnaireService.downloadCSV();
      },
    };

    this._scrollService.scrollToBottom$.pipe(takeUntil(this.onDestroy)).subscribe((result) => {
      if (result) {
        const elem = document.getElementById('questionnaireScrollView');

        if (elem === null) {
          return;
        }

        const scrollalble = elem.querySelector('.dx-scrollable-container');

        scrollalble.scrollTo({
          top: result.offsetTop,
          behavior: 'auto',
        });
      }
    });

    this._questionnaireService.currentGroupProgress.pipe(takeUntil(this.onDestroy)).subscribe((current) => {
      this.currentGroupProgress = current;
    });

    this._questionnaireService.totalGroupProgress.pipe(takeUntil(this.onDestroy)).subscribe((total) => {
      this.totalGroupProgress = total;
    });

    this._groupService.getQuestionnaireFileByDepartmentId(this.currentDepartmentId);
  }

  private saveGroupAndReload() {
    this._groupService.onGroupSelected(
      {
        itemData: { _id: this.currentlySelectedGroup._id },
      },
      this.isAuditFinished
    );
    this._groupService.getGroupFile({
      metaDataId: this._questionnaireService.questionnaireGroupDto.metaDataId,
    });
    if (this._questionnaireService.questionnaireGroupDto.questions.length > 0) {
      const group = this.groupProgressInfos.find((gr) => gr.groupId === this.currentlySelectedGroup._id);
      this.updateGroupProgress(group);
    }

    this.translatedCurrentlyAvailableGroups.forEach((gr) => {
      this.setGroupIcons(gr);
    });
  }

  private updateGroupProgress(group) {
    const hasEndTag = this._questionnaireService.questionnaireGroupDto.questions.some((question) =>
      question.answers.some((answer) => answer.nextQuestionId.toLocaleUpperCase() === QuestionnaireTags.EndTag)
    );
    const hasSkipTag = this._questionnaireService.questionnaireGroupDto.questions.some((question) =>
      question.answers.some((answer) => answer.id.toLocaleUpperCase() === QuestionnaireTags.SkipTag)
    );

    if (hasEndTag && !hasSkipTag) {
      this.updateProgressToCompleted(group);
    } else {
      this.updateProgressToInProgress(group);
    }
  }

  private updateProgressToCompleted(group) {
    if (group.progress === ProgressPoints.NoProgress || group.progress === ProgressPoints.InProgress) {
      const progressDifference = ProgressPoints.Completed - group.progress;
      group.progress = ProgressPoints.Completed;
      this.currentProgress += progressDifference;
    }
  }

  private updateProgressToInProgress(group) {
    if (group.progress === ProgressPoints.NoProgress) {
      group.progress = ProgressPoints.InProgress;
      this.currentProgress += ProgressPoints.InProgress - ProgressPoints.NoProgress;
    } else if (group.progress === ProgressPoints.Completed) {
      group.progress = ProgressPoints.InProgress;
      this.currentProgress -= ProgressPoints.Completed - ProgressPoints.InProgress;
    }
  }

  public onHidingHandler(event: any) {
    if (this._questionnaireService.isDirty && !this.isHiddenFromDialog) {
      event.cancel = true;
      this.closeQuestionnaireHandler = () => {
        // close
        this.isHiddenFromDialog = true; // Do not open dialog second time
        this.questionnairePopupVisible = false; // Notify parent component
      };
      this.exitPopupVisible = true;
    } else {
      this.questionnairePopupVisible = false;
    }
  }

  public showQuestionnaire(visible: boolean): void {
    if (this._questionnaireService.isDirty && !this.isHiddenFromDialog) {
      this.closeQuestionnaireHandler = () => {
        // close
        this.isHiddenFromDialog = true; // Do not open dialog second time
        this.questionnairePopupVisible = false; // Notify parent component
      };
      this.exitPopupVisible = true;
    } else {
      this._departmentFormService.questionnairePopupClosed();
      this.questionnairePopupVisible = visible;
    }
  }

  public onGroupSelected(e: any) {
    const nodesWithNotesWithoutAnswers = this._questionnaireService.getNodesWithNotesWithoutHavingAnswerProvided();
    if (nodesWithNotesWithoutAnswers.length > 0) {
      // inform user that he has some questions with notes but without answers
      this.orphanedNotesFnArg = e;
      this.orphanedNotesConfirmFn = () => {
        this._groupService.onGroupSelected(this.orphanedNotesFnArg, this.isAuditFinished);
      };
      this.orphanedNotesPopupVisible = true;
    } else {
      this._groupService.onGroupSelected(e, this.isAuditFinished);
    }
  }

  public onClickDowloadCSVButton(): void {
    this._questionnaireService.downloadCSV();
  }

  closeQuestionnaireHandler: any;
  public onCloseQuestionnaire(e: CloseResult) {
    this.exitPopupVisible = false;
    if (e === CloseResult.Confirm) {
      this.closeQuestionnaireHandler();
    }
    this._departmentFormService.questionnairePopupClosed();
  }

  public clearAnswers(): void {
    // Show exit dialog
    this.discardPopupVisible = true;
  }

  public onDiscardQuestionnaire(e: CloseResult) {
    this.discardPopupVisible = false;
    if (e === CloseResult.Confirm) {
      this._questionnaireService.dropDependentNodes(this._questionnaireService.treeRootNode, true);
      this._groupService.isNextGroupButtonDisabledSubject.next(false);
      this._groupService.isPreviousGroupButtonDisabledSubject.next(true);

      // call backend in order to drop everything below questionnaire
      this._groupService.deleteAllGroups().subscribe(() => {
        // emit event in services that makes component to ask again for group
        this._groupService.clearQuestionsSubject.next(true);
        this._questionnaireService.isDirty = false;
        this._groupService.redownloadFirstGroupSubject.next(true);
      });

      this.discardPopupVisible = false;
    }
  }

  public updateQuestionnaire(): void {
    this._groupService.upgradeQuestionnaire().subscribe(() => {
      // emit event in services that makes component to ask again for group
      this._questionnaireService.dropDependentNodes(this._questionnaireService.treeRootNode, true);
      this._groupService.isNextGroupButtonDisabledSubject.next(false);
      this._groupService.isPreviousGroupButtonDisabledSubject.next(true);
      this._groupService.clearQuestionsSubject.next(true);
      this._questionnaireService.isDirty = false;
      this._groupService.redownloadFirstGroupSubject.next(true);
      this.updateButtonVisible = false;
    });
    this.discardPopupVisible = false;
  }

  onUpdateQuestionnaire(e: CloseResult) {
    if (e === CloseResult.Confirm) {
      // exit
      this.updateQuestionnaire();
    }

    this.updateQuestionnairePopupVisible = false;
  }

  showUpdateQuestionnairePopup() {
    if (this.groupsToBeDiscarded.length > 0) {
      this.updateQuestionnairePopupVisible = true;
    } else {
      // no harm in the updating process - just update it.
      this.updateQuestionnaire();
    }
  }

  private setGroupIcons(group: QuestionGroup) {
    const progressInfo = this._questionnaireService.questionnaireGroupDto.metaInfo.groupProgressInfos.find(
      (gr) => gr.groupId === group._id
    );

    if (progressInfo === undefined) {
      group._icon = 'drop-down-button-no-icon';
    } else {
      if (progressInfo.progress === ProgressPoints.NoProgress) {
        group._icon = 'drop-down-button-no-icon';
      }
      if (progressInfo.progress === ProgressPoints.InProgress) {
        group._icon = 'fi fi-rr-pencil';
      }
      if (progressInfo.progress === ProgressPoints.Completed) {
        group._icon = 'fi fi-rr-check';
      }
    }
  }

  public translateAvailableGroups(): Array<QuestionGroup> {
    const translatedArray = new Array<QuestionGroup>();
    this.currentlyAvailableGroups.forEach((group) => {
      this.setGroupIcons(group);
      translatedArray.push({
        _name: this.localizeQuestionnaireGroupName(group),
        _id: group._id,
        _next: group._next,
        guid: group.guid,
        _icon: group._icon,
      });
    });

    return translatedArray;
  }

  private orphanedNotesConfirmFn: any;
  private orphanedNotesFnArg: any;
  onOrphanedNotesAction(e: any) {
    this.orphanedNotesPopupVisible = false;
    if (e === CloseResult.Confirm) {
      if (this.orphanedNotesFnArg != null) {
        this.orphanedNotesConfirmFn(this.orphanedNotesFnArg);
      } else {
        this.orphanedNotesConfirmFn();
      }
    }
    this.orphanedNotesConfirmFn = null;
    this.orphanedNotesFnArg = null;
  }

  public exitPopupVisible: boolean = false;
  public isHiddenFromDialog = false;
  public nextGroupButtonOptions: any;
  public prevGroupButtonOptions: any;
  public saveButtonOptions: any;
  public downloadCSVButtonOptions: any;
  public groupSelectorDropdownOptions: any;

  public currentlySelectedGroup: QuestionGroup;
  public currentlyAvailableGroups: Array<QuestionGroup>;
  public translatedCurrentlyAvailableGroups: Array<QuestionGroup>;
  public discardPopupVisible: boolean = false;
  public updateButtonVisible: boolean = false;
  public versionButtonVisible: boolean = isAdmin();
}
