<!-- Copyright C Alfa-Net. All Rights Reserved. -->

<div
  class="account-container"
  [ngClass]="{ mobile: isMobile }"
>
  <div class="avatar">
    <dx-button
      icon="fi fi-rr-circle-user"
      [activeStateEnabled]="false"
      [focusStateEnabled]="false"
      [hoverStateEnabled]="false"
    ></dx-button>
  </div>
  <div
    class="text"
    *ngIf="!isMobile"
  >
    <p class="body-text bold form-header-margin">{{ currentUser.firstName }} {{ currentUser.lastName }}</p>
    <p class="details-text form-header-margin">{{ this._('account') }}</p>
  </div>
  <div class="button">
    <dx-button
      data-testid="dropdown-account-button"
      class="focus-border text-button-focus-hover"
      stylingMode="text"
      [icon]="accountDropdownIcon"
      (onClick)="toggleDropdown($event)"
    ></dx-button>
  </div>

  <div
    class="dropdown-menu"
    [ngClass]="{ 'dropdown-menu-mobile': isMobile }"
    *ngIf="showDropdown"
  >
    <dx-menu
      #menu
      [dataSource]="menuDataSource"
      displayExpr="name"
      showFirstSubmenuMode="onClick"
      orientation="vertical"
      [hideSubmenuOnMouseLeave]="false"
      itemTemplate="items"
      width="100%"
      (onItemClick)="itemClick($event)"
    >
      <div
        *dxTemplate="let item of 'items'"
        class="new-menu-item"
        [attr.data-testid]="item.name"
      >
        <ng-container *ngIf="item.image">
          <img
            [src]="item.image"
            [alt]="item.name + 'flag'"
            class="item-image flag"
            width="100%"
            height="100%"
          />
        </ng-container>
        <ng-container *ngIf="!item.image">
          <span
            *ngIf="item.icon"
            [ngClass]="item.icon"
            class="dx-icon icon"
          ></span>
        </ng-container>
        <span class="dx-button-text case-sensitive">{{ this._(item.name) }}</span>
      </div>
    </dx-menu>
  </div>
</div>
