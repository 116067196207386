import {
  Component,
  NgModule,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DxTreeViewModule, DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import { navigation } from '../../../app-navigation';
import { Localizable } from '../../../locale/localizable';

import * as events from 'devextreme/events';
import { AuthenticationService } from '../../services/authentication.service';
import { AppSettings } from '../../../AppSettings';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { AuditsCountService } from '../../services/audits-count.service';

@Component({
  selector: 'app-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss'],
})
export class SideNavigationMenuComponent extends Localizable implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DxTreeViewComponent, { static: true })
  menu: DxTreeViewComponent;

  subscription: Subscription;
  public numberOfAudits = null;
  public navigationAuditName = null;

  public unselectSubscription: Subscription;
  @Output()
  selectedItemChanged = new EventEmitter<string>();

  @Output()
  openMenu = new EventEmitter<any>();

  private _selectedItem: string;
  @Input()
  set selectedItem(value: string) {
    this._selectedItem = value;
    if (!this.menu.instance) {
      return;
    }

    this.menu.instance.selectItem(value);
  }

  public items: any[];

  constructor(
    private elementRef: ElementRef,
    private authenticationService: AuthenticationService,
    private router: Router,
    private autitsCountService: AuditsCountService
  ) {
    super();
    this.navigationAuditName = AppSettings.NAVIGATION_AUDITS;
    this.items = this.initializeItems();
  }

  private initializeItems(): any[] {
    const items = navigation.map((item) => {
      if (item.path && !/^\//.test(item.path)) {
        item.path = `/${item.path}`;
      }
      this.translateItem(item);
      return { ...item, expanded: !this._compactMode };
    });

    return this.filterNavigationByRole(items);
  }

  private translateItem(item: any): void {
    item.text = this._(item.textKey);

    if (item.items && item.items.length) {
      item.items.forEach((subItem: any) => this.translateItem(subItem));
    }
  }

  private filterNavigationByRole(navItems: any) {
    const decodedUser = this.authenticationService.getDecodedUser();
    if (!decodedUser) {
      return [];
    }
    if (decodedUser.role !== AppSettings.ADMIN_ROLE_NAME) {
      return navItems.filter(
        (item) => item.textKey === AppSettings.NAVIGATION_AUDITS || item.textKey === AppSettings.NAVIGATION_DASHBOARD
      );
    }
    return navItems;
  }

  private _compactMode = false;
  @Input()
  get compactMode() {
    return this._compactMode;
  }
  set compactMode(val) {
    this._compactMode = val;

    if (!this.menu.instance) {
      return;
    }

    if (val) {
      this.menu.instance.collapseAll();
    } else {
      this.menu.instance.expandItem(this._selectedItem);
    }
  }

  ngOnInit(): void {
    this.autitsCountService.updateAuditCount();

    this.unselectSubscription = this.subscription = this.authenticationService.isUserAuthorized$.subscribe(
      (isLoggedIn) => {
        this.items = this.initializeItems();
      }
    );

    this.autitsCountService.auditsCount$.subscribe((count: number) => {
      this.numberOfAudits = count > AppSettings.SHRINK_AUDIT_COUNT ? '99+' : count;
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.startsWith('/pages/audit')) {
          this.handleUrlChange();
        }
      }
    });
  }

  handleUrlChange(): void {
    this.menu.instance.unselectAll();
  }

  onItemClick(event) {
    this.selectedItemChanged.emit(event);
  }

  ngAfterViewInit() {
    events.on(this.elementRef.nativeElement, 'dxclick', (e) => {
      this.openMenu.next(e);
    });
  }

  setDefaultSelectedItem() {
    if (this.selectedItem === undefined) {
      this.menu.instance.selectItem(AppSettings.DEFAULT_NAVIGATION_SELECTION_ROUTE);
    }
  }

  ngOnDestroy() {
    events.off(this.elementRef.nativeElement, 'dxclick');
    this.unselectSubscription.unsubscribe();
  }
}

@NgModule({
  imports: [DxTreeViewModule, CommonModule],
  declarations: [SideNavigationMenuComponent],
  exports: [SideNavigationMenuComponent],
})
export class SideNavigationMenuModule {}
