<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="container">
  <dx-button
    [text]="_('gallery-take-picture-button-text')"
    icon="image"
    (onClick)="showCamPopup()"
  ></dx-button>
</div>

<dx-popup
  *ngIf="takePicturePopup"
  [title]="_('gallery-take-picture-popup-title')"
  [fullScreen]="true"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [visible]="true"
  (onHidden)="popupClosed()"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <webcam
    [height]="500"
    [width]="500"
    [trigger]="triggerObservable"
    (imageCapture)="handleImage($event)"
    *ngIf="showWebcam"
    [allowCameraSwitch]="allowCameraSwitch"
    [switchCamera]="nextWebcamObservable"
    [videoOptions]="videoOptions"
    [imageQuality]="1"
    (cameraSwitched)="cameraWasSwitched($event)"
    (initError)="handleInitError($event)"
    [videoOptions]="videoOptions"
  ></webcam>
  <button
    class="actionBtn"
    (click)="triggerSnapshot()"
  >
    Take A Snapshot
  </button>
</dx-popup>
