import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import {
  ConnectionGroupe,
  ElectricityConnection,
  EnergyDistributor,
  EnergySeller,
  MeasuringSystem,
  Tariff,
} from 'src/app/shared/models/area/types/electricity-connection.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-electricity-connection',
  templateUrl: './electricity-connection.component.html',
  styleUrls: ['./electricity-connection.component.scss'],
})
export class ElectricityConnectionComponent extends Localizable implements OnInit {
  @Input()
  public auditId: string = '';

  @Input()
  public electricityConnection: ElectricityConnection = null;

  connectionGroupes: ArrayStore;
  measuringSystems: ArrayStore;
  energySellers: ArrayStore;
  energyDistributors: ArrayStore;
  tariffs: ArrayStore;

  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getConnectionGroupes().subscribe((res: Array<ConnectionGroupe>) => {
      this.connectionGroupes = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getMeasuringSystems().subscribe((res: Array<MeasuringSystem>) => {
      this.measuringSystems = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getEnergySellers().subscribe((res: Array<EnergySeller>) => {
      this.energySellers = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getEnergyDistributors().subscribe((res: Array<EnergyDistributor>) => {
      this.energyDistributors = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getTariffs().subscribe((res: Array<Tariff>) => {
      this.tariffs = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
