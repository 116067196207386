import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Enterprise } from 'src/app/shared/models/enterprise/enterprise.model';
import { IndustryType } from 'src/app/shared/models/enterprise/industry-type.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { UtilsService } from '../../../services/utils.service';
import { FormType } from '../../../models/audit/audit-tree-navigation.model';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseFormService {
  private enterpriseCopy: Enterprise;

  public _isFinished = new Subject<boolean>();
  public isFinished = this._isFinished.asObservable();

  private _performAction = new Subject<any>();
  public performAction = this._performAction.asObservable();

  private _isLoaded = new BehaviorSubject<{ value: boolean; formtype: FormType }>({
    value: false,
    formtype: FormType.Audit,
  });
  public isLoaded = this._isLoaded.asObservable();

  constructor(
    private _apiRequestService: ApiRequestService,
    private _utilsService: UtilsService
  ) {}

  public getEmptyEnterprise(): Enterprise {
    const enterprise: Enterprise = {
      id: '1',
      parentFormId: '1',
      name: '',
      numberOfDepartments: null,
      description: '',
      industryType: null,
      conclusion: '',
      departments: [],
      address: {
        country: '',
        zipCode: '',
        city: '',
        address: '',
      },
    };

    return enterprise;
  }

  public createCopy(enterprsie: Enterprise): void {
    this.enterpriseCopy = this._utilsService.deepCopy(enterprsie);
  }

  public formChanged(enterprsie: Enterprise): boolean {
    return !this._utilsService.areObjectsEqual(enterprsie, this.enterpriseCopy);
  }

  public update(eneterprise: Enterprise): Observable<Enterprise> {
    return this._apiRequestService.updateEnterprise(eneterprise);
  }

  public getCurrentEnterprise(enterpriseId: string): Observable<Enterprise> {
    return this._apiRequestService.getEnterprise(enterpriseId);
  }

  public getIndustryTypes(): Observable<Array<IndustryType>> {
    return this._apiRequestService.getIndustryTypes();
  }

  public formLoaded(value: boolean): void {
    this._isLoaded.next({ value, formtype: FormType.Enterprise });
  }

  public selectedAction(e: any): void {
    this._performAction.next(e);
  }

  public toggleFinishedAudit(isFinished: boolean): void {
    this._isFinished.next(isFinished);
  }
}
