<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="injectionMachine.numberOfDevices"
        stylingMode="outlined"
        [min]="0"
        [max]="1000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('injection-machine-number-of-devices')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="injectionMachine.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('injection-machine-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="injectionMachine.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('injection-machine-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="injectionMachine.serialNumber"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('injection-machine-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="injectionMachine.yearOfProduction"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('injection-machine-year-of-production')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="injectionMachineDriveTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="injectionMachine.injectionMachineDriveType"
        [dataSource]="injectionMachineDriveTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('injection-machine-injection-machine-drive-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('injection-machine-injection-machine-drive-type')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="injectionMachinePumpTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="injectionMachine.injectionMachinePumpType"
        [dataSource]="injectionMachinePumpTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="-'injection-machine-injection-machine-pump-type-placeholder'"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('injection-machine-injection-machine-pump-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' bar'"
        [(value)]="injectionMachine.injectionPressure"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('injection-machine-injection-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' t'"
        [(value)]="injectionMachine.maxClampingForce"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('injection-machine-max-clamping-force')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="injectionMachine.enginePower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('injection-machine-engine-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="injectionMachine.heatersPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('injection-machine-heaters-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' s'"
        [(value)]="injectionMachine.averageCycleTime"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('injection-machine-average-cycle-time')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' g'"
        [(value)]="injectionMachine.averageSizeItem"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('injection-machine-average-size-item')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="injectionMachine.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('injection-machine-working-hours-in-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="injectionMachine.inverters"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('injection-machine-inverters')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="injectionMachine.insulatedCylinders"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('injection-machine-insulated-cylinders')"></dxo-label>
  </dxi-item>
</dx-form>
