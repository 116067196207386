import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { VentilationCentral, VentilationCentralType } from 'src/app/shared/models/area/types/ventilation-central.model';
import { TechnicalCondition } from 'src/app/shared/models/area/types/water-pump.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { Localizable } from '../../../../../../../locale/localizable';

@Component({
  selector: 'app-ventilation-central',
  templateUrl: './ventilation-central.component.html',
  styleUrls: ['./ventilation-central.component.scss'],
})
export class VentilationCentralComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  ventilationCentral: VentilationCentral = null;

  @Input()
  templateMode: boolean = false;

  docVariableCode: string = null;
  ventilationCentralTypesList: ArrayStore;
  technicalConditionsList: ArrayStore;

  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    if (this.templateMode) {
      this._apiRequestService.getVentilationCentralDocVariableCode().subscribe((res) => {
        this.docVariableCode = res;
        this.ventilationCentral = this.getNewEmptyVentilationCentral();
      });
    } else {
      this.currentYear = new Date().getFullYear();

      this._apiRequestService.getAreaTypeVentilationCentralTypes().subscribe((res: Array<VentilationCentralType>) => {
        this.ventilationCentralTypesList = new ArrayStore({
          key: 'id',
          data: res,
        });
      });

      this._apiRequestService.getAreaTechnicalConditions().subscribe((res: Array<TechnicalCondition>) => {
        this.technicalConditionsList = new ArrayStore({
          key: 'id',
          data: res,
        });
      });
    }
  }

  private getNewEmptyVentilationCentral(): VentilationCentral {
    return {
      name: '',
      description: '',
      producer: '',
      model: '',
      ventilationCentralType: null,
      serialNumber: '',
      productionYear: 0,
      fansPowerInput: 0,
      airFlowInput: 0,
      fansPowerOutput: 0,
      airFlowOutput: 0,
      radiatorHeatOutput: 0,
      heaterThermalPower: 0,
      targetTemp: 0,
      recuperation: false,
      inverters: false,
      ductsInsulated: false,
      technicalCondition: null,
      conclusion: '',
    };
  }
}
