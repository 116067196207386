import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../../app.config';
import { QuestionnaireService } from './questionnaire.service';
import { AppSettings } from '../../../AppSettings';
import { concatMap, retry, switchMap } from 'rxjs/operators';
import notify from 'devextreme/ui/notify';
import { formatMessage } from 'devextreme/localization';
import { Observable, Subject } from 'rxjs';
import { QuestionnaireDto, QuestionnaireMetaInfo } from './questionnaire.model';
import { XMLParser } from 'fast-xml-parser';
import { Localizable } from '../../../locale/localizable';
import { QuestionGroup, QuestionnaireTags } from './models/questionnaire.models';

enum QuestionnaireNavigationButtons {
  Next,
  Prev,
  Select,
}

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireGroupService extends Localizable {
  apiUrl: string;
  public isNextGroupButtonDisabledSubject: Subject<boolean> = new Subject<boolean>();
  public isPreviousGroupButtonDisabledSubject: Subject<boolean> = new Subject<boolean>();
  public selectedGroupSubject: Subject<QuestionGroup> = new Subject<QuestionGroup>();
  public availableGroupsSubject: Subject<Array<QuestionGroup>> = new Subject<Array<QuestionGroup>>();
  public redownloadFirstGroupSubject: Subject<boolean> = new Subject<boolean>();
  public progressInfoSubject: Subject<QuestionnaireMetaInfo> = new Subject<QuestionnaireMetaInfo>();
  public xmlLoadedSubject: Subject<{ title: string; loaded: boolean }> = new Subject<{
    title: string;
    loaded: boolean;
  }>();
  public closeQuestionnairePopupSubject: Subject<boolean> = new Subject<boolean>();
  public clearQuestionsSubject: Subject<boolean> = new Subject<boolean>();

  public currentlySelectedGroup: QuestionGroup; // does contain _prev
  public currentlyAvailableGroups: Array<QuestionGroup>;
  private _currentGroup: QuestionGroup; // does not contain _prev field

  public get currentGroup() {
    return this._currentGroup;
  }
  public set currentGroup(group: QuestionGroup) {
    this._questionnaireService.currentGroup = group;
    this._currentGroup = group;
  }

  constructor(
    private _httpClient: HttpClient,
    private _config: AppConfig,
    private _questionnaireService: QuestionnaireService
  ) {
    super('QuestionnaireComponent');
    this.apiUrl = this._config.getConfig('DbEnergyDatabaseUrl');

    this.selectedGroupSubject.subscribe((group) => {
      this.currentlySelectedGroup = group;
    });

    this.availableGroupsSubject.subscribe((groups) => {
      this.currentlyAvailableGroups = groups;
    });
  }

  private clearTreeStructure() {
    this._questionnaireService.dropDependentNodes(this._questionnaireService.treeRootNode, true);
  }

  private handlePreviousNextButtons(next: QuestionnaireNavigationButtons) {
    if (QuestionnaireNavigationButtons.Next === next || QuestionnaireNavigationButtons.Select === next) {
      const nextXmlGroup = this.findGroupInXML(this.currentlySelectedGroup._next); // xml group for old data
      const isNextGroupAvailable = this.currentlyAvailableGroups.some(
        (g) => g._id === this.currentlySelectedGroup._next
      ); // new backend approach
      if (nextXmlGroup === undefined && !isNextGroupAvailable) {
        this.isNextGroupButtonDisabledSubject.next(true);
      }
    }
    if (QuestionnaireNavigationButtons.Prev === next || QuestionnaireNavigationButtons.Select === next) {
      if (this.currentlySelectedGroup._id === QuestionnaireTags.RootTag) {
        this.isPreviousGroupButtonDisabledSubject.next(true);
      }
    }
  }

  public handleNextGroupButton(isAuditFinished: boolean) {
    // we are one next - so we can go back.
    this.isPreviousGroupButtonDisabledSubject.next(false);
    const xmlGroup = this.findGroupInXML(this.currentGroup._next);
    if (xmlGroup != null) {
      this.selectedGroupSubject.next(xmlGroup);
    }

    // save current group
    if (this._questionnaireService.isDirty && !isAuditFinished) {
      this._questionnaireService
        .saveCurrentQuestionsObservable()
        .pipe(
          concatMap(() => {
            this.clearTreeStructure();
            this.handlePreviousNextButtons(QuestionnaireNavigationButtons.Next);
            return this.getOrCreateGroup(this.currentGroup._next);
          })
        )
        .subscribe(
          (response) => {
            this.switchGroups(response);
          },
          () => {
            this.errorLoadToast();
          }
        );
    } else {
      this.clearTreeStructure();
      this.handlePreviousNextButtons(QuestionnaireNavigationButtons.Next);

      // create next group on backend
      this.getOrCreateGroup(this.currentGroup._next).subscribe(
        (response) => {
          this.switchGroups(response);
        },
        () => {
          this.errorLoadToast();
        }
      );
    }
  }

  public handlePreviousGroupButton(isAuditFinished: boolean) {
    // we are one back - so we can go further.
    this.isNextGroupButtonDisabledSubject.next(false);

    // save current group
    if (this._questionnaireService.isDirty && !isAuditFinished) {
      this._questionnaireService
        .saveCurrentQuestionsObservable()
        .pipe(
          concatMap(() => {
            this.clearTreeStructure();
            this.handlePreviousNextButtons(QuestionnaireNavigationButtons.Prev);
            let prevXmlGroupId = '';
            if (this.currentGroup._prev != null) {
              prevXmlGroupId = this.currentGroup._prev; // new backend approach
            } else {
              const prevXmlGroup = this.findPreviousGroupInXML(this.currentGroup._id); // for older compatibility
              prevXmlGroupId = prevXmlGroup._id;
            }

            return this.getOrCreateGroup(prevXmlGroupId);
          })
        )
        .subscribe(
          (response) => {
            this.switchGroups(response);
          },
          () => {
            this.errorLoadToast();
          }
        );
    } else {
      this.clearTreeStructure();
      this.handlePreviousNextButtons(QuestionnaireNavigationButtons.Prev);

      let prevXmlGroupId = '';
      if (this.currentGroup._prev != null) {
        prevXmlGroupId = this.currentGroup._prev; // new backend approach
      } else {
        const prevXmlGroup = this.findPreviousGroupInXML(this.currentGroup._id); // for older compatibility
        prevXmlGroupId = prevXmlGroup._id;
      }

      // get previous group on backend
      this.getOrCreateGroup(prevXmlGroupId).subscribe(
        (response) => {
          this.switchGroups(response);
        },
        () => {
          this.errorLoadToast();
        }
      );
    }
  }

  public onGroupSelected(e, isAuditFinished: boolean) {
    this.selectedGroupSubject.next(e.itemData);
    this.isPreviousGroupButtonDisabledSubject.next(false);
    this.isNextGroupButtonDisabledSubject.next(false);

    // save current group
    if (this._questionnaireService.isDirty && !isAuditFinished) {
      this._questionnaireService
        .saveCurrentQuestionsObservable()
        .pipe(
          concatMap(() => {
            this.clearTreeStructure();
            this.handlePreviousNextButtons(QuestionnaireNavigationButtons.Select);
            return this.getOrCreateGroup(this.currentlySelectedGroup._id);
          })
        )
        .subscribe(
          (response) => {
            this.switchGroups(response);
          },
          () => {
            this.errorLoadToast();
          }
        );
    } else {
      this.clearTreeStructure();
      this.handlePreviousNextButtons(QuestionnaireNavigationButtons.Select);

      // create next group on backend
      this.getOrCreateGroup(this.currentlySelectedGroup._id).subscribe(
        (response) => {
          this.switchGroups(response);
        },
        () => {
          this.errorLoadToast();
        }
      );
    }
  }

  private mapAnswers(answers: any[]) {
    return answers.map((answer) => ({
      ...answer,
      numberValue: answer.numbervalue,
      stringValue: answer.stringvalue,
      booleanValue: answer.booleanvalue,
    }));
  }

  private mapQuestions(questions: any[]) {
    return questions.map((question) => ({ ...question, answers: this.mapAnswers(question.answers) }));
  }

  private switchGroups(response: string) {
    // we got some group info
    const groupResponse = JSON.parse(response);
    this.currentGroup =
      // groupDTO
      {
        _id: groupResponse.id,
        _next: groupResponse.nextGroupId,
        _prev: groupResponse.prevGroupId,
        guid: groupResponse.guid,
        _name: undefined,
      };

    if (this.currentGroup._id === QuestionnaireTags.RootTag) {
      this.isPreviousGroupButtonDisabledSubject.next(true);
    }
    if (this.currentGroup._next == null && this.currentGroup._prev != null) {
      // when next group does not exists in version on backend. Old version has two nulls here.
      this.isNextGroupButtonDisabledSubject.next(true);
    }

    this._questionnaireService.questionnaireGroupDto = {
      ...groupResponse,
      questions: this.mapQuestions(groupResponse.questions),
    };

    this.progressInfoSubject.next(groupResponse.metaInfo);

    // get xml id, next structure.
    const xmlGroup = this.findGroupInXML(this.currentGroup._id);
    if (xmlGroup != undefined) {
      // it is in the same file, change group id and reload
      this.currentGroup._id = xmlGroup._id;
      this.currentGroup._next = xmlGroup._next;

      this.findQuestion();
      this.clearQuestionsSubject.next(true);

      this._questionnaireService.findRootQuestion();
      if (xmlGroup._name === undefined) {
        xmlGroup._name = 'no-group-name';
      }
      this.selectedGroupSubject.next(xmlGroup);
    } else {
      // in another file. Ask backend
      this.getGroupFile(groupResponse);
    }
  }

  public getGroupFile(groupResponse: any) {
    this.getFileByMetaDataId(groupResponse).subscribe((data) => {
      this.clearQuestionsSubject.next(true);
      this.handleQuestionnaireFileResponse(data, false);
    });
  }

  private getOrCreateGroup(id: string) {
    return this._httpClient
      .get(
        `${this.apiUrl}api/questionnaires/groups/${this._questionnaireService.questionnaireGroupDto.questionnaireId}/${id}`,
        { responseType: 'text' }
      )
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  public deleteAllGroups() {
    return this._httpClient
      .delete(
        `${this.apiUrl}api/questionnaires/groups/${this._questionnaireService.questionnaireGroupDto.questionnaireId}`
      )
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  public upgradeQuestionnaire() {
    return this._httpClient
      .get(
        `${this.apiUrl}api/questionnaires/metadata/update/${this._questionnaireService.questionnaireGroupDto.questionnaireId}`
      )
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  public findGroupInXML(id: string): QuestionGroup {
    if (Array.isArray(this._questionnaireService.parsedXml.questionnaire.group)) {
      return this._questionnaireService.parsedXml.questionnaire.group.find((g) => g._id === id);
    } else {
      return this._questionnaireService.parsedXml.questionnaire.group._id === id
        ? this._questionnaireService.parsedXml.questionnaire.group
        : undefined;
    }
  }

  public findPreviousGroupInXML(id: string): QuestionGroup {
    if (Array.isArray(this._questionnaireService.parsedXml.questionnaire.group)) {
      return this._questionnaireService.parsedXml.questionnaire.group.find((g) => g._next === id);
    } else {
      return undefined;
    }
  }

  private getFirstQuestionnaireGroupByDepartmentId(departmentId: string): Observable<any> {
    return this._httpClient.get<QuestionnaireDto>(`${this.apiUrl}api/questionnaires/groups/first/${departmentId}`);
  }

  private getFileByMetaDataId(questionnaireGroup: any): Observable<HttpResponse<any> | string> {
    return this._httpClient
      .get(`${this.apiUrl}api/questionnaires/file/metadata/${questionnaireGroup.metaDataId}`, { responseType: 'text' })
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  public getQuestionnaireFileByDepartmentId(departmentId) {
    this.getFirstQuestionnaireGroupByDepartmentId(departmentId)
      .pipe(
        switchMap((response: any) => {
          this.currentGroup = {
            _id: response.id,
            _next: response.nextGroupId,
            _prev: response.prevGroupId,
            guid: response.guid,
            _name: undefined,
          };
          this._questionnaireService.questionnaireGroupDto = {
            ...response,
            questions: this.mapQuestions(response.questions),
          };

          this.progressInfoSubject.next(response.metaInfo);

          if (response.metaInfo.currentProgress === 0) {
            // no progress and this is first time we land in survey
            this._questionnaireService.isDirty = false;
          }
          return this.getFileByMetaDataId(response);
        })
      )
      .subscribe(
        (data) => {
          // first time, first group - block previous button
          this.handleQuestionnaireFileResponse(data, true);
          this.isPreviousGroupButtonDisabledSubject.next(true);
        },
        (error: any) => {
          this.closeQuestionnairePopupSubject.next(false);
          this.errorLoadToast(error.error);
        }
      );
  }

  private handleQuestionnaireFileResponse(data: any, first: boolean) {
    // new xml file, parse first
    this.parseQuestionnaireXml(data);

    if (Array.isArray(this._questionnaireService.parsedXml.questionnaire.group)) {
      // Multiple groups - this case is for multiple groups in one file.
      this.handleMultipleGroupsFileResponse();
    } else {
      // This case is for one group per file - newer approach but older files with only one group can end up here.
      this.handleSingleGroupFileResponse(first);
    }
  }

  private handleSingleGroupFileResponse(first: boolean) {
    this.getAvailableGroupsInVersion().subscribe((data: Array<any>) => {
      if (data.length === 0) {
        this.handleMultipleGroupsFileResponse();
        return;
      }
      this.availableGroupsSubject.next(data as QuestionGroup[]);

      if (Array.isArray(this.currentlyAvailableGroups)) {
        // questionnaire has multiple groups in multiple files
        if (first) {
          // For first entry we always want to land on the first group
          this.selectedGroupSubject.next(this.currentlyAvailableGroups[0]);
        } else {
          // this handles all other requests and makes sure that when using select button, next/prev we always update select value.
          this.selectedGroupSubject.next(
            this.currentlyAvailableGroups.find(
              (cag) => cag._id === this._questionnaireService.parsedXml.questionnaire.group._id
            )
          );
        }
      } else {
        // questionnaire has only one single group
        this.selectedGroupSubject.next(this.currentlyAvailableGroups);
      }

      const nextXmlGroup = this.findGroupInXML(this.currentlySelectedGroup._next); // xml group for old data
      const isNextGroupAvailable = this.currentlyAvailableGroups.some(
        (g) => g._id === this.currentlySelectedGroup._next
      ); // new backend approach
      if (nextXmlGroup === undefined && !isNextGroupAvailable) {
        this.isNextGroupButtonDisabledSubject.next(true);
      } else {
        this.isNextGroupButtonDisabledSubject.next(false);
      }
    });
  }

  private handleMultipleGroupsFileResponse() {
    if (!Array.isArray(this._questionnaireService.parsedXml.questionnaire.group)) {
      this._questionnaireService.parsedXml.questionnaire.group = [
        this._questionnaireService.parsedXml.questionnaire.group,
      ];
    }
    this._questionnaireService.parsedXml.questionnaire.group.forEach((g) => {
      if (g._name === undefined) {
        // If group has no name...
        g._name = `${this._('no-question-group-name')} ${g._id}`;
      }
    });

    // sort available groups if all in xml
    this._questionnaireService.parsedXml.questionnaire.group = this.sortGroupsByReferenceAttributes(
      this._questionnaireService.parsedXml.questionnaire.group
    );
    this.availableGroupsSubject.next(this._questionnaireService.parsedXml.questionnaire.group);

    if (Array.isArray(this.currentlyAvailableGroups)) {
      this.selectedGroupSubject.next(this.currentlyAvailableGroups[0]);
    } else {
      this.selectedGroupSubject.next(this.currentlyAvailableGroups);
    }

    const nextXmlGroup = this.findGroupInXML(this.currentlySelectedGroup._next);
    if (nextXmlGroup === undefined && this.currentGroup._next == null) {
      // Check if next attribute is currently set if not then block.
      this.isNextGroupButtonDisabledSubject.next(true);
    }
  }

  private getAvailableGroupsInVersion() {
    return this._httpClient
      .get(
        `${this.apiUrl}api/questionnaires/groups/available/${this._questionnaireService.questionnaireGroupDto.questionnaireId}`,
        { responseType: 'json' }
      )
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  private sortGroupsByReferenceAttributes(groups: QuestionGroup[]): QuestionGroup[] {
    const sortedGroups = [];
    const rootGroup = groups.find((group) => group._id === AppSettings.QUESTIONNAIRE_ROOT_NAME);

    if (!rootGroup) {
      console.log('No ROOT group in Questionnaire');
    }

    let currentGroup = rootGroup;
    let nextGroup = null;
    sortedGroups.push(currentGroup);

    for (const _ of groups) {
      nextGroup = groups.find((group) => group._id === currentGroup._next);

      if (nextGroup) {
        currentGroup = nextGroup;
        sortedGroups.push(currentGroup);
      }
    }
    return sortedGroups;
  }

  private parseQuestionnaireXml(xml: any): void {
    // Use this to parse questionnaire xml
    // it updates questionnaire service with: parsedxml, root question

    const options = {
      ignoreAttributes: false,
      attributeNamePrefix: '_',
      allowBooleanAttributes: true,
    };

    const parser = new XMLParser(options);
    this._questionnaireService.parsedXml = parser.parse(xml);

    this.findQuestion();
    this._questionnaireService.findRootQuestion();
    this._questionnaireService.fillTreeGraph();

    this.xmlLoadedSubject.next({ title: this._questionnaireService.parsedXml.title, loaded: true });
  }

  public findQuestion(): void {
    // This method populates question list in questionnaire service.
    // It is also tracking xml and current group
    if ('group' in this._questionnaireService.parsedXml.questionnaire) {
      if (Array.isArray(this._questionnaireService.parsedXml.questionnaire.group)) {
        // If groups are in array
        this.handleGroupArray();
      } else {
        // If only one group
        this.handleSingleGroup();
      }
    } else {
      // This if/else may become obsolete as group usage is now required.
      this.handleNoGroup();
    }
  }

  private handleGroupArray(): void {
    const xmlGroup = this.findGroupInXML(this.currentGroup._id);
    this.setQuestionList(xmlGroup.question);
    this.currentGroup._id = xmlGroup._id;
    this.currentGroup._next = xmlGroup._next;
    this._questionnaireService.currentXMLGroup = xmlGroup;
  }

  private handleSingleGroup(): void {
    this.setQuestionList(this._questionnaireService.parsedXml.questionnaire.group.question);
    this._questionnaireService.currentXMLGroup = this._questionnaireService.parsedXml.questionnaire.group;
  }

  private handleNoGroup(): void {
    if ('question' in this._questionnaireService.parsedXml.questionnaire) {
      this._questionnaireService.setQuestionList(this._questionnaireService.parsedXml.questionnaire.question);
    } else {
      notify({
        message: formatMessage('load-questions-error'),
        type: 'error',
        displayTime: AppSettings.NOTIFY_DURATION,
        position: 'top center',
      });
    }
  }

  private setQuestionList(question: any): void {
    if (Array.isArray(question)) {
      this._questionnaireService.setQuestionList(question);
    } else {
      this._questionnaireService.setQuestionList([question]);
    }
  }

  private errorLoadToast(text?: string): void {
    notify({
      message: text ? text : formatMessage('load-questionnaire-error'),
      type: 'error',
      displayTime: AppSettings.NOTIFY_DURATION,
      position: 'top center',
    });
  }
}
