import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxSankeyComponent } from 'devextreme-angular/ui/sankey';
import { SankeyDataItem, EnergyBalanceSankey } from './energy-balance-sankey.service';
import { Localizable } from '../../../../locale/localizable';
import { Subscription } from 'rxjs';
import { AppSettings } from '../../../../AppSettings';
import { FormMenuActions } from '../../../models/FormMenuActions.enum';
import { ScreenService } from '../../../services';
import { takeUntil } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';

enum EnergyUnit {
  MWh = 'MWh',
  GJ = 'GJ',
}

@Component({
  selector: 'app-energy-balance-chart',
  templateUrl: './energy-balance-chart.component.html',
  styleUrls: ['./energy-balance-chart.component.scss'],
})
export class EnergyBalanceChartComponent extends Localizable implements OnInit, OnDestroy {
  @ViewChild('sankey', { static: true }) sankeyChart: DxSankeyComponent;
  @ViewChild('sankeyContainer') sankeyContainerElement: ElementRef;
  @ViewChild('outerContainer') outerContainerElement: ElementRef;

  public sankeySubscription: Subscription;
  private sankeyLoadingSubscription: Subscription;
  @Input() auditId: string;
  public loadingVisible = false;
  public data: SankeyDataItem[] = null;
  public sankeyWidthPx: number;
  public sankeyContainerWidthPx: number;
  public sankeyHeightPx: number;
  public sankeySizePx: any;
  private sankeyMarginInPx: number = 46;
  private max80RemWidth: number = 1280;
  private minDrawerWidth: number = 400;
  private sankeyUpdateTimeMsOffset = 500;
  private defaultDrawerWidth: number = 0.25;
  private sankeyMaringInPxWhenSqueeze = 50;
  private isMobile: boolean = false;
  private drawerOpen: boolean = false;
  private smallScreenWidth = 960;
  private marginWhenDrawerOpenLessThen80Rem = 200;
  private marginCheckDrawerOpenLessThen80Rem = 156;
  public showSankey: boolean = false;

  public exportFileTypes: string[] = ['PNG', 'JPEG', 'PDF', 'SVG'];
  public eneryBalanceUnits: string[] = [EnergyUnit.MWh, EnergyUnit.GJ];
  public unit: string = EnergyUnit.MWh;

  constructor(
    private _energyBalanceSankey: EnergyBalanceSankey,
    private _changeDetecetor: ChangeDetectorRef,
    private _el: ElementRef,
    private _screenService: ScreenService
  ) {
    super();
    this.customizeLinkTooltip = this.customizeLinkTooltip.bind(this);
  }

  ngOnInit(): void {
    this.loadingVisible = true;
    this._energyBalanceSankey.updateEnergyBalanceSankey(this.auditId);

    this.sankeyLoadingSubscription = this._energyBalanceSankey.sankeyLoading$.subscribe((res) => {
      this.loadingVisible = res;
    });

    this._screenService.changed.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.isMobile = this._screenService.sizes['screen-small'] || this._screenService.sizes['screen-x-small'];
    });

    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= this.smallScreenWidth;
      setTimeout(() => {
        const twentyFiveVw = Math.max(window.innerWidth * this.defaultDrawerWidth, this.minDrawerWidth);
        if (
          !this.isMobile &&
          this.drawerOpen &&
          window.innerWidth <
            this.minDrawerWidth + this._el.nativeElement.offsetWidth + this.marginCheckDrawerOpenLessThen80Rem
        ) {
          if (this._el.nativeElement.offsetWidth > this.max80RemWidth) {
            this.sankeyContainerWidthPx = this.max80RemWidth - this.sankeyMarginInPx;
            this.sankeyWidthPx = this.sankeyContainerWidthPx;
          } else {
            this.sankeyContainerWidthPx =
              window.innerWidth - this.minDrawerWidth - this.marginWhenDrawerOpenLessThen80Rem;
            this.sankeyWidthPx = this.sankeyContainerWidthPx;
          }
        } else if (!this.isMobile && !this.drawerOpen && this._el.nativeElement.offsetWidth < this.max80RemWidth) {
          this.sankeyContainerWidthPx = this._el.nativeElement.offsetWidth - this.sankeyMaringInPxWhenSqueeze;
          this.sankeyWidthPx = this.sankeyContainerWidthPx;
        } else if (!this.isMobile && !this.drawerOpen && this._el.nativeElement.offsetWidth > this.max80RemWidth) {
          this.sankeyContainerWidthPx = this.max80RemWidth - this.sankeyMarginInPx;
          this.sankeyWidthPx = this.sankeyContainerWidthPx;
        } else if (
          !this.isMobile &&
          this.drawerOpen &&
          this._el.nativeElement.offsetWidth - twentyFiveVw < this.max80RemWidth
        ) {
          if (this._el.nativeElement.offsetWidth > this.max80RemWidth) {
            this.sankeyContainerWidthPx = this.max80RemWidth - this.sankeyMarginInPx;
            this.sankeyWidthPx = this.sankeyContainerWidthPx;
          } else {
            this.sankeyContainerWidthPx = this._el.nativeElement.offsetWidth - this.sankeyMaringInPxWhenSqueeze;
            this.sankeyWidthPx = this.sankeyContainerWidthPx;
          }
        } else if (this.isMobile) {
          const maxwidthSankey = Math.min(this._el.nativeElement.offsetWidth, this.max80RemWidth);
          this.sankeyContainerWidthPx = maxwidthSankey - this.sankeyMarginInPx;
          this.sankeyWidthPx = this.sankeyContainerWidthPx;
        } else {
          const maxwidthSankey = Math.min(this._el.nativeElement.offsetWidth, this.max80RemWidth);
          this.sankeyContainerWidthPx = maxwidthSankey - this.sankeyMaringInPxWhenSqueeze - this.sankeyMarginInPx;
          this.sankeyWidthPx = this.sankeyContainerWidthPx;
        }
      }, 100);
    });

    this.sankeySubscription = this._energyBalanceSankey.sankeyData$.subscribe((res: Array<SankeyDataItem>) => {
      if (res) {
        this.loadingVisible = true;

        this.data = res.filter((node: SankeyDataItem) => node.weight > 0);
        if (this.unit === EnergyUnit.GJ) {
          this.changeUnit({ item: EnergyUnit.GJ });
        }
        this.loadingVisible = false;
        this._changeDetecetor.detectChanges();
      }
    });

    this._energyBalanceSankey.drawerAction.subscribe((action) => {
      if (action && action.itemData.key === FormMenuActions.OpenDrawer && !this.isMobile) {
        this.drawerOpen = true;
        const twentyFiveVw = Math.max(window.innerWidth * this.defaultDrawerWidth, this.minDrawerWidth);

        if (this._el.nativeElement.offsetWidth - twentyFiveVw < this.max80RemWidth) {
          this.sankeyContainerWidthPx =
            this._el.nativeElement.offsetWidth - twentyFiveVw - this.sankeyMaringInPxWhenSqueeze;
          this.sankeyWidthPx = this.sankeyContainerWidthPx;
        }

        setTimeout(() => {
          this.sankeyWidthPx = this.outerContainerElement.nativeElement.offsetWidth - this.sankeyMarginInPx;
          this.sankeyContainerWidthPx = this.outerContainerElement.nativeElement.offsetWidth - this.sankeyMarginInPx;
        }, this.sankeyUpdateTimeMsOffset);
      }
      if (action && action.itemData.key === FormMenuActions.CloseDrawer && !this.isMobile) {
        this.drawerOpen = false;
        setTimeout(() => {
          if (this._el.nativeElement.offsetWidth < this.max80RemWidth) {
            this.sankeyWidthPx = this._el.nativeElement.offsetWidth - this.sankeyMarginInPx;
            this.sankeyContainerWidthPx = this._el.nativeElement.offsetWidth - this.sankeyMarginInPx;
          } else {
            this.sankeyWidthPx = this.max80RemWidth - this.sankeyMarginInPx;
            this.sankeyContainerWidthPx = this.max80RemWidth - this.sankeyMarginInPx;
          }
        }, this.sankeyUpdateTimeMsOffset);
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this._el.nativeElement.offsetWidth < this.max80RemWidth) {
        this.sankeyWidthPx = this._el.nativeElement.offsetWidth - this.sankeyMarginInPx;
        this.sankeyContainerWidthPx = this._el.nativeElement.offsetWidth - this.sankeyMarginInPx;
      } else {
        this.sankeyWidthPx = this.max80RemWidth - this.sankeyMarginInPx;
        this.sankeyContainerWidthPx = this.max80RemWidth - this.sankeyMarginInPx;
      }
      this.sankeyHeightPx = this.sankeyContainerElement.nativeElement.offsetHeight - this.sankeyMarginInPx;
      this.showSankey = true;
      this._changeDetecetor.detectChanges();
    }, 0);
  }

  ngOnDestroy(): void {
    this.sankeySubscription.unsubscribe();
    this.sankeyLoadingSubscription.unsubscribe();
    this.data = null;
  }

  public zoomIn(): void {
    this.sankeyHeightPx += 100;
    this.sankeyWidthPx += 100;
  }

  public zoomOut(): void {
    this.sankeyHeightPx -= 100;
    this.sankeyWidthPx -= 100;
  }

  public exportButtonClick(e: any): void {
    this.sankeyChart.instance.exportTo('Exported Chart', e.itemData.replace(/[^A-Z]+/g, ''));
  }

  customizeLinkTooltip(info: any) {
    const precision: number = 2;
    return {
      html: `<b>${this._('energy-balance-chart-tooltip-source')}:</b> ${info.source}<br/>
      <b>${this._('energy-balance-chart-tooltip-receiver')}:</b> ${info.target}<br/>
      <b>${this._('energy-balance-chart-tooltip-energy')}:</b> ${info.weight.toFixed(precision)} ${this.unit}`,
    };
  }

  customizeNodeTooltip(info: any) {
    return {
      text: '',
      color: '00000000',
    };
  }

  public changeUnit(e: any): void {
    this.unit = e.item;
    if (e.item === EnergyUnit.GJ) {
      this.convertWeights(AppSettings.GJ_TO_MWH_RATIO);
    }
    if (e.item === EnergyUnit.MWh) {
      this.convertWeights(1 / AppSettings.GJ_TO_MWH_RATIO);
    }
    this.updateChartDataSource();
  }

  private convertWeights(conversionFactor: number): void {
    for (const node of this.data) {
      if (node.weight > 0) {
        node.weight = node.weight * conversionFactor;
      }
    }
  }

  private updateChartDataSource(): void {
    const newData = this.data;
    this.sankeyChart.dataSource = [];
    this.sankeyChart.dataSource = newData;
    this.sankeyChart.instance.render();
  }
}
