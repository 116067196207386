import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { DtoEnergyBalanceReceiver, EnergyBalanceReceiver } from 'src/app/shared/models/energies/energy.model';
import { Unit } from 'src/app/shared/models/unit.model';
import { AppSettings } from '../../../../AppSettings';
import { ScreenService } from '../../../services';
import { EnergyBalanceService } from '../energy-balance.service';
import { Localizable } from '../../../../locale/localizable';

@Component({
  selector: 'app-energy-receiver-popup',
  templateUrl: './energy-receiver-popup.component.html',
  styleUrls: ['./energy-receiver-popup.component.scss'],
})
export class EnergyReceiverPopupComponent extends Localizable implements OnInit {
  @Input() editMode: boolean = false;
  @Input() editEnergyReceiver: EnergyBalanceReceiver;
  @Input() energyReceiver: DtoEnergyBalanceReceiver;
  @Input() currencyFormat: string = '';

  @Output() addEnergyReceiver = new EventEmitter<any>();
  @Output() updateEnergyReceiver = new EventEmitter<any>();

  public unitsList: Array<Unit>;
  public mobile: boolean = false;

  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;

  constructor(
    private _energyBalanceService: EnergyBalanceService,
    private _screenService: ScreenService
  ) {
    super('EnergyBalanceComponent');
  }

  ngOnInit(): void {
    this._energyBalanceService.getUnitsListForEnergyBalance().subscribe((res) => (this.unitsList = res));

    this._screenService.changed.subscribe(() => {
      this.setPopUpFullScreen();
    });

    if (this.editMode) {
      this.energyReceiver = {
        id: this.editEnergyReceiver.id,
        name: this.editEnergyReceiver.name,
        energyAmount: this.editEnergyReceiver.energyAmount,
        unitId: this.editEnergyReceiver.unit.id,
        energyCost: this.editEnergyReceiver.energyCost,
      };
    }
  }

  formClosed(): void {
    this.addEnergyReceiver.emit(null);
  }

  getUnitsForEnergyBalance(): void {
    this._energyBalanceService.getUnitsListForEnergyBalance().subscribe((res) => (this.unitsList = res));
  }

  addNewReceiver(e: any, energyReceiver: DtoEnergyBalanceReceiver): void {
    if (e.validationGroup.validate().isValid) {
      this._energyBalanceService.addNewEnergyBalanceReceiver(energyReceiver).subscribe((res: EnergyBalanceReceiver) => {
        this.addEnergyReceiver.emit(res);
        notify({
          message: `Odbiornik dodany`,
          type: 'success',
          displayTime: AppSettings.NOTIFY_DURATION,
          position: 'top center',
        });
      });
    }
  }

  updateEnergyBalanceReceiver(e: any, energySource: DtoEnergyBalanceReceiver): void {
    if (e.validationGroup.validate().isValid) {
      this._energyBalanceService.updateEnergyBalanceReceiver(energySource).subscribe((res: EnergyBalanceReceiver) => {
        this.updateEnergyReceiver.emit(res);
        notify({
          message: `Zmiany zapisane`,
          type: 'success',
          displayTime: AppSettings.NOTIFY_DURATION,
          position: 'top center',
        });
      });
    }
  }

  public setPopUpFullScreen(): void {
    if (this._screenService.sizes['screen-x-small'] || this._screenService.sizes['screen-small']) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }
}
