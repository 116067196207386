<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-data-grid
  id="gridMissingFiles"
  [dataSource]="missingFiles"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  columnResizingMode="widget"
  [showBorders]="true"
  [showColumnLines]="true"
  [showRowLines]="false"
  [rowAlternationEnabled]="true"
>
  <dxo-selection
    mode="single"
    selectAllMode="allPages"
  >
  </dxo-selection>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[5, 10, 20]"
    [showInfo]="true"
  >
  </dxo-pager>

  <dxo-filter-row
    [visible]="true"
    applyFilter="auto"
    [showOperationChooser]="false"
  >
  </dxo-filter-row>

  <dxo-header-filter visible="true"> </dxo-header-filter>

  <dxi-column
    caption="Nazwa pliku"
    alignment="left"
    [allowFiltering]="true"
    [allowHeaderFiltering]="false"
    dataField="name"
  ></dxi-column>

  <dxi-column
    caption="Typ szablonu"
    alignment="left"
    [allowFiltering]="true"
    [allowHeaderFiltering]="false"
    dataField="templateType.name"
  ></dxi-column>

  <dxi-column
    caption="Typ audytu"
    alignment="left"
    [allowFiltering]="true"
    [allowHeaderFiltering]="false"
    dataField="auditType.name"
  ></dxi-column>

  <dxi-column
    caption="Typ formularza"
    alignment="left"
    [allowFiltering]="true"
    [allowHeaderFiltering]="false"
    dataField="formCode"
  ></dxi-column>
</dx-data-grid>
