<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="container">
  <app-field-doc-variable
    *ngFor="let variable of docVariablesList"
    [text]="variable.name"
    [tooltipText]="variable.tooltipText"
    [position]="'left'"
    [specialVariable]="true"
  ></app-field-doc-variable>
</div>
