import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { TreeNavigationService } from './tree-navigation.service';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuardService {
  constructor(private _treeNavigationService: TreeNavigationService) {}
  public confirmationSubject = new Subject<boolean>();
  public guardSet = false;

  getConfirmation(): Observable<boolean> {
    return this.confirmationSubject.asObservable();
  }

  openConfirmationDialog() {
    this.guardSet = true;
    this._treeNavigationService.exitFormNoFn = () => true;
    this._treeNavigationService.exitFormYesFn = () => true;
    this._treeNavigationService.exitFormSpecialFn = () => true;

    this._treeNavigationService.setGuardExitFormVisible(true);
  }

  closeConfirmationDialog() {
    this._treeNavigationService.setExitFormVisible(false);
  }
}
