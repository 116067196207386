import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import {
  Blower,
  BlowerOperatingPointVolatility,
  BlowerRegulationType,
  BlowerType,
} from 'src/app/shared/models/area/types/blower';
import { OperatingPointVolatility, TechnicalCondition } from 'src/app/shared/models/area/types/water-pump.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-blower',
  templateUrl: './blower.component.html',
  styleUrls: ['./blower.component.scss'],
})
export class BlowerComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  blower: Blower = null;

  @Input()
  templateMode: boolean = false;

  docVariableCode: string = null;
  blowerTypesList: ArrayStore;
  blowerRegulationTypesList: ArrayStore;
  technicalConditionsList: ArrayStore;
  blowerOperatingPointVolatilitiesList: ArrayStore;

  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    if (this.templateMode) {
      this._apiRequestService.getBlowerDocVariableCode().subscribe((res) => {
        this.docVariableCode = res;
        this.blower = this.getNewEmptyBlower();
      });
    } else {
      this.currentYear = new Date().getFullYear();

      this._apiRequestService.getAreaTechnicalConditions().subscribe((res: Array<TechnicalCondition>) => {
        this.technicalConditionsList = new ArrayStore({
          key: 'id',
          data: res,
        });
      });

      this._apiRequestService.getBlowerTypes().subscribe((res: Array<BlowerType>) => {
        this.blowerTypesList = new ArrayStore({
          key: 'id',
          data: res,
        });
      });

      this._apiRequestService.getBlowerRegularionTypes().subscribe((res: Array<BlowerRegulationType>) => {
        this.blowerRegulationTypesList = new ArrayStore({
          key: 'id',
          data: res,
        });
      });

      this._apiRequestService
        .getBlowerOperatingPointVolatilities()
        .subscribe((res: Array<OperatingPointVolatility>) => {
          this.blowerOperatingPointVolatilitiesList = new ArrayStore({
            key: 'id',
            data: res,
          });
        });
    }
  }

  private getNewEmptyBlower(): Blower {
    return {
      producer: '',
      model: '',
      serialNumber: '',
      productionYear: 1950,
      blowerType: new BlowerType(),
      ratedPower: 0,
      ratedCapacity: 0,
      ratedSpring: 0,
      blowerRegulationType: new BlowerRegulationType(),
      workingHoursInYear: 0,
      averageEfficiency: 0,
      averageLoad: 0,
      blowerOperatingPointVolatility: new BlowerOperatingPointVolatility(),
      technicalCondition: new TechnicalCondition(),
    };
  }
}
