import { Injectable } from '@angular/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import { Audit } from '../../models/audit/audit.model';
import { ApiRequestService } from '../../services/api-request.service';
import { Subject } from 'rxjs';
import { LoadOptions } from 'devextreme/data';
import { Localizable } from '../../../locale/localizable';
import { AppSettings } from '../../../AppSettings';
import { AuthenticationService } from '../../services/authentication.service';
import notify from 'devextreme/ui/notify';
import { AuditsCountService } from '../../services/audits-count.service';

@Injectable({
  providedIn: 'root',
})
export class AuditCardsService extends Localizable {
  private static readonly localFile = 'dictionary';
  public auditsArray: Subject<Array<Audit>> = new Subject<Array<Audit>>();
  public dataStoreAudits: CustomStore;

  private lastFilterOptions: LoadOptions = null;
  constructor(
    private _apiRequestService: ApiRequestService,
    private authenticationService: AuthenticationService,
    private auditCountService: AuditsCountService
  ) {
    super(AuditCardsService.localFile);

    this.dataStoreAudits = AspNetData.createStore({
      key: 'id',
      loadUrl: `${this._apiRequestService.urlDBEnergyDatabase}api/audits`,
      deleteUrl: `${this._apiRequestService.urlDBEnergyDatabase}api/audits`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        const token = localStorage.getItem(AppSettings.AUTH_TOKEN);
        ajaxOptions.headers = { Authorization: `Bearer ${token}` };
      },
      onLoaded: (data: Array<Audit>) => this.auditsArray.next(data),
      onAjaxError: (e) => {
        if (e.xhr.status === AppSettings.HTTP_STATUS_CODE_NOT_AUTHORIZED) {
          this.authenticationService.logout();
          notify(this._('not-authorized-logout'), 'error', AppSettings.NOTIFY_DURATION);
        }
      },
    });
  }

  public loadWithFilters(options: LoadOptions): void {
    this.lastFilterOptions = options;
    this.dataStoreAudits.load(options);
  }

  public deleteAudit(id: string): void {
    this.dataStoreAudits.remove(id).then(() => {
      this.auditCountService.updateAuditCount();
    });
  }
}
