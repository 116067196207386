import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ScrollService {
  private _windowScrolled$ = new BehaviorSubject<boolean>(false);
  public windowScrolled$ = this._windowScrolled$.asObservable();

  private _scrollToTop$ = new BehaviorSubject<boolean>(false);
  public scrollToTop$ = this._scrollToTop$.asObservable();

  private _scrollToBottom$ = new BehaviorSubject<any>(null);
  public scrollToBottom$ = this._scrollToBottom$.asObservable();

  private _blockScroll$ = new BehaviorSubject<boolean>(false);
  public blockScroll$ = this._blockScroll$.asObservable();

  windowScrolled = true;

  public updateScrolledData(isScrolled: boolean) {
    this._windowScrolled$.next(isScrolled);
  }

  public updateScrollToTop(isScrolledToTop: boolean) {
    this._scrollToTop$.next(isScrolledToTop);
  }

  public updateScrollToBottom(elem: any) {
    this._scrollToBottom$.next(elem);
  }

  public updateBlockScroll(isScrollingBlock: boolean) {
    this._blockScroll$.next(isScrollingBlock);
  }
}
