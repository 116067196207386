<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="dx-card responsive-paddings">
  <div class="container">
    <div class="group-container">
      <div *ngFor="let constant of constantsList">
        <app-constant [constant]="constant"></app-constant>
      </div>
    </div>
    <div class="group-container">
      <div>
        <h2>{{ _('energy-connection') }}</h2>
      </div>
      <div *ngFor="let constant of constantsListEnergyConnection">
        <app-constant [constant]="constant"></app-constant>
      </div>
    </div>

    <div class="group-container">
      <div>
        <h2>{{ _('transport') }}</h2>
      </div>
      <div *ngFor="let constant of constantsListTransport">
        <app-constant [constant]="constant"></app-constant>
      </div>
    </div>

    <div class="group-container">
      <div>
        <h2>{{ _('manufacturing-process') }}</h2>
      </div>
      <div *ngFor="let constant of constantsListManufacturingProcess">
        <app-constant [constant]="constant"></app-constant>
      </div>
    </div>

    <div class="group-container">
      <div>
        <h2>{{ _('injection-molding-machine') }}</h2>
      </div>
      <div *ngFor="let constant of constantsListInjectionMolding">
        <app-constant [constant]="constant"></app-constant>
      </div>
    </div>

    <div class="group-container">
      <div>
        <h2>{{ _('armature') }}</h2>
      </div>
      <div *ngFor="let constant of constantsListArmature">
        <app-constant [constant]="constant"></app-constant>
      </div>
    </div>

    <div class="group-container">
      <div>
        <h2>{{ _('lighting') }}</h2>
      </div>
      <div *ngFor="let constant of constatntsListLighting">
        <app-constant [constant]="constant"></app-constant>
      </div>
    </div>

    <div class="group-container">
      <div>
        <h2>{{ _('heating') }}</h2>
      </div>
      <div *ngFor="let constant of constatntsListHeating">
        <app-constant [constant]="constant"></app-constant>
      </div>
    </div>

    <div
      *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7]"
      class="child-filler"
    ></div>
  </div>
</div>
