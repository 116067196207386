import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Fan, FanType } from 'src/app/shared/models/area/types/fan.model';
import {
  OperatingPointVolatility,
  RegulationType,
  TechnicalCondition,
} from 'src/app/shared/models/area/types/water-pump.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { Localizable } from '../../../../../../../locale/localizable';

@Component({
  selector: 'app-fan',
  templateUrl: './fan.component.html',
  styleUrls: ['./fan.component.scss'],
})
export class FanComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  fan: Fan = null;

  typeList: ArrayStore;
  technicalCondition: ArrayStore;
  regulationTypesList: ArrayStore;
  operatinPointsVolatilityList: ArrayStore;

  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getAreaTechnicalConditions().subscribe((res: Array<TechnicalCondition>) => {
      this.technicalCondition = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getRegulationTypes().subscribe((res: Array<RegulationType>) => {
      this.regulationTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getOperatingPointVolatilities().subscribe((res: Array<OperatingPointVolatility>) => {
      this.operatinPointsVolatilityList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getFanAreaTypes().subscribe((res: Array<FanType>) => {
      this.typeList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
