import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Directive()
export class InitDestroy implements OnInit, OnDestroy {
  private destroySubject = new Subject<Date>();
  public get onDestroy(): Observable<Date> {
    return this.destroySubject.asObservable();
  }

  private _isInitialized = false;
  public get isInitialized(): boolean {
    return this._isInitialized;
  }

  constructor() {}

  public ngOnInit(): void {
    this._isInitialized = true;
  }

  public ngOnDestroy(): void {
    this._isInitialized = false;
    this.destroySubject.next(new Date());
  }
}
