import { Component, OnInit, NgModule, Input, ViewChild } from '@angular/core';
import { SideNavigationMenuModule, HeaderModule } from '../../shared/components';
import { ScreenService } from '../../shared/services';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxScrollViewModule, DxScrollViewComponent } from 'devextreme-angular/ui/scroll-view';
import { CommonModule } from '@angular/common';
import { ScrollService } from '../../shared/services/scroll.service';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../../AppSettings';

@Component({
  selector: 'app-side-nav-outer-toolbar',
  templateUrl: './side-nav-outer-toolbar.component.html',
  styleUrls: ['./side-nav-outer-toolbar.component.scss'],
})
export class SideNavOuterToolbarComponent implements OnInit {
  @ViewChild(DxScrollViewComponent, { static: true }) scrollView: DxScrollViewComponent;
  selectedRoute = '';

  menuOpened: boolean = false;
  temporaryMenuOpened = false;

  @Input()
  title: string;
  menuMode = 'shrink';
  menuRevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;

  windowScrolled = false;
  private static readonly DISTANCE_TO_SHOW_ARROW = 10;
  scrollDisabled = false;

  constructor(
    private screen: ScreenService,
    private router: Router,
    private scrollService: ScrollService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split('?')[0];
      }
    });

    this.screen.changed.subscribe(() => this.updateDrawer());

    this.updateDrawer();

    this.scrollService.scrollToTop$.subscribe((result: boolean) => {
      if (result) {
        this.scrollView.instance?.scrollTo(0);
      }
    });

    this.scrollService.blockScroll$.subscribe((isScrolllingBlock: boolean) => {
      this.scrollDisabled = isScrolllingBlock;
    });
  }

  updateDrawer() {
    const isSmall = this.screen.sizes['screen-small'] || this.screen.sizes['screen-x-small'];

    this.menuMode = 'overlap';
    this.menuRevealMode = isSmall ? 'slide' : 'expand';
    this.minMenuSize = isSmall ? 0 : AppSettings.MIN_MENU_SIZE;
    this.shaderEnabled = true;
  }

  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap' || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }

  navigationChanged(event) {
    const path = event.itemData.path;
    const pointerEvent = event.event;

    if (path) {
      this.router.navigate([path]);
      this.scrollView.instance?.scrollTo(0);

      if (this.hideMenuAfterNavigation) {
        this.temporaryMenuOpened = false;
        this.menuOpened = false;
        pointerEvent.stopPropagation();
        this.scrollService.updateBlockScroll(false);
      }
    } else {
      pointerEvent.preventDefault();
    }
  }

  navigationClick() {
    if (this.showMenuAfterClick && (this.screen.sizes['screen-small'] || this.screen.sizes['screen-x-small'])) {
      this.temporaryMenuOpened = true;
      this.menuOpened = true;
    }
  }

  public onScroll(event) {
    if (event.scrollOffset.top < SideNavOuterToolbarComponent.DISTANCE_TO_SHOW_ARROW) {
      this.windowScrolled = false;
    } else {
      this.windowScrolled = true;
    }
    this.scrollService.updateScrolledData(this.windowScrolled);
  }
}

@NgModule({
  imports: [SideNavigationMenuModule, DxDrawerModule, HeaderModule, DxScrollViewModule, CommonModule],
  exports: [SideNavOuterToolbarComponent],
  declarations: [SideNavOuterToolbarComponent],
})
export class SideNavOuterToolbarModule {}
