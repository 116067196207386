<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="transformer.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('transformer-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="transformer.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('transformer-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="transformer.serialNumber"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('transformer-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="transformer.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('transformer-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kVa'"
        [(value)]="transformer.power"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('transformer-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' %'"
        [(value)]="transformer.averageLoad"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('transformer-average-load')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kVa'"
        [(value)]="transformer.maxLoad"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('transformer-max-load')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="transformer.idleLosses"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('transformer-idle-losses')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="transformer.loadLosses"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('transformer-load-losses')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="transformer.workingHoursUnderLoad"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('transformer-working-hours-under-load')"></dxo-label>
  </dxi-item>
</dx-form>
