import { formatMessage, formatDate, formatNumber, loadMessages, locale } from 'devextreme/localization';
import DevExpress from 'devextreme/bundles/dx.all';
import { Directive, Injectable } from '@angular/core';
import { AppSettings } from '../AppSettings';
import { Question } from '../shared/components/questionnaire/models/questions.models';
import { InitDestroy } from '../shared/viewmodel/init-destroy';
import { QuestionGroup } from '../shared/components/questionnaire/models/questionnaire.models';

@Directive()
@Injectable()
export class Localizable extends InitDestroy {
  private static _fileSet = new Set<string>();
  private static readonly _enDictionary = './assets/locale/dictionary/en.dictionary.json';
  private static readonly _plDictionary = './assets/locale/dictionary/pl.dictionary.json';
  private static readonly _enDevExpressDictionary = './assets/locale/DevExpressDictionary/en.DevExpressDictionary.json';
  private static readonly _plDevExpressDictionary = './assets/locale/DevExpressDictionary/pl.DevExpressDictionary.json';
  public fullNumberFormat = AppSettings.NUMBER_FULL_FORMAT;
  public integerNumberFormat = AppSettings.NUMBER_INTEGER_FORMAT;

  constructor(localFileName: string = '') {
    super();
    const name = localFileName === '' ? (this as any).constructor.name : localFileName;
    const pathPL = `./assets/locale/${name}/pl.${name}.json`;
    const pathEN = `./assets/locale/${name}/en.${name}.json`;

    let localeString = localStorage.getItem(AppSettings.USER_LOCALE);
    localeString = localeString != null ? localeString : navigator.language;

    const dashPosition = localeString.toLowerCase().indexOf('-');
    localeString =
      dashPosition === -1 ? localeString.toLowerCase() : localeString.toLowerCase().substring(0, dashPosition);

    localStorage.setItem(AppSettings.USER_LOCALE, localeString);

    if (localeString !== 'pl' && localeString !== 'en') {
      localeString = 'en';
      localStorage.setItem(AppSettings.USER_LOCALE, localeString);
    }

    locale(this.getLocale());

    switch (localeString) {
      case 'pl':
        this.loadMessagesFromPath(pathPL);
        this.loadMessagesFromPath(Localizable._plDictionary);
        this.loadMessagesFromPath(Localizable._plDevExpressDictionary);
        break;
      case 'en':
        this.loadMessagesFromPath(pathEN);
        this.loadMessagesFromPath(Localizable._enDictionary);
        this.loadMessagesFromPath(Localizable._enDevExpressDictionary);
        break;
      default:
        document.location.reload();
        break;
    }
  }

  private loadMessagesFromPath(path: string) {
    if (!Localizable._fileSet.has(path)) {
      try {
        const jsonData = this.loadJsonFileSync(path);
        loadMessages(jsonData);
        Localizable._fileSet.add(path);
      } catch (error) {
        console.log('Error loading JSON file:', path, error);
      }
    }
  }

  getLocale() {
    let sessionLocale = localStorage.getItem(AppSettings.USER_LOCALE);
    sessionLocale = sessionLocale != null ? sessionLocale : navigator.language;
    localStorage.setItem(AppSettings.USER_LOCALE, sessionLocale);
    return sessionLocale;
  }

  private loadJsonFileSync(filePath: string): any {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', filePath, false); // false for synchronous request
    xhr.send();

    if (xhr.status !== AppSettings.HTTP_STATUS_CODE_200_OK) {
      throw new Error(`HTTP error! Status: ${xhr.status}`);
    }

    return JSON.parse(xhr.responseText);
  }

  public _(key: string, value?: string): string {
    const result = formatMessage(key, value);
    return result === '' ? key : result;
  }

  public _d(value: Date, format: DevExpress.ui.Format): string {
    return formatDate(value, format);
  }

  public _n(value: number, format: DevExpress.ui.Format): string {
    return formatNumber(value, format);
  }

  public localizeQuestionnaireQuestionText(question: Question): string {
    if (!question.text['#text']) {
      // meaning no translation up to now
      return question.text;
    }

    // Check if there is a localized text, if not return #text one
    const sessionLocale = localStorage.getItem(AppSettings.USER_LOCALE);
    if (question.text[sessionLocale]) {
      // we have localized content - nice
      return question.text[sessionLocale];
    } else {
      return question.text['#text'];
    }
  }

  public localizeQuestionnaireAnswerText(answer: any): string {
    if (!answer.text['#text']) {
      // meaning no translation up to now
      return answer.text;
    }

    // Check if there is a localized text, if not return #text one
    const sessionLocale = localStorage.getItem(AppSettings.USER_LOCALE);
    if (answer.text[sessionLocale]) {
      // we have localized content - nice
      return answer.text[sessionLocale];
    } else {
      return answer.text['#text'];
    }
  }

  public localizeQuestionnaireGroupName(group: QuestionGroup): string {
    const sessionLocale = localStorage.getItem(AppSettings.USER_LOCALE);
    if (group[`_name-${sessionLocale}`]) {
      return group[`_name-${sessionLocale}`];
    }
    // meaning no translation up to now
    return group._name;
  }
}
