import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuditTreeNavigation, FormType } from 'src/app/shared/models/audit/audit-tree-navigation.model';
import { TreeNavigationService } from '../../services/tree-navigation.service';
import { EnterpriseFormService } from '../forms/enterprise-form/enterprise-form.service';
import { DepartmentFormService } from '../forms/department-form/department-form/department-form.service';
import { AuditService } from '../forms/audit-form/audit.service';
import { DxTreeListComponent } from 'devextreme-angular';
import { AreaFormService } from '../forms/area-form/area-form/area-form.service';
import { AppSettings } from '../../../AppSettings';
import { Localizable } from '../../../locale/localizable';
import { ScreenService } from '../../services';
import { filter, takeUntil } from 'rxjs/operators';
import { GalleryService } from '../../services/gallery.service';
import notify from 'devextreme/ui/notify';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-tree-navigation',
  templateUrl: './tree-navigation.component.html',
  styleUrls: ['./tree-navigation.component.scss'],
})
export class TreeNavigationComponent extends Localizable implements OnInit, OnDestroy {
  @Input() auditId: string;
  @Input() enterpriseId: string;

  @Input() public isAuditFinished: boolean = false;

  @ViewChild('treeViewRef', { static: false }) treeList: DxTreeListComponent;

  auditNavigation: Array<AuditTreeNavigation> = [];
  expandedRowsKeys: Array<string> = [];

  isMobile = true;

  isGalleryTab = false;
  public imagesNumberMap = new Map<string, number>();

  selectedItemId = '';
  unselectableItemId = '';
  lastSelectedItemId = '';

  public screenWidth: string;
  private isSavedDataInForm = true;

  private displayedFormId = '';
  private isMovedWithoutSavingChanges = false;

  constructor(
    private _auditService: AuditService,
    private _treeNavigationService: TreeNavigationService,
    private _enterpriseFormService: EnterpriseFormService,
    private _departmentFormService: DepartmentFormService,
    private _areaFormService: AreaFormService,
    private _screenService: ScreenService,
    private _galleryService: GalleryService,
    private _router: Router
  ) {
    super();
    this.showForm = this.showForm.bind(this);
    this.formatNodeName_calculateCellValue = this.formatNodeName_calculateCellValue.bind(this);
  }

  private updateComponentView(): void {
    this.isMobile = this._screenService.sizes['screen-small'] || this._screenService.sizes['screen-x-small'];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkImagesNumberAviability();

    this._router.events
      .pipe(takeUntil(this.onDestroy))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkImagesNumberAviability();
      });

    this._galleryService.formIdNumberOfImagesMap.subscribe((formNoImgMap) => {
      this.imagesNumberMap = formNoImgMap;
    });
    this._screenService.changed.subscribe(() => this.updateComponentView());

    this._treeNavigationService.getAuditNavigationTree(this.auditId).subscribe();

    this._treeNavigationService.navigationTreeData
      .pipe(takeUntil(this.onDestroy))
      .subscribe((res: Array<AuditTreeNavigation>) => {
        if (res !== undefined && res !== null && res.length > 0) {
          this.auditNavigation = res;
          this._treeNavigationService.setSelectedRowKey(this.auditId);
          if (this.lastSelectedItemId === '') {
            this.lastSelectedItemId = this.auditId;
          }
          if (this.selectedItemId === '') {
            this.selectedItemId = this.auditId;
            this.displayedFormId = this.selectedItemId;
          }
          if (this.treeList !== undefined) {
            this.treeList.instance.option('focusedRowKey', null);
            this.checkFocusedRowWillBeShown();
            this.treeList.instance.refresh();
          }
        }
      });

    this._treeNavigationService.displayedFormId.pipe(takeUntil(this.onDestroy)).subscribe((id) => {
      this.displayedFormId = id;
      this.selectedItemId = id;
      this.focusRow();
    });

    this._treeNavigationService.formDataUnsaved.pipe(takeUntil(this.onDestroy)).subscribe((result) => {
      if (result.id !== null && result.id !== undefined && this.displayedFormId !== undefined) {
        if (result.id.toUpperCase() === this.displayedFormId.toUpperCase()) {
          this.isSavedDataInForm = result.isSaved;
        }
      }
    });

    this._treeNavigationService.clearSelection.pipe(takeUntil(this.onDestroy)).subscribe((response: boolean) => {
      if (response) {
        this.treeList?.instance.clearSelection();
      }
    });
  }

  public checkImagesNumberAviability(): void {
    const lastUrlSegment = this._router.url.split('?')[0].split('/').pop();
    if (lastUrlSegment === 'gallery') {
      this.isGalleryTab = true;
    } else {
      this.isGalleryTab = false;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public getSelectedRow(): void {
    this._treeNavigationService.expandedRowsKeys.subscribe({
      next: (data: Array<string>) => {
        this.expandedRowsKeys = data;
        this.checkFocusedRowWillBeShown();
      },
    });
  }

  rowExpanded(row: any): void {
    this.expandOrCollapse(true, row);
  }

  getSeletedRow(): Array<string> {
    return this.treeList.instance.getSelectedRowKeys();
  }

  setSelectedRow(id: string): void {
    this.treeList.instance.selectRows([id], false);
  }

  rowCollapsed(row: any): void {
    this.expandOrCollapse(false, row);
  }

  private expandOrCollapse(isExpanding: boolean, selectedRowId: string): void {
    const expandedElement = this.auditNavigation.find((value) => {
      return value.id === selectedRowId;
    });

    if (this.isDesktopViewAndDataIsSaved(expandedElement) || this.isMobile) {
      if (isExpanding) {
        this._treeNavigationService.addExpandedRowKey(selectedRowId);
      } else {
        this._treeNavigationService.removeExpandedRowKey(selectedRowId);
      }
    }
  }

  private isDesktopViewAndDataIsSaved(expandedElement: AuditTreeNavigation) {
    return (this.isSavedDataInForm || !expandedElement?.selectable) && !this.isMobile;
  }

  public rowCollapsing(): void {
    this.treeList.instance.option('focusedRowKey', null);
  }

  onItemSelectionChanged(row: any): void {
    const selectedItem = row.selectedRowsData[0];

    if (selectedItem) {
      if (!selectedItem.selectable) {
        this.unselectableItemId = row.currentSelectedRowKeys[0];
      } else {
        this.selectedItemId = selectedItem.id;
        this._treeNavigationService.setSelectedRowKey(selectedItem.id);
        if (!this.isMobile && !this.isMovedWithoutSavingChanges) {
          this.changeForm(selectedItem);
        } else {
          this.isMovedWithoutSavingChanges = false;
        }
      }
    }
  }

  private changeSelectedForm(selectedForm: AuditTreeNavigation): void {
    this._treeNavigationService.setNavigationToFormId(selectedForm);

    this.getChosenFormData(selectedForm);
    if (selectedForm.formType === FormType.Area) {
      this._treeNavigationService.expandParentNode(selectedForm.id);
    }
    if (this.selectedItemId !== selectedForm.id && this.unselectableItemId !== this.selectedItemId) {
      this._treeNavigationService.addExpandedRowKey(selectedForm.id);
    }
    this.isMovedWithoutSavingChanges = false;
  }

  private getChosenFormData(chosenForm: AuditTreeNavigation): void {
    switch (chosenForm.formType) {
      case FormType.Audit: {
        this._auditService.getCurrentAudit(chosenForm.id.toString());
        break;
      }
      case FormType.Enterprise: {
        this._enterpriseFormService.getCurrentEnterprise(chosenForm.id.toString());
        break;
      }
      case FormType.Department: {
        this._departmentFormService.getCurrentDepartment(chosenForm.id.toString());
        break;
      }
      case FormType.Area: {
        this._areaFormService.getCurrentAreaForm(chosenForm.id.toString());
        break;
      }
    }
  }

  public onClick(id): void {
    if (this.wasClickedItemNotArrow(id) && !this.expandedRowsKeys.includes(id)) {
      this.rowExpanded(id);
    } else if (
      (this.currentClickedItemIsSameAsLastOne(id) || this.wasClickedUnselectableItem(id)) &&
      this.expandedRowsKeys.includes(id)
    ) {
      this.rowCollapsed(id);
    }

    if (this.wasClickedSelectableItem(id)) {
      this.lastSelectedItemId = this.selectedItemId;
    }
  }

  private wasClickedItemNotArrow(id: string): boolean {
    return this.selectedItemId === id || this.unselectableItemId === id;
  }

  private currentClickedItemIsSameAsLastOne(id: string): boolean {
    return this.lastSelectedItemId === id && this.selectedItemId === id;
  }

  private wasClickedUnselectableItem(id: string): boolean {
    return this.unselectableItemId === id;
  }

  private wasClickedSelectableItem(id: string): boolean {
    return this.selectedItemId === id;
  }

  async showForm(event) {
    this._treeNavigationService.setSelectedRowKey(event.row.data.id);
    const selectedItem = event.row.data;
    if (selectedItem) {
      this._treeNavigationService.updateCollapseNavSideMenu(true);
      this.changeForm(selectedItem);
    }
  }

  public isShowFromButtonVisible(e): void {
    return e.row.data.selectable;
  }

  public changeForm(selectedItem: AuditTreeNavigation): boolean {
    if (this.selectedItemId.toLowerCase() === this.displayedFormId.toLowerCase()) {
      return false;
    }

    if (selectedItem.selectable && this.displayedFormId !== selectedItem.id) {
      this._treeNavigationService.updateCheckFormData(true);
      if (this.isSavedDataInForm) {
        this.changeSelectedForm(selectedItem);
        this._treeNavigationService.addExpandedRowKey(selectedItem.id);
      } else if (selectedItem.id !== this.unselectableItemId) {
        this.changeSelectedForm(selectedItem);
        return true;
      }
    }
    return true;
  }

  public onFocusedRowChanging(event): void {
    event.cancel = true;
  }

  public focusRow(): void {
    if (this.treeList !== undefined) {
      this.setSelectedRow(this.displayedFormId);
      this.treeList.instance.option('focusedRowKey', this.displayedFormId);
    }
  }

  public checkFocusedRowWillBeShown(): void {
    const visibleRows = this.treeList.instance.getVisibleRows();

    const result = visibleRows.find((row) => {
      return row.key === this.displayedFormId;
    });

    if (result !== undefined) {
      this.treeList.instance.option('focusedRowKey', this.displayedFormId);
    }
  }

  private formatRowName(name: string, depth: number): string {
    let nodeName = name;
    const nodeNameLength = nodeName.length;
    const nodeNameLengthLimit =
      AppSettings.TREE_NODE_NAME_LENGTH_LIMIT - depth * AppSettings.TREE_NODE_NAME_LEVEL_DECREASE;

    if (nodeNameLength > nodeNameLengthLimit) {
      nodeName = nodeName.slice(0, nodeNameLengthLimit) + '...';
    }
    return nodeName;
  }

  public formatNodeName_calculateCellValue(rowInfo): string {
    if (rowInfo.parentId === '-1') {
      return rowInfo.name;
    }
    return this.formatRowName(rowInfo.name, this.formatNodeName_getRowLevel(rowInfo));
  }

  private formatNodeName_getRowLevel(rowInfo): number {
    if (rowInfo.parentId === '-1') {
      return 0;
    }
    const parentNode = this.auditNavigation.find((value) => {
      return value.id === rowInfo.parentId;
    });
    return this.formatNodeName_getRowLevel(parentNode) + 1;
  }

  public onDragOver(event, cell): void {
    if (!this.isAuditFinished && this.isFormType(cell.data.formType)) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public hasImageId(imagesIdsList: string[]): boolean {
    return imagesIdsList.every((imageId) => imageId != null && imageId !== '');
  }

  public isFormType(formType): boolean {
    return Object.values(FormType).includes(formType);
  }

  public isAnotherForm(imageFormId: string, formDataId: string) {
    return formDataId.toLocaleUpperCase() !== imageFormId.toUpperCase();
  }

  public onDrop(event, cell): void {
    if (this.isAuditFinished) {
      return;
    }
    const imagesIds = event.dataTransfer.getData('imagesIds');
    const imagesIdsList = imagesIds.split(',');

    const imageFormId = event.dataTransfer.getData('imageFormId');

    if (
      this.hasImageId(imagesIdsList) &&
      this.isFormType(cell.data.formType) &&
      this.isAnotherForm(imageFormId, cell.data.id)
    ) {
      this._galleryService.changeImageLocation(imagesIdsList, cell.data.id).subscribe(() => {
        this._galleryService.deselect();
        this._galleryService.fetchAuditAndChildrenGalleries(this._galleryService.getAuditId());
        let message = '';
        if (imagesIdsList.length > 1) {
          message = 'audit-gallery-images-form-updated-message';
        } else {
          message = 'audit-gallery-image-dragged-and-dropped-message';
        }
        notify({
          message: this._(message),
          type: 'success',
          displayTime: AppSettings.NOTIFY_DURATION,
          position: 'top center',
        });
      });
    }

    event.stopPropagation();
    event.preventDefault();
  }

  public getNumberOfImagesInsideFormById(cell: any): number {
    return this.imagesNumberMap.get(cell.data.id);
  }
}
