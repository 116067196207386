import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models/user.model';
import { UserFormService } from './user-form.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  user: User;

  academicTitles: string[];
  positionsTypes: string[];
  permissionLevels: string[];

  constructor(private userFormService: UserFormService) {}

  ngOnInit(): void {
    this.user = this.userFormService.getEmptyUser();

    this.academicTitles = this.userFormService.placeHolderAcademicTitles();
    this.positionsTypes = this.userFormService.placeHolderPositionTypes();
    this.permissionLevels = this.userFormService.placeHolderPermissionLevels();
  }

  public save(e: any, user: User): void {
    if (e.validationGroup.validate().isValid) {
      this.userFormService.save(user);
    }
  }
}
