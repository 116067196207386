import { Component, Input, OnInit } from '@angular/core';
import { VacuumPump } from 'src/app/shared/models/area/types/vacuum-pump.model';
import { Localizable } from '../../../../../../../locale/localizable';

@Component({
  selector: 'app-vacuum-pump',
  templateUrl: './vacuum-pump.component.html',
  styleUrls: ['./vacuum-pump.component.scss'],
})
export class VacuumPumpComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  vacuumPump: VacuumPump = null;

  currentYear: number;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
}
