<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-popup
  [title]="_('attention')"
  [fullScreen]="false"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [visible]="true"
  (onHidden)="popupClosed()"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <dx-scroll-view
    width="100%"
    height="100%"
  >
    <div class="inner">
      <p *ngIf="type === FormTypesEnum.Department">{{ _('cannot-delete-department', name) }}</p>
      <p *ngIf="type === FormTypesEnum.Area">{{ _('cannot-delete-area', name) }}</p>
      <ul>
        <li *ngFor="let item of receiversBlockingDeletionList">{{ item }}</li>
      </ul>
      <p *ngIf="receiversToDeleteList.length > 0">{{ _('other-receivers') }}</p>
      <ul>
        <li *ngFor="let item of receiversToDeleteList">{{ item }}</li>
      </ul>
      <p *ngIf="formsList.length > 0">{{ _('list-of-subforms') }}</p>
      <ul>
        <li *ngFor="let item of formsList">{{ item }}</li>
      </ul>
    </div>
  </dx-scroll-view>
</dx-popup>
