import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../../../app.config';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export class SankeyDataItem {
  source: string;
  target: string;
  weight: number;
  type: number;
}

@Injectable({
  providedIn: 'root',
})
export class EnergyBalanceSankey {
  public apiUrl: string;
  private _sankeyData$ = new BehaviorSubject<Array<SankeyDataItem>>(null);
  public sankeyData$ = this._sankeyData$.asObservable();
  private _sankeyLoading$ = new BehaviorSubject<boolean>(null);
  public sankeyLoading$ = this._sankeyLoading$.asObservable();

  private _drawerAction = new BehaviorSubject<any>(null);
  public drawerAction = this._drawerAction.asObservable();

  public constructor(
    private _http: HttpClient,
    private _appConfig: AppConfig
  ) {
    this.apiUrl = this._appConfig.getConfig('DbEnergyDatabaseUrl');
  }

  public getEnergyBalanceSankeyData(auditId: string): Observable<Array<SankeyDataItem>> {
    return this._http.get<Array<SankeyDataItem>>(`${this.apiUrl}api/audits/${auditId}/energy-balances`);
  }

  public updateEnergyBalanceSankey(auditId: string): void {
    this._sankeyLoading$.next(true);
    this.getEnergyBalanceSankeyData(auditId).subscribe((res: Array<SankeyDataItem>) => {
      this._sankeyData$.next(res);
    });
  }

  public performDrawerAction(e: any): void {
    this._drawerAction.next(e);
  }
}
