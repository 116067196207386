import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AreaTypes } from '../../../../../shared/models/area/area-types';
import { Area, AreaType } from '../../../../../shared/models/area/area.model';
import { AuditTreeNavigation, FormType } from '../../../../../shared/models/audit/audit-tree-navigation.model';
import { ApiRequestService } from '../../../../../shared/services/api-request.service';
import { ScrollService } from '../../../../services/scroll.service';
import { AppSettings } from '../../../../../AppSettings';
import { UtilsService } from '../../../../services/utils.service';

@Injectable({
  providedIn: 'root',
})
export class AreaFormService {
  private _areaCopy: Area;

  public _isFinished = new Subject<boolean>();
  public isFinished = this._isFinished.asObservable();

  private _performAction = new Subject<any>();
  public performAction = this._performAction.asObservable();

  private _isLoaded = new BehaviorSubject<{ value: boolean; formtype: FormType }>({
    value: false,
    formtype: FormType.Audit,
  });
  public isLoaded = this._isLoaded.asObservable();

  constructor(
    private _apiRequestService: ApiRequestService,
    private _scrollService: ScrollService,
    private _utilsService: UtilsService
  ) {}

  public getEmptyAreaRoot(): Area {
    const area: Area = {
      conclusion: '',
      name: '',
      description: '',
      type: {
        id: null,
        name: '',
        areaCategory: {
          id: null,
          name: '',
        },
      },
      root: true,
    };

    return area;
  }

  public getEmptyArea(): Area {
    const area: Area = {
      name: '',
      description: '',
      conclusion: '',
      type: {
        id: null,
        name: '',
        areaCategory: {
          id: null,
          name: '',
        },
      },
      root: false,
    };

    return area;
  }

  public save(area: any): Observable<any> {
    switch (area.type.name) {
      case AreaTypes.BoilerRoom:
        return this._apiRequestService.saveBoilerRoom(area);
      case AreaTypes.Boiler:
        return this._apiRequestService.updateBoiler(area);
      case AreaTypes.WaterPump:
        return this._apiRequestService.updateWaterPump(area);
      case AreaTypes.Fan:
        return this._apiRequestService.updateFan(area);
      case AreaTypes.HeatRecovery:
        return this._apiRequestService.updateHeatRecovery(area);
      case AreaTypes.ElectricityConnection:
        return this._apiRequestService.updateElectrictyConnection(area);
      case AreaTypes.Photovoltaics:
        return this._apiRequestService.updateAreaPhotovoltaics(area);
      case AreaTypes.AirConditioner:
        return this._apiRequestService.updateAirConditioner(area);
      case AreaTypes.Transformer:
        return this._apiRequestService.updateTransformerArea(area);
      case AreaTypes.Deaerator:
        return this._apiRequestService.updateDeaeratorArea(area);
      case AreaTypes.ColdCompressor:
        return this._apiRequestService.updateColdCompressorArea(area);
      case AreaTypes.GassCogeneration:
        return this._apiRequestService.updateGassCogeneration(area);
      case AreaTypes.AbsorptionAggregate:
        return this._apiRequestService.updateAbsorptionAggregate(area);
      case AreaTypes.Blower:
        return this._apiRequestService.updateAreaTypeBlower(area);
      case AreaTypes.CompressedAirGenerator:
        return this._apiRequestService.updateAreaTypeCompressedAirGenerator(area);
      case AreaTypes.AirCompressor:
        return this._apiRequestService.updateAreaTypeAirCompressor(area);
      case AreaTypes.AirCurtain:
        return this._apiRequestService.updateAreaTypeAirCurtain(area);
      case AreaTypes.AirEnhancer:
        return this._apiRequestService.updateAreaTypeAirEnhancer(area);
      case AreaTypes.FanCooler:
        return this._apiRequestService.updateAreaTypeFanCooler(area);
      case AreaTypes.AirCurtain:
        return this._apiRequestService.updateAreaTypeAirCurtain(area);
      case AreaTypes.Dehumidifier:
        return this._apiRequestService.updateAreaTypeDehumidifier(area);
      case AreaTypes.HeatPump:
        return this._apiRequestService.updateAreaTypeHeatPump(area);
      case AreaTypes.ElectricHeater:
        return this._apiRequestService.updateAreaTypeElectricHeater(area);
      case AreaTypes.FanCooler:
        return this._apiRequestService.updateAreaTypeFanCooler(area);
      case AreaTypes.Building:
        return this._apiRequestService.updateAreaTypeBuilding(area);
      case AreaTypes.WaterHeater:
        return this._apiRequestService.updateAreaTypeWaterHeater(area);
      case AreaTypes.SteamGenerator:
        return this._apiRequestService.updateAreaTypeSteamGenerator(area);
      case AreaTypes.Lighting:
        return this._apiRequestService.updateAreaTypeLighting(area);
      case AreaTypes.RadiantHeater:
        return this._apiRequestService.updateAreaTypeRadiantHeater(area);
      case AreaTypes.Armature:
        return this._apiRequestService.updateAreaTypeArmature(area);
      case AreaTypes.VentilationCentral:
        return this._apiRequestService.updateAreaTypeVentilationCentral(area);
      case AreaTypes.Heater:
        return this._apiRequestService.updateAreaTypeHeater(area);
      case AreaTypes.InjectionMachine:
        return this._apiRequestService.updateAreaTypeInjectionMachine(area);
      case AreaTypes.Press:
        return this._apiRequestService.updateAreaTypePress(area);
      case AreaTypes.Transport:
        return this._apiRequestService.updateAreaTypeTransport(area);
      case AreaTypes.ElectricDrive:
        return this._apiRequestService.updateAreaTypeElectricDrive(area);
      case AreaTypes.VacuumPump:
        return this._apiRequestService.updateAreaTypeVacuumPump(area);
      case AreaTypes.PowerGenerator:
        return this._apiRequestService.updateAreaTypePowerGenerator(area);
      default:
        notify({
          message: `Niepoprawny typ obszaru`,
          type: 'error',
          displayTime: AppSettings.NOTIFY_DURATION,
          position: 'top center',
        });
    }
  }

  public createCopy(area: Area): void {
    this._areaCopy = this._utilsService.deepCopy(area);
  }

  public formChanged(area: Area): boolean {
    return !this._utilsService.areObjectsEqual(area, this._areaCopy);
  }

  getCurrentAreaForm(areaId: string): Observable<Area> {
    return this._apiRequestService.getArea(areaId);
  }

  addNewArea(area: Area): Observable<Area> {
    return this._apiRequestService.addArea(area);
  }

  getAreaTypes(typeId: string): Observable<Array<AreaType>> {
    return this._apiRequestService.getAreaTypesForType(typeId);
  }

  getAreaCategories(): Observable<Array<AuditTreeNavigation>> {
    return this._apiRequestService.getAreaCategories();
  }

  getAreaTypesForCategorie(categoryId: number): Observable<Array<AreaType>> {
    return this._apiRequestService.getAreaTypes(categoryId);
  }

  public scrollToTop(): void {
    this._scrollService.updateScrollToTop(true);
  }

  public formLoaded(value: boolean): void {
    this._isLoaded.next({ value, formtype: FormType.Area });
  }

  public selectedAction(e: any): void {
    this._performAction.next(e);
  }

  public toggleFinishedAudit(isFinished: boolean): void {
    this._isFinished.next(isFinished);
  }
}
