<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-popup
  [title]="titleText"
  [width]="popupWidth"
  [height]="popupHeight"
  [fullScreen]="false"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="showMoveImagePopup"
  (onHiding)="hidePopup()"
  [wrapperAttr]="popupWrapper"
  (onShown)="setupSizeOfPopup()"
>
  <dx-tree-list
    #treeViewRef
    [dataSource]="formsTree"
    [rootValue]="rootValue"
    [expandedRowKeys]="expandedRowsKeyForms"
    [columnAutoWidth]="true"
    height="100%"
    width="100%"
    displayExpr="name"
    keyExpr="id"
    parentIdExpr="parentId"
    [dataStructure]="'plain'"
    (onRowExpanding)="isExpandingOrCollapsing = true"
    (onRowExpanded)="setupSizeOfPopup()"
    (onRowCollapsed)="setupSizeOfPopup()"
    (onRowClick)="onSelectForm($event.node.data)"
    [showColumnHeaders]="false"
  >
    <dxo-search-panel
      [visible]="true"
      width="100%"
      [placeholder]="searchText"
    ></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-selection mode="single"></dxo-selection>
    <dxi-column
      dataField="name"
      [caption]="messageText"
    >
    </dxi-column>
  </dx-tree-list>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    cssClass="popup-bottom-toolbar toolbar-contained-button-focus-border button-contained button-case-sensitive"
    [options]="confirmMoveButtonOptions"
    [disabled]="!nodeSelected"
    [visible]="!selectFormOnly"
  >
  </dxi-toolbar-item>
</dx-popup>
