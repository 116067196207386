import { Component, ContentChild, Directive, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Localizable } from '../../../../locale/localizable';

export enum CloseResult {
  Confirm,
  Cancel,
  Special,
}

@Directive({
  selector: '[appExitConfirmationPopupContent]',
})
export class ExitConfirmationPopupContentDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
  selector: 'app-exit-confirmation-popup',
  templateUrl: './exit-confirmation-popup.component.html',
  styleUrls: ['./exit-confirmation-popup.component.scss'],
})
export class ExitConfirmationPopupComponent extends Localizable implements OnInit {
  @Input() popupVisible: boolean = false;
  @Input() title: string;
  @Input() message: string = null;
  @Input() confirmationYes: string;
  @Input() confirmationNo: string;
  @Input() confirmationSpecial: string;
  @Input() specialButtonVisible: boolean = false;
  @Input() customContent: boolean = false;

  @Output() closeEvent: EventEmitter<CloseResult> = new EventEmitter<CloseResult>();
  @ContentChild(ExitConfirmationPopupContentDirective) content!: ExitConfirmationPopupContentDirective;

  constructor() {
    super('Questionnaire');
  }
  ngOnInit(): void {}

  public exitConfirm(): void {
    this.popupVisible = false;
    this.closeEvent.emit(CloseResult.Confirm);
  }

  public exitCancel(): void {
    if (this.popupVisible) {
      this.popupVisible = false;
      this.closeEvent.emit(CloseResult.Cancel);
    }
  }

  public exitSpecial(): void {
    this.popupVisible = false;
    this.closeEvent.emit(CloseResult.Special);
  }
}
