import { Component, Input } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { AppConfig } from '../../../../app.config';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { AppConstant } from './constant.model';
import { Localizable } from '../../../../locale/localizable';
import { ErrorHandlerService } from '../../../../shared/services/errorHandlerService';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { AppSettings } from '../../../../AppSettings';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-constant',
  templateUrl: './constant.component.html',
  styleUrls: ['./constant.component.scss'],
})
export class ConstantComponent extends Localizable {
  private static readonly localFile = 'GlobalConstantListComponent';

  @Input() constant: AppConstant;

  public definedConstantsList: CustomStore;
  public popupVisible: boolean = false;

  private _urlDbEnergyApi: string = '';

  constructor(
    private _config: AppConfig,
    private _authenticationService: AuthenticationService,
    private _errorHandlerService: ErrorHandlerService
  ) {
    super(ConstantComponent.localFile);
    this._urlDbEnergyApi = this._config.getConfig('DbEnergyDatabaseUrl');
  }

  public openPopup(): void {
    this.popupVisible = true;
    this.fetchStore();
  }

  public fetchStore(): void {
    this.definedConstantsList = AspNetData.createStore({
      key: 'id',
      loadUrl: `${this._urlDbEnergyApi}${this.constant.apiUrl}`,
      updateUrl: `${this._urlDbEnergyApi}${this.constant.apiUrl}`,
      deleteUrl: `${this._urlDbEnergyApi}${this.constant.apiUrl}`,
      insertUrl: `${this._urlDbEnergyApi}${this.constant.apiUrl}`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        const token = localStorage.getItem(AppSettings.AUTH_TOKEN);
        ajaxOptions.headers = { Authorization: `Bearer ${token}` };
      },
      onAjaxError: (e) => {
        if (e.xhr.status === AppSettings.HTTP_STATUS_CODE_NOT_AUTHORIZED) {
          this._authenticationService.logout();
          notify(this._('not-authorized-logout'), 'error', AppSettings.NOTIFY_DURATION);
        } else {
          this._errorHandlerService.handleError(e.xhr);
        }
      },
    });
  }

  public cleanStore(): void {
    this.popupVisible = false;
  }
}
