import { QuestionType } from './models/questionnaire.models';

export class QuestionnaireDto {
  id?: string;
  groups: Array<QuestionnaireGroupDto>;
}

export class QuestionnaireGroupDto {
  guid: string;
  id: string;
  questionnaireId: string;
  questions: Array<QuestionDto>;
  metaDataId: string;
  metaInfo: QuestionnaireMetaInfo;
}

export class QuestionnaireMetaInfo {
  totalProgress: number;
  currentProgress: number;
  updateReady: boolean;
  groupProgressInfos: Array<{
    groupId: string;
    progress: ProgressPoints;
  }>;
  groupUpdateInfos: Array<{
    groupId: string;
    updateable: boolean;
  }>;
}

export class QuestionDto {
  id: string;
  guid: string;
  root?: boolean;
  type: QuestionType;
  note: string;
  answers: Array<AnswerDto>;
}

export class AnswerDto {
  guid: string;
  id: string;
  nextQuestionGuid?: string;
  nextQuestionId: string;

  stringValue?: string;
  numberValue?: number;
  booleanValue?: boolean;
  isText?: boolean;
  unit?: string;
}
export enum ProgressPoints {
  NoProgress = 0,
  InProgress = 1,
  Completed = 2,
}
