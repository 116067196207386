import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { Localizable } from '../../locale/localizable';
import { AuditType } from 'src/app/shared/models/audit/audit-type.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { TemplateService } from 'src/app/shared/services/template.service';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
})
export class TemplateListComponent extends Localizable implements OnInit {
  urlDBEnergyDatabase: string = null;
  formTypesList: Array<string> = [];
  auditTypesList: Array<AuditType> = [];
  auditType: AuditType = null;
  selectedFormType: string = null;

  constructor(
    private httpClient: HttpClient,
    private _templateService: TemplateService,
    private _config: AppConfig,
    private _apiRequestService: ApiRequestService
  ) {
    super();
    this.urlDBEnergyDatabase = this._config.getConfig('DbEnergyDatabaseUrl');
  }

  ngOnInit(): void {
    this.httpClient
      .get<Array<string>>(this.urlDBEnergyDatabase + 'api/areas/categories?includeMainForms=true')
      .subscribe((res) => (this.formTypesList = res));

    this._apiRequestService.getAuditTypes().subscribe((res) => (this.auditTypesList = res));
  }

  public screen(width) {
    return width < 700 ? 'sm' : 'lg';
  }

  public selectedFromTypeChanged(selected: any): void {
    this.selectedFormType = selected.text;
    this._templateService.setFormType(this.selectedFormType);
  }

  public selectAuditType(selected: any): void {
    this.auditType = selected.selectedItem;
    this._templateService.setAuditType(selected.selectedItem);
  }
}
