export enum AreaTypes {
  BoilerRoom = 'Kotłownia',
  Boiler = 'Kocioł',
  CoolingSysten = 'System Generowania Chłodu',
  WaterPump = 'Pompa wody',
  Fan = 'Wentylator',
  HeatRecovery = 'Odzysk ciepła',
  ElectricityConnection = 'Przyłącze energii elektrycznej',
  Photovoltaics = 'Fotowoltaika',
  AirConditioner = 'Klimatyzator',
  Transformer = 'Transformator',
  Deaerator = 'Odgazowywacz',
  ColdCompressor = 'Sprężarka chłodu',
  GassCogeneration = 'Kogeneracja gazowa',
  AbsorptionAggregate = 'Agregat absorpcyjny',
  Blower = 'Dmuchawa',
  CompressedAirGenerator = 'Układ wytwarzania sprężonego powietrza',
  AirCompressor = 'Sprężarka powietrza',
  AirCurtain = 'Kurtyna powietrzna',
  AirEnhancer = 'Doprężacz powietrza',
  FanCooler = 'Chłodnia wentylatorowa',
  ElectricHeater = 'Grzejnik elektryczny',
  Dehumidifier = 'Osuszacz powietrza',
  Building = 'Budynek',
  HeatPump = 'Pompa ciepła',
  WaterHeater = 'Podgrzewacz wody',
  SteamGenerator = 'Wytwornica pary',
  Lighting = 'Oświetlenie',
  RadiantHeater = 'Promiennik ciepła',
  Armature = 'Armatura',
  VentilationCentral = 'Centrala wentylacyjna',
  Heater = 'Nagrzewnica',
  InjectionMachine = 'Wtryskarka',
  Press = 'Prasa',
  Transport = 'Transport',
  ElectricDrive = 'Napęd elektryczny',
  VacuumPump = 'Pompa próżniowa',
  PowerGenerator = 'Agregat prądotwórczy',
}
