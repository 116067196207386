import { Component, Input, OnInit } from '@angular/core';
import { AreaTypes } from '../../../../shared/models/area/area-types';
import { Localizable } from '../../../../locale/localizable';

@Component({
  selector: 'app-area-template-form',
  templateUrl: './area-template-form.component.html',
})
export class AreaTemplateFormComponent extends Localizable implements OnInit {
  @Input() areaCode: string = null;
  @Input() areaType: string = null;

  public get AreaTypesEnum(): typeof AreaTypes {
    return AreaTypes;
  }

  constructor() {
    super('TemplateFileListComponent');
  }

  ngOnInit(): void {}
}
