<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="vacuumPump.numberOfDevices"
        stylingMode="outlined"
        [min]="0"
        [max]="1000"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('vacuum-pump-number-of-devices')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="vacuumPump.producer"
        stylingMode="outlined"
      ></dx-text-box>
    </div>
    <dxo-label [text]="_('vacuum-pump-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="vacuumPump.model"
        stylingMode="outlined"
      ></dx-text-box>
    </div>
    <dxo-label [text]="_('vacuum-pump-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="vacuumPump.serialNumber"
        stylingMode="outlined"
      ></dx-text-box>
    </div>
    <dxo-label [text]="_('vacuum-pump-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="vacuumPump.yearOfProduction"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('vacuum-pump-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="vacuumPump.ratedPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('vacuum-pump-rated-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kV'"
        [(value)]="vacuumPump.ratedVoltage"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('vacuum-pump-rated-voltage')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' Pa'"
        [(value)]="vacuumPump.output"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('vacuum-pump-output')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' dm³/min'"
        [(value)]="vacuumPump.averageEfficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('vacuum-pump-average-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="vacuumPump.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('vacuum-pump-working-hours-in-year')"></dxo-label>
  </dxi-item>
</dx-form>
