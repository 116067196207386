<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="dx-card responsive-paddings energy-balance-max-width">
  <h2 class="headerStyle georama-font">{{ _('energy-balance') }}</h2>
  <div
    class="balanceDescription"
    *ngIf="fromDate && toDate"
  >
    {{ _('energy-balance-description') }} {{ fromDate }} - {{ toDate }}
  </div>
  <div
    *ngIf="this.energyBalanceTypes"
    id="energiesSelector"
  >
    <dx-responsive-box
      singleColumnScreen="sm"
      [(screenByWidth)]="screen"
    >
      <dxi-row [ratio]="2"></dxi-row>
      <dxi-row [ratio]="2"></dxi-row>

      <dxi-col [ratio]="1"></dxi-col>

      <dxi-item *ngIf="chosenTypes">
        <dxi-location
          [row]="0"
          [col]="0"
          screen="lg"
        ></dxi-location>
        <dxi-location
          [row]="0"
          [col]="0"
          screen="sm"
        ></dxi-location>
        <div
          *dxTemplate
          class="left-side-bar item"
        >
          <div>{{ _('energy-balance-energy-type') }}</div>
          <dx-tag-box
            #tagBox
            [value]="chosenTypesIds"
            [items]="energyBalanceTypes"
            [searchEnabled]="true"
            [placeholder]="_('energy-balance-energy-type-placeholder')"
            (onValueChanged)="onInputEnergySelectionChanged($event)"
            valueExpr="id"
            displayExpr="name"
            stylingMode="outlined"
            searchMode="contains"
            tagTemplate="tagTemplate"
          >
            <div *dxTemplate="let data of 'tagTemplate'">
              <div
                *ngIf="isTypeInChosen(data)"
                class="dx-tag-content tag-disable-delete"
              >
                <span> {{ data.name }}</span>
              </div>
              <div
                *ngIf="!isTypeInChosen(data)"
                class="dx-tag-content"
              >
                <span> {{ data.name }}</span>
                <div class="dx-tag-remove-button"></div>
              </div>
            </div>
          </dx-tag-box>
        </div>
      </dxi-item>
    </dx-responsive-box>
  </div>

  <div *ngIf="energySourcesList">
    <div
      id="selectedEnergySet"
      class="selected-energy-card responsive-paddings"
      *ngFor="let chosenSourceEnergies of energySourcesList"
    >
      <!-- class="dx-card" -->
      <dx-responsive-box
        singleColumnScreen="sm"
        [(screenByWidth)]="screen"
      >
        <dxi-col [ratio]="1"></dxi-col>
        <dxi-col [ratio]="1"></dxi-col>

        <dxi-item>
          <dxi-location
            [row]="0"
            [col]="0"
            screen="lg"
            [colspan]="2"
          ></dxi-location>
          <dxi-location
            [row]="0"
            [col]="0"
            screen="sm"
          ></dxi-location>
          <div
            *dxTemplate
            class="left-side-bar item"
          >
            <div class="energiesEntry">
              <div>
                {{ _('energy-balance-energy-type') }}
                <strong>{{ chosenSourceEnergies?.name | uppercase }}</strong>
              </div>
            </div>
          </div>
        </dxi-item>
        <dxi-item>
          <dxi-location
            [row]="2"
            [col]="0"
            [colspan]="2"
            screen="lg"
          ></dxi-location>
          <dxi-location
            [row]="2"
            [col]="0"
            [colspan]="2"
            screen="sm"
          ></dxi-location>
          <div
            class="responsive-paddings"
            style="border-left: 10px solid #363640; border-radius: 10px; margin-top: 20px"
            *ngFor="let energySourceDetails of chosenSourceEnergies.energySources"
          >
            <dx-button
              style="float: left"
              type="danger"
              icon="fi fi-rr-trash"
              stylingMode="outlined"
              (onClick)="deleteSource(energySourceDetails)"
              [disabled]="isEnergyBalanceDisabled"
            >
            </dx-button>
            <dx-button
              style="float: left"
              icon="fi fi-rr-pencil"
              stylingMode="outlined"
              (onClick)="openSourceEditForm(energySourceDetails)"
              [disabled]="isEnergyBalanceDisabled"
            >
            </dx-button>
            <dx-responsive-box
              singleColumnScreen="sm"
              [(screenByWidth)]="screen"
            >
              <dxi-row [ratio]="1"></dxi-row>
              <dxi-row [ratio]="1"></dxi-row>
              <dxi-row [ratio]="1"></dxi-row>
              <dxi-row [ratio]="1"></dxi-row>

              <dxi-col [ratio]="1"></dxi-col>
              <dxi-col [ratio]="1"></dxi-col>

              <dxi-item>
                <dxi-location
                  [row]="0"
                  [col]="0"
                  screen="lg"
                ></dxi-location>
                <dxi-location
                  [row]="0"
                  [col]="0"
                  screen="sm"
                ></dxi-location>
                <dx-responsive-box
                  singleColumnScreen="sm"
                  [(screenByWidth)]="screen"
                >
                  <dxi-row [ratio]="1"></dxi-row>
                  <dxi-row [ratio]="1"></dxi-row>
                  <dxi-row [ratio]="1"></dxi-row>
                  <dxi-row [ratio]="1"></dxi-row>
                  <dxi-row [ratio]="1"></dxi-row>
                  <dxi-item>
                    <dxi-location
                      [row]="0"
                      screen="lg"
                    ></dxi-location>
                    <dxi-location
                      [row]="0"
                      screen="sm"
                    ></dxi-location>
                    <div *dxTemplate>
                      <div>{{ _('energy-balance-source-name') }}:</div>
                      <dx-text-box
                        [(value)]="energySourceDetails.name"
                        stylingMode="outlined"
                        [readOnly]="true"
                      ></dx-text-box>
                    </div>
                  </dxi-item>
                  <dxi-item>
                    <dxi-location
                      [row]="1"
                      screen="lg"
                    ></dxi-location>
                    <dxi-location
                      [row]="1"
                      screen="sm"
                    ></dxi-location>

                    <div>{{ _('energy-balance-source-amount') }}:</div>
                    <dx-text-box
                      [(value)]="energySourceDetails.energyAmount"
                      stylingMode="outlined"
                      [readOnly]="true"
                    ></dx-text-box>
                  </dxi-item>
                  <dxi-item>
                    <dxi-location
                      [row]="2"
                      screen="lg"
                    ></dxi-location>
                    <dxi-location
                      [row]="2"
                      screen="sm"
                    ></dxi-location>
                    <div *dxTemplate>
                      <div>{{ _('energy-balance-source-unit') }}:</div>
                      <dx-text-box
                        [(value)]="energySourceDetails.unit.shortName"
                        stylingMode="outlined"
                        [readOnly]="true"
                      ></dx-text-box>
                    </div>
                  </dxi-item>
                  <dxi-item>
                    <dxi-location
                      [row]="3"
                      screen="lg"
                    ></dxi-location>
                    <dxi-location
                      [row]="3"
                      screen="sm"
                    ></dxi-location>
                    <div *dxTemplate>
                      <div>{{ _('energy-balance-source-energy-cost') }}:</div>
                      <dx-number-box
                        step="0"
                        [(value)]="energySourceDetails.energyCost"
                        stylingMode="outlined"
                        [readOnly]="true"
                        [format]="currencyFormat"
                      ></dx-number-box>
                    </div>
                  </dxi-item>
                  <dxi-item>
                    <dxi-location
                      [row]="4"
                      screen="lg"
                    ></dxi-location>
                    <dxi-location
                      [row]="4"
                      screen="sm"
                    ></dxi-location>
                    <div *dxTemplate>
                      <div class="item-top-empty-space">
                        <div>
                          {{ _('energy-balance-source-level') }}:
                          {{ energySourceDetails.baseFormEntity.name }}
                        </div>
                      </div>
                    </div>
                  </dxi-item>
                </dx-responsive-box>
              </dxi-item>
              <dxi-item style="margin-left: 1em">
                <dxi-location
                  [row]="0"
                  [rowspan]="3"
                  [col]="1"
                  screen="lg"
                ></dxi-location>
                <dxi-location
                  [row]="0"
                  [rowspan]="3"
                  [col]="1"
                  screen="sm"
                ></dxi-location>
                <div
                  class="responsive-paddings"
                  style="border-left: 10px solid #c3d500; border-radius: 10px; margin-top: 20px"
                  *ngFor="let energyReceiversDetails of energySourceDetails.energyReceivers"
                >
                  <dx-button
                    style="float: right"
                    type="danger"
                    icon="fi fi-rr-trash"
                    stylingMode="outlined"
                    (onClick)="deleteReceiver(energyReceiversDetails)"
                    [disabled]="isEnergyBalanceDisabled"
                  >
                  </dx-button>
                  <dx-button
                    style="float: right"
                    icon="fi fi-rr-pencil"
                    stylingMode="outlined"
                    (onClick)="openReceiverEditForm(energyReceiversDetails)"
                    [disabled]="isEnergyBalanceDisabled"
                  >
                  </dx-button>
                  <dx-responsive-box
                    singleColumnScreen="sm"
                    [(screenByWidth)]="screen"
                  >
                    <dxi-row [ratio]="1"></dxi-row>
                    <dxi-row [ratio]="1"></dxi-row>
                    <dxi-row [ratio]="1"></dxi-row>
                    <dxi-row [ratio]="1"></dxi-row>
                    <dxi-row [ratio]="1"></dxi-row>

                    <dxi-col [ratio]="1"></dxi-col>

                    <dxi-item>
                      <dxi-location
                        [row]="0"
                        [col]="0"
                        screen="lg"
                      ></dxi-location>
                      <dxi-location
                        [row]="0"
                        [col]="0"
                        screen="sm"
                      ></dxi-location>
                      <div *dxTemplate>
                        <div>{{ _('energy-balance-receiver-name') }}:</div>
                        <dx-text-box
                          [(value)]="energyReceiversDetails.name"
                          stylingMode="outlined"
                          [readOnly]="true"
                        ></dx-text-box>
                      </div>
                    </dxi-item>
                    <dxi-item>
                      <dxi-location
                        [row]="1"
                        [col]="0"
                        screen="lg"
                      ></dxi-location>
                      <dxi-location
                        [row]="1"
                        [col]="0"
                        screen="sm"
                      ></dxi-location>
                      <div *dxTemplate>
                        <div>{{ _('energy-balance-receiver-amount') }}:</div>
                        <dx-text-box
                          [(value)]="energyReceiversDetails.energyAmount"
                          stylingMode="outlined"
                          [readOnly]="true"
                        ></dx-text-box>
                      </div>
                    </dxi-item>
                    <dxi-item>
                      <dxi-location
                        [row]="2"
                        [col]="0"
                        screen="lg"
                      ></dxi-location>
                      <dxi-location
                        [row]="2"
                        [col]="0"
                        screen="sm"
                      ></dxi-location>
                      <div *dxTemplate>
                        <div>{{ _('energy-balance-receiver-unit') }}:</div>
                        <dx-text-box
                          [(value)]="energyReceiversDetails.unit.shortName"
                          stylingMode="outlined"
                          [readOnly]="true"
                        ></dx-text-box>
                      </div>
                    </dxi-item>
                    <dxi-item>
                      <dxi-location
                        [row]="3"
                        [col]="0"
                        screen="lg"
                      ></dxi-location>
                      <dxi-location
                        [row]="3"
                        [col]="0"
                        screen="sm"
                      ></dxi-location>
                      <div *dxTemplate>
                        <div>{{ _('energy-balance-receiver-energy-cost') }}:</div>
                        <dx-number-box
                          step="0"
                          [(value)]="energyReceiversDetails.energyCost"
                          stylingMode="outlined"
                          [format]="currencyFormat"
                          [readOnly]="true"
                        ></dx-number-box>
                      </div>
                    </dxi-item>
                    {{ energyReceiversDetails.name }}
                    <dxi-item>
                      <dxi-location
                        [row]="4"
                        [col]="0"
                        screen="lg"
                      ></dxi-location>
                      <dxi-location
                        [row]="4"
                        [col]="0"
                        screen="sm"
                      ></dxi-location>
                      <div *dxTemplate>
                        <div>
                          {{ _('energy-balance-receiver-level') }}:
                          {{ energyReceiversDetails.baseFormEntity.name }}
                        </div>
                      </div>
                    </dxi-item>
                  </dx-responsive-box>
                </div>
                <dx-button
                  class="outlined-button-focus-hover"
                  style="margin-top: 2em"
                  [text]="_('energy-balance-add-receiver')"
                  type="outlined"
                  stylingMode="outlined"
                  icon="fi fi-rr-plus"
                  (onClick)="openReceiverForm(energySourceDetails)"
                  [disabled]="isEnergyBalanceDisabled"
                >
                </dx-button>
              </dxi-item>
            </dx-responsive-box>
          </div>
          <dx-button
            class="outlined-button-focus-hover"
            style="margin-top: 1em"
            [text]="_('energy-balance-add-source')"
            type="outlined"
            stylingMode="outlined"
            icon="fi fi-rr-plus"
            (onClick)="openSourceForm(chosenSourceEnergies)"
            [disabled]="isEnergyBalanceDisabled"
          >
          </dx-button>
        </dxi-item>
      </dx-responsive-box>
    </div>
  </div>

  <app-energy-receiver-popup
    *ngIf="receiverPopupVisible"
    [energyReceiver]="energyBalanceReceiver"
    [editEnergyReceiver]="editEnergyBalanceReceiver"
    [editMode]="editReceiver"
    [currencyFormat]="currencyFormat"
    (updateEnergyReceiver)="updatedEnergyReceiver($event)"
    (addEnergyReceiver)="addedEnergyReceiver($event)"
  ></app-energy-receiver-popup>
  <app-energy-source-popup
    *ngIf="sourcePopupVisible"
    [editMode]="editSource"
    [energySource]="energyBalanceSource"
    [editEnergySource]="editEnergyBalanceSource"
    [currencyFormat]="currencyFormat"
    (updateEnergySourced)="updatedEnergySourced($event)"
    (addEnergySource)="addedEnergySource($event)"
  ></app-energy-source-popup>
  <app-energy-source-delete-popup
    *ngIf="deleteSourcePopupVisible"
    [energyBalanceSource]="energySourceToDelete"
    [currencyFormat]="currencyFormat"
    (deleteSourceWithReceivers)="deleteEnergySource($event)"
    (popupClosed)="closedDeleteSourcePopup($event)"
  >
  </app-energy-source-delete-popup>
</div>
