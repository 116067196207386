import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AuditFormComponent } from './shared/components/forms/audit-form/audit-form.component';
import { UserFormComponent } from './shared/components/forms/user-form/user-form.component';
import { UserComponent } from './pages/user/user.component';
import { EnterpriseFormComponent } from './shared/components/forms/enterprise-form/enterprise-form.component';
import { AuditsListComponent } from './pages/audits-list/audits-list/audits-list.component';
import { CommonModule, DatePipe } from '@angular/common';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDrawerModule,
  DxDropDownBoxModule,
  DxFileUploaderModule,
  DxFormModule,
  DxListModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxResponsiveBoxModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSliderModule,
  DxSpeedDialActionModule,
  DxSwitchModule,
  DxTabPanelModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTreeListModule,
  DxTreeViewModule,
  DxValidationGroupModule,
  DxValidationSummaryModule,
  DxValidatorModule,
  DxToolbarModule,
  DxAccordionModule,
  DxButtonGroupModule,
  DxSankeyModule,
  DxDropDownButtonModule,
  DxRadioGroupModule,
  DxLoadIndicatorModule,
  DxProgressBarModule,
  DxLoadPanelModule,
  DxDateRangeBoxModule,
  DxDiagramModule,
  DxContextMenuModule,
} from 'devextreme-angular';
import { DepartmentFormComponent } from './shared/components/forms/department-form/department-form/department-form.component';
import { AreaFormComponent } from './shared/components/forms/area-form/area-form/area-form.component';
import { EnergyBalanceComponent } from './shared/components/energy-balance/energy-balance.component';
import { DxiItemModule } from 'devextreme-angular/ui/nested';
import { TreeNavigationComponent } from './shared/components/tree-navigation/tree-navigation.component';
import { AuditsListWidgetComponent } from './shared/components/lists/audits-list-widget/audits-list-widget.component';
import { BrowserModule } from '@angular/platform-browser';
import { EnergyReceiverPopupComponent } from './shared/components/energy-balance/energy-receiver-popup/energy-receiver-popup.component';
import { EnergySourcePopupComponent } from './shared/components/energy-balance/energy-source-popup/energy-source-popup.component';
import { EnergySourceDeletePopupComponent } from './shared/components/energy-balance/energy-source-delete-popup/energy-source-delete-popup.component';
import { BoilerRoomFormComponent } from './shared/components/forms/area-form/area-form/types-form/boiler-room-form/boiler-room-form.component';
import { BoilerFormComponent } from './shared/components/forms/area-form/area-form/types-form/boiler-room-form/boiler-form/boiler-form.component';
import { CoolingSystemComponent } from './shared/components/forms/area-form/area-form/types-form/cooling-system/cooling-system.component';
import { WaterPumpComponent } from './shared/components/forms/area-form/area-form/types-form/water-pump/water-pump.component';
import { FanComponent } from './shared/components/forms/area-form/area-form/types-form/fan/fan.component';
import { HeatRecoveryComponent } from './shared/components/forms/area-form/area-form/types-form/heat-recovery/heat-recovery.component';
import { ElectricityConnectionComponent } from './shared/components/forms/area-form/area-form/types-form/electricity-connection/electricity-connection.component';
import { PhotovoltaicsComponent } from './shared/components/forms/area-form/area-form/types-form/photovoltaics/photovoltaics.component';
import { AirConditionerComponent } from './shared/components/forms/area-form/area-form/types-form/air-conditioner/air-conditioner.component';
import { TransformerComponent } from './shared/components/forms/area-form/area-form/types-form/transformer/transformer.component';
import { DeaeratorComponent } from './shared/components/forms/area-form/area-form/types-form/deaerator/deaerator.component';
import { ColdCompressorComponent } from './shared/components/forms/area-form/area-form/types-form/cold-compressor/cold-compressor.component';
import { GassCogenerationComponent } from './shared/components/forms/area-form/area-form/types-form/gass-cogeneration/gass-cogeneration.component';
import { AbsorptionAggregateComponent } from './shared/components/forms/area-form/area-form/types-form/absorption-aggregate/absorption-aggregate.component';
import { BlowerComponent } from './shared/components/forms/area-form/area-form/types-form/blower/blower.component';
import { CompressedAirGeneratorComponent } from './shared/components/forms/area-form/area-form/types-form/compressed-air-generator/compressed-air-generator.component';
import { AirCompressorComponent } from './shared/components/forms/area-form/area-form/types-form/air-compressor/air-compressor.component';
import { FanCoolerComponent } from './shared/components/forms/area-form/area-form/types-form/fan-cooler/fan-cooler.component';
import { ElectricHeaterComponent } from './shared/components/forms/area-form/area-form/types-form/electric-heater/electric-heater.component';
import { AirCurtainComponent } from './shared/components/forms/area-form/area-form/types-form/air-curtain/air-curtain.component';
import { AirEnhancerComponent } from './shared/components/forms/area-form/area-form/types-form/air-enhancer/air-enhancer.component';
import { DehumidifierComponent } from './shared/components/forms/area-form/area-form/types-form/dehumidifier/dehumidifier.component';
import { HeatPumpComponent } from './shared/components/forms/area-form/area-form/types-form/heat-pump/heat-pump.component';
import { BuildingComponent } from './shared/components/forms/area-form/area-form/types-form/building/building.component';
import { WaterHeaterComponent } from './shared/components/forms/area-form/area-form/types-form/water-heater/water-heater.component';
import { SteamGeneratorComponent } from './shared/components/forms/area-form/area-form/types-form/steam-generator/steam-generator.component';
import { LightingComponent } from './shared/components/forms/area-form/area-form/types-form/lighting/lighting.component';
import { RadiantHeaterComponent } from './shared/components/forms/area-form/area-form/types-form/radiant-heater/radiant-heater.component';
import { ArmatureComponent } from './shared/components/forms/area-form/area-form/types-form/armature/armature.component';
import { VentilationCentralComponent } from './shared/components/forms/area-form/area-form/types-form/ventilation-central/ventilation-central.component';
import { HeaterComponent } from './shared/components/forms/area-form/area-form/types-form/heater/heater.component';
import { InjectionMachineComponent } from './shared/components/forms/area-form/area-form/types-form/injection-machine/injection-machine.component';
import { PressComponent } from './shared/components/forms/area-form/area-form/types-form/press/press.component';
import { TransportComponent } from './shared/components/forms/area-form/area-form/types-form/transport/transport.component';
import { ElectricDriveComponent } from './shared/components/forms/area-form/area-form/types-form/electric-drive/electric-drive.component';
import { VacuumPumpComponent } from './shared/components/forms/area-form/area-form/types-form/vacuum-pump/vacuum-pump.component';
import { TemplateListComponent } from './pages/template-list/template-list.component';
import { TemplateFormComponent } from './pages/template-list/template-form/template-form.component';
import { AddTemplateComponent } from './shared/components/add-template/add-template.component';
import { CopyDirective } from './pages/template-list/template-form/field-doc-variable/copy.directive';
import { FieldDocVariableComponent } from './pages/template-list/template-form/field-doc-variable/field-doc-variable.component';
import { PowerGeneratorComponent } from './shared/components/forms/area-form/area-form/types-form/power-generator/power-generator.component';
import { TemplateFileListComponent } from './pages/template-list/template-form/template-file-list/template-file-list.component';
import { DocVariablesListComponent } from './shared/components/doc-variables-list/doc-variables-list.component';
import { GalleryComponent } from './shared/components/gallery/gallery.component';
import { PictureComponent } from './shared/components/gallery/picture/picture.component';
import { UploadImageComponent } from './shared/components/gallery/upload-image/upload-image.component';
import { MissingTemplatesComponent } from './shared/components/missing-templates/missing-templates.component';
import { TakePictureComponent } from './shared/components/gallery/take-picture/take-picture.component';
import { WebcamModule } from 'ngx-webcam';
import { MissingTemplateFilesComponent } from './shared/components/missing-template-files/missing-template-files.component';
import { ScrollToTopButtonComponent } from './shared/components/scroll-to-top-button/scroll-to-top-button.component';
import { AuditCardComponent } from './shared/components/audit-cards-container/audit-card/audit-card.component';
import { AuditCardsContainerComponent } from './shared/components/audit-cards-container/audit-cards-container.component';
import { AdminPanelComponent } from './pages/admin-panel/admin-panel.component';
import { GlobalConstantListComponent } from './pages/admin-panel/global-constant-list/global-constant-list.component';
import { AuditGalleryComponent } from './shared/components/gallery/audit-gallery/audit-gallery.component';
import { GalleryAccordionComponent } from './shared/components/gallery/audit-gallery/gallery-accordion/gallery-accordion.component';
import { AuditPhotoComponent } from './shared/components/gallery/audit-gallery/audit-photo/audit-photo.component';
import { ConstantComponent } from './pages/admin-panel/global-constant-list/constant/constant.component';
import { AreaTemplateFormComponent } from './pages/template-list/template-form/area-template-form/area-template-form.component';
import { EnergyBalanceChartComponent } from './shared/components/energy-balance-chart/energy-balance-chart/energy-balance-chart.component';
import { QuestionnaireComponent } from './shared/components/questionnaire/questionnaire.component';
import { ClosedQuestionComponent } from './shared/components/questionnaire/closed-question/closed-question.component';
import { OpenQuestionComponent } from './shared/components/questionnaire/open-question/open-question.component';
import { MultipleChoiceQuestionComponent } from './shared/components/questionnaire/multiple-choice-question/multiple-choice-question.component';
import { ImagesFormSelectorComponent } from './shared/components/gallery/audit-gallery/images-form-selector/images-form-selector.component';
import { CustomPopupComponent } from './shared/components/custom-popup/custom-popup.component';
import { VersionCreatorComponent } from './shared/components/questionnaire/version-creator/version-creator.component';
import { FooterComponent } from './shared/components';
import { ChangeAnswerPopupComponent } from './shared/components/questionnaire/change-answer-popup/change-answer-popup.component';
import { CanDeactivateFnGuard } from './core/guards/can-deactivate.guard';
import { FormsModule } from '@angular/forms';
import { AdminUserManagementComponent } from './pages/admin-user-management/admin-user-management.component';
import { AppComponent } from './app.component';
import { QuestionnairePopupComponent } from './shared/components/questionnaire/questionnaire-popup/questionnaire-popup.component';
import {
  ExitConfirmationPopupComponent,
  ExitConfirmationPopupContentDirective,
} from './shared/components/questionnaire/exit-confirmation-popup/exit-confirmation-popup.component';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HintComponent } from './shared/components/hint/hint.component';
import { AccordionModule } from 'primeng/accordion';
import { VersionOverviewComponent } from './shared/components/questionnaire/version-overview/version-overview.component';
import { QuestionnaireDiagramComponent } from './shared/components/questionnaire/questionnaire-editor/questionnaire-diagram/questionnaire-diagram.component';
import { adminGuard } from './core/guards/user-role.guard';

const routes: Routes = [
  {
    path: 'pages/user',
    component: UserComponent,
  },
  {
    path: 'pages/questionnaire',
    component: QuestionnaireComponent,
  },
  {
    path: 'pages/questionnaire-version-overview',
    component: VersionOverviewComponent,
    canActivate: [adminGuard],
  },
  {
    path: 'pages/questionnaire-diagram/:id',
    component: QuestionnaireDiagramComponent,
    canActivate: [adminGuard],
  },
  {
    path: 'pages/audits-list',
    component: AuditsListComponent,
  },
  {
    path: 'pages/audit/:auditId',
    component: HomeComponent,
    children: [
      {
        path: 'enterprise/:enterpriseId',
        component: EnterpriseFormComponent,
        canDeactivate: [CanDeactivateFnGuard],
        children: [
          { path: 'form', component: EnterpriseFormComponent },
          { path: 'gallery', component: EnterpriseFormComponent },
          { path: 'sankey', component: EnterpriseFormComponent },
          { path: '', redirectTo: 'form', pathMatch: 'full' },
        ],
      },
      {
        path: 'department/:departmentId',
        component: DepartmentFormComponent,
        canDeactivate: [CanDeactivateFnGuard],
        children: [
          { path: 'form', component: DepartmentFormComponent },
          { path: 'gallery', component: DepartmentFormComponent },
          { path: 'sankey', component: DepartmentFormComponent },
          { path: 'survey', component: DepartmentFormComponent },
          { path: '', redirectTo: 'form', pathMatch: 'full' },
        ],
      },
      {
        path: 'area/:areaId',
        component: AreaFormComponent,
        canDeactivate: [CanDeactivateFnGuard],
        children: [
          { path: 'form', component: AreaFormComponent },
          { path: 'gallery', component: AreaFormComponent },
          { path: 'sankey', component: AreaFormComponent },
          { path: '', redirectTo: 'form', pathMatch: 'full' },
        ],
      },
      {
        path: '',
        component: AuditFormComponent,
        canDeactivate: [CanDeactivateFnGuard],
        children: [
          { path: 'form', component: AreaFormComponent },
          { path: 'gallery', component: AreaFormComponent },
          { path: 'sankey', component: AreaFormComponent },
          { path: '', redirectTo: 'form', pathMatch: 'full' },
        ],
      },
    ],
  },
  {
    path: 'pages/templates-list',
    component: TemplateListComponent,
    canActivate: [adminGuard],
  },
  {
    path: 'pages/admin-panel',
    component: AdminPanelComponent,
    canActivate: [adminGuard],
  },
  {
    path: 'login',
    component: AppComponent,
  },
  {
    path: 'pages/users-management',
    component: AdminUserManagementComponent,
    canActivate: [adminGuard],
  },
  {
    path: '**',
    redirectTo: 'pages/audits-list',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    DxButtonModule,
    DxTabsModule,
    DxPopupModule,
    DxScrollViewModule,
    DxSwitchModule,
    DxTabPanelModule,
    DxFormModule,
    DxiItemModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    DxValidatorModule,
    DxTreeListModule,
    DxTreeViewModule,
    DxDataGridModule,
    DxNumberBoxModule,
    DxTextAreaModule,
    DxDateBoxModule,
    DxCheckBoxModule,
    DxTabsModule,
    DxTagBoxModule,
    DxResponsiveBoxModule,
    BrowserModule,
    DxDropDownBoxModule,
    DxListModule,
    DxValidationGroupModule,
    DxSliderModule,
    DxTreeListModule,
    DxFileUploaderModule,
    DxSpeedDialActionModule,
    DxValidationSummaryModule,
    WebcamModule,
    DxDrawerModule,
    DxToolbarModule,
    DxToolbarModule,
    DxAccordionModule,
    DxButtonGroupModule,
    DxSankeyModule,
    DxDropDownButtonModule,
    DxRadioGroupModule,
    DxLoadIndicatorModule,
    DxValidationGroupModule,
    FormsModule,
    ButtonModule,
    TabViewModule,
    CardModule,
    ToggleButtonModule,
    InputTextModule,
    CalendarModule,
    BrowserAnimationsModule,
    AccordionModule,
    DxLoadPanelModule,
    DxProgressBarModule,
    DxDateRangeBoxModule,
    DxDiagramModule,
    DxContextMenuModule,
  ],
  providers: [DatePipe, TreeNavigationComponent],
  exports: [RouterModule],
  declarations: [
    HomeComponent,
    UserComponent,
    UserFormComponent,
    AuditsListComponent,
    TreeNavigationComponent,
    AuditsListWidgetComponent,
    EnterpriseFormComponent,
    DepartmentFormComponent,
    AuditFormComponent,
    AreaFormComponent,
    EnergyReceiverPopupComponent,
    EnergySourcePopupComponent,
    EnergyBalanceComponent,
    EnergySourceDeletePopupComponent,
    BoilerRoomFormComponent,
    BoilerFormComponent,
    CoolingSystemComponent,
    WaterPumpComponent,
    FanComponent,
    HeatRecoveryComponent,
    ElectricityConnectionComponent,
    PhotovoltaicsComponent,
    AirConditionerComponent,
    TransformerComponent,
    DeaeratorComponent,
    ColdCompressorComponent,
    GassCogenerationComponent,
    AbsorptionAggregateComponent,
    BlowerComponent,
    CompressedAirGeneratorComponent,
    AirCompressorComponent,
    DehumidifierComponent,
    AirCurtainComponent,
    ElectricHeaterComponent,
    HeatPumpComponent,
    FanCoolerComponent,
    AirEnhancerComponent,
    AirCurtainComponent,
    BuildingComponent,
    WaterHeaterComponent,
    SteamGeneratorComponent,
    LightingComponent,
    RadiantHeaterComponent,
    ArmatureComponent,
    VentilationCentralComponent,
    HeaterComponent,
    InjectionMachineComponent,
    PressComponent,
    TransportComponent,
    ElectricDriveComponent,
    VacuumPumpComponent,
    TemplateListComponent,
    TemplateFormComponent,
    AddTemplateComponent,
    CopyDirective,
    FieldDocVariableComponent,
    PowerGeneratorComponent,
    TemplateFileListComponent,
    DocVariablesListComponent,
    GalleryComponent,
    PictureComponent,
    UploadImageComponent,
    MissingTemplatesComponent,
    TakePictureComponent,
    MissingTemplateFilesComponent,
    ScrollToTopButtonComponent,
    AuditCardComponent,
    AuditCardsContainerComponent,
    AdminPanelComponent,
    GlobalConstantListComponent,
    AuditGalleryComponent,
    GalleryAccordionComponent,
    AuditPhotoComponent,
    ConstantComponent,
    AreaTemplateFormComponent,
    EnergyBalanceChartComponent,
    QuestionnaireComponent,
    ClosedQuestionComponent,
    OpenQuestionComponent,
    MultipleChoiceQuestionComponent,
    ImagesFormSelectorComponent,
    VersionCreatorComponent,
    VersionOverviewComponent,
    CustomPopupComponent,
    FooterComponent,
    ChangeAnswerPopupComponent,
    AdminUserManagementComponent,
    QuestionnairePopupComponent,
    ExitConfirmationPopupComponent,
    HintComponent,
    ExitConfirmationPopupContentDirective,
    QuestionnaireDiagramComponent,
  ],
})
export class AppRoutingModule {}
