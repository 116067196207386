import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Localizable } from '../../locale/localizable';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DxButtonModule, DxTextBoxModule, DxValidatorModule, DxValidationGroupModule } from 'devextreme-angular';
import { PasswordConfirmationModule } from '../../shared/components/passwordConfirmation/passwordConfirmation.component';
import { PasswordResetModule } from '../../shared/components/password-reset/password-reset.component';
import notify from 'devextreme/ui/notify';
import { AppSettings } from '../../AppSettings';

enum RequestDialog {
  SignInPage,
  ConfirmationDialogPage,
  ResetDialogPage,
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends Localizable {
  email: string;
  password: string;
  stylingMode = 'filled';
  passwordMode: any;
  passwordButton: any;
  public requestDialog: RequestDialog;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    super();
    this.requestDialog = this.authenticationService.requestDialog;
    this.passwordMode = 'password';
    this.passwordButton = {
      icon: 'find',
      type: 'default',
      onClick: () => {
        this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
      },
    };

    this.authenticationService.isUserAuthorized$.subscribe(() => {
      this.requestDialog = this.authenticationService.requestDialog;
      this.email = '';
      this.password = '';
    });

    this.authenticationService.getReturnToSignInPage().subscribe((res) => {
      this.requestDialog = RequestDialog.SignInPage;
      this.password = '';
      this.email = '';
    });
  }

  async onLoginClick() {
    try {
      await this.authenticationService.login(this.email, this.password);
    } catch (error) {
      console.log(error);
      switch (error.status) {
        case AppSettings.HTTP_STATUS_CODE_NOT_AUTHORIZED:
          this.notifyIncorrectEmailOrPassword();
          break;
        case AppSettings.HTTP_STATUS_CODE_BAD_REQUEST:
          this.notifyIncorrectEmailOrPassword();
          break;
        case AppSettings.HTTP_STATUS_CODE_CONFLICT:
          notify(
            {
              message: this._('login-onExpiredOrIncorrectTemporaryPassword'),
              width: 'auto',
              position: AppSettings.NOTIFY_TOP_POSTION,
            },
            'warning'
          );
          break;
        default:
          break;
      }
    }
  }

  clickOnRegister(): void {
    this.router.navigateByUrl('/pages/register');
  }

  onForgetpasswordClick() {
    this.requestDialog = RequestDialog.ResetDialogPage;
  }

  notifyIncorrectEmailOrPassword(): void {
    notify(
      {
        message: this._('login-onIncorrectEmailOrPassword'),
        width: 'auto',
        position: AppSettings.NOTIFY_TOP_POSTION,
      },
      'warning'
    );
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DxButtonModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationGroupModule,
    PasswordConfirmationModule,
    PasswordResetModule,
  ],
  declarations: [LoginComponent],
  exports: [LoginComponent],
})
export class LoginModule {}
