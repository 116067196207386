import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { AuditTimeRange } from 'src/app/shared/models/audit/audit-time-range.model';
import { Currency } from 'src/app/shared/models/audit/currency.model';
import { BoilerRoom } from 'src/app/shared/models/area/types/boiler-room.model';
import { Unit } from 'src/app/shared/models/unit.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { Localizable } from '../../../../../../../locale/localizable';

@Component({
  selector: 'app-boiler-room-form',
  templateUrl: './boiler-room-form.component.html',
  styleUrls: ['./boiler-room-form.component.scss'],
})
export class BoilerRoomFormComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  boilerRoom: BoilerRoom = null;

  public timeRange: AuditTimeRange;
  public currencyFormat: string;
  public unitList: ArrayStore;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this._apiRequestService.getAuditTimeRange(this.auditId).subscribe((res: AuditTimeRange) => (this.timeRange = res));

    this._apiRequestService
      .getAuditCurrency(this.auditId)
      .subscribe((res: Currency) => (this.currencyFormat = `#0.## ${res.short}`));

    this._apiRequestService.getUnitsForEnergyBalance().subscribe((res: Array<Unit>) => {
      this.unitList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
