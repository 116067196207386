<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<h1
  data-testid="constants-header"
  class="content-block"
>
  {{ _('managing-constants') }}
</h1>
<div class="content-block content">
  <app-global-constant-list></app-global-constant-list>
</div>
<app-footer></app-footer>
