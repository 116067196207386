<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="container">
  <dx-list
    [dataSource]="missingTemplatesList"
    noDataText=""
  >
  </dx-list>
  <dx-button
    [disabled]="!canGenerate"
    class="outlined-button-focus-hover report-button"
    text="Wygeneruj raport pomimo braku wskazanych szablonów"
    type="normal"
    icon="doc"
    (onClick)="emitGenerateReport()"
    stylingMode="outlined"
  >
  </dx-button>
</div>
