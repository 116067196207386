<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form
  [showColonAfterLabel]="true"
  *ngIf="steamGenerator"
>
  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="steamGenerator.numberOfDevices"
        stylingMode="outlined"
        [min]="0"
        [max]="1000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('steam-generator-number-of-devices')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="steamGenerator.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('steam-generator-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="steamGenerator.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('steam-generator-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="steamGenerator.serialNumber"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('steam-generator-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' Hg/h'"
        [(value)]="steamGenerator.efficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('steam-generator-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="steamGenerator.electricPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('steam-generator-electric-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="steamGenerator.thermalPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('steam-generator-thermal-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' ℃'"
        [(value)]="steamGenerator.steamTemp"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('steam-generator-steam-temp')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' bar'"
        [(value)]="steamGenerator.steamPressure"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('steam-generator-steam-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-select-box
        [(value)]="steamGenerator.steamGeneratorFuelType"
        [dataSource]="fuelTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('steam-generator-fuel-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('steam-generator-fuel-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' %'"
        [(value)]="steamGenerator.averageEfficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('steam-generator-average-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="steamGenerator.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('steam-generator-working-hours-in-year')"></dxo-label>
  </dxi-item>
</dx-form>
