import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SaltingService } from './salting.service';
import { AppConfig } from '../../app.config';
import { AppSettings } from '../../../app/AppSettings';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { BehaviorSubject, Subject } from 'rxjs';
import notify from 'devextreme/ui/notify';
import { Localizable } from '../../locale/localizable';

enum RequestDialog {
  SignInPage,
  ConfirmationDialogPage,
  ResetDialogPage,
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends Localizable {
  UsersData: any;

  private _urlDbEnergyApi: string = '';

  public requestDialog: RequestDialog = RequestDialog.SignInPage;

  public temporaryUser: any;
  private subject = new Subject<any>();
  public isUserAuthorizedSubject = new BehaviorSubject<boolean>(null);
  public isUserAuthorized$ = this.isUserAuthorizedSubject.asObservable();

  private http: HttpClient;

  constructor(
    private httpBackend: HttpBackend,
    private saltingService: SaltingService,
    private router: Router,
    private _config: AppConfig
  ) {
    super('dictionary');
    this.http = new HttpClient(httpBackend);
    this._urlDbEnergyApi = this._config.getConfig('DbEnergyDatabaseUrl');
    this.showCreatedUserNotification = this.showCreatedUserNotification.bind(this);

    const url = `${this._urlDbEnergyApi}api/users/`;
    this.UsersData = AspNetData.createStore({
      key: 'id',
      loadUrl: url,
      loadMethod: 'GET',
      insertUrl: url,
      insertMethod: 'POST',
      updateUrl: url,
      updateMethod: 'PUT',
      deleteUrl: url,
      deleteMethod: 'DELETE',
      onInserted: (values, key) => {
        this.showCreatedUserNotification();
      },
      onBeforeSend: async (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        const token = localStorage.getItem(AppSettings.AUTH_TOKEN);
        const locale = localStorage.getItem(AppSettings.USER_LOCALE);
        ajaxOptions.headers = {
          Authorization: `Bearer ${token}`,
          'Client-Language': `${locale}`,
        };
      },
      onAjaxError: (e: { xhr: XMLHttpRequest; error: Error }) => {
        console.log('OnAjaxErrorOccured(xhr): ' + e.xhr.statusText);
        if (e.xhr.status === AppSettings.HTTP_STATUS_CODE_NOT_AUTHORIZED) {
          notify(this._('not-authorized-logout'), 'error', AppSettings.NOTIFY_DURATION);
          this.logout();
        }
      },
      errorHandler: (s: Error) => {
        console.error(s);
      },
    });
  }

  showCreatedUserNotification() {
    notify(this._('userManagement-createdUser'), 'success');
  }

  parseJwtToken(token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  }

  getDecodedUser() {
    return this.parseJwtToken(localStorage.getItem(AppSettings.AUTH_TOKEN));
  }

  getDecodedCurrentUser() {
    return JSON.parse(localStorage.getItem(AppSettings.AUTH_CURRENT_USER));
  }

  async confirmationEmailAndSetPassword(userId, confirmationToken, newPassword) {
    const salt = this.saltingService.generateRandomString(AppSettings.RANDOM_TEXT_LENGTH);
    newPassword = await this.saltingService.hash(newPassword, salt);
    return this.http.post<any>(`${this._urlDbEnergyApi}api/users/ConfirmEmailAndSetPassword`, {
      userId,
      confirmationToken,
      newPassword,
      salt,
    });
  }

  setReturnToSignInpage(confirmation: boolean) {
    this.subject.next(confirmation);
  }

  getReturnToSignInPage() {
    return this.subject.asObservable();
  }

  login(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getSalt(email).subscribe(async (res) => {
        if (res != null) {
          const h1 = await this.saltingService.hash(password, res.salt);
          const hashedPassword = await this.saltingService.hash(h1, res.random);
          this.http
            .post<any>(`${this._urlDbEnergyApi}api/users/login`, { email, password: hashedPassword })
            .pipe(
              map((data) => {
                if (data.token) {
                  localStorage.setItem(AppSettings.AUTH_CURRENT_USER, JSON.stringify(data));
                  localStorage.setItem(AppSettings.AUTH_TOKEN, data.token);
                  localStorage.setItem(AppSettings.USER_LOCALE, data.language.name);
                  this.isUserAuthorizedSubject.next(true);
                  this.router.navigateByUrl('pages/audits-list');
                } else if (data.confirmationToken) {
                  this.temporaryUser = { ...data };
                  this.requestDialog = RequestDialog.ConfirmationDialogPage;
                  this.isUserAuthorizedSubject.next(false);
                }
              })
            )
            .subscribe(
              (data) => {
                resolve(data);
              },
              (error) => {
                reject(error);
              }
            );
        }
      });
    });
  }

  getSalt(Login: string) {
    return this.http.get<any>(`${this._urlDbEnergyApi}api/users/salt/${Login}`);
  }

  logout() {
    const token = localStorage.getItem(AppSettings.AUTH_TOKEN);
    const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });
    this.http.post(`${this._urlDbEnergyApi}api/users/Logout`, null, { headers }).subscribe(
      (result) => {
        console.log('logout in progress:');
      },
      (error) => {
        console.log('logout.error:');
      },
      () => console.log('Task logout() Complete')
    );
    localStorage.removeItem(AppSettings.AUTH_TOKEN);
    localStorage.removeItem(AppSettings.USER_LOCALE);
    localStorage.removeItem(AppSettings.AUTH_CURRENT_USER);
    this.isUserAuthorizedSubject.next(false);
  }

  async register(
    email: string,
    plaintextpassword: string,
    firstname: string,
    lastname: string,
    phoneNumber: string,
    position: string,
    role: string
  ) {
    const salt = this.saltingService.generateRandomString(AppSettings.RANDOM_TEXT_LENGTH);
    const password = await this.saltingService.hash(plaintextpassword, salt);
    return this.http.post(`${this._urlDbEnergyApi}api/users/register`, {
      email,
      firstname,
      lastname,
      password,
      phoneNumber,
      position,
      salt,
      role,
    });
  }

  public resetPasswordByEmail(emailAddress) {
    const salt = this.saltingService.generateRandomString(AppSettings.RANDOM_TEXT_LENGTH);
    return this.http.post<any>(`${this._urlDbEnergyApi}api/users/resetpasswordbyemail`, { emailAddress, salt });
  }

  public getBearerTokenHeader(): string {
    return `Bearer ${localStorage.getItem(AppSettings.AUTH_TOKEN)}`;
  }
}
