import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AppSettings } from '../../../../AppSettings';
import { Answer, SelectAnswer } from '../models/answers.models';
import { Question } from '../models/questions.models';
import { QuestionnaireService } from '../questionnaire.service';
import { DxButtonComponent } from 'devextreme-angular';
import { SkippableQuestion } from '../models/skippable-question';

@Component({
  selector: 'app-closed-question',
  templateUrl: './closed-question.component.html',
  styleUrls: ['./closed-question.component.scss'],
})
export class ClosedQuestionComponent extends SkippableQuestion implements OnInit, AfterViewInit {
  @ViewChildren('buttons') buttons: QueryList<DxButtonComponent>;

  @Input() question: Question;
  @Input() isAuditFinished: boolean = false;
  public activeButtonIndex: number;
  public lastClickedButtonIndex: number;
  public confirmPopupVisible: boolean = false;

  public currentAnswer: Answer;

  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;

  constructor(private questionnaireService: QuestionnaireService) {
    super(questionnaireService, 'Questionnaire');
  }

  ngOnInit(): void {
    this.skipQuestionButtonText();
    this.fillAnswers();
    this.fillNotes(this.question);
  }

  ngAfterViewInit(): void {
    this.buttons.first.instance.focus();
  }

  public skipQuestion(e: any) {
    super.skipQuestion(this.question);
    this.activeButtonIndex = -1;
  }

  public skipQuestionButtonText() {
    super.skipQuestionButtonText(this.question);
  }

  private fillAnswers(): void {
    const questionNode = this._questionnaireService.findNode(this.question.guid);
    const answerNode = questionNode.model.answer;
    if (answerNode != null) {
      const selectAnswer = this.question.answer as SelectAnswer[];
      const answerIndex = selectAnswer.findIndex((answer) => answer._id === answerNode.id);
      this.setActiveIndex(answerIndex);
      this.skipQuestionButtonShow = false;
    }
  }

  public answerSelect(answer: Answer, questionId: string, index: number): void {
    this.skipQuestionButtonShow = false;
    answer._questionId = questionId;
    answer.questionGuid = this.question.guid;

    if (this.alreadyAnswered(index)) {
      this.setLastClickedButtonIndex(index);
      this.currentAnswer = answer;

      if (this.activeButtonIndex === -1) {
        // a case for gibberish answers for skipping questions
        this.setNewAnswer(true, true);
        return;
      }

      if (this.isChangeAnswerChangesNextQuestion(index)) {
        if (this.question.answer[this.activeButtonIndex]._next !== 'END') {
          this.confirmPopupVisible = true;
        } else {
          this.setNewAnswer(true, true);
        }
      } else {
        this.setNewAnswer(true, false);
      }
    } else {
      this.activeButtonIndex = index;
      this._questionnaireService.setUserAnswer(answer, false);
    }
  }

  public setLastClickedButtonIndex(index: number): void {
    this.lastClickedButtonIndex = index;
  }

  public setActiveIndex(index: number): void {
    this.activeButtonIndex = index;
  }

  private isChangeAnswerChangesNextQuestion(index: number): boolean {
    if (this.question.answer[index]._next !== this.question.answer[this.activeButtonIndex]._next) {
      return true;
    }
    return false;
  }

  public alreadyAnswered(index: number): boolean {
    if (this.activeButtonIndex !== undefined && index !== this.activeButtonIndex) {
      return true;
    }
    return false;
  }

  public setNewAnswer(changeAnswer: boolean, dropNodes: boolean): void {
    if (changeAnswer) {
      this.activeButtonIndex = this.lastClickedButtonIndex;
      this.confirmPopupVisible = false;
      this._questionnaireService.questionChangeAnswer(this.currentAnswer, dropNodes);
    }
    this.confirmPopupVisible = false;
  }
}
