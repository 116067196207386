import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScreenService } from '../../../../services';
import { AppSettings } from '../../../../../AppSettings';
import { Localizable } from '../../../../../locale/localizable';
import { GalleryService } from '../../../../services/gallery.service';
import { TreeNavigationService } from '../../../../services/tree-navigation.service';
import { AuditTreeNavigation } from '../../../../models/audit/audit-tree-navigation.model';

@Component({
  selector: 'app-images-form-selector',
  templateUrl: './images-form-selector.component.html',
  styleUrls: ['./images-form-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImagesFormSelectorComponent extends Localizable implements OnInit, OnDestroy {
  @Input()
  showMoveImagePopup = false;

  @Input()
  imagesIds: string[] = [];

  disabledForm: string = '';

  @Output()
  onImagesMoved = new EventEmitter();

  @Output()
  onPopupHiding = new EventEmitter();

  @Input()
  selectFormOnly: boolean = false;

  @Input()
  formRootId: string = '-1';

  @Input()
  titleText: string = this._('audit-gallery-move-image-title');
  @Input()
  messageText: string = this._('audit-gallery-move-image-select-form-caption');
  @Input()
  searchText: string = this._('audit-gallery-move-image-select-form-placeholder');

  @Output()
  onFormSelected: EventEmitter<AuditTreeNavigation> = new EventEmitter<AuditTreeNavigation>();

  public expandedRowsKeyForms: string[] = [];

  public mobile: boolean = true;
  public popupWidth: any = AppSettings.POPUP_WIDTH + '%';
  public popupHeight: any = AppSettings.POPUP_HEIGHT + '%';
  public isExpandingOrCollapsing = false;
  public formsTree: Array<AuditTreeNavigation> = [];

  public componentDestroyed$: Subject<boolean> = new Subject();
  public rootValue: string = '-1';
  public popupWrapper = { class: 'selector-component custom-popup' };
  confirmMoveButtonOptions: any;

  constructor(
    private _galleryService: GalleryService,
    private _treeNavigationService: TreeNavigationService,
    private _screenService: ScreenService
  ) {
    super('dictionary');
  }

  ngOnInit(): void {
    if (this.selectFormOnly) {
      this.rootValue = this.formRootId;
      this.popupWrapper = {
        class: 'selector-component custom-popup selector-component-form-only',
      };
    }

    this._treeNavigationService.navigationTreeData
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((res: Array<AuditTreeNavigation>) => {
        if (res !== undefined && res !== null && res.length > 0) {
          this.formsTree = res;

          if (this.selectFormOnly) {
            this.rootValue = this.formRootId;
            this.trimTreeToDepartments(this.formRootId);
            this.popupWrapper = {
              class: 'selector-component custom-popup selector-component-form-only',
            };
          } else {
            this.disabledForm = this._galleryService.getFormIdDisableToMove(this.imagesIds);
            this.popupWrapper = { class: 'selector-component custom-popup' };
          }
          this.expandMainForms();
        }
      });

    this._screenService.isMobile.pipe(takeUntil(this.componentDestroyed$)).subscribe((isMobile: boolean) => {
      this.mobile = isMobile;
    });

    this.setupSizeOfPopup();

    this.confirmMoveButtonOptions = {
      icon: 'fi fi-rr-check',
      type: 'normal',
      text: this._('OK'),
      onClick: () => {
        this._galleryService.changeImageLocation(this.imagesIds, this.treeNavigationNode.id).subscribe(() => {
          this._galleryService.fetchAuditAndChildrenGalleries(this._galleryService.getAuditId());
          this.onImagesMoved.emit();
          this.nodeSelected = false;
        });
      },
    };
  }

  private expandMainForms(): void {
    const mainForms = this.formsTree.filter(
      (form) => form.formType === 'Department' || form.formType === 'Enterprise' || form.formType === 'Audit'
    );
    this.expandedRowsKeyForms = mainForms.map((form) => form.id);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setupSizeOfPopup();
  }

  setupSizeOfPopup(): void {
    const isMobile = this.mobile;
    const windowHeight = window.innerHeight;

    if (isMobile || windowHeight < AppSettings.POPUP_HEIGHT_THRESHOLD) {
      this.popupWidth = '96%';
      this.popupHeight = '96%';
    } else {
      this.popupWidth = '50%';
      this.popupHeight = '80%';
    }
  }

  removeFurtherNodes(nodeId: string) {
    const branchesToRemove = this.formsTree.filter((tr) => tr.parentId === nodeId);

    branchesToRemove.forEach((br) => {
      this.removeFurtherNodes(br.id);
    });

    this.formsTree = this.formsTree.filter((tr) => tr.parentId !== nodeId);
  }

  trimTreeToDepartments(departmentParentId: string) {
    const departments = this.formsTree.filter((tree) => tree.parentId === departmentParentId);
    departments.forEach((dep) => {
      this.removeFurtherNodes(dep.id);
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public onSelectForm(node: AuditTreeNavigation): void {
    if (
      !this.isExpandingOrCollapsing &&
      this.disabledForm?.toUpperCase() !== node.id.toUpperCase() &&
      node.selectable
    ) {
      this.onFormSelected.emit(node);
      this.nodeSelected = true;
      if (!this.selectFormOnly) {
        this.treeNavigationNode = node;
      }
    }
    this.isExpandingOrCollapsing = false;
  }

  public hidePopup(): void {
    this.onPopupHiding.emit();
  }
  private treeNavigationNode: AuditTreeNavigation;
  public nodeSelected = false;
  public confimationButtonVisible = true;
}
