<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div
  class="dx-card responsive-paddings"
  (click)="openAudit(audit.id)"
  (keydown)="handleKeyDownOpen($event, audit.id)"
  tabindex="0"
>
  <div class="flex-row">
    <div class="flex-row-header">
      <div class="larger-icon">
        <span class="fi fi-rr-assept-document"></span>
      </div>
      <div>
        <div class="details-text bold">{{ audit.auditType.name }}</div>
        <div class="smallest-text">{{ audit.createdAt | date: 'yyyy-MM-dd' }}</div>
      </div>
    </div>
    <dx-button
      class="delete-button"
      icon="fi fi-rr-trash"
      (click)="onDeleteAudit($event, audit.id)"
      (keydown)="handleKeyDownDelete($event, audit.id)"
    ></dx-button>
  </div>
  <div class="container body-text bold">
    {{ audit.name }}
  </div>
</div>
<app-exit-confirmation-popup
  [popupVisible]="deleteAuditPopupVisible"
  [confirmationNo]="_('No')"
  [confirmationYes]="_('Yes')"
  [title]="_('audit-delete-confirm-title')"
  [message]="_('audit-delete-confirm-message')"
  (closeEvent)="onDeleteAuditPopupClose($event)"
></app-exit-confirmation-popup>
