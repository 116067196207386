import { Component, Input, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { ScreenService } from '../../services';
import { TemplateService } from '../../services/template.service';
import { AppSettings } from '../../../AppSettings';
import { ScrollService } from '../../services/scroll.service';
import { Localizable } from '../../../locale/localizable';

@Component({
  selector: 'app-add-template',
  templateUrl: './add-template.component.html',
  styleUrls: ['./add-template.component.scss'],
})
export class AddTemplateComponent extends Localizable implements OnInit {
  @Input() areaTypeCode: string = '';
  @Input() docVariablesListVisible: boolean = true;

  public uploadUrl: string = '';
  public fileListVisible: boolean = false;
  public docVariablesVisible: boolean = false;
  public mobile: boolean = false;

  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;

  constructor(
    private _config: AppConfig,
    private _templateService: TemplateService,
    private _screenService: ScreenService,
    private _scrollService: ScrollService
  ) {
    super('TemplateFileListComponent');
    this.uploadUrl = this._config.getConfig('DbEnergyDatabaseUrl');
  }

  public ngOnInit(): void {
    this._screenService.isMobile.subscribe((isMobile: boolean) => (this.mobile = isMobile));
    this._scrollService.updateBlockScroll(false);

    this.uploadUrl += `api/templates/${this.areaTypeCode}`;
    this._templateService.setFormCode(this.areaTypeCode);
  }

  public showFileList(): void {
    this.fileListVisible = true;
  }

  public showDocVariables(): void {
    this.docVariablesVisible = true;
  }
}
