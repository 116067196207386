import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpBackend,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../../shared/services/errorHandlerService';
import { AppSettings } from '../../AppSettings';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const locale = localStorage.getItem(AppSettings.USER_LOCALE);
    const token = localStorage.getItem(AppSettings.AUTH_TOKEN);

    const modifiedReq = req.clone({
      setHeaders: {
        'Client-Language': `${locale}`,
        Authorization: `Bearer ${token}`,
      },
    });

    return next.handle(modifiedReq);
  }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorHandlerService: ErrorHandlerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedReq = req.clone();

    return next.handle(modifiedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandlerService.handleError(error);
        return of(null);
      })
    );
  }
}

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  constructor(private handler: HttpBackend) {} //inject interceptor you want skip to

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedReq = req.clone();

    if (
      modifiedReq.url.includes('/questionnaires/groups/first/') ||
      modifiedReq.url.includes('/questionnaires/file/metadata/') ||
      modifiedReq.url.includes('/questionnaires/file') ||
      modifiedReq.url.includes('/questionnaires/files/acceptversion/')
    ) {
      return this.handler.handle(modifiedReq); // skip to next valid interceptor
    }

    return next.handle(modifiedReq);
  }
}
