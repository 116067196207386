import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CheckBoxAnswer } from '../models/answers.models';
import { MultipleChoiceQuestion } from '../models/questions.models';
import { QuestionnaireService } from '../questionnaire.service';
import { WAIT_BEFORE_SAVE_INPUT } from '../../../../AppSettings';
import { DxCheckBoxComponent, DxRadioGroupComponent } from 'devextreme-angular';
import { SkippableQuestion } from '../models/skippable-question';

@Component({
  selector: 'app-multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls: ['./multiple-choice-question.component.scss'],
})
export class MultipleChoiceQuestionComponent extends SkippableQuestion implements OnInit, AfterViewInit {
  @ViewChild(DxRadioGroupComponent, { static: false })
  radioGroup: DxRadioGroupComponent;
  @Input() question: MultipleChoiceQuestion;
  @Input() isAuditFinished: boolean = false;
  @Output() validationError = new EventEmitter<boolean>();

  @ViewChildren('checkBoxes') checkBoxes: QueryList<DxCheckBoxComponent>;

  public static readonly WAIT_BEFORE_SAVE_INPUT: number = 2000;

  private currentAnswer: CheckBoxAnswer = null;
  private newAnswer: CheckBoxAnswer = null;
  public error: boolean = false;
  public confirmPopupVisible: boolean = false;
  public radioAnswer: CheckBoxAnswer = null;
  public translatedRadioAnswers: Array<CheckBoxAnswer>;
  public skipQuestionButtonDisabled = false;
  public skipQuestionButtonShow = true;
  public skipButtonText: string;

  public translateRadioAnswers(): Array<CheckBoxAnswer> {
    const translatedArray = new Array<CheckBoxAnswer>();
    this.question.answer.forEach((answer) => {
      translatedArray.push({
        text: this.localizeQuestionnaireAnswerText(answer),
        _id: answer._id,
        _questionId: answer._questionId,
        _next: answer._next,
        type: answer.type,
        questionGuid: answer.questionGuid,
        nextQuestionGuid: answer.nextQuestionGuid,
      });
    });

    return translatedArray;
  }

  constructor(private questionnaireService: QuestionnaireService) {
    super(questionnaireService, 'Questionnaire');
  }

  ngOnInit() {
    this.question.answer.forEach((answer) => {
      answer._questionId = this.question._id;
      answer.questionGuid = this.question.guid;
    });

    this.translatedRadioAnswers = this.translateRadioAnswers();
    this.skipQuestionButtonText();
    this.fillAnswers();
    this.fillNotes(this.question);
  }

  ngAfterViewInit(): void {
    if (this.question.multiplechoice) {
      this.checkBoxes.first.instance.focus();
    } else {
      this.radioGroup.instance.focus();
    }
  }

  public skipQuestion(e: any) {
    super.skipQuestion(this.question);
  }

  public skipQuestionButtonText() {
    super.skipQuestionButtonText(this.question);
  }

  private fillAnswers(): void {
    const questionNode = this._questionnaireService.findNode(this.question.guid);
    const answerNode = questionNode.model.answer;

    if (this.question.multiplechoice) {
      // Clear checkboxes from null
      this.question.answer.forEach((ans) => (ans.booleanValue = false));

      if (answerNode != null) {
        answerNode.forEach((answerN) => {
          const visibleAnswer = this.question.answer.find((multiAnsw) => multiAnsw._id === answerN.id);
          if (visibleAnswer != null) {
            this.skipQuestionButtonShow = false;
            visibleAnswer.booleanValue = true;
          }
        });
      }
    } else {
      if (Array.isArray(answerNode) && answerNode.length > 0) {
        const singleAnswer = this.translatedRadioAnswers.find((ans) => ans._id === answerNode[0].id);
        this.radioAnswer = singleAnswer;
        this.skipQuestionButtonShow = false;
      }
    }
  }

  public saveAnswer(answer: CheckBoxAnswer): void {
    this.skipQuestionButtonShow = false;
    if (answer != null) {
      setTimeout(() => {
        this._questionnaireService.setUserAnswer(answer, false);
        this.currentAnswer = answer;
      }, WAIT_BEFORE_SAVE_INPUT);
    }
  }

  public checkIfAnswerSelected(answer: CheckBoxAnswer) {
    if (this.currentAnswer) {
      if (this.isChangeAnswerChangesNextQuestion(this.currentAnswer, answer)) {
        if (this.currentAnswer._next !== 'END') {
          this.confirmPopupVisible = true;
        } else {
          this.newAnswer = answer;
          this.setNewAnswer(true);
        }
      } else {
        this.newAnswer = answer;
        this.setNewAnswer(true);
      }
    } else {
      this.saveAnswer(answer);
    }
  }

  private isChangeAnswerChangesNextQuestion(oldAnswer: CheckBoxAnswer, newAnswer: CheckBoxAnswer): boolean {
    if (oldAnswer._next !== newAnswer._next) {
      return true;
    }
    return false;
  }

  public saveAnswers(e: any, answer: CheckBoxAnswer): void {
    this.skipQuestionButtonShow = false;
    answer.booleanValue = e.value;
    if (answer.booleanValue != null) {
      setTimeout(() => this._questionnaireService.setUserAnswer(answer, false), WAIT_BEFORE_SAVE_INPUT);
    }
    this.checkIfAllAnswersUnchecked();
  }

  public checkIfAllAnswersUnchecked(): void {
    this.error = !this.question.answer.some((a) => a.booleanValue === true);
    this.validationError.next(this.error);
  }

  public setNewAnswer(changeAnswer: boolean): void {
    if (changeAnswer) {
      this.currentAnswer = this.newAnswer;
      this._questionnaireService.questionChangeAnswer(this.currentAnswer, true);
    } else {
      this.radioGroup.value = this.currentAnswer;
    }
    this.confirmPopupVisible = false;
  }
}
