import { Injectable, inject } from '@angular/core';
import { AppSettings } from '../../AppSettings';
import { CanActivateFn, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserRoleGuard {
  constructor() {}

  isAdmin(): boolean {
    try {
      const user = JSON.parse(localStorage.getItem(AppSettings.AUTH_CURRENT_USER));
      return user.roleName === 'Admin';
    } catch {
      return false;
    }
  }
}

export function isAdmin(): boolean {
  const userRoleGuard = inject(UserRoleGuard);
  return userRoleGuard.isAdmin();
}

export const adminGuard: CanActivateFn = () => {
  const userRoleGuard = inject(UserRoleGuard);
  return userRoleGuard.isAdmin();
};
