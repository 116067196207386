<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form>
  <dxi-item dataField="{{ _('template-file-area-name') }}">
    <div *dxTemplate>
      <dx-text-box
        stylingMode="outlined"
        [readOnly]="true"
      ></dx-text-box>
      <app-field-doc-variable [text]="this.areaCode + 'Type'"></app-field-doc-variable>
    </div>
  </dxi-item>

  <dxi-item dataField="{{ _('template-file-area-type') }}">
    <div *dxTemplate>
      <dx-text-box
        [(value)]="areaType"
        stylingMode="outlined"
        [readOnly]="true"
      >
      </dx-text-box>
      <app-field-doc-variable [text]="this.areaCode + 'Type'"></app-field-doc-variable>
    </div>
  </dxi-item>

  <dxi-item dataField="{{ _('template-file-area-description') }}">
    <div *dxTemplate>
      <dx-text-area
        height="100"
        stylingMode="outlined"
        [readOnly]="true"
      ></dx-text-area>
      <app-field-doc-variable [text]="this.areaCode + 'Description'"></app-field-doc-variable>
    </div>
  </dxi-item>

  <dxi-item>
    <app-transport
      *ngIf="areaType === AreaTypesEnum.Transport"
      [templateMode]="true"
      [auditId]="currentAuditId"
      [transport]="currentArea"
    ></app-transport>
  </dxi-item>
</dx-form>
