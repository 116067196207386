import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { RadiantHeater, RadiantHeaterSupplyType } from 'src/app/shared/models/area/types/radiant-heater.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { Localizable } from '../../../../../../../locale/localizable';

@Component({
  selector: 'app-radiant-heater',
  templateUrl: './radiant-heater.component.html',
  styleUrls: ['./radiant-heater.component.scss'],
})
export class RadiantHeaterComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  radiantHeater: RadiantHeater = null;

  supplyTypesList: ArrayStore;

  currentYear: number = null;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getAreaTypeRadiantHeaterSupplyTypes().subscribe((res: Array<RadiantHeaterSupplyType>) => {
      this.supplyTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
