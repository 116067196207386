<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form
  [showColonAfterLabel]="true"
  *ngIf="building"
>
  <dxi-item *ngIf="typesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="building.buildingType"
        [dataSource]="typesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('building-type-placeholder')"
        [disabled]="templateMode"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'BuildingType'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('building-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="building.yearOfConstruction"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'YearOfConstruction'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('building-year-of-construction')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m²'"
        [(value)]="building.area"
        stylingMode="outlined"
        [min]="0"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'Area'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('building-area')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³'"
        [(value)]="building.cubature"
        stylingMode="outlined"
        [min]="0"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'Cubature'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('building-cubature')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="erectingTechnologiesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="building.erectingBuildingTechnologie"
        [dataSource]="erectingTechnologiesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('building-erecting-technology-placeholder')"
        [disabled]="templateMode"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'ErectingBuildingTechnologie'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('building-erecting-technology')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kWh/m²'"
        [(value)]="building.energyConsumptionIndicator"
        stylingMode="outlined"
        [min]="0"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'EnergyConsumptionIndicator'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('building-energy-consumption-indicator')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="building.isolated"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
        [disabled]="templateMode"
      ></dx-switch>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'Isolated'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('building-isolated')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="building.windowsExchangable"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
        [disabled]="templateMode"
      ></dx-switch>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'WindowsExchangable'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('building-windows-exchangeable')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="building.energyCertificate"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
        [disabled]="templateMode"
      ></dx-switch>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'EnergyCertificate'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('building-energy-certificate')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="building.conservatorProtection"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
        [disabled]="templateMode"
      ></dx-switch>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'ConservatorProtection'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('building-conservator-protection')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="building.useTime"
        stylingMode="outlined"
        [min]="0"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'UseTime'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('building-use-time')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="templateMode">
    <div *dxTemplate>
      <app-add-template [areaTypeCode]="this.docVariableCode"></app-add-template>
    </div>
  </dxi-item>
</dx-form>
