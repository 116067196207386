import { Component, OnInit, ViewChild } from '@angular/core';
import { AuditCardsService } from './audit-cards.service';
import { Audit } from '../../models/audit/audit.model';
import { LoadOptions } from 'devextreme/data';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuditFilter } from './audit-filter.model';
import { AppSettings } from '../../../AppSettings';
import { ScrollService } from '../../services/scroll.service';
import { Localizable } from '../../../locale/localizable';
import { ApiRequestService } from '../../services/api-request.service';
import { AuditFormComponent } from '../forms/audit-form/audit-form.component';
import { CloseResult } from '../questionnaire/exit-confirmation-popup/exit-confirmation-popup.component';
import { AuditService } from '../forms/audit-form/audit.service';
import { DxDateRangeBoxModule } from 'devextreme-angular';

@Component({
  selector: 'app-audit-cards-container',
  templateUrl: './audit-cards-container.component.html',
  styleUrls: ['./audit-cards-container.component.scss'],
})
export class AuditCardsContainerComponent extends Localizable implements OnInit {
  @ViewChild('auditForm') auditFormPopup!: AuditFormComponent;
  public auditTypes = [];

  searchButton = {
    icon: 'fi fi-rr-search',
    stylingMode: 'text',
  };

  public dropDownOptions = {
    hideOnParentScroll: true,
  };

  private readonly filterSortButtonClass: string = 'filter-sort-button';

  private static readonly localFile = 'AuditsListComponent';
  public auditsArray: Array<Audit> = [];
  public fromDate: Date = null;
  public minDate: Date = new Date(new Date().setFullYear(AppSettings.PROJECT_START_YEAR));

  public toDate: Date = null;
  public currentDate: Date = new Date();
  public isFinished: boolean = null;

  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;

  public createAuditPopupVisible: boolean = false;

  public createButtonOptions = {
    icon: 'fi fi-rr-plus',
    type: 'normal',
    stylingMode: 'outlined',
    text: this._('create-audit'),
    onClick: () => this.onCreateAudit(),
  };

  public createAndDisplayButtonOptions = {
    icon: 'fi fi-rr-plus',
    type: 'normal',
    text: this._('create-and-display-enterprise'),
    onClick: () => this.onCreateAuditAndDisplayEnterprise(),
  };

  public isFilterExpanded = false;

  public name: AuditFilter = {
    icon: AppSettings.SORTUP,
    sort: null,
    filter: null,
  };

  public dateSortIcon: AuditFilter = {
    icon: AppSettings.SORTDOWN,
    sort: true,
    filter: null,
  };

  public auditType: AuditFilter = {
    icon: AppSettings.SORTUP,
    sort: null,
    filter: null,
  };

  private filter = new Subject<Array<any>>();

  private options: LoadOptions = {
    filter: null,
    sort: {
      selector: 'createdAt',
      desc: true,
    },
    skip: 0,
  };

  sortNameButton = {
    icon: this.name.icon,
    onClick: (e) => {
      if (this.name.icon === AppSettings.SORTUP) {
        e.component.option('icon', AppSettings.SORTDOWN);
      }
      if (this.name.icon === AppSettings.SORTDOWN) {
        e.component.option('icon', AppSettings.SORTUP);
      }
      this.sortByName(this.name.icon);
    },
    class: this.filterSortButtonClass,
  };

  sortDateButton = {
    icon: this.dateSortIcon.icon,
    onClick: (e) => {
      if (this.dateSortIcon.icon === AppSettings.SORTUP) {
        e.component.option('icon', AppSettings.SORTDOWN);
      }
      if (this.dateSortIcon.icon === AppSettings.SORTDOWN) {
        e.component.option('icon', AppSettings.SORTUP);
      }
      this.sortByDate(this.dateSortIcon.icon);
    },
    class: this.filterSortButtonClass,
  };

  sortTypeButton = {
    icon: this.auditType.icon,
    onClick: (e) => {
      if (this.auditType.icon === AppSettings.SORTUP) {
        e.component.option('icon', AppSettings.SORTDOWN);
      }
      if (this.auditType.icon === AppSettings.SORTDOWN) {
        e.component.option('icon', AppSettings.SORTUP);
      }
      this.sortByType(this.auditType.icon);
    },
    class: this.filterSortButtonClass,
  };

  constructor(
    private _auditCardService: AuditCardsService,
    private _scrollService: ScrollService,
    private _apiRequestService: ApiRequestService,
    private _auditService: AuditService
  ) {
    super(AuditCardsContainerComponent.localFile);
    this.filter
      .pipe(debounceTime(AppSettings.USER_TYPE_TIME_WAIT), distinctUntilChanged())
      .subscribe((filter: Array<string>) => {
        this.options.filter = filter;
        this._auditCardService.loadWithFilters(this.options);
      });

    this._scrollService.updateBlockScroll(false);
  }

  public ngOnInit(): void {
    this._auditCardService.loadWithFilters(this.options);

    this._auditCardService.auditsArray.subscribe((audits: Array<Audit>) => {
      this.auditsArray = this.auditsArray.concat(audits);
    });

    this._apiRequestService.getAuditTypes().subscribe((result) => {
      this.auditTypes = result;
    });
  }

  public handleChange(e: any) {
    switch (e.index) {
      case AppSettings.AUDIT_CARDS_TABPANEL_ALL:
        this.isFinished = null;
        break;
      case AppSettings.AUDIT_CARDS_TABPANEL_FINISHED:
        this.isFinished = true;
        break;
      case AppSettings.AUDIT_CARDS_TABPANEL_IN_PROGESS:
        this.isFinished = false;
        break;
    }
    this.filterNewValue();
  }

  public filterNewValue(): void {
    let filter: Array<any>;
    filter = [['name', 'contains', this.name.filter]];

    if (this.auditType.filter) {
      filter.push('and', ['auditType.name', 'contains', this.auditType.filter]);
    }

    if (this.fromDate) {
      filter.push('and', ['createdAt', '>=', this.fromDate]);
    }

    if (this.toDate) {
      filter.push('and', ['createdAt', '<=', this.toDate]);
    }

    if (this.isFinished !== null) {
      filter.push('and', ['isFinished', '=', this.isFinished]);
    }

    this.clearLoadedAudits();
    this.filter.next(filter);
  }

  public sortByName(nameIcon: string): void {
    if (nameIcon === AppSettings.SORTUP) {
      this.name.icon = AppSettings.SORTDOWN;
      this.name.sort = true;
    }
    if (nameIcon === AppSettings.SORTDOWN) {
      this.name.icon = AppSettings.SORTUP;
      this.name.sort = false;
    }

    this.clearLoadedAudits();
    this.options.sort = [{ selector: 'name', desc: this.name.sort }];
    this._auditCardService.loadWithFilters(this.options);
  }

  public sortByDate(auditDateIcon: string): void {
    if (auditDateIcon === AppSettings.SORTUP) {
      this.dateSortIcon.icon = AppSettings.SORTDOWN;
      this.dateSortIcon.sort = true;
    }
    if (auditDateIcon === AppSettings.SORTDOWN) {
      this.dateSortIcon.icon = AppSettings.SORTUP;
      this.dateSortIcon.sort = false;
    }

    this.clearLoadedAudits();
    this.options.sort = [{ selector: 'createdAt', desc: this.dateSortIcon.sort }];
    this._auditCardService.loadWithFilters(this.options);
  }

  public sortByType(auditTypeIcon: string): void {
    if (auditTypeIcon === AppSettings.SORTUP) {
      this.auditType.icon = AppSettings.SORTDOWN;
      this.auditType.sort = true;
    }
    if (auditTypeIcon === AppSettings.SORTDOWN) {
      this.auditType.icon = AppSettings.SORTUP;
      this.auditType.sort = false;
    }

    this.clearLoadedAudits();
    this.options.sort = [{ selector: 'auditType.name', desc: this.auditType.sort }];
    this._auditCardService.loadWithFilters(this.options);
  }

  public onScroll(ev: any): void {
    if (ev.target.offsetHeight + ev.target.scrollTop >= ev.target.scrollHeight) {
      this.options.skip += this.options.take;
      this._auditCardService.loadWithFilters(this.options);
    }
  }

  private clearLoadedAudits(): void {
    this.options.skip = 0;
    this.auditsArray = [];
  }

  public deleteAudit(audit: Audit): void {
    const index = this.auditsArray.indexOf(audit);
    if (index > -1) {
      this.auditsArray.splice(index, 1);
    }
  }

  displayCreateAuditPopup(): void {
    this.createAuditPopupVisible = true;
  }

  onCreateAudit(): void {
    this.auditFormPopup.createAudit(this.auditFormPopup.currentAudit);
  }

  onCreateAuditAndDisplayEnterprise(): void {
    this.auditFormPopup.createAuditAndGoToEnterprise(this.auditFormPopup.currentAudit);
  }

  onCloseAuditCreation(e: CloseResult) {
    if (e === CloseResult.Confirm) {
      // exit
      this.createAuditPopupVisible = false;
      this.closeFromPopup = true;
    }

    this.createAuditExitPopupVisible = false;
  }

  onAuditCreationPopupHiding(e: any) {
    if (this.closeFromPopup === false) {
      const noChanges = this.auditFormPopup.triggerParentDeactivateCheck();
      if (!noChanges) {
        e.cancel = true;
        this.createAuditExitPopupVisible = true;
      }
    }
    this.closeFromPopup = false;
  }

  onAuditCreationPopupShown(e: any) {
    this._auditService.createCopy(this.auditFormPopup.currentAudit);
  }

  private closeFromPopup = false;
  public createAuditExitPopupVisible = false;
}
