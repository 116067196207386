import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { Armature, ArmatureInsulationMaterial } from 'src/app/shared/models/area/types/armature.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-armature',
  templateUrl: './armature.component.html',
  styleUrls: ['./armature.component.scss'],
})
export class ArmatureComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  armature: Armature = null;

  @Input()
  templateMode: boolean = false;

  docVariableCode: string = null;
  armatureInsulationMaterialsList: ArrayStore;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    if (this.templateMode) {
      this._apiRequestService.getArmatureDocVariableCode().subscribe((res) => {
        this.docVariableCode = res;
        this.armature = this.getNewEmptyArmature();
      });
    } else {
      this._apiRequestService
        .getAreaTypeArmatureInsulationMaterials()
        .subscribe((res: Array<ArmatureInsulationMaterial>) => {
          this.armatureInsulationMaterialsList = new ArrayStore({
            key: 'id',
            data: res,
          });
        });
    }
  }

  getNewEmptyArmature(): Armature {
    return {
      termalInsulation: false,
      uninsulatedSurfaceArea: 0,
      similarItemsNumber: 0,
      surroundingsTemp: 0,
      surfaceTemp: 0,
      armatureInsulationMaterial: null,
      insulationThickness: 0,
      thermalConductivity: 0,
      workingHours: 0,
    };
  }
}
