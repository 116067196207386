import { Component, NgModule } from '@angular/core';
import { Localizable } from '../../../locale/localizable';
import dxForm from 'devextreme/ui/form';
import notify from 'devextreme/ui/notify';
import Popup from 'devextreme/ui/popup';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DxValidatorModule, DxPopupModule, DxButtonModule, DxFormModule } from 'devextreme-angular';

enum RequestDialog {
  SignInPage,
  ConfirmationDialogPage,
  ResetDialogPage,
}
@Component({
  selector: 'app-password-confirmation',
  templateUrl: './passwordConfirmation.component.html',
  styleUrls: ['./passwordConfirmation.component.scss'],
})
export class PasswordConfirmationComponent extends Localizable {
  oldPasswordButton: any;
  DataPasswordManual: any;

  passwordPattern: any =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$|,_!%*?&'"^()#.\/\\+-=<>:;[\]{}`~])[A-Za-z\d@$|!,_%*?&'"^()#.\/\\+-=<>:;[\]{}~`]{6,}$/;

  private loginUrl = '/login';
  private centerTop = 'center top';
  passwordComparison = () => {
    return this.FormDataPasswordInstance.option('formData').NewPassword;
  };

  // instancja formularza zmiany generowania/zmiany hasła
  public FormDataPasswordInstance: dxForm;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    super();
    this.oldPasswordButton = {
      mode: 'password',
      buttons: [
        {
          name: 'passwordMode',
          location: 'after',
          options: {
            icon: 'find',
            type: 'default',
            onClick: () => {
              let editor = this.FormDataPasswordInstance.getEditor('NewPassword');
              const newMode = editor.option('mode') === 'text' ? 'password' : 'text';
              editor.option('mode', newMode);
              editor = this.FormDataPasswordInstance.getEditor('ConfirmNewPassword');
              editor.option('mode', newMode);
            },
          },
        },
      ],
    };

    Popup.defaultOptions({
      device: { deviceType: 'phone' },
      options: {
        fullScreen: true,
      },
    });
  }

  saveFormDataPasswordInstance(event) {
    this.FormDataPasswordInstance = event.component;
  }

  async FormPasswordManualConfirm() {
    if (this.FormDataPasswordInstance.validate().isValid) {
      const password = this.FormDataPasswordInstance.option('formData').NewPassword;
      const tempUser = this.authenticationService.temporaryUser;
      if (!tempUser) {
        this.onChangePasswordFailedLog();
        this.router.navigateByUrl(this.loginUrl);
        return;
      }
      (
        await this.authenticationService.confirmationEmailAndSetPassword(
          tempUser.userID,
          tempUser.confirmationToken,
          password
        )
      ).subscribe(
        (result) => {
          this.authenticationService.setReturnToSignInpage(true);
          this.authenticationService.requestDialog = RequestDialog.SignInPage;
          this.authenticationService.isUserAuthorizedSubject.next(false);
          this.onPasswordChangeSuccessLog();
        },
        (error) => {
          this.onChangePasswordFailedLog();
          this.router.navigateByUrl(this.loginUrl);
        }
      );
    }
  }

  onChangePasswordFailedLog() {
    notify(
      {
        message: this._('user-onChangePasswordFailedLogMessage'),
        width: 'auto',
        position: {
          my: this.centerTop,
          at: this.centerTop,
        },
      },
      'warning'
    );
  }

  onPasswordChangeSuccessLog() {
    notify(
      {
        message: this._('user-onChangePasswordSuccessMessage'),
        width: 'auto',
        position: {
          my: this.centerTop,
          at: this.centerTop,
        },
      },
      'success'
    );
  }
}

@NgModule({
  imports: [CommonModule, FormsModule, DxValidatorModule, DxPopupModule, DxButtonModule, DxFormModule, FormsModule],
  declarations: [PasswordConfirmationComponent],
  exports: [PasswordConfirmationComponent],
})
export class PasswordConfirmationModule {}
