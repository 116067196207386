<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-tab-panel
  id="boilerRoomTabPanel"
  [loop]="false"
  [swipeEnabled]="true"
  itemTitleTemplate="title"
>
  <div *dxTemplate="let content of 'title'">
    <div class="tabsTitle">{{ content.title }}</div>
  </div>
  <dxi-item [title]="_('boiler-room-general-information')">
    <div *dxTemplate>
      <dx-form [showColonAfterLabel]="true">
        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat"
              [(value)]="boilerRoom.heatSourcePower"
              min="0"
              max="10000000"
              stylingMode="outlined"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-room-heat-source-power')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat"
              [(value)]="boilerRoom.averageEfficiency"
              min="0"
              max="10000000"
              stylingMode="outlined"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-room-average-efficiency')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="integerNumberFormat"
              [(value)]="boilerRoom.ageOfDevices"
              min="0"
              max="50"
              stylingMode="outlined"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-room-age-of-devices')"></dxo-label>
        </dxi-item>

        <dxi-item *ngIf="currencyFormat">
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [(value)]="boilerRoom.maintenanceCost"
              min="0"
              max="10000000"
              stylingMode="outlined"
              [(format)]="currencyFormat"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-room-maintenance-cost')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-switch
              [(value)]="boilerRoom.periodicInspection"
              switchedOffText="Nie"
              switchedOnText="Tak"
            ></dx-switch>
          </div>
          <dxo-label [text]="_('boiler-room-periodic-inspection')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-switch
              [(value)]="boilerRoom.etsSystem"
              switchedOffText="Nie"
              switchedOnText="Tak"
            ></dx-switch>
          </div>
          <dxo-label [text]="_('boiler-room-ets-system')"></dxo-label>
        </dxi-item>

        <dxi-item *ngIf="currencyFormat">
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [(value)]="boilerRoom.etsCost"
              min="0"
              max="10000000"
              stylingMode="outlined"
              [(format)]="currencyFormat"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-room-ets-cost')"></dxo-label>
        </dxi-item>
      </dx-form>
    </div>
  </dxi-item>
  <dxi-item [title]="_('boiler-room-fuel-consumption')">
    <div *dxTemplate>
      <dx-form [showColonAfterLabel]="true">
        <dxi-item>
          <div *dxTemplate>
            <div class="dateDescription">
              {{ _('boiler-room-fuel-consumption-date-description') }}
              {{ timeRange?.fromDate | date: 'shortDate' }} -
              {{ timeRange?.toDate | date: 'shortDate' }}
            </div>
          </div>
          <dxo-label [text]="_('')"></dxo-label>
        </dxi-item>
        <dxi-item>
          <div *dxTemplate>
            <dx-text-box
              [(value)]="boilerRoom.fuelConsumption.name"
              stylingMode="outlined"
            >
            </dx-text-box>
          </div>
          <dxo-label [text]="_('boiler-room-fuel-consumption-name')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat"
              [(value)]="boilerRoom.fuelConsumption.amount"
              min="0"
              max="10000000"
              stylingMode="outlined"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-room-fuel-consumption-amount')"></dxo-label>
        </dxi-item>

        <dxi-item *ngIf="unitList">
          <div *dxTemplate>
            <dx-select-box
              [dataSource]="unitList"
              [(value)]="boilerRoom.fuelConsumption.unit"
              displayExpr="name"
              stylingMode="outlined"
              [searchEnabled]="true"
              searchMode="contains"
            >
            </dx-select-box>
          </div>
          <dxo-label [text]="_('boiler-room-fuel-consumption-unit')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat"
              [(value)]="boilerRoom.fuelConsumption.calorificValue"
              min="0"
              max="10000000"
              stylingMode="outlined"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-room-fuel-consumption-calorific-value')"></dxo-label>
        </dxi-item>

        <dxi-item *ngIf="currencyFormat">
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [(value)]="boilerRoom.fuelConsumption.cost"
              min="0"
              max="10000000"
              [(format)]="currencyFormat"
              [showClearButton]="true"
              stylingMode="outlined"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-room-fuel-consumption-cost')"></dxo-label>
        </dxi-item>
      </dx-form>
    </div>
  </dxi-item>
</dx-tab-panel>
