import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { FuelType, WaterHeater, WaterHeaterType } from 'src/app/shared/models/area/types/water-heater.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { Localizable } from '../../../../../../../locale/localizable';

@Component({
  selector: 'app-water-heater',
  templateUrl: './water-heater.component.html',
  styleUrls: ['./water-heater.component.scss'],
})
export class WaterHeaterComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  waterHeater: WaterHeater = null;

  waterHeaterTypesList: ArrayStore;
  fuelTypesList: ArrayStore;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this._apiRequestService.getAreaTypewaterHeaterTypes().subscribe((res: Array<WaterHeaterType>) => {
      this.waterHeaterTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getAreaTypewaterHeaterFuelTypes().subscribe((res: Array<FuelType>) => {
      this.fuelTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
