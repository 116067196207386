export class AppSettings {
  public static readonly REQUEST_TRIES: number = 0;
  public static readonly USER_TYPE_TIME_WAIT: number = 500;
  public static readonly NOTIFY_DURATION: number = 5000;
  public static readonly NOTIFY_LONG_DURATION: number = 15000;
  public static readonly NOTIFY_TOP_POSTION: string = 'top center';
  public static readonly MAX_IMAGE_SIZE: number = 20971520; //20MB
  public static readonly MOBILE_WIDTH: number = 1279.98;
  public static readonly POPUP_WIDTH: number = 96;
  public static readonly POPUP_HEIGHT: number = 96;
  public static readonly TREE_NODE_NAME_LENGTH_LIMIT = 100;
  public static readonly TREE_NODE_NAME_LEVEL_DECREASE = 15;
  public static readonly AUDIT_MAIN_TEMPLATE: string = 'Db01Audit';
  public static readonly REQUEST_NUMBER_OF_RETRY = 3;
  public static readonly DEPARTMENT_HAS_ENERGY_RECEIVERS_ERROR = 'form-has-energy-receivers';
  public static readonly NUMBER_OF_API_TRIES = 3;
  public static readonly NUMBER_OF_LANGUAGE_RELEVANT_CHARS = 2;
  public static readonly WAIT_BEFORE_SAVE_INPUT: number = 2000;
  public static readonly CONFLICT_HTTP_CODE = 409;
  public static readonly HTTP_STATUS_CODE_200_OK = 200;
  public static readonly HTTP_STATUS_CODE_NOT_AUTHORIZED = 401;
  public static readonly HTTP_STATUS_CODE_BAD_REQUEST = 400;
  public static readonly HTTP_STATUS_CODE_FORBIDDEN = 403;
  public static readonly HTTP_STATUS_CODE_NOT_FOUND = 404;
  public static readonly HTTP_STATUS_CODE_CONFLICT = 409;
  public static readonly RANDOM_TEXT_LENGTH = 10;
  public static readonly ARGON2_PARALLELISM: number = 1;
  public static readonly ARGON2_MEMORY: number = 16;
  public static readonly ARGON2_ITERATIONS: number = 2;
  public static readonly ARGON2_HASH_LENGTH: number = 16;
  public static readonly ADMIN_ROLE_NAME = 'Admin';
  public static readonly USER_ROLE_NAME = 'User';
  public static readonly USER_LITE_ROLE_NAME = 'LiteUser';
  public static readonly MIN_MENU_SIZE = 56;
  public static readonly NAVIGATION_PREFERENCES = 'navigation-preferences';
  public static readonly NAVIGATION_AUDITS = 'navigation-audits';
  public static readonly NAVIGATION_DASHBOARD = 'navigation-dashboard';
  public static readonly DEFAULT_NAVIGATION_SELECTION_ROUTE = '/pages/audits-list';
  public static readonly SHRINK_AUDIT_COUNT = 99;
  public static readonly PROJECT_START_YEAR = 2020;
  public static readonly QUESTIONNAIRE_ROOT_NAME = 'ROOT';
  public static readonly NAVIGATION_TREE_ICON_LEFT = 'fi fi-rr-angle-double-small-left';
  public static readonly NAVIGATION_TREE_ICON_RIGHT = 'fi fi-rr-angle-double-small-right';
  public static readonly NAVIGATION_TREE_ICON_DOWN = 'fi fi-rr-angle-double-small-down';
  public static readonly NAVIGATION_TREE_ICON_UP = 'fi fi-rr-angle-double-small-up';
  public static readonly AUDIT_CARDS_TABPANEL_ALL = 0;
  public static readonly AUDIT_CARDS_TABPANEL_FINISHED = 1;
  public static readonly AUDIT_CARDS_TABPANEL_IN_PROGESS = 2;
  public static readonly FORM_TABPANEL_FORM = 0;
  public static readonly FORM_TABPANEL_GALLERY = 1;
  public static readonly FORM_TABPANEL_ENERGY_BALANCE = 2;
  public static readonly GJ_TO_MWH_RATIO = 3.6;
  public static readonly ACCOUNT_DROPDOWN_MENU_ICON_UP = 'fi fi-rr-angle-small-up';
  public static readonly ACCOUNT_DROPDOWN_MENU_ICON_DOWN = 'fi fi-rr-angle-small-down';
  public static readonly NUMBER_INTEGER_FORMAT = '#0';
  public static readonly NUMBER_FULL_FORMAT = ',##0.##';
  public static readonly REASONABLE_DELAY = 200;
  public static readonly AUTH_TOKEN = 'db_energy_auth_token';
  public static readonly USER_LOCALE = 'db_energy_locale';
  public static readonly AUTH_CURRENT_USER = 'db_energy_current_user';
  public static readonly SORTUP = 'sortup';
  public static readonly SORTDOWN = 'sortdown';
  public static readonly QUESTIONNAIRE_LOADING_DURATION = 1000;
  public static readonly FORM_TAB = 'form';
  public static readonly GALLERY_TAB = 'gallery';
  public static readonly SANKEY_TAB = 'sankey';
  public static readonly SURVEY_TAB = 'survey';
  public static readonly LOAD_INDICATOR_WIDTH = 100;
  public static readonly LOAD_INDICATOR_HEIGHT = 100;
  public static readonly LOAD_IMAGE_INDICATOR_WIDTH = 160;
  public static readonly LOAD_IMAGE_INDICATOR_HEIGHT = 160;
  public static readonly POPUP_HEIGHT_THRESHOLD = 400;
  public static readonly LANG_PL = 'pl-PL';
  public static readonly LANG_EN = 'en-US';
}

export const WAIT_BEFORE_SAVE_INPUT: number = 300;
