import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Localizable } from '../../locale/localizable';
import { AppSettings } from '../../AppSettings';
import { Language } from '../../shared/models/language';
import { ApiRequestService } from '../../shared/services/api-request.service';

@Component({
  selector: 'app-admin-user-management',
  templateUrl: './admin-user-management.component.html',
  styleUrls: ['./admin-user-management.component.scss'],
})
export class AdminUserManagementComponent extends Localizable implements OnInit {
  email: string;
  firstname: string;
  lastname: string;
  phonenumber: string;
  position: string;
  password: string;
  role: string;

  roles = [AppSettings.ADMIN_ROLE_NAME, AppSettings.USER_ROLE_NAME, AppSettings.USER_LITE_ROLE_NAME];

  languages: Language[] = [];
  UsersData: any;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private apiRequestService: ApiRequestService
  ) {
    super();
    this.UsersData = authenticationService.UsersData;
    this.languageCellValue = this.languageCellValue.bind(this);
  }

  ngOnInit(): void {
    this.apiRequestService.getLanguages().subscribe((res) => {
      this.languages = res.map((l) => {
        return {
          id: l.id,
          name: this._('admin-' + l.name),
        };
      });
    });
  }

  async register() {
    (
      await this.authenticationService.register(
        this.email,
        this.password,
        this.firstname,
        this.lastname,
        this.phonenumber,
        this.position,
        this.role
      )
    ).subscribe(
      (data) => {
        console.log(data);
        this.router.navigateByUrl('/login');
      },
      (error) => {
        console.log(error);
      }
    );
  }

  navigateToLogin(): void {
    this.router.navigateByUrl('/login');
  }

  public languageCellValue(rowData) {
    return rowData.language?.name;
    return this._(rowData.language?.name);
  }

  public onCellPrepared(e) {
    if (e.column.command === 'edit' && e.rowType === 'data' && e.data.isSuper) {
      const deleteLink = e.cellElement.querySelector('.dx-link-delete');

      if (deleteLink) {
        deleteLink.remove(); // This removes the element from the DOM
      }
    }
  }

  public onEditorPreparing(e) {
    if (e.row && e.row.data.isSuper && e.dataField === 'roleName') {
      e.editorOptions.disabled = true;
    }
  }
}
