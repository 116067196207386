<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form
  [showColonAfterLabel]="true"
  *ngIf="lighting"
>
  <dxi-item *ngIf="fixturesList">
    <div *dxTemplate>
      <dx-data-grid
        id="gridContainer"
        [dataSource]="fixturesList"
        [remoteOperations]="true"
        [allowColumnReordering]="true"
        [columnAutoWidth]="false"
        [showBorders]="true"
      >
        <dxo-paging [enabled]="true"></dxo-paging>
        <dxo-editing
          [allowUpdating]="true"
          [allowDeleting]="true"
          [allowAdding]="true"
        >
          <dxo-texts
            [deleteRow]="_('lighting-fixtures-delete-row')"
            [addRow]="_('lighting-fixtures-add-row')"
            [editRow]="_('lighting-fixtures-edit-row')"
            [confirmDeleteMessage]="_('lighting-fixtures-confirm-delete-message')"
            [saveRowChanges]="_('lighting-fixtures-save-row-changes')"
            [cancelRowChanges]="_('lighting-fixtures-cancel-row-changes')"
          >
          </dxo-texts>
        </dxo-editing>

        <dxi-column
          dataField="type.id"
          [caption]="_('lighting-fixtures-type')"
          [editorOptions]="{ placeholder: this._('lighting-fixtures-type-placeholder') }"
        >
          <dxo-lookup
            [dataSource]="fixtureTypesList"
            displayExpr="name"
            valueExpr="id"
          >
          </dxo-lookup>
        </dxi-column>
        <dxi-column
          dataField="power"
          [caption]="_('lighting-fixtures-power')"
          format="#0.## W"
        ></dxi-column>
        <dxi-column
          dataField="number"
          [caption]="_('lighting-fixtures-number')"
          format="#0 szt."
        ></dxi-column>
        <dxi-column
          dataField="workingHours"
          [caption]="_('lighting-fixtures-working-hours')"
          format="#0 h"
        >
          ></dxi-column
        >
      </dx-data-grid>
    </div>
    <dxo-label [text]="_('lighting-fixtures-list')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="lightingControlSystemsList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="lighting.lightingControlSystem"
        [dataSource]="lightingControlSystemsList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('Wybierz typ sterowania oświetleniem')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('lighting-lighting-control-system')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="lighting.luminairesEX"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('lighting-luminaires-ex')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="lighting.approvalPZH"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('lighting-approval-pzh')"></dxo-label>
  </dxi-item>
</dx-form>
