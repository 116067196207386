export enum MobileTabPanelOptionType {
  FormContent = 'FormContent',
  TreeNavigation = 'TreeNavigation',
}

export interface Tab {
  id: number;
  text: string;
  icon: string;
  type: MobileTabPanelOptionType;
}
