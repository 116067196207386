import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuditTreeNavigation, FormType } from 'src/app/shared/models/audit/audit-tree-navigation.model';
import { AuditType } from 'src/app/shared/models/audit/audit-type.model';
import { Audit } from 'src/app/shared/models/audit/audit.model';
import { Currency } from 'src/app/shared/models/audit/currency.model';
import { User } from 'src/app/shared/models/user.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { TreeNavigationService } from 'src/app/shared/services/tree-navigation.service';
import { UtilsService } from '../../../services/utils.service';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private auditCopy: Audit;

  public _isFinished = new Subject<boolean>();
  public isFinished = this._isFinished.asObservable();

  private _performAction = new Subject<any>();
  public performAction = this._performAction.asObservable();

  private _isLoaded = new BehaviorSubject<{ value: boolean; formtype: FormType }>({
    value: false,
    formtype: FormType.Audit,
  });
  public isLoaded = this._isLoaded.asObservable();

  constructor(
    private _apiRequestService: ApiRequestService,
    private _treeNavigationService: TreeNavigationService,
    private _utilsService: UtilsService
  ) {}

  public getEmptyAudit(): Audit {
    const audit: Audit = {
      auditType: null,
      goal: '',
      clientGuidelines: '',
      date: new Date(),
      cost: 0,
      spbt: 0,
      irr: 0,
      client: '',
      currency: null,
      fromDate: new Date(),
      toDate: new Date(),
      users: [],
      name: '',
      conclusion: '',
      auditRange: {
        audit: true,
        optimizationDesign: false,
        implementation: false,
      },
      isFinished: false,
      contactPerson: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        position: null,
      },
      createdAt: null,
    };

    return audit;
  }

  public createCopy(audit: Audit): void {
    this.auditCopy = this._utilsService.deepCopy(audit);
  }

  public formChanged(audit: Audit): boolean {
    return !this._utilsService.areObjectsEqual(audit, this.auditCopy);
  }

  public placeHolderTypes(): Observable<Array<AuditType>> {
    return this._apiRequestService.getAuditTypes();
  }

  public getNavigationTree(id: string): any {
    return this._apiRequestService.getNavigationTree(id);
  }

  public getAuditTypes(): Observable<Array<AuditType>> {
    return this._apiRequestService.getAuditTypes();
  }

  public getUsersList(): Observable<Array<User>> {
    return this._apiRequestService.getUsersList();
  }

  public getCurrentAudit(auditId: string): Observable<Audit> {
    return this._apiRequestService.getAudit(auditId);
  }

  public getCurrencies(): Observable<Array<Currency>> {
    return this._apiRequestService.getCurrencies();
  }

  public getAuditNavigation(auditId: string): Observable<Array<AuditTreeNavigation>> {
    return this._apiRequestService.getNavigationTree(auditId);
  }

  public createAudit(audit: Audit): Observable<Audit> {
    this._treeNavigationService.clearNavigationTree();
    return this._apiRequestService.createAudit(audit);
  }

  public formLoaded(value: boolean): void {
    this._isLoaded.next({ value, formtype: FormType.Audit });
  }

  public selectedAction(e: any): void {
    this._performAction.next(e);
  }

  public toggleFinishedAudit(isFinished: boolean): void {
    this._isFinished.next(isFinished);
  }
}
