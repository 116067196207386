<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="card">
  <div
    id="img"
    class="image"
  >
    <div
      *ngIf="!imageUnavailable && !loadingVisible"
      class="absolute-zoom-container"
    >
      <dx-button
        icon="plus"
        class="outlined-button-focus-hover"
        stylingMode="outlined"
        type="normal"
        (onClick)="zoomIn()"
      >
      </dx-button>
      <dx-button
        icon="minus"
        class="outlined-button-focus-hover"
        stylingMode="outlined"
        type="normal"
        (onClick)="zoomOut()"
        [disabled]="!canZoomOut"
      >
      </dx-button>
    </div>
    <dx-scroll-view
      #scrollView
      id="scrollView"
      height="100%"
      width="100%"
      direction="both"
      scrollByThumb="true"
      scrollByContent="false"
      useNative="false"
    >
      <dx-load-indicator
        [(visible)]="loadingVisible"
        [height]="loadIndicatorHeight"
        [width]="loadIndicatorWidth"
      >
      </dx-load-indicator>

      <img
        *ngIf="!imageUnavailable"
        draggable="false"
        id="thumbnailElement"
        [width]="imgWidth"
        [height]="imgHeight"
        [alt]="imageUnavailable ? _('gallery-image-unavailable') : ''"
      />
      <p *ngIf="imageUnavailable">
        {{ _('gallery-image-unavailable') }}
      </p>
    </dx-scroll-view>
  </div>

  <div class="text-fields">
    <div class="caption-field">
      <dx-text-box
        [(value)]="image.caption"
        stylingMode="outlined"
        [disabled]="isAuditFinished"
        [label]="_('gallery-picture-caption') + ':'"
        labelMode="outside"
      ></dx-text-box>
    </div>

    <div class="description-field">
      <dx-text-area
        [(value)]="image.description"
        stylingMode="outlined"
        [disabled]="isAuditFinished"
        [label]="_('gallery-picture-description') + ':'"
        labelMode="outside"
      >
      </dx-text-area>
    </div>
  </div>
</div>
