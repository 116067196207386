<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div *ngIf="docVariablesListVisible">
  <hr
    class="line"
    *ngIf="docVariablesListVisible"
  />
  <p>{{ _('template-file-additional-variables') }}</p>
  <app-doc-variables-list></app-doc-variables-list>
  <hr class="line" />
</div>

<dx-button
  class="outlined-button-focus-hover template-button"
  stylingMode="outlined"
  [text]="_('template-file-show-templates')"
  type="normal"
  (onClick)="showFileList()"
>
</dx-button>

<dx-popup
  *ngIf="fileListVisible"
  [title]="_('template-file-form-templates')"
  [width]="(mobile ? popupWidth * 0.9 : 50) + '%'"
  [height]="(mobile ? popupHeight * 0.9 : 50) + '%'"
  [fullScreen]="false"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="fileListVisible"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <dx-scroll-view
    width="100%"
    height="100%"
  >
    <app-template-file-list></app-template-file-list>
  </dx-scroll-view>
</dx-popup>
