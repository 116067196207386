import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { AppConfig } from '../../../app.config';
import { AppSettings } from '../../../AppSettings';
import { AuditTimeRange } from '../../models/audit/audit-time-range.model';
import { Currency } from '../../models/audit/currency.model';
import {
  DtoEnergyBalanceReceiver,
  DtoEnergyBalanceSource,
  EnergyBalanceReceiver,
  EnergyBalanceSource,
  EnergyBalanceType,
} from '../../models/energies/energy.model';
import { Unit } from '../../models/unit.model';
import { ApiRequestService } from '../../services/api-request.service';

@Injectable({
  providedIn: 'root',
})
export class EnergyBalanceService {
  private _apiUrl: string;

  public chosenInputs: BehaviorSubject<Array<EnergyBalanceType>> = new BehaviorSubject<Array<EnergyBalanceType>>(null);

  constructor(
    private _apiRequestService: ApiRequestService,
    private _httpClient: HttpClient,
    private _appConfig: AppConfig
  ) {
    this._apiUrl = this._appConfig.getConfig('DbEnergyDatabaseUrl');
  }

  getUnitsListForEnergyBalance(): Observable<Array<Unit>> {
    return this._apiRequestService.getUnitsForEnergyBalance();
  }

  addNewEnergyBalanceSource(energyBalanceSource: DtoEnergyBalanceSource): Observable<EnergyBalanceSource> {
    return this._apiRequestService.addEnergyBalanceSource(energyBalanceSource);
  }

  addNewEnergyBalanceReceiver(energyBalanceReceiver: DtoEnergyBalanceReceiver): Observable<EnergyBalanceReceiver> {
    return this._apiRequestService.addEnergyBalanceReceiver(energyBalanceReceiver);
  }

  updateEnergyBalanceSource(energyBalanceSource: DtoEnergyBalanceSource): Observable<EnergyBalanceSource> {
    return this._apiRequestService.updateEnergyBalanceSource(energyBalanceSource);
  }

  updateEnergyBalanceReceiver(energyBalanceReceiver: DtoEnergyBalanceReceiver): Observable<EnergyBalanceReceiver> {
    return this._apiRequestService.updateEnergyBalanceReceiver(energyBalanceReceiver);
  }

  getEnergyBalanceSource(id: string): Observable<EnergyBalanceSource> {
    return this._apiRequestService.getEnergyBalanceSource(id);
  }

  getAuditTimeRange(auditId: string): Observable<AuditTimeRange> {
    return this._apiRequestService.getAuditTimeRange(auditId);
  }

  getAuditCurrency(id: string): Observable<Currency> {
    return this._httpClient
      .get<Currency>(`${this._apiUrl}api/audits/${id}/currency`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  public selectedEnergyTypesChanged(types: Array<EnergyBalanceType>): void {
    this.chosenInputs.next(types);
  }
}
