import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
})
export class HintComponent {
  @Input()
  title: string = '';

  @Input()
  message: string = '';

  @Input()
  mainButtonText: string = '';

  @Input()
  secondaryButton: boolean = false;

  @Input()
  secondaryButtonText: string = '';

  @Output()
  mainButtonClick = new EventEmitter<void>();

  @Output()
  secondaryButtonClick = new EventEmitter<void>();

  public onMainButtonClick(): void {
    this.mainButtonClick.emit();
  }

  public onSecondaryButtonClick(): void {
    this.secondaryButtonClick.emit();
  }
}
