<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="airConditioner.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('air-conditioner-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="airConditioner.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('air-conditioner-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="airConditioner.serialNumber"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('air-conditioner-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="airConditioner.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-conditioner-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="refrigerantTypes">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="airConditioner.refrigerantType"
        [dataSource]="refrigerantTypes"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('air-conditioner-refrigerant-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('air-conditioner-refrigerant-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="airConditioner.refrigerantAmount"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-conditioner-refrigerant-amount')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="airConditioner.ratedPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-conditioner-rated-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="airConditioner.ratedCoolingCapacity"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-conditioner-rated-cooling-capacity')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="airConditioner.seerFactor"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('air-conditioner-seer-factor')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="airConditioner.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-conditioner-working-hours-in-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="airConditioner.inverter"
        switchedOffText="Nie"
        switchedOnText="Tak"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('air-conditioner-inverter')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="airConditioner.similarDevicesNumber"
        stylingMode="outlined"
        [min]="0"
        [max]="1000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-conditioner-similar-devices-number')"></dxo-label>
  </dxi-item>
</dx-form>
