import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { Area, AreaType } from 'src/app/shared/models/area/area.model';
import { TreeNavigationService } from 'src/app/shared/services/tree-navigation.service';
import { AreaFormService } from './area-form.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { AppConfig } from 'src/app/app.config';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { ActionType } from 'src/app/shared/models/area/action-type.model';
import { Currency } from 'src/app/shared/models/audit/currency.model';
import { Unit } from 'src/app/shared/models/unit.model';
import ArrayStore from 'devextreme/data/array_store';
import { AreaTypes } from 'src/app/shared/models/area/area-types';
import { AuditTreeNavigation, FormType } from 'src/app/shared/models/audit/audit-tree-navigation.model';
import { DxDataGridComponent, DxFormComponent, DxTreeViewComponent } from 'devextreme-angular';
import { map, takeUntil } from 'rxjs/operators';
import { ReportService } from '../../../../services/report.service';
import { FormMenuActions } from '../../../../models/FormMenuActions.enum';
import { AppSettings } from '../../../../../AppSettings';
import { ScreenService } from '../../../../services';
import { Subscription } from 'rxjs';
import { Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Localizable } from '../../../../../locale/localizable';
import { EnergySavingType } from '../../../../models/area/energy-saving-type';
import { AuthenticationService } from '../../../../../shared/services/authentication.service';
import { ErrorHandlerService } from '../../../../../shared/services/errorHandlerService';
import { CloseResult } from '../../../questionnaire/exit-confirmation-popup/exit-confirmation-popup.component';
import { EnergyBalanceSankey } from '../../../energy-balance-chart/energy-balance-chart/energy-balance-sankey.service';
import { GalleryService } from '../../../../services/gallery.service';
import { CanComponentDeactivate } from '../../../../../core/guards/can-deactivate.guard';

@Component({
  selector: 'app-area-form',
  templateUrl: './area-form.component.html',
  styleUrls: ['./area-form.component.scss'],
})
export class AreaFormComponent extends Localizable implements OnInit, AfterViewInit, OnDestroy, CanComponentDeactivate {
  @ViewChild(DxTreeViewComponent, { static: false })
  treeView: DxTreeViewComponent;
  @ViewChild(DxFormComponent, { static: false }) areaForm: DxFormComponent;

  @ViewChild('actionDataGrid', { static: false })
  dataGrid!: DxDataGridComponent;

  @Input() departmentId: string = null;
  @Input() existingForm: boolean = true;
  @Input() parentAreaType: AreaType = null;
  @Input() parentAreaId: number = null;

  private _areaId: string = null;
  activeIndex: number;
  @Input()
  public set areaId(id: string) {
    this._areaId = id;
    if (this.existingForm) {
      this.fetchDataForArea(id);
    }
  }

  public get areaId() {
    return this._areaId;
  }

  @Output() areaAdded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() departmentChangesIsSaved: boolean = true;
  @Input() areaChangesIsSaved: boolean = true;

  public get AreaTypesEnum(): typeof AreaTypes {
    return AreaTypes;
  }

  public currencies: Array<Currency>;
  public units: Array<Unit>;
  public energySavingTypes: Array<EnergySavingType>;
  public currencyFormat: string = '';

  public currentAuditId: string;
  public currentArea: Area;
  public areaCategories: Array<AuditTreeNavigation>;
  public areaTypes: ArrayStore;
  public actionTypes: Array<ActionType>;
  public energyFormat: string;
  private _energyUnit = 'kW #.##';
  public dataSource: any;
  public selectedType: AreaType = {
    id: null,
    name: '',
    areaCategory: {
      id: null,
      name: '',
    },
  };

  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;

  deleteAreaPopup: boolean = false;
  deleteAreaMessage: any;

  deletePopupVisible = false;
  areaPopupVisible = false;
  isGalleryVisible: boolean = false;
  urlDbEnergyDatabase: string;
  public energyBalanceChart: boolean = false;

  callbacks = [];
  borderStyle: string = 'none';
  private _isDeleted: boolean = false;

  public adapterConfig = {
    getValue: () => {
      return this.selectedType;
    },
    applyValidationResults: (e) => {
      this.borderStyle = e.isValid ? 'none' : '1px solid red';
    },
    validationRequestsCallbacks: this.callbacks,
  };
  revalidate() {
    this.callbacks.forEach((func) => {
      func();
    });
  }

  saveText: string = '';
  addAreaText: string = '';
  deleteAreaText: string = '';
  galleryText: string = '';
  public imagesNumber: number = 0;

  private savedArea: Area;
  private checkChangesSubscription: Subscription;
  public isChangingDisabled = false;
  public isAuditFinishedFlag = false;

  private _authenticationService: AuthenticationService = inject(AuthenticationService);
  private _errorHandlerService: ErrorHandlerService = inject(ErrorHandlerService);

  private exitFormReturnSubscription: Subscription;

  @ViewChild('areaFormPopup', { static: false })
  areaFormPopup!: AreaFormComponent;

  constructor(
    private _areaFormService: AreaFormService,
    private _apiRequestService: ApiRequestService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _treeNavigationService: TreeNavigationService,
    private _config: AppConfig,
    private _reportService: ReportService,
    private _screenService: ScreenService,
    private _energyBalanceSankey: EnergyBalanceSankey,
    private _galleryService: GalleryService
  ) {
    super();
    this.energyFormat = this._energyUnit;
    this.urlDbEnergyDatabase = this._config.getConfig('DbEnergyDatabaseUrl');
  }

  public createButtonOptions = {
    icon: 'fi fi-rr-plus',
    type: 'normal',
    stylingMode: 'outlined',
    text: this._('create-area'),
    onClick: () => {
      this.onCreateArea();
    },
  };

  public createAndDisplayButtonOptions = {
    icon: 'fi fi-rr-plus',
    type: 'normal',
    text: this._('create-area-and-go'),
    onClick: () => {
      this.onCreateAndDisplayArea();
    },
  };

  public saveActionButtonOptions = {
    text: this._('area-acton-save'),
    stylingMode: 'contained',
    onClick: () => {
      this.dataGrid.instance.saveEditData();
    },
  };

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this._route.params.pipe(takeUntil(this.onDestroy)).subscribe((params) => {
      this.areaId = params['areaId'];
    });

    this._route.firstChild?.url.subscribe((urlPath) => {
      const path = urlPath[0]?.path;
      this.setActiveTab(path);
    });

    this._areaFormService.performAction.pipe(takeUntil(this.onDestroy)).subscribe((action) => {
      if (action) {
        this.selectedAction(action);
      }
    });
    this._route.parent.params.pipe(takeUntil(this.onDestroy)).subscribe((params) => {
      this.currentAuditId = params['auditId'];
    });

    this._screenService.changed.subscribe((screenSizeBreakPoint: BreakpointState) =>
      this.updateButtonsText(screenSizeBreakPoint)
    );

    this._screenService.changed.subscribe((screenSizeBreakPoint: BreakpointState) =>
      this.updateButtonsText(screenSizeBreakPoint)
    );

    this.isAuditFinishedFlag = await this._treeNavigationService.isAuditFinished(this.currentAuditId);

    if (this.existingForm) {
      this.fetchDataForArea(this.areaId);
    } else {
      if (this.parentAreaId) {
        this.getAreaTypes(this.parentAreaType.id);
        this.currentArea = this._areaFormService.getEmptyArea();
        this.currentArea.parentId = this.parentAreaId;
        this.currentArea.departmentId = this.departmentId;
      } else {
        this._areaFormService.getAreaCategories().subscribe((res) => {
          this.areaCategories = res;
        });
        this.currentArea = this._areaFormService.getEmptyAreaRoot();
        this.currentArea.departmentId = this.departmentId;
      }
      this._areaFormService.formLoaded(true);
      this.savedArea = this._treeNavigationService.deepCopy(this.currentArea);
    }

    this._treeNavigationService.checkFormData.pipe(takeUntil(this.onDestroy)).subscribe((result) => {
      if (result) {
        this._treeNavigationService.updateformDataSaved(this.currentArea, this.savedArea, this._areaId);
      }
    });

    this._treeNavigationService.exitFormReturn.pipe(takeUntil(this.onDestroy)).subscribe((result) => {
      switch (result) {
        case CloseResult.Cancel:
          break;
        case CloseResult.Confirm:
          this._treeNavigationService.exitFormYesFn();
          break;
        case CloseResult.Special:
          this._treeNavigationService.exitFormSpecialFn();
          break;
      }
    });
  }

  public isAuditFinished(): boolean {
    return this.isAuditFinishedFlag;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._areaFormService.formLoaded(false);
  }

  ngAfterViewInit(): void {
    this._treeNavigationService.setComponentRendered(this);
  }

  canBeDeactivated(): boolean {
    return this.triggerParentDeactivateCheck();
  }

  triggerParentDeactivateCheck(): boolean {
    if (this.currentArea && this._isDeleted === false) {
      return !this._areaFormService.formChanged(this.currentArea);
    } else {
      return true;
    }
  }

  public fetchDataForArea(id: string): void {
    this.fetchActionTypes();
    this.fetchCurrencies();
    this.fetchEnergyBalanceUnits();
    this.fetchEnergySavingTypes();
    this.fetchArea(id);
  }

  private fetchActionTypes(): void {
    this._apiRequestService.getAreaActionTypes().subscribe((res) => (this.actionTypes = res));
  }

  private fetchCurrencies(): void {
    this._apiRequestService.getCurrencies().subscribe((res) => (this.currencies = res));
  }

  private fetchEnergySavingTypes(): void {
    this._apiRequestService.getEnergySavingTypes().subscribe((res) => (this.energySavingTypes = res));
  }

  private fetchEnergyBalanceUnits(): void {
    this._apiRequestService.getUnitsForEnergyBalance().subscribe((res) => (this.units = res));
  }

  private fetchArea(id): void {
    this.isGalleryVisible = false;
    this._areaFormService.getCurrentAreaForm(id).subscribe((res) => {
      this.currentArea = res;
      this._areaFormService.createCopy(this.currentArea);
      this._areaFormService.formLoaded(true);
      this.isGalleryVisible = true;
      this.savedArea = this._treeNavigationService.deepCopy(this.currentArea);
      const urlDBEnergyDatabase = this._config.getConfig('DbEnergyDatabaseUrl');
      this.getImagesNumberInGallery(this.currentArea.formGallery.id);
      this.dataSource = AspNetData.createStore({
        key: 'id',
        loadUrl: `${urlDBEnergyDatabase}api/areas/${this.currentArea.id}/actions`,
        insertUrl: `${urlDBEnergyDatabase}api/areas/${this.currentArea.id}/actions`,
        updateUrl: `${urlDBEnergyDatabase}api/areas/actions`,
        deleteUrl: `${urlDBEnergyDatabase}api/areas/actions`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: true };
          const token = localStorage.getItem(AppSettings.AUTH_TOKEN);
          ajaxOptions.headers = { Authorization: `Bearer ${token}` };
        },
        onAjaxError: (e) => {
          if (e.xhr.status === AppSettings.HTTP_STATUS_CODE_NOT_AUTHORIZED) {
            this._authenticationService.logout();
            notify(this._('not-authorized-logout'), 'error', AppSettings.NOTIFY_DURATION);
          } else {
            this._errorHandlerService.handleError(e.xhr);
          }
        },
      });
      this._treeNavigationService.setComponentRendered(this);
    });
  }

  getAreaTypes(id: string): void {
    if (id) {
      this._areaFormService.getAreaTypes(id).subscribe((res) => {
        res.sort((a, b) => a.name.localeCompare(b.name));
        this.areaTypes = new ArrayStore({
          key: 'id',
          data: res,
        });
      });
    }
  }

  selectItem(e: any): void {
    if (e.itemData.selectable === false) {
      this.treeView.instance.unselectItem(e.itemData);
      this.treeView.instance.repaint();
    } else {
      this.selectedType = e.itemData;
    }
  }

  selectedItemChanged(item: any): void {
    this.selectedType = item.selectedItem;
  }

  getAreaTypeForCategory(categoryId: number): void {
    if (categoryId) {
      this._areaFormService.getAreaTypesForCategorie(categoryId).subscribe((res) => {
        res.sort((a, b) => a.name.localeCompare(b.name));
        this.areaTypes = new ArrayStore({
          key: 'id',
          data: res,
        });
      });
    }
  }

  getAreaParentType(categoryId: number): void {
    if (this.parentAreaType) {
      this.getAreaTypes(this.parentAreaType.id.toString());
    } else {
      this.getAreaTypeForCategory(categoryId);
    }
  }

  addFromValid(): boolean {
    const validator: any = this.areaForm.instance.validate();
    if (!validator.isValid) {
      validator.brokenRules[0].validator.focus();
      return false;
    }

    if (this.selectedType.id === null && validator.isValid) {
      notify({
        message: this._('area-type-select-placeholder'),
        type: 'error',
        displayTime: AppSettings.NOTIFY_DURATION,
        position: AppSettings.NOTIFY_TOP_POSTION,
      });
      return false;
    }
    return true;
  }

  newAreaAdded(deleteUnsavedChanges: boolean): void {
    this.getEmptyArea();
    this.areaAdded.emit(deleteUnsavedChanges);
    notify({
      message: this._('area-created'),
      type: 'success',
      displayTime: AppSettings.NOTIFY_DURATION,
      position: AppSettings.NOTIFY_TOP_POSTION,
    });
  }

  addAreaAndStay(area: Area): void {
    if (this.addFromValid()) {
      area.type = this.selectedType;
      this._areaFormService
        .addNewArea(area)
        .pipe(
          map((res) => {
            this._treeNavigationService
              .getAuditNavigationTree(this.currentAuditId)
              .subscribe(() => this._treeNavigationService.expandAllParents(res.id.toUpperCase()));
            this.newAreaAdded(false);
          })
        )
        .subscribe({
          complete: () => this.newAreaAdded(false),
        });
    }
  }

  addAreaAndGo(area: Area): void {
    if (this.addFromValid()) {
      if (!this.areaChangesIsSaved || !this.departmentChangesIsSaved) {
        this._treeNavigationService.exitFormYesFn = () => {
          this.addAndGo(area);
          return true;
        };

        this._treeNavigationService.exitFormSpecialFn = () => {
          this.saveForm();
          this.addAndGo(area);
          return true;
        };
      } else {
        this.addAndGo(area);
      }
    }
  }

  private addAndGo(area: Area): void {
    area.type = this.selectedType;
    this._areaFormService
      .addNewArea(area)
      .pipe(
        map((res) => {
          this._treeNavigationService.getAuditNavigationTree(this.currentAuditId).subscribe(() => {
            this._treeNavigationService.expandAllParents(res.id.toUpperCase());
            const areaNavigation: AuditTreeNavigation = {
              id: res.id,
              name: res.name,
              selectable: true,
              formType: FormType.Area,
              expanded: true,
              parentId: null,
            };
            this._treeNavigationService.setNavigationToFormId(areaNavigation);
            this._areaFormService.scrollToTop();
          });
        })
      )
      .subscribe({
        complete: () => this.newAreaAdded(true),
      });
  }

  saveArea(area: Area): void {
    const validator: any = this.areaForm.instance.validate();
    if (!validator.isValid) {
      validator.brokenRules[0].validator.focus();
    } else {
      this._areaFormService.save(area).subscribe((res) => {
        this.currentArea = res;
        this._areaFormService.createCopy(res);
        this.savedArea = this._treeNavigationService.deepCopy(this.currentArea);
        this._treeNavigationService.getAuditNavigationTree(res.auditFormId).subscribe();
        notify({
          message: this._('changes-saved'),
          type: 'success',
          displayTime: AppSettings.NOTIFY_DURATION,
          position: AppSettings.NOTIFY_TOP_POSTION,
        });
        this._energyBalanceSankey.updateEnergyBalanceSankey(this.currentAuditId);
      });
    }
  }

  getCurrency(): void {
    this._apiRequestService
      .getAuditCurrency(this.areaId)
      .subscribe((res: Currency) => (this.currencyFormat = `#0.## ${res.short}`));
  }

  deleteArea(area: Area): void {
    this._apiRequestService.canAreaBeDeleted(area.id).subscribe((response) => {
      if (!response.canDelete) {
        this.deleteAreaMessage = response;
        this.deleteAreaPopup = true;
      } else {
        this.deleteAreaPopupMessage = this.createDeleteConfirmationMessage(response);
        this.deleteAreaPopupVisible = true;
      }
    });
  }

  private deleteAreaRequest(area: Area): void {
    this._apiRequestService
      .deleteArea(area.id)
      .pipe(
        map(() => {
          this._isDeleted = true;
          const areaNavigation: AuditTreeNavigation = {
            id: area.departmentId,
            name: null,
            selectable: true,
            formType: FormType.Department,
            expanded: true,
            parentId: null,
          };
          this._treeNavigationService.setNavigationToFormId(areaNavigation);

          this._treeNavigationService.getAuditNavigationTree(area.auditFormId).subscribe();
          notify({
            message: this._('delete-area-notify-message'),
            type: 'success',
            displayTime: AppSettings.NOTIFY_DURATION,
            position: AppSettings.NOTIFY_TOP_POSTION,
          });
          this._energyBalanceSankey.updateEnergyBalanceSankey(this.currentAuditId);
        })
      )
      .subscribe();
  }

  popupClosed(): void {
    this.deleteAreaPopup = false;
    this.deleteAreaMessage = {};
  }

  getImagesNumber() {
    let imagesNumberResult = this._galleryService.getImagesNumberByFormId(this.areaId.toUpperCase());
    if (imagesNumberResult == null) {
      imagesNumberResult = this.imagesNumber;
    }
    return imagesNumberResult;
  }

  public getImagesNumberInGallery(galleryId: string): void {
    this._apiRequestService.getImagesNumberInGallery(galleryId).subscribe((res: number) => {
      this.imagesNumber = res;
    });
  }

  public getEmptyArea() {
    if (this.parentAreaId) {
      this.currentArea = this._areaFormService.getEmptyArea();
    } else {
      this.currentArea = this._areaFormService.getEmptyAreaRoot();
    }
    this.savedArea = this._treeNavigationService.deepCopy(this.currentArea);
  }

  public showGallery(): void {
    this.isGalleryVisible = true;
  }

  public galleryClosed(imagesNumber: number): void {
    this.isGalleryVisible = false;
    this.imagesNumber = imagesNumber;
  }

  private checkDepartmentChangesBeforeShowAreaPopup() {
    const isChanged = this._areaFormService.formChanged(this.currentArea);
    if (isChanged) {
      this._treeNavigationService.exitFormYesFn = () => {
        this.currentArea = this.savedArea;
        this.areaPopupVisible = true;
        return true;
      };

      this._treeNavigationService.exitFormSpecialFn = () => {
        this.saveForm();
        this.areaPopupVisible = true;
        return true;
      };
      this._treeNavigationService.addAndGoAskToSaveChanges();
    } else {
      this.areaPopupVisible = true;
    }
  }

  private displayCreateAreaPopup(): void {
    this.areaPopupVisible = true;
  }

  public async toggleFinishedAudit() {
    const audit = await this._treeNavigationService.toggleFinishedAudit(this.currentAuditId);
    this.isAuditFinishedFlag = audit.isFinished;
    this._areaFormService.toggleFinishedAudit(this.isAuditFinishedFlag);
  }

  public saveForm() {
    this.saveArea(this.currentArea);
  }

  public selectedAction(e: any): void {
    switch (e.itemData.key) {
      case FormMenuActions.Save:
        this.saveForm();
        break;
      case FormMenuActions.Add:
        this.checkDepartmentChangesBeforeShowAreaPopup();
        break;
      case FormMenuActions.Delete:
        this.deleteArea(this.currentArea);
        break;
      case FormMenuActions.Gallery:
        this.showGallery();
        break;
      case FormMenuActions.ToggleFinishedAudit:
        this.toggleFinishedAudit();
      default:
        break;
    }
  }

  public generateReport(auditId: string): void {
    this._reportService.generateReport(auditId);
  }

  private updateButtonsText(screenSize: BreakpointState) {
    const isXSmall = screenSize.breakpoints[Breakpoints.XSmall];

    this.saveText = isXSmall ? '' : this._('button-save-text');
    this.galleryText = isXSmall ? '' : this._('button-gallery-text');
    this.addAreaText = isXSmall ? '' : this._('button-add-area');
    this.deleteAreaText = isXSmall ? '' : this._('button-delete-area');
  }

  public isChangesSaved(): boolean {
    return JSON.stringify(this.currentArea) === JSON.stringify(this.savedArea);
  }

  setActiveTab(path: string): void {
    switch (path) {
      case AppSettings.FORM_TAB:
        this.activeIndex = 0;
        break;
      case AppSettings.GALLERY_TAB:
        this.activeIndex = 1;
        this.showGallery();
        break;
      case AppSettings.SANKEY_TAB:
        this.activeIndex = 2;
        this.showEnergyBalance();
        break;
      default:
        this.activeIndex = 0;
    }
  }

  handleChange(e: any): void {
    const tabMap = [AppSettings.FORM_TAB, AppSettings.GALLERY_TAB, AppSettings.SANKEY_TAB];
    const path = tabMap[e.index];
    this._router.navigate([path], { relativeTo: this._route });
    this.setActiveTab(path);
  }

  public showEnergyBalance(): void {
    this.energyBalanceChart = true;
  }

  areaAddedToParentArea(deleteUnsavedChanges: boolean) {
    this.areaPopupVisible = false;
    if (deleteUnsavedChanges) {
      this.currentArea = this.savedArea;
    }
  }

  private createDeleteConfirmationMessage(response: any): string {
    let info = '';

    if (response.energyReceiversToDelete.length === 0 && response.childrenForms.length === 0) {
      info = this._('delete-area-no-areas-and-receivers-message');
      return info;
    }

    info = `<p>${this._('delete-area-warning-message')}</p>`;

    if (response.energyReceiversToDelete.length > 0) {
      const receiversListText = this._treeNavigationService.createHtmlList(response.energyReceiversToDelete);
      info += `<p>${this._('list-of-receivers')}</p> ${receiversListText}`;
    }

    if (response.childrenForms.length > 0) {
      const subformsListText = this._treeNavigationService.createHtmlList(response.childrenForms);
      info += `<p>${this._('list-of-subforms')}</p> ${subformsListText}`;
    }

    return info;
  }

  private onCreateArea(): void {
    this.areaFormPopup.addAreaAndStay(this.areaFormPopup.currentArea);
  }

  private onCreateAndDisplayArea(): void {
    this.areaFormPopup.addAreaAndGo(this.areaFormPopup.currentArea);
  }

  onCloseAreaCreation(e: CloseResult) {
    if (e === CloseResult.Confirm) {
      // exit
      this.areaPopupVisible = false;
      this.closeFromPopup = true;
    }

    this.createAreaExitPopupVisible = false;
  }

  onAreaCreationPopupHiding(e: any) {
    if (this.closeFromPopup === false) {
      const noChanges = this.areaFormPopup.triggerParentDeactivateCheck();
      if (!noChanges) {
        e.cancel = true;
        this.createAreaExitPopupVisible = true;
      }
    }
    this.closeFromPopup = false;
    this._areaFormService.createCopy(this.currentArea);
  }

  private closeFromPopup = false;
  public createAreaExitPopupVisible = false;
  public onDxoPopupShowing(e: any) {
    e.component.content().parentNode.parentNode.classList.add('custom-popup'); //add class to overlay content
  }

  deleteAreaPopupMessage: any;
  deleteAreaPopupVisible = false;
  onDeleteAreaPopupClose(e: any) {
    if (e === CloseResult.Confirm) {
      this.deleteAreaRequest(this.currentArea);
    }
    this.deleteAreaPopupVisible = false;
  }
}
