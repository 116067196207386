import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import * as saveAs from 'file-saver';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { AppSettings } from '../../AppSettings';
import { Gallery } from '../models/gallery.model';
import { Image } from '../models/image.model';

@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  private gallery: Gallery;
  private images: Array<Image> = [];
  private apiUrl: string;
  private auditId: string;

  private _formImagesMap: Map<string, number>;
  private _images = new BehaviorSubject<Array<Image>>([]);
  private _galleryId = new BehaviorSubject<string>('');
  private _auditGalleries = new BehaviorSubject<Array<Gallery>>([]);
  private _formIdNumberOfImagesMap = new BehaviorSubject<Map<string, number>>(new Map<string, number>());

  public formIdNumberOfImagesMap = this._formIdNumberOfImagesMap.asObservable();
  public imagesObserable = this._images.asObservable();
  public galleryId = this._galleryId.asObservable();
  public auditGalleries = this._auditGalleries.asObservable();
  public fetchAuditImageSubscription: Subscription;

  public selectedImagesIdsList: Array<string> = [];
  public selectedImagesChanged = new Subject<Array<string>>();
  public deletedImagesIdsList = new Subject<Array<string>>();
  public deselectAllImages = new Subject<boolean>();

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private _httpClient: HttpClient,
    private _appConfig: AppConfig
  ) {
    this.apiUrl = this._appConfig.getConfig('DbEnergyDatabaseUrl');
  }

  public getAuditId(): string {
    return this.auditId;
  }

  public getImagesNumberByFormId(formId: string) {
    if (this._formImagesMap) {
      return this._formImagesMap.get(formId);
    } else {
      return null;
    }
  }

  public updateImages(images: Array<Image>): void {
    this._images.next(images);
  }

  public updateGalleryId(id: string): void {
    this._galleryId.next(id);
  }

  public updateAuditGalleries(galleries: Array<Gallery>): void {
    this._auditGalleries.next(galleries);
    const map = new Map<string, number>();
    galleries.forEach((gallery) => {
      map.set(gallery.formId.toUpperCase(), gallery.images.length);
    });
    this._formImagesMap = map;
    this._formIdNumberOfImagesMap.next(this._formImagesMap);
  }

  public removeImagesFromView(idsList: Array<string>): void {
    this.deletedImagesIdsList.next(idsList);
    this.fetchAuditAndChildrenGalleries(this.auditId);
  }

  public fetchAuditAndChildrenGalleries(auditId: string): void {
    this.auditId = auditId;

    if (this.fetchAuditImageSubscription) {
      this.fetchAuditImageSubscription.unsubscribe();
    }
    this.fetchAuditImageSubscription = this._httpClient
      .get<Gallery[]>(`${this.apiUrl}api/galleries/form/${this.auditId}/images`)
      .subscribe((galleries) => {
        this.updateAuditGalleries(galleries);
      });
  }

  public fetchGallery(formId: string): void {
    this.getGalleryObserver(formId).subscribe((res) => {
      this.images = res;
      this.updateImages(res);
    });
  }

  public getGalleryObserver(formId: string): Observable<Image[]> {
    return this._httpClient.get<Gallery>(`${this.apiUrl}api/galleries/forms/${formId}`).pipe(
      tap((gallery: Gallery) => {
        this.gallery = gallery;
        this.updateGalleryId(gallery.id);
      }),
      switchMap((gallery: Gallery) => this.fetchImages(gallery.id))
    );
  }

  public fetchImages(galleryId: string): Observable<Array<Image>> {
    return this._httpClient.get<Array<Image>>(`${this.apiUrl}api/galleries/${galleryId}/images`);
  }

  public fetchImage(id: string): Observable<Image> {
    return this._httpClient.get<Image>(`${this.apiUrl}api/galleries/images/${id}`);
  }

  public addImageToCollection(id: string, images: Image[]): void {
    this.fetchImage(id).subscribe((res: Image) => {
      images.push(res);
      this.updateImages(images);
    });
  }

  public removeImage(id: string, images: Image[]): void {
    const removeIndex = images.map((item) => item.id).indexOf(id);

    ~removeIndex && images.splice(removeIndex, 1);
  }

  public uploadImage(e: any, images: Image[]): void {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size < AppSettings.MAX_IMAGE_SIZE) {
        const fileToUpload = e.target.files[0];
        const formData: FormData = new FormData();
        formData.append('fileKey', fileToUpload, fileToUpload.name);
        this._httpClient
          .post<string>(`${this.apiUrl}api/galleries/${this._galleryId.getValue()}/images`, formData)
          .subscribe((res) => this.addImageToCollection(res.replace(/[^a-zA-Z0-9-]/g, ''), images));
      }
    }
  }

  public createImageUrlById(id: string): string {
    return this.apiUrl + `api/galleries/images/${id}/file`;
  }

  public getImageThumbnailById(id: string): string {
    return this.apiUrl + `api/galleries/images/${id}/thumbnail`;
  }

  public changeImageLocation(imagesIds: string[], formId: string): Observable<any> {
    const body = {
      idsList: imagesIds,
      formId,
    };
    return this._httpClient.put<any>(`${this.apiUrl}api/galleries/images/form`, body, {
      headers: this.headers,
    });
  }

  public updateImage(image: Image): Observable<Image> {
    return this._httpClient.put<Image>(this.apiUrl + 'api/galleries/images', image);
  }

  public downloadOrginal(image: Image): void {
    this._httpClient
      .get(this.apiUrl + `api/galleries/images/${image.id}/orginal`, { responseType: 'blob' })
      .subscribe((res: Blob) => {
        saveAs(res, image.fileName);
        notify({
          message: `Pobrano zdjęcie.`,
          type: 'success',
          displayTime: AppSettings.NOTIFY_DURATION,
          position: 'top center',
        });
      });
  }

  public deleteImage(id: string): Observable<string> {
    return this._httpClient.delete<string>(`${this.apiUrl}api/galleries/images/${id}`);
  }

  public deleteSelectedImaged(idsList: Array<string>): Observable<Array<string>> {
    return this._httpClient.delete<Array<string>>(`${this.apiUrl}api/galleries/images/`, { body: idsList });
  }

  public updateSelectedImagesIncludeInReportStatus(idsList: Array<string>, status: boolean) {
    const body = {
      idsList,
      includeStatus: status,
    };
    return this._httpClient.put(`${this.apiUrl}api/galleries/images/include`, body, {
      headers: this.headers,
    });
  }

  public clearSelectedImagesList(): void {
    this.selectedImagesIdsList = [];
    this.selectedImagesChanged.next(this.selectedImagesIdsList);
  }

  public onImageSelected(id: string): void {
    if (this.selectedImagesIdsList.indexOf(id) === -1) {
      this.selectedImagesIdsList.push(id);
    }
    this.selectedImagesChanged.next(this.selectedImagesIdsList);
  }

  public onImageDeSelected(id: string): void {
    const index = this.selectedImagesIdsList.indexOf(id, 0);
    if (index > -1) {
      this.selectedImagesIdsList.splice(index, 1);
    }
    this.selectedImagesChanged.next(this.selectedImagesIdsList);
  }

  public getFormIdDisableToMove(imagesIds: string[]): string {
    const imagesFormDicitonary = this.convertAuditGalleriesArrayToImageFormDictionary();
    const uniqueFormIds = new Set<string>();

    imagesIds.forEach((id) => {
      uniqueFormIds.add(imagesFormDicitonary.get(id.toUpperCase()));
    });

    if (uniqueFormIds.size !== 1) {
      return '';
    }

    if (uniqueFormIds.size === 1) {
      return Array.from(uniqueFormIds)[0];
    }
    return '';
  }

  private convertAuditGalleriesArrayToImageFormDictionary(): Map<string, string> {
    const imagesFormDicitonary = new Map();

    this._auditGalleries.getValue().forEach((gallery) => {
      gallery.images.forEach((image) => {
        imagesFormDicitonary.set(image.id.toUpperCase(), gallery.formId.toUpperCase());
      });
    });

    return imagesFormDicitonary;
  }

  public deselect(): void {
    this.clearSelectedImagesList();
    this.deselectAllImages.next(true);
  }

  public showSuccessNotify(text: string): void {
    notify({
      message: text,
      type: 'success',
      displayTime: AppSettings.NOTIFY_DURATION,
      position: 'top center',
    });
  }
}
