import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuditType } from '../models/audit/audit-type.model';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  formTypeUpdated: Subject<string> = new Subject<string>();

  private _auditType: AuditType = null;
  private _formCode: string = null;
  private _formType: string = null;

  constructor() {}

  public setAuditType(auditType: AuditType): void {
    this._auditType = auditType;
  }

  public getAuditType(): AuditType {
    return this._auditType;
  }

  public setFormCode(formCode: string): void {
    this._formCode = formCode;
  }

  public getFormCode(): string {
    return this._formCode;
  }

  public setFormType(formType: string): void {
    this._formType = formType;
    this.formTypeUpdated.next(this._formType);
  }

  public getFormType(): string {
    return this._formType;
  }
}
