<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-button
  class="outlined-button-focus-hover field-doc-variable-button"
  icon="copy"
  [style.float]="position"
  [text]="text"
  [copyToClipboard]="text"
  [specialVariable]="specialVariable"
  [hint]="tooltipText"
  stylingMode="outlined"
></dx-button>
