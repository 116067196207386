<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div *ngIf="currentDepartment">
  <h1
    *ngIf="currentDepartment && existingForm"
    class="form-header-margin-top form-header-margin-bottom"
  >
    {{ currentDepartment.name }}
  </h1>
  <p-tabView
    id="tabview"
    [activeIndex]="activeIndex"
    (onChange)="handleChange($event)"
    *ngIf="currentDepartment && existingForm"
  >
    <p-tabPanel [header]="_('button-form-text')">
      <ng-template [ngTemplateOutlet]="form"></ng-template>
    </p-tabPanel>
    <p-tabPanel header="{{ _('button-gallery-text') }} ({{ getImagesNumber() }})">
      <app-audit-gallery
        [formId]="departmentId"
        [auditId]="currentAuditId"
        *ngIf="isGalleryVisible"
        [isAuditFinished]="isAuditFinishedFlag"
      ></app-audit-gallery>
    </p-tabPanel>
    <p-tabPanel [header]="_('button-sankey-text')">
      <app-energy-balance-chart
        *ngIf="energyBalanceChart"
        [auditId]="currentAuditId"
      ></app-energy-balance-chart>
      <app-energy-balance
        *ngIf="currentDepartment && existingForm"
        [auditId]="currentAuditId"
        [energyBalanceId]="this.currentDepartment.energyBalance.id"
        [assignedToFormId]="this.currentDepartmentId"
        [isEnergyBalanceDisabled]="isAuditFinishedFlag"
      >
      </app-energy-balance>
    </p-tabPanel>
  </p-tabView>

  <ng-template
    *ngIf="currentDepartment && !existingForm"
    [ngTemplateOutlet]="form"
  ></ng-template>

  <app-custom-popup
    *ngIf="currentDepartmentId && deleteDepartmentPopup && !templateMode"
    [message]="deleteDepartmentMessage"
    [name]="deleteDepartmentMessage.name"
    [type]="deleteDepartmentMessage.type"
    [receiversToDeleteList]="deleteDepartmentMessage.energyReceiversToDelete"
    [receiversBlockingDeletionList]="deleteDepartmentMessage.energyReceiversBlockingDeletion"
    [formsList]="deleteDepartmentMessage.childrenForms"
    (popupClose)="popupClosed($event)"
  >
  </app-custom-popup>
</div>

<dx-popup
  *ngIf="departmentFetched && currentDepartment && existingForm && createAreaPopupVisible"
  [title]="_('create-area')"
  [fullScreen]="false"
  maxWidth="70rem"
  [height]="popupHeight + '%'"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="createAreaPopupVisible"
  (onHiding)="onAreaCreationPopupHiding($event)"
  (onShown)="onAreaCreationPopupShown($event)"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <dx-scroll-view
    width="100%"
    height="100%"
  >
    <div class="forms">
      <app-area-form
        #areaForm
        [departmentId]="currentDepartment.id"
        [existingForm]="false"
        [departmentChangesIsSaved]="isChangesSaved()"
        (areaAdded)="areaAdded($event)"
      ></app-area-form>
    </div>
  </dx-scroll-view>

  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    cssClass="button-rounded-regular toolbar-button-focus-border"
    [options]="createButtonOptions"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    cssClass="toolbar-contained-button-focus-border button-contained button-case-sensitive"
    [options]="createAndDisplayButtonOptions"
  >
  </dxi-toolbar-item>
</dx-popup>

<app-questionnaire-popup
  *ngIf="departmentFetched && questionnairePopupVisible && currentDepartmentId && currentDepartment"
  [currentAuditId]="currentAuditId"
  [currentDepartmentId]="currentDepartmentId"
  [enterpriseId]="currentDepartment.enterpriseId"
  [currentDepartmentName]="currentDepartment.name"
  [popupWidth]="popupWidth"
  [popupHeight]="popupHeight"
  [(questionnairePopupVisible)]="questionnairePopupVisible"
  [isAuditFinished]="isAuditFinishedFlag"
>
</app-questionnaire-popup>

<app-exit-confirmation-popup
  [popupVisible]="createAreaExitPopupVisible"
  [confirmationNo]="_('No')"
  [confirmationYes]="_('Yes')"
  [title]="_('unsaved-changes-title')"
  [message]="_('unsaved-changes-message')"
  (closeEvent)="onCloseAreaCreation($event)"
></app-exit-confirmation-popup>

<app-exit-confirmation-popup
  [popupVisible]="deleteDepartmentPopupVisible"
  [confirmationNo]="_('No')"
  [confirmationYes]="_('Yes')"
  [title]="_('delete-department-confirm-title')"
  (closeEvent)="onDeleteDepartmentPopupClose($event)"
  customContent="true"
>
  <ng-template appExitConfirmationPopupContent>
    <div [innerHTML]="deleteDepartmentPopupMessage"></div>
  </ng-template>
</app-exit-confirmation-popup>

<ng-template #form>
  <dx-form
    *ngIf="currentDepartment"
    id="department-form"
    [showColonAfterLabel]="true"
    validationGroup="departmentForm"
    [disabled]="isChangingDisabled || isAuditFinished()"
  >
    <dxi-item>
      <h3 class="form-header-margin">{{ _('general') }}</h3>
    </dxi-item>
    <dxi-item>
      <dxi-validation-rule
        type="required"
        [message]="_('department-name-required')"
      ></dxi-validation-rule>
      <div *dxTemplate>
        <dx-text-box
          [disabled]="templateMode"
          [(value)]="currentDepartment.name"
          stylingMode="outlined"
        >
        </dx-text-box>
        <div *ngIf="templateMode">
          <app-field-doc-variable [text]="this.docVariableCode + 'Name'"></app-field-doc-variable>
        </div>
      </div>
      <dxo-label [text]="_('department-name')"></dxo-label>
    </dxi-item>

    <dxi-item>
      <div *dxTemplate>
        <dx-text-area
          [disabled]="templateMode"
          height="100"
          [(value)]="currentDepartment.description"
          stylingMode="outlined"
        >
        </dx-text-area>
        <div *ngIf="templateMode">
          <app-field-doc-variable [text]="this.docVariableCode + 'Description'"></app-field-doc-variable>
        </div>
      </div>
      <dxo-label [text]="_('department-description')"></dxo-label>
    </dxi-item>

    <dxi-item>
      <div *dxTemplate>
        <dx-text-area
          [disabled]="templateMode"
          height="100"
          [(value)]="currentDepartment.monitoringSystemDescription"
          stylingMode="outlined"
        >
        </dx-text-area>
        <div *ngIf="templateMode">
          <app-field-doc-variable
            [text]="this.docVariableCode + 'MonitoringSystemDescription'"
          ></app-field-doc-variable>
        </div>
      </div>
      <dxo-label [text]="_('department-energy-monitoring-description')"></dxo-label>
    </dxi-item>

    <dxi-item
      itemType="group"
      cssClass="georama-font"
      [caption]="_('address-details')"
    >
      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            [disabled]="templateMode"
            [(value)]="currentDepartment.address.country"
            stylingMode="outlined"
          >
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'Country'"></app-field-doc-variable>
          </div>
        </div>
        <dxo-label [text]="_('department-country')"></dxo-label>
      </dxi-item>

      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            #zipCodeTextBox
            [(value)]="currentDepartment.address.zipCode"
            [disabled]="templateMode"
            stylingMode="outlined"
          >
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'ZipCode'"></app-field-doc-variable>
          </div>
        </div>
        <dxo-label [text]="_('department-post-code')"></dxo-label>
      </dxi-item>

      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            [disabled]="templateMode"
            [(value)]="currentDepartment.address.address"
            stylingMode="outlined"
          >
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'Address'"></app-field-doc-variable>
          </div>
        </div>
        <dxo-label [text]="_('department-address')"></dxo-label>
      </dxi-item>
    </dxi-item>

    <dxi-item
      *ngIf="currentDepartment && existingForm"
      itemType="group"
      cssClass="georama-font"
      [caption]="_('production-brakedown')"
    >
      <div *dxTemplate>
        <dx-data-grid
          #productionDataGrid
          id="gridContainer"
          [dataSource]="productionListDataSource"
          [allowColumnReordering]="true"
          [showBorders]="true"
          [noDataText]="_('production-brakedown-no-data-text')"
          (onInitNewRow)="getUnitTypes()"
        >
          <dxo-paging [enabled]="true"></dxo-paging>
          <dxo-editing
            mode="popup"
            [allowUpdating]="true"
            [allowDeleting]="true"
            [allowAdding]="true"
          >
            <dxo-popup
              [title]="_('production-details')"
              showTitle="true"
              [fullScreen]="false"
              maxWidth="70rem"
              [height]="popupHeight + '%'"
              hideOnOutsideClick="true"
              showCloseButton="true"
              (onShown)="getUnitTypes()"
              [onShowing]="onDxoPopupShowing"
            >
              <dxi-toolbar-item
                widget="dxButton"
                toolbar="bottom"
                location="after"
                cssClass="item-contained-button-focus-hover"
                [options]="saveProductionButtonOptions"
              >
              </dxi-toolbar-item>
            </dxo-popup>

            <dxo-form
              [showColonAfterLabel]="true"
              labelLocation="top"
            >
              <dxi-item
                dataField="name"
                caption="Nazwa"
                editorType="dxTextBox"
                [editorOptions]="{
                  stylingMode: 'outlined',
                }"
              >
              </dxi-item>
              <dxi-item
                dataField="amount"
                editorType="dxNumberBox"
                [editorOptions]="{
                  stylingMode: 'outlined',
                }"
              >
              </dxi-item>
              <dxi-item
                dataField="unitId"
                [editorOptions]="{
                  stylingMode: 'outlined',
                  placeholder: this._('unit-select-placeholder'),
                }"
              ></dxi-item>
            </dxo-form>
            <dxo-texts
              [deleteRow]="this._('production-breakdown-delete-hint')"
              [addRow]="this._('production-breakdown-add-hint')"
              [editRow]="this._('production-breakdown-edit-hint')"
              [confirmDeleteMessage]="this._('production-breakdown-delete-confirm-message')"
              [saveRowChanges]="this._('production-breakdown-save-hint')"
              [cancelRowChanges]="this._('production-breakdown-cancel-row-changes-message')"
            >
            </dxo-texts>
          </dxo-editing>

          <dxi-column
            dataField="name"
            [caption]="_('department-name')"
          ></dxi-column>
          <dxi-column
            dataField="amount"
            [caption]="_('department-number')"
          ></dxi-column>
          <dxi-column
            dataField="unitId"
            [caption]="_('department-unit')"
          >
            <dxo-lookup
              [dataSource]="unitTypes"
              displayExpr="name"
              valueExpr="id"
            >
            </dxo-lookup>
          </dxi-column>
        </dx-data-grid>
      </div>
    </dxi-item>

    <dxi-item
      itemType="group"
      cssClass="georama-font"
      [caption]="_('contact-details')"
    >
      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            [disabled]="templateMode"
            [(value)]="currentDepartment.contactPerson.firstName"
            stylingMode="outlined"
          >
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'ContactPersonFirstName'"></app-field-doc-variable>
          </div>
        </div>
        <dxo-label [text]="_('department-contact-person')"></dxo-label>
      </dxi-item>

      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            [(value)]="currentDepartment.contactPerson.phoneNumber"
            stylingMode="outlined"
            [disabled]="templateMode"
            mode="tel"
          >
            <dx-validator>
              <dxi-validation-rule
                type="pattern"
                [pattern]="phonePattern"
                [message]="_('department-contact-person-phone-invalid')"
              ></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'ContactPersonPhone'"></app-field-doc-variable>
          </div>
        </div>
        <dxo-label [text]="_('department-contact-person-phone')"></dxo-label>
      </dxi-item>

      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            [(value)]="currentDepartment.contactPerson.email"
            [disabled]="templateMode"
            stylingMode="outlined"
            mode="email"
          >
            <dx-validator>
              <dxi-validation-rule type="email"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'ContactPersonEmail'"></app-field-doc-variable>
          </div>
        </div>
        <dxo-label [text]="_('department-contact-person-email')"></dxo-label>
      </dxi-item>
    </dxi-item>

    <dxi-item *ngIf="docVariableCode">
      <div *dxTemplate>
        <app-add-template [areaTypeCode]="this.docVariableCode"></app-add-template>
      </div>
    </dxi-item>

    <dxi-item *ngIf="docVariableCode">
      <div *dxTemplate>
        <app-add-template [areaTypeCode]="this.docVariableCode"></app-add-template>
      </div>
    </dxi-item>
  </dx-form>
</ng-template>
