<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item *ngIf="places">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="heatRecovery.heatRecoveryPlace"
        [dataSource]="places"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('heat-recovery-heat-recovery-place-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('heat-recovery-heat-recovery-place')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="types">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="heatRecovery.heatRecoveryType"
        [dataSource]="types"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('heat-recovery-heat-recovery-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('heat-recovery-heat-recovery-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="heatRecovery.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('heat-recovery-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="heatRecovery.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('heat-recovery-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="heatRecovery.serialNumber"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('heat-recovery-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="heatRecovery.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-recovery-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="heatRecovery.ratedPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-recovery-rated-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' Pa'"
        [(value)]="heatRecovery.pressureLossInitialSide"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-recovery-pressure-loss-initial-side')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' Pa'"
        [(value)]="heatRecovery.pressureLossSecondarySide"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-recovery-pressure-loss-secondary-side')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="heatRecoveryMediumTypes">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="heatRecovery.recoveryMediumType"
        [dataSource]="heatRecoveryMediumTypes"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('heat-recovery-recovery-medium-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('heat-recovery-recovery-medium-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="heatRecovery.tempMediumBeforeRecovery"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-recovery-temp-medium-before-recovery')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="heatRecovery.tempMediumAfterRecovery"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-recovery-temp-medium-after-recovery')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="mediumTypes">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="heatRecovery.mediumType"
        [dataSource]="mediumTypes"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('heat-recovery-medium-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('heat-recovery-medium-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="heatRecovery.mediumTempBefore"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-recovery-medium-temp-before')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="heatRecovery.mediumTempAfter"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-recovery-medium-temp-after')"></dxo-label>
  </dxi-item>
</dx-form>
