<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form
  [showColonAfterLabel]="true"
  *ngIf="powerGenerator"
>
  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="powerGenerator.producer"
        stylingMode="outlined"
        [disabled]="templateMode"
      >
      </dx-text-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'Producer'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('power-generator-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="powerGenerator.model"
        stylingMode="outlined"
        [disabled]="templateMode"
      >
      </dx-text-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'Model'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('power-generator-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="powerGenerator.serialNumber"
        stylingMode="outlined"
        [disabled]="templateMode"
      >
      </dx-text-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'SerialNumber'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('power-generator-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="powerGenerator.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'ProductionYear'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('power-generator-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kVA'"
        [(value)]="powerGenerator.power"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'Power'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('power-generator-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-select-box
        [(value)]="powerGenerator.fuelType"
        [dataSource]="fuelTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('power-generator-fuel-type-placeholder')"
        [disabled]="templateMode"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'FuelType'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('power-generator-fuel-type')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="templateMode">
    <div *dxTemplate>
      <app-add-template [areaTypeCode]="this.docVariableCode"></app-add-template>
    </div>
  </dxi-item>
</dx-form>
