import { Localizable } from '../../../../locale/localizable';
import { QuestionnaireService } from '../questionnaire.service';
import { Question } from './questions.models';

export class NoteQuestion extends Localizable {
  noteShown = false;

  constructor(
    protected _questionnaireService: QuestionnaireService,
    dictionary: string
  ) {
    super(dictionary);
  }

  public addQuestionNote() {
    this.noteShown = true;
  }

  public removeQuestionNote(question: Question) {
    this.noteShown = false;
    question.note = '';

    // remove question note
    this._questionnaireService.removeQuestionNote(question);
  }

  public saveQuestionNote(question: Question) {
    // set question note
    this._questionnaireService.addQuestionNote(question);
  }

  public fillNotes(question: Question): void {
    // just decide if we need to show notes
    if (question.note != null) {
      this.noteShown = true;
    }
  }
}
