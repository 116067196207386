<!-- Copyright C Alfa-Net. All Rights Reserved. -->

<div *ngIf="currentAudit">
  <h1
    *ngIf="currentAudit && auditId"
    class="no-bottom-margin form-header-margin-top"
  >
    {{ currentAudit.name }}
  </h1>
  <p
    *ngIf="currentAudit && auditId"
    class="form-header-margin bold form-created-at-height"
  >
    {{ _('audit-creation-at') }}:
    {{ currentAudit.createdAt | date: 'yyyy-MM-dd' }}
  </p>
  <p-tabView
    id="tabview"
    [activeIndex]="activeIndex"
    (onChange)="handleChange($event)"
    *ngIf="currentAudit && auditId"
  >
    <p-tabPanel [header]="_('button-form-text')">
      <ng-template [ngTemplateOutlet]="form"></ng-template>
    </p-tabPanel>
    <p-tabPanel header="{{ _('button-gallery-text') }} ({{ getImagesNumber() }})">
      <app-audit-gallery
        [formId]="auditId"
        [auditId]="auditId"
        [isAuditFinished]="isAuditFinishedFlag"
        *ngIf="isGalleryVisible"
      ></app-audit-gallery>
    </p-tabPanel>
    <p-tabPanel [header]="_('button-sankey-text')">
      <app-energy-balance-chart
        *ngIf="isEnergyBalanceVisible"
        [auditId]="auditId"
      ></app-energy-balance-chart>
    </p-tabPanel>
  </p-tabView>

  <ng-template
    *ngIf="currentAudit && !auditId"
    [ngTemplateOutlet]="form"
  ></ng-template>

  <dx-popup
    *ngIf="showMissedTemplates"
    title="{{ _('missing-template-types') }}"
    [width]="(isMobile ? popupWidth * 0.9 : 50) + '%'"
    [height]="(isMobile ? popupHeight * 0.9 : 50) + '%'"
    [fullScreen]="false"
    [showTitle]="true"
    [dragEnabled]="false"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="showMissedTemplates"
    [wrapperAttr]="{ class: 'custom-popup' }"
  >
    <dx-scroll-view
      width="100%"
      height="100%"
    >
      <app-missing-templates
        [canGenerate]="canGenerateReport"
        [missingTemplatesList]="missingTemplates"
        (generateReport)="generateReport(this.auditId)"
      ></app-missing-templates>
    </dx-scroll-view>
  </dx-popup>
</div>

<ng-template #form>
  <dx-form
    *ngIf="currentAudit"
    id="audit-form"
    [showColonAfterLabel]="true"
    validationGroup="auditData"
    [disabled]="isChangingDisabled || isAuditFinishedFlag"
  >
    <dxi-item>
      <h3 class="form-header-margin">{{ _('general') }}</h3>
    </dxi-item>
    <dxi-item
      *ngIf="dataAuditTypes"
      dataField="{{ _('audit-type') }}"
    >
      <dxi-validation-rule
        type="required"
        message="{{ _('audit-type-required-message') }}"
      >
        <div *dxTemplate>
          <dx-select-box
            displayExpr="name"
            showClearButton="true"
            [placeholder]="_('audit-type-select')"
            stylingMode="outlined"
            [dataSource]="dataAuditTypes"
            [(value)]="currentAudit.auditType"
            [disabled]="templateMode"
            [searchEnabled]="true"
            searchMode="contains"
          >
          </dx-select-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'Type'"></app-field-doc-variable>
          </div>
        </div>
      </dxi-validation-rule>
    </dxi-item>

    <dxi-item dataField="{{ _('audit-name') }}">
      <dxi-validation-rule
        type="required"
        message="{{ _('audit-name-required-message') }}"
      >
        <div *dxTemplate>
          <dx-text-box
            [(value)]="currentAudit.name"
            stylingMode="outlined"
            [disabled]="templateMode"
          >
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'Name'"></app-field-doc-variable>
          </div>
        </div>
      </dxi-validation-rule>
    </dxi-item>

    <dxi-item dataField="{{ _('currency') }}">
      <dxi-validation-rule
        type="required"
        message="{{ _('currecny-required-message') }}"
      >
        <div *dxTemplate>
          <dx-select-box
            [displayExpr]="getDisplayExpr"
            showClearButton="true"
            placeholder="{{ _('currency-select-audit') }}"
            stylingMode="outlined"
            [dataSource]="currencies"
            [(value)]="currentAudit.currency"
            [disabled]="templateMode"
            [searchEnabled]="true"
            searchMode="contains"
          >
          </dx-select-box>

          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'Currency'"></app-field-doc-variable>
          </div>
        </div>
      </dxi-validation-rule>
    </dxi-item>

    <dxi-item
      dataField="{{ _('enterprise-name') }}"
      *ngIf="!templateMode && !auditId"
    >
      <div *dxTemplate>
        <dx-text-box
          [(value)]="currentAudit.enterpriseName"
          stylingMode="outlined"
        >
        </dx-text-box>
      </div>
    </dxi-item>

    <dxi-item dataField="{{ _('audit-purpose') }}">
      <div *dxTemplate>
        <dx-text-area
          height="140"
          [(value)]="currentAudit.goal"
          stylingMode="outlined"
          [disabled]="templateMode"
        ></dx-text-area>
        <div *ngIf="templateMode">
          <app-field-doc-variable [text]="this.docVariableCode + 'Goal'"></app-field-doc-variable>
        </div>
      </div>
    </dxi-item>

    <dxi-item dataField="{{ _('audit-customer-guidelindes') }}">
      <div *dxTemplate>
        <dx-text-area
          height="140"
          [(value)]="currentAudit.clientGuidelines"
          stylingMode="outlined"
          [disabled]="templateMode"
        ></dx-text-area>
        <div *ngIf="templateMode">
          <app-field-doc-variable [text]="this.docVariableCode + 'ClientGuidelines'"></app-field-doc-variable>
        </div>
      </div>
    </dxi-item>

    <dxi-item dataField="{{ _('audit-execution-date') }}">
      <div *dxTemplate>
        <dx-date-box
          type="date"
          showClearButton="true"
          openOnFieldClick="true"
          [showDropDownButton]="false"
          displayFormat="yyyy-MM-dd"
          placeholder="{{ _('audit-execution-date-placeholder') }}"
          invalidDateMessage="{{ _('invalid-format-message', [_('audit-execution-date'), _('date-format')]) }}"
          stylingMode="outlined"
          [(value)]="currentAudit.date"
          [disabled]="templateMode"
        >
        </dx-date-box>
        <div *ngIf="templateMode">
          <app-field-doc-variable [text]="this.docVariableCode + 'Date'"></app-field-doc-variable>
        </div>
      </div>
    </dxi-item>

    <dxi-item dataField="{{ _('audit-investment-budget') }}">
      <div *dxTemplate>
        <dx-number-box
          min="0"
          max="10000000"
          showClearButton="true"
          [(value)]="currentAudit.cost"
          stylingMode="outlined"
          [disabled]="templateMode"
          [format]="fullNumberFormat"
          step="0"
        ></dx-number-box>
        <div *ngIf="templateMode">
          <app-field-doc-variable [text]="this.docVariableCode + 'Cost'"></app-field-doc-variable>
        </div>
      </div>
    </dxi-item>

    <dxi-item dataField="{{ _('audit-SPBT') }}">
      <div *dxTemplate>
        <dx-number-box
          min="0"
          max="10000000"
          showClearButton="true"
          [(value)]="currentAudit.spbt"
          stylingMode="outlined"
          [disabled]="templateMode"
          [format]="fullNumberFormat"
          step="0"
        ></dx-number-box>
        <div *ngIf="templateMode">
          <app-field-doc-variable [text]="this.docVariableCode + 'SPBT'"></app-field-doc-variable>
        </div>
      </div>
    </dxi-item>

    <dxi-item dataField="{{ _('audit-IRR') }}">
      <div *dxTemplate>
        <dx-number-box
          min="0"
          max="10000000"
          showClearButton="true"
          [(value)]="currentAudit.irr"
          stylingMode="outlined"
          [disabled]="templateMode"
          [format]="fullNumberFormat"
          step="0"
        ></dx-number-box>
        <div *ngIf="templateMode">
          <app-field-doc-variable [text]="this.docVariableCode + 'IRR'"></app-field-doc-variable>
        </div>
      </div>
    </dxi-item>

    <dxi-item dataField="{{ _('audit-range') }}">
      <div
        *dxTemplate
        class="icon-beneath"
      >
        <dxi-item>
          <dx-check-box
            [(value)]="currentAudit.auditRange.audit"
            text="{{ _('audit') }}"
            [disabled]="templateMode"
          ></dx-check-box>
        </dxi-item>
        <dxi-item>
          <dx-check-box
            [(value)]="currentAudit.auditRange.optimizationDesign"
            text="{{ _('optimization-design') }}"
            [disabled]="templateMode"
          ></dx-check-box>
        </dxi-item>
        <dxi-item>
          <dx-check-box
            [(value)]="currentAudit.auditRange.implementation"
            text="{{ _('implementation') }}"
            [disabled]="templateMode"
          ></dx-check-box>
        </dxi-item>
      </div>
    </dxi-item>

    <dxi-item dataField="{{ _('audit-analysis-from-to-date') }}">
      <div *dxTemplate>
        <dx-date-range-box
          openOnFieldClick="true"
          startDateLabel="{{ _('audit-from') }}:"
          endDateLabel="{{ _('audit-to') }}:"
          stylingMode="outlined"
          labelMode="static"
          [(startDate)]="currentAudit.fromDate"
          [(endDate)]="currentAudit.toDate"
          displayFormat="yyyy-MM-dd"
          [showClearButton]="true"
          [disabled]="templateMode"
          [showDropDownButton]="false"
          invalidStartDateMessage="{{ _('invalid-format-message', [_('audit-from'), _('date-format')]) }}"
          invalidEndDateMessage="{{ _('invalid-format-message', [_('audit-to'), _('date-format')]) }}"
          startDateOutOfRangeMessage="{{ _('out-of-range', _('audit-from')) }}"
          endDateOutOfRangeMessage="{{ _('out-of-range', _('audit-to')) }}"
          [dropDownOptions]="dropDownOptions"
        >
        </dx-date-range-box>

        <div *ngIf="templateMode">
          <app-field-doc-variable [text]="this.docVariableCode + 'FromDate'"></app-field-doc-variable>
          <app-field-doc-variable [text]="this.docVariableCode + 'ToDate'"></app-field-doc-variable>
        </div>
      </div>
    </dxi-item>
    <dxi-item
      itemType="group"
      cssClass="georama-font"
      [caption]="_('contact-details')"
    >
      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            [disabled]="templateMode"
            [(value)]="currentAudit.contactPerson.firstName"
            stylingMode="outlined"
          >
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'ContactPersonFirstName'"></app-field-doc-variable>
          </div>
        </div>
        <dxo-label [text]="_('audit-contact-person')"></dxo-label>
      </dxi-item>

      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            [(value)]="currentAudit.contactPerson.phoneNumber"
            stylingMode="outlined"
            [disabled]="templateMode"
            mode="tel"
          >
          <dx-validator>
            <dxi-validation-rule
              type="pattern"
              [message]="_('audit-contact-person-phone-invalid')"
              [pattern]="phonePattern"
            ></dxi-validation-rule>
          </dx-validator>
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'ContactPersonPhone'"></app-field-doc-variable>
          </div>
        </div>
        <dxo-label [text]="_('audit-contact-person-phone')"></dxo-label>
      </dxi-item>

      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            [(value)]="currentAudit.contactPerson.email"
            [disabled]="templateMode"
            stylingMode="outlined"
          >
            <dx-validator>
              <dxi-validation-rule type="email"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'ContactPersonEmail'"></app-field-doc-variable>
          </div>
        </div>
        <dxo-label [text]="_('audit-contact-person-email')"></dxo-label>
      </dxi-item>
    </dxi-item>

    <dxi-item
      *ngIf="currentAudit && sourceUsers"
      [colSpan]="1"
      dataField="{{ _('audit-users') }}"
    >
      <div *dxTemplate>
        <dx-tag-box
          id="tagBox"
          [displayExpr]="customUsersDisplayExpr"
          valueExpr="id"
          [dataSource]="sourceUsers"
          [value]="auditUsers"
          placeholder="{{ _('audit-select-users') }}"
          stylingMode="outlined"
          [disabled]="templateMode"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          searchMode="contains"
          (onValueChanged)="onTagBoxValueChanged($event)"
        >
        </dx-tag-box>
        <div *ngIf="templateMode">
          <app-field-doc-variable [text]="this.docVariableCode + 'Users'"></app-field-doc-variable>
        </div>
      </div>
    </dxi-item>

    <dxi-item *ngIf="docVariableCode">
      <div *dxTemplate>
        <app-add-template [areaTypeCode]="this.docVariableCode"></app-add-template>
      </div>
    </dxi-item>
  </dx-form>
</ng-template>

<app-exit-confirmation-popup
  [popupVisible]="deleteAuditPopupVisible"
  [confirmationNo]="_('No')"
  [confirmationYes]="_('Yes')"
  [message]="_('audit-delete-confirm-message')"
  [title]="_('audit-delete-confirm-title')"
  (closeEvent)="onDeleteAuditPopupClose($event)"
>
</app-exit-confirmation-popup>
