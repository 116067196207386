import { Injectable } from '@angular/core';
import { User } from 'src/app/shared/models/user.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Injectable({
  providedIn: 'root',
})
export class UserFormService {
  constructor(private apiRequestService: ApiRequestService) {}

  public placeHolderAcademicTitles(): string[] {
    const titles = ['Profesor', 'Doktor', 'Magister', 'Inżynier', 'Licienjat'];

    return titles;
  }

  public placeHolderPositionTypes(): string[] {
    const types = ['Stanowisko1', 'Stanowisko2', 'Stanowisko3'];

    return types;
  }

  public placeHolderPermissionLevels(): string[] {
    const levels: string[] = ['Poziom1', 'Poziom2', 'Poziom3'];

    return levels;
  }

  public getEmptyUser(): User {
    const user: User = {
      firstName: '',
      lastName: '',
      email: '',
    };

    return user;
  }

  public save(user: User): void {
    this.apiRequestService.saveUser(user).subscribe();
  }
}
