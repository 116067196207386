import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import {
  AirCompressor,
  CompressorType,
  EfficiencyRegulationType,
  EntityTypeAirCompressor,
} from 'src/app/shared/models/area/types/air-compressor';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-air-compressor',
  templateUrl: './air-compressor.component.html',
  styleUrls: ['./air-compressor.component.scss'],
})
export class AirCompressorComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  airCompressor: AirCompressor = null;

  airCompressorTypesList: ArrayStore;
  entityTypeAirCompressorsList: ArrayStore;
  efficiencyRegulationTypesList: ArrayStore;
  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getAirCompressorTypes().subscribe((res: Array<CompressorType>) => {
      this.airCompressorTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService
      .getAirCompressorEntityTypeAirCompressor()
      .subscribe((res: Array<EntityTypeAirCompressor>) => {
        this.entityTypeAirCompressorsList = new ArrayStore({
          key: 'id',
          data: res,
        });
      });

    this._apiRequestService
      .getAirCompressorEfficiencyRegulationTypes()
      .subscribe((res: Array<EfficiencyRegulationType>) => {
        this.efficiencyRegulationTypesList = new ArrayStore({
          key: 'id',
          data: res,
        });
      });
  }
}
