<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="fanCooler.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('fan-cooler-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="fanCooler.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('fan-cooler-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="fanCooler.serialNumber"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('fan-cooler-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="mediumTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="fanCooler.refrigeratedMedium"
        [dataSource]="mediumTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('fan-cooler-refrigerated-medium-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('fan-cooler-refrigerated-medium')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³/h'"
        [(value)]="fanCooler.refrigerantFlow"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-cooler-refrigerant-flow')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="fanCooler.numberOfFans"
        stylingMode="outlined"
        [min]="0"
        [max]="100"
        [step]="1"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-cooler-number-of-fans')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="fanCooler.fansPower"
        stylingMode="outlined"
        [min]="0"
        [max]="1000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-cooler-fans-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="fanCooler.numberOfPumps"
        stylingMode="outlined"
        [min]="0"
        [max]="100"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-cooler-number-of-pumps')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="fanCooler.pumpsPower"
        stylingMode="outlined"
        [min]="0"
        [max]="1000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-cooler-pumps-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' ℃'"
        [(value)]="fanCooler.inputRefrigerantTemp"
        stylingMode="outlined"
        [min]="0"
        [max]="1000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-cooler-input-refrigerant-temp')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' ℃'"
        [(value)]="fanCooler.outputRefrigerantTemp"
        stylingMode="outlined"
        [min]="0"
        [max]="1000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-cooler-output-refrigerant-temp')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="fanCooler.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-cooler-working-hours-in-year')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="controlSystemTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="fanCooler.fanCoolerControl"
        [dataSource]="controlSystemTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('fan-cooler-fan-cooler-control-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('fan-cooler-fan-cooler-control')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="fanCooler.enoughPower"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('fan-cooler-enough-power')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="technicalConditionsList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="fanCooler.technicalCondition"
        [dataSource]="technicalConditionsList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('fan-cooler-technical-condition-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('fan-cooler-technical-condition')"></dxo-label>
  </dxi-item>
</dx-form>
