<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="airCurtain.numberOfDevices"
        stylingMode="outlined"
        [min]="0"
        [max]="100"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-curtain-number-of-devices')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="airCurtain.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('air-curtain-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="airCurtain.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('air-curtain-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' '"
        [(value)]="airCurtain.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-curtain-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="airCurtain.serialNumber"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('air-curtain-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item
    *ngIf="airCurtainTypesList"
    dataField="Typ kurtyny"
  >
    <div *dxTemplate>
      <dx-select-box
        [(value)]="airCurtain.airCurtainType"
        [dataSource]="airCurtainTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('air-curtain-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('air-curtain-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="airCurtain.thermalPower"
        stylingMode="outlined"
        [min]="0"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-curtain-thermal-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³/h'"
        [(value)]="airCurtain.fanEfficiency"
        stylingMode="outlined"
        [min]="0"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-curtain-fan-efficiency')"></dxo-label>
  </dxi-item>
</dx-form>
