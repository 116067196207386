<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="hint-container">
  <div class="hint-icon">
    <span class="fi fi-rr-info"></span>
  </div>
  <div class="hint-content">
    <div class="hint-title">
      <p>{{ title }}</p>
    </div>
    <div class="hint-message">
      <p>{{ message }}</p>
    </div>
    <div class="hint-button-container">
      <dx-button
        class="contained-button-focus-hover button-contained button-case-sensitive"
        [text]="mainButtonText"
        (onClick)="onMainButtonClick()"
      >
      </dx-button>
      <dx-button
        *ngIf="secondaryButton"
        class="outlined-button-focus-hover button-case-sensitive"
        stylingMode="outlined"
        [text]="secondaryButtonText"
        (onClick)="onSecondaryButtonClick()"
      >
      </dx-button>
    </div>
  </div>
</div>
