import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import config from 'devextreme/core/config';

@Injectable()
export class AppConfig {
  private http: HttpClient;
  private config: Object = null;
  constructor(private httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
    config({
      floatingActionButtonConfig: {
        icon: 'chevronup',
        position: {
          at: 'right bottom',
          my: 'right bottom',
          offset: '-100 -16',
        },
      },
    });
  }

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    return this._fixPathEndWithSlash(this.config[key]);
  }

  private _fixPathEndWithSlash(configString: string): string {
    if (configString.slice(-1) !== '/') {
      configString += '/';
    }
    return configString;
  }

  /**
   * This method:
   *   Loads "env.json" to get the current working environment variables
   */
  public load() {
    return new Promise((resolve, reject) => {
      this.http
        .get('./../env/env.json')
        .pipe()
        .subscribe(
          (data) => {
            this.config = data;
            resolve(true);
          },
          (error: any) => {
            this._createErrorTipMessageForUser();
            return of(error.message || 'Server error');
          }
        );
    });
  }

  private _createErrorTipMessageForUser() {
    const app = document.querySelector('app-root');
    const p = document.createElement('p');
    p.textContent = 'Plik konfiguracyjny (env.json) nie istnieje lub jest nieprawidłowy.';
    app?.appendChild(p);
  }
}
