import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { formatMessage } from 'devextreme/localization';
import { Localizable } from '../../../../locale/localizable';
import { DxComponent } from 'devextreme-angular';

@Component({
  selector: 'app-change-answer-popup',
  templateUrl: './change-answer-popup.component.html',
  styleUrls: ['./change-answer-popup.component.scss'],
})
export class ChangeAnswerPopupComponent extends Localizable {
  @ViewChild('confirmButton', { static: false }) focusButton: DxComponent;

  @Input() popupVisible: boolean = false;

  @Output() answerChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    super('Questionnaire');
  }

  public confirm(): void {
    this.popupVisible = false;
    this.answerChanged.emit(true);
  }

  public cancel(): void {
    if (this.popupVisible) {
      this.popupVisible = false;
      this.answerChanged.emit(false);
    }
  }

  get answerChangeMessage() {
    return formatMessage('answer-change-message');
  }

  setFocus(): void {
    this.focusButton.instance.focus();
  }
}
