import { TechnicalCondition } from './water-pump.model';

export class Blower {
  producer: string;
  model: string;
  serialNumber: string;
  productionYear: number;
  blowerType: BlowerType;
  ratedPower: number;
  ratedCapacity: number;
  ratedSpring: number;
  blowerRegulationType: BlowerRegulationType;
  workingHoursInYear: number;
  averageEfficiency: number;
  averageLoad: number;
  blowerOperatingPointVolatility: BlowerOperatingPointVolatility;
  technicalCondition: TechnicalCondition;
}

export class BlowerOperatingPointVolatility {
  id: number;
  name: string;
}

export class BlowerRegulationType {
  id: number;
  name: string;
}

export class BlowerType {
  id: number;
  name: string;
}
