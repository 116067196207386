<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="container">
  <div class="filter-container">
    <div class="filter">
      <dx-text-box
        class="search-filter filter-field"
        [(value)]="name"
        valueChangeEvent="input"
        label="{{ _('audit-gallery-search-label') }}"
        (onValueChanged)="filterNewValue()"
        stylingMode="outlined"
        labelMode="floating"
        [showClearButton]="true"
      >
        <dxi-button
          name="search-button"
          location="before"
          [options]="searchButton"
        ></dxi-button>
        <dxi-button name="clear"></dxi-button>
      </dx-text-box>
    </div>
    <div class="galery-main-buttons-container">
      <dx-button
        *ngIf="imageSelection"
        class="outlined-button-focus-hover big-button button-rounded-regular"
        stylingMode="outlined"
        text="{{ _('audit-gallery-deselect-button-text') }} ({{ selectedImagesIdList.length }})"
        (onClick)="deselect(selectedImagesIdList)"
      ></dx-button>

      <dx-button
        *ngIf="!imageSelection"
        class="outlined-button-focus-hover big-button button-rounded-regular"
        stylingMode="outlined"
        text="{{ _('audit-gallery-select-button-text') }}"
        (onClick)="turnOnSelection()"
        [disabled]="isAuditFinished || images.length == 0"
      ></dx-button>

      <dx-file-uploader
        *ngIf="isExpanded(formData.id)"
        class="contained-uploader-focus-hover big-file-uploader add-button button-case-sensitive"
        [text]="_('audit-gallery-add-image-text')"
        type="normal"
        [uploadUrl]="getUploadUrl(formData.id)"
        accept=".jpg,.png,jpeg."
        labelText=""
        [selectButtonText]="_('audit-gallery-add-image-text')"
        uploadMode="instantly"
        [multiple]="true"
        [showFileList]="false"
        [maxFileSize]="maxImageSize"
        (onUploaded)="uploadComplete()"
        (onUploadError)="handleUploadError($event)"
        (focusin)="isCollapsible = false"
        (focusout)="isCollapsible = true"
        [uploadHeaders]="{
          Authorization: token,
        }"
        [disabled]="isAuditFinished"
      >
      </dx-file-uploader>
    </div>
  </div>

  <div
    class="buttons-container"
    *ngIf="imageSelection"
  >
    <dx-button
      class="text-button-focus-hover button-rounded-regular"
      stylingMode="text"
      icon="fi fi-rr-exchange"
      text="{{ _('audit-gallery-reverse-selected-button-text') }}"
      (onClick)="reverseSelection()"
    ></dx-button>
    <dx-button
      class="text-button-focus-hover button-rounded-regular"
      stylingMode="text"
      icon="fi fi-rr-trash"
      text="{{ _('audit-gallery-delete-selected-button-text') }} ({{ selectedImagesIdList.length }})"
      (onClick)="triggerImageDeleteConfirmation()"
      [disabled]="selectedImagesIdList.length === 0"
    ></dx-button>
    <dx-button
      class="text-button-focus-hover button-rounded-regular"
      stylingMode="text"
      icon="fi fi-rr-square-plus"
      text="{{ _('audit-gallery-add-selected-to-report-button-text') }} ({{ selectedImagesIdList.length }})"
      (onClick)="setSelectedImagesIncludeFlag(selectedImagesIdList, true)"
      [disabled]="selectedImagesIdList.length === 0"
    ></dx-button>
    <dx-button
      class="text-button-focus-hover button-rounded-regular"
      stylingMode="text"
      icon="fi fi-rr-square-minus"
      text="{{ _('audit-gallery-remove-selected-from-report-button-text') }} ({{ selectedImagesIdList.length }})"
      (onClick)="setSelectedImagesIncludeFlag(selectedImagesIdList, false)"
      [disabled]="selectedImagesIdList.length === 0"
    ></dx-button>
    <dx-button
      class="text-button-focus-hover button-rounded-regular"
      stylingMode="text"
      icon="fi fi-rr-move-to-folder"
      text="{{ _('audit-gallery-move-selected-to-another-form-button-text') }} ({{ selectedImagesIdList.length }})"
      (onClick)="showMoveImagePopup = true"
      [disabled]="selectedImagesIdList.length === 0"
    ></dx-button>
  </div>

  <div class="gallery">
    <div *ngFor="let image of imgView">
      <app-audit-photo
        [draggable]="!isAuditFinished"
        [isAuditFinished]="isAuditFinished"
        [imageSelection]="imageSelection"
        [image]="image"
        [formId]="formData.id"
        (imageDeleted)="onImageDeleted()"
        (selectedImageId)="onImageSelected($event)"
        (deSelectedImageId)="onImageDeSelected($event)"
        (imageEdited)="onImageEdited()"
        (dragstart)="onDragStart($event, image.id, formData.id)"
      ></app-audit-photo>
    </div>
    <p
      class="no-images"
      *ngIf="images?.length === 0 && isFormType()"
    >
      {{ _('audit-gallery-no-image-text') }}
    </p>
  </div>
  <app-images-form-selector
    *ngIf="showMoveImagePopup"
    [showMoveImagePopup]="showMoveImagePopup"
    [imagesIds]="selectedImagesIdList"
    (onImagesMoved)="onImagesMoved()"
    (onPopupHiding)="onPopupHiding()"
  ></app-images-form-selector>
</div>

<app-exit-confirmation-popup
  [popupVisible]="showDeleteConfirmationPopup"
  [confirmationNo]="_('No')"
  [confirmationYes]="_('Yes')"
  [title]="_('audit-gallery-delete-images-confirm-title')"
  [message]="_('audit-gallery-delete-images-confirm-message')"
  (closeEvent)="deleteSelected($event, selectedImagesIdList)"
></app-exit-confirmation-popup>
