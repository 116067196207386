<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-popup
  height="auto"
  width="auto"
  [fullScreen]="false"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  [visible]="popupVisible"
  titleTemplate="popupTitle"
  contentTemplate="popupContent"
  (onShown)="setFocus()"
  [tabIndex]="-1"
  (onHiding)="cancel()"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <div
    *dxTemplate="let data of 'popupTitle'"
    class="dxPopup-confirmation"
  >
    <h3 class="exitPopupTitle">{{ _('answer-change-title') }}</h3>
  </div>
  <div
    *dxTemplate="let data of 'popupContent'"
    class="dxPopup-confirmation"
  >
    <p class="answerChangePopupMessage">{{ answerChangeMessage }}</p>
    <div class="confirm-buttons-container">
      <dx-button
        #confirmButton
        class="active-button button dxPopupConfirmButton"
        stylingMode="contained"
        [text]="_('confirm-answer-change-button')"
        type="normal"
        (onClick)="confirm()"
      ></dx-button>
      <dx-button
        class="button button-focus-border"
        stylingMode="outlined"
        [text]="_('cancel-answer-change-button')"
        type="normal"
        (onClick)="cancel()"
      ></dx-button>
    </div>
  </div>
</dx-popup>
