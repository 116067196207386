import { Directive, HostListener, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Directive({
  selector: '[copyToClipboard]',
})
export class CopyDirective {
  @Input()
  public copyToClipboard!: string;

  @Input()
  public specialVariable: boolean = false;

  constructor(private clipboard: Clipboard) {}

  @HostListener('click') copyToClipBoard(): void {
    if (this.specialVariable) {
      this.clipboard.copy(`{ DOCVARIABLE ${this.copyToClipboard} \\* MERGEFORMAT }`);
    } else {
      this.clipboard.copy(`{ MERGEFIELD ${this.copyToClipboard} \\* MERGEFORMAT }`);
    }
  }
}
