<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form
  [showColonAfterLabel]="true"
  *ngIf="armature"
>
  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="armature.termalInsulation"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
        [disabled]="templateMode"
      ></dx-switch>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'TermalInsulation'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('armature-thermal-insulation')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m²'"
        [(value)]="armature.uninsulatedSurfaceArea"
        stylingMode="outlined"
        [min]="0"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'UninsulatedSurfaceArea'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('armature-uninsulated-surface-area')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="armature.similarItemsNumber"
        stylingMode="outlined"
        [min]="0"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'SimilarItemsNumber'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('armature-similar-items-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="armature.surroundingsTemp"
        stylingMode="outlined"
        [min]="0"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'SurroundingsTemp'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('armature-surroundings-temperature')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="armature.surfaceTemp"
        stylingMode="outlined"
        [min]="0"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'SurfaceTemp'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('armature-surface-temperature')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-select-box
        [(value)]="armature.armatureInsulationMaterial"
        [dataSource]="armatureInsulationMaterialsList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('armature-insulation-material-placeholder')"
        [disabled]="templateMode"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'InsulationMaterial'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('armature-insulation-material')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m'"
        [(value)]="armature.insulationThickness"
        stylingMode="outlined"
        [min]="0"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'InsulationThickness'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('armature-insulation-thickness')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' W/m⸱K'"
        [(value)]="armature.thermalConductivity"
        stylingMode="outlined"
        [min]="0"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'ThermalConductivity'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('armature-thermal-conductivity')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="armature.workingHours"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'WorkingHours'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('armature-working-hours')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="templateMode">
    <div *dxTemplate>
      <app-add-template [areaTypeCode]="this.docVariableCode"></app-add-template>
    </div>
  </dxi-item>
</dx-form>
