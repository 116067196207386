<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-tree-list
  #treeViewRef
  id="treeViewRef"
  *ngIf="auditNavigation"
  [dataSource]="auditNavigation"
  [rootValue]="'-1'"
  [expandedRowKeys]="expandedRowsKeys"
  [columnAutoWidth]="false"
  [wordWrapEnabled]="true"
  [focusedRowEnabled]="true"
  [focusedRowIndex]="0"
  [showColumnHeaders]="false"
  [showBorders]="false"
  [showRowLines]="false"
  width="100%"
  displayExpr="name"
  keyExpr="id"
  parentIdExpr="parentId"
  dataStructure="plain"
  (onContentReady)="getSelectedRow()"
  (onRowExpanded)="rowExpanded($event.key)"
  (onRowCollapsed)="rowCollapsed($event.key)"
  (onRowCollapsing)="rowCollapsing()"
  (onSelectionChanged)="onItemSelectionChanged($event)"
  (onRowClick)="onClick($event.key)"
  (onFocusedRowChanging)="onFocusedRowChanging($event)"
>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-scrolling mode="standard"></dxo-scrolling>
  <dxi-column
    dataField="name"
    [caption]="_('navigation-tree')"
    cellTemplate="cellTemplate"
  >
  </dxi-column>
  <dxi-column
    *ngIf="isGalleryTab"
    [width]="60"
    cellTemplate="buttonTemplate"
  >
  </dxi-column>
  <dxi-column
    *ngIf="isMobile"
    cssClass="buttons-column"
    [width]="50"
    type="buttons"
  >
    <dxi-button
      [visible]="isShowFromButtonVisible"
      icon="arrowright"
      name="show"
      height="20px"
      width="20px"
      [onClick]="showForm"
      cssClass="show-form-button"
    >
    </dxi-button>
  </dxi-column>

  <div *dxTemplate="let data of 'buttonTemplate'">
    <div
      class="column-images-template"
      *ngIf="isFormType(data.data.formType)"
    >
      <dx-button
        class="images-number-icon"
        *ngIf="isGalleryTab"
        icon="fi fi-rr-images"
        stylingMode="text"
        [activeStateEnabled]="false"
        [focusStateEnabled]="false"
        [hoverStateEnabled]="false"
      >
      </dx-button>
      <span
        *ngIf="isGalleryTab"
        class="dx-list-item-badge dx-badge"
      >
        {{ getNumberOfImagesInsideFormById(data) }}
      </span>
    </div>
  </div>

  <div *dxTemplate="let cell of 'cellTemplate'">
    <div
      (drop)="onDrop($event, cell)"
      (dragover)="onDragOver($event, cell)"
    >
      {{ cell.text }}
    </div>
  </div>
</dx-tree-list>
