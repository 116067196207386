import { OpenAnswer, SelectAnswer } from './answers.models';
import { v4 as uuidv4 } from 'uuid';

export class Question {
  _id: string;
  _skipToId?: string;
  text: string;
  type: string;
  root?: boolean;
  multiplechoice?: boolean;
  guid?: string;
  answer: OpenAnswer | Array<SelectAnswer>;
  note?: string;

  constructor(question: Question) {
    this._id = question._id;
    this.root = question.root;
    this.text = question.text;
    this.type = question.type;
    this.multiplechoice = question.multiplechoice;
    this.answer = JSON.parse(JSON.stringify(question.answer));
    this._skipToId = question._skipToId;
    if (question.guid != '' && question.guid != null) {
      this.guid = question.guid;
    } else {
      this.guid = uuidv4();
    }
  }
}

export class SelectQuestion extends Question {
  answer: Array<SelectAnswer>;

  constructor(question: SelectQuestion) {
    super(question);
  }
}

export class OpenQuestion extends Question {
  answer: OpenAnswer;

  constructor(question: OpenQuestion) {
    super(question);
  }
}

export class MultipleChoiceQuestion extends SelectQuestion {}
