<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="content">
  <h1
    data-testid="templates-header"
    class="content-block"
  >
    {{ _('managing-templates') }}
  </h1>
  <dx-responsive-box
    singleColumnScreen="sm"
    [(screenByWidth)]="screen"
  >
    <dxi-row [ratio]="1"></dxi-row>
    <dxi-row [ratio]="1"></dxi-row>

    <dxi-col [ratio]="1"></dxi-col>

    <dxi-item>
      <dxi-location
        [row]="0"
        [col]="0"
        screen="lg"
      ></dxi-location>
      <dxi-location
        [row]="0"
        [col]="0"
        screen="sm"
      ></dxi-location>
      <div
        *dxTemplate
        class="header item"
      >
        <div class="content-block">
          <div class="dx-card responsive-paddings">
            <dx-scroll-view
              [scrollByContent]="true"
              [scrollByThumb]="true"
              [showScrollbar]="always"
              [useNative]="false"
              [bounceEnabled]="false"
            >
              <div [ngClass]="{ 'select-templete-type': auditType }">
                <dx-select-box
                  [items]="auditTypesList"
                  stylingMode="outlined"
                  placeholder="{{ _('audit-type-select') }}"
                  displayExpr="name"
                  (onSelectionChanged)="selectAuditType($event)"
                  [searchEnabled]="true"
                  searchMode="contains"
                >
                </dx-select-box>
                <div
                  *ngIf="auditType"
                  class="form-type"
                >
                  <dx-tree-view
                    #treeView
                    [items]="formTypesList"
                    [searchEnabled]="true"
                    [rootValue]="'-1'"
                    [searchEditorOptions]="{
                      placeholder: _('form-type-select'),
                    }"
                    (onItemSelectionChanged)="selectedFromTypeChanged($event.node)"
                    displayExpr="name"
                    keyExpr="id"
                    parentIdExpr="parentId"
                    dataStructure="plain"
                    searchMode="contains"
                    selectionMode="single"
                    selectByClick="true"
                    noDataText="{{ _('failed-load-forms') }}"
                  >
                  </dx-tree-view>
                </div>
              </div>
            </dx-scroll-view>
          </div>
        </div>
      </div>
    </dxi-item>
    <dxi-item>
      <dxi-location
        [row]="1"
        [col]="0"
        screen="lg"
      ></dxi-location>
      <dxi-location
        [row]="2"
        [col]="0"
        screen="sm"
      ></dxi-location>
      <app-template-form></app-template-form>
    </dxi-item>
  </dx-responsive-box>
</div>
<app-footer></app-footer>
