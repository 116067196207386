import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { TreeNavigationService } from 'src/app/shared/services/tree-navigation.service';
import { Component, OnInit, inject } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { FormType } from '../../../models/audit/audit-tree-navigation.model';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { AppSettings } from '../../../../AppSettings';
import notify from 'devextreme/ui/notify';
import { Localizable } from '../../../../locale/localizable';
import { ErrorHandlerService } from '../../../../shared/services/errorHandlerService';

@Component({
  selector: 'app-audits-list-widget',
  templateUrl: './audits-list-widget.component.html',
  styleUrls: ['./audits-list-widget.component.scss'],
})
export class AuditsListWidgetComponent extends Localizable implements OnInit {
  public dataAuditsList: CustomStore;
  public urlDBEnergyDatabase: any;

  constructor(
    private _apiRequestService: ApiRequestService,
    private _treeNavigationService: TreeNavigationService,
    private _authenticationService: AuthenticationService,
    private _errorHandlerService: ErrorHandlerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.dataAuditsList = AspNetData.createStore({
      key: 'id',
      loadUrl: `${this._apiRequestService.urlDBEnergyDatabase}api/audits`,
      deleteUrl: `${this._apiRequestService.urlDBEnergyDatabase}api/audits`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        const token = localStorage.getItem(AppSettings.AUTH_TOKEN);
        ajaxOptions.headers = { Authorization: `Bearer ${token}` };
      },
      onAjaxError: (e) => {
        if (e.xhr.status === AppSettings.HTTP_STATUS_CODE_NOT_AUTHORIZED) {
          this._authenticationService.logout();
          notify(this._('not-authorized-logout'), 'error', AppSettings.NOTIFY_DURATION);
        } else {
          this._errorHandlerService.handleError(e.xhr);
        }
      },
    });
  }

  public onCellClick(e: any): void {
    const rowId = e.row.key;
    if (e.column.dataField === 'name') {
      this.moveToAudit(rowId);
    }
  }

  private moveToAudit(id: string): void {
    this._treeNavigationService.changeForm(FormType.Audit, id);
  }
}
