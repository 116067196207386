<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item *ngIf="powerSuppliesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="heater.powerSupply"
        [dataSource]="powerSuppliesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('heater-power-supply-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('heater-power-supply')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="heater.deviceNumber"
        stylingMode="outlined"
        [min]="0"
        [max]="100"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heater-device-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="heater.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('heater-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="heater.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('heater-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="heater.minThermalLoad"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heater-min-thermal-load')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="heater.maxThermalLoad"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heater-max-thermal-load')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³/h'"
        [(value)]="heater.maxAirFlow"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heater-max-air-flow')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="heater.capacityRegulation"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('heater-capacity-regulation')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="heater.condensingHeater"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('heater-condensing-heater')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' %'"
        [(value)]="heater.efficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heater-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' ℃'"
        [(value)]="heater.exhaustAirTemp"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heater-exhaust-air-temp')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' ℃'"
        [(value)]="heater.tempInBuilding"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heater-temp-in-building')"></dxo-label>
  </dxi-item>
</dx-form>
