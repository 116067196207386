<!-- Copyright C Alfa-Net. All Rights Reserved. -->

<dx-popup
  *ngIf="energySource"
  [width]="(mobile ? popupWidth * 0.9 : 50) + '%'"
  [height]="mobile ? popupHeight * 0.9 + '%' : auto"
  [fullScreen]="false"
  [showTitle]="true"
  [title]="!editMode ? _('energy-balance-add-source') : _('energy-balance-edit-source')"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [visible]="true"
  [showCloseButton]="true"
  (onHidden)="formClosed()"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <div class="responsive-paddings">
    <dx-scroll-view
      width="100%"
      height="100%"
    >
      <dx-validation-group>
        <dx-form [showColonAfterLabel]="true">
          <dxi-item [dataField]="_('energy-balance-source-name')">
            <div *dxTemplate>
              <dx-text-box
                [(value)]="energySource.name"
                stylingMode="outlined"
              >
                <dx-validator>
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </dxi-item>

          <dxi-item [dataField]="_('energy-balance-source-amount')">
            <div *dxTemplate>
              <dx-number-box
                step="0"
                [format]="fullNumberFormat"
                min="0"
                max="10000000"
                showClearButton="true"
                [(value)]="energySource.energyAmount"
                stylingMode="outlined"
              >
                <dx-validator>
                  <dxi-validation-rule type="required"> </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </div>
          </dxi-item>

          <dxi-item [dataField]="_('energy-balance-source-unit')">
            <div *dxTemplate>
              <dx-select-box
                [items]="unitsList"
                displayExpr="shortName"
                valueExpr="id"
                [(value)]="energySource.unitId"
                [placeholder]="_('energy-balance-energy-unit-placeholder')"
                [searchEnabled]="true"
                searchMode="contains"
              >
                <dx-validator>
                  <dxi-validation-rule type="required"> </dxi-validation-rule>
                </dx-validator>
              </dx-select-box>
            </div>
          </dxi-item>

          <dxi-item [dataField]="_('energy-balance-source-energy-cost')">
            <div *dxTemplate>
              <dx-number-box
                step="0"
                min="0"
                max="10000000"
                showClearButton="true"
                [(value)]="energySource.energyCost"
                [format]="currencyFormat"
                stylingMode="outlined"
              >
              </dx-number-box>
            </div>
          </dxi-item>
          <dxi-item>
            <div *dxTemplate>
              <dx-button
                [text]="editMode ? _('energy-balance-edit-source') : _('energy-balance-add-source')"
                [icon]="editMode ? 'save' : 'add'"
                width="100%"
                type="normal"
                (onClick)="
                  editMode ? updateEnergyBalanceSource($event, energySource) : addNewSource($event, energySource)
                "
                class="outlined-button-focus-hover"
                stylingMode="outlined"
              >
              </dx-button>
            </div>
          </dxi-item>
        </dx-form>
      </dx-validation-group>
    </dx-scroll-view>
  </div>
</dx-popup>
