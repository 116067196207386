import { Injectable } from '@angular/core';
import { XMLParser } from 'fast-xml-parser';

export class FlowNode {
  id: string;

  textt: string;

  type: string;

  style: any;

  textStyle: any;

  left: number;

  top: number;
}

export class FlowEdge {
  id: string;

  from: string;

  to: string;

  text: string;

  lineType: string;

  fromPoint: number;

  toPoint: number;

  style: any;
}

class QuestionPosition {
  qid: string;
  xpos: number;
  ypos: number;
  qrefid: string;
}

@Injectable()
export class QuestionnaireDiagramDataService {
  private _flowNodes: FlowNode[] = [];
  private _flowEdges: FlowEdge[] = [];
  private parsedXml: any;
  private currentId = 1;
  private topPosition = 0;
  private leftPosition = 100;

  private xQuestionBranchShift = 75;
  private xAnswerShift = 10;
  private yQuestionShift = 20;
  private yAnswerShift = 10;
  private _qposList: QuestionPosition[] = [];

  getTopPosition() {
    this.topPosition += this.yQuestionShift;
    return this.topPosition;
  }

  getNextId = () => {
    this.currentId++;
    return this.currentId.toString();
  };

  questionLine() {
    return { stroke: '#ADBA00' };
  }
  skipLine() {
    return { stroke: '#384e61' };
  }

  parseXmlToArrays(xmlContent: any) {
    const options = {
      ignoreAttributes: false,
      attributeNamePrefix: '_',
      allowBooleanAttributes: true,
    };

    const parser = new XMLParser(options);
    this.parsedXml = parser.parse(xmlContent);

    const questionnaire = this.parsedXml.questionnaire;
    const group = questionnaire.group;
    const questions = group.question;

    this._flowNodes.push({
      id: 'START',
      textt: 'START',
      type: 'rectangle',
      style: { fill: 'green', stroke: 'green' },
      textStyle: {
        fill: '#FFFFFF',
      },
      left: this.leftPosition,
      top: this.getTopPosition(),
    });

    this._flowEdges.push({
      from: 'START',
      id: this.getNextId(),
      text: '',
      to: 'ROOT',
      lineType: 'straight',
      fromPoint: 2,
      toPoint: 0,
      style: this.questionLine,
    });

    questions.forEach((question: any) => {
      const questionId = question._id;
      const questionText = typeof question.text !== 'object' ? question.text : question.text['#text'];

      const found = this._qposList.find((el) => el.qid === questionId);
      if (found !== undefined) {
        // this question was a question fork
        this._flowNodes.push({
          id: questionId,
          textt: questionText,
          type: 'question',
          style: { fill: '#384E61', stroke: '#384E61' },
          textStyle: {
            fill: '#FFFFFF',
          },
          left: found.xpos,
          top: found.ypos,
        });
      } else {
        this._flowNodes.push({
          id: questionId,
          textt: questionText,
          type: 'question',
          style: { fill: '#384E61', stroke: '#384E61' },
          textStyle: {
            fill: '#FFFFFF',
          },
          left: this.leftPosition,
          top: this.getTopPosition(),
        });
      }

      if (question.answer) {
        if (!Array.isArray(question.answer)) {
          question.answer = [question.answer];
        }

        let answerLeftPosition = found !== undefined ? found.xpos : this.leftPosition;
        let questionLeftPosition = found !== undefined ? found.xpos : this.leftPosition;
        let parallelQuestionsAdded = false;
        question.answer.forEach((answer: any) => {
          const answerId = answer._id;
          const answerText = typeof answer.text !== 'object' ? answer.text : answer.text['#text'];
          const nextQuestionId = answer._next;

          if (found !== undefined || question.answer.some((el) => el._next !== nextQuestionId)) {
            // there is an answer that points to other question, we need to remeber this
            this._qposList.push({
              qid: nextQuestionId,
              xpos: questionLeftPosition,
              ypos: found !== undefined ? found.ypos + this.yQuestionShift : this.topPosition + this.yQuestionShift,
              qrefid: question._id,
            });
            questionLeftPosition += this.xQuestionBranchShift;
            parallelQuestionsAdded = true;
          }

          if (found !== undefined) {
            this._flowNodes.push({
              id: answerId,
              textt: answerText,
              type: 'answer',
              style: { fill: '#ADBA00', stroke: '#ADBA00' },
              textStyle: {
                fill: '#FFFFFF',
              },
              left: answerLeftPosition,
              top: found.ypos + this.yAnswerShift,
            });
          } else {
            this._flowNodes.push({
              id: answerId,
              textt: answerText,
              type: 'answer',
              style: { fill: '#ADBA00', stroke: '#ADBA00' },
              textStyle: {
                fill: '#FFFFFF',
              },
              left: answerLeftPosition,
              top: this.topPosition + this.yAnswerShift,
            });
          }
          answerLeftPosition += this.xAnswerShift;

          this._flowEdges.push({
            from: questionId,
            id: this.getNextId(),
            text: '',
            to: answerId,
            lineType: 'straight',
            fromPoint: 2,
            toPoint: 0,
            style: this.questionLine,
          });

          if (nextQuestionId) {
            this._flowEdges.push({
              from: answerId,
              id: this.getNextId(),
              text: '',
              to: nextQuestionId,
              lineType: 'straight',
              fromPoint: 2,
              toPoint: 0,
              style: this.questionLine,
            });
          }
        });

        if (parallelQuestionsAdded) {
          this.getTopPosition();
        }
      }

      if (question._skipToId) {
        const skipToId = question._skipToId;

        this._flowEdges.push({
          from: questionId,
          id: this.getNextId(),
          text: 'SKIP',
          to: skipToId,
          lineType: 'straight',
          fromPoint: 1,
          toPoint: 1,
          style: this.skipLine,
        });
      }
    });

    this._flowNodes.push({
      id: 'END',
      textt: 'END',
      type: 'rectangle',
      style: { fill: 'red', stroke: 'red' },
      textStyle: {
        fill: '#FFFFFF',
      },
      left: this.leftPosition,
      top: this.getTopPosition(),
    });
  }

  getFlowNodes() {
    return this._flowNodes;
  }

  getFlowEdges() {
    return this._flowEdges;
  }
}
