import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { FanCooler, FanCoolerControl, RefrigeratedMedium } from 'src/app/shared/models/area/types/fan-cooler.model';
import { TechnicalCondition } from 'src/app/shared/models/area/types/water-pump.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-fan-cooler',
  templateUrl: './fan-cooler.component.html',
  styleUrls: ['./fan-cooler.component.scss'],
})
export class FanCoolerComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  fanCooler: FanCooler = null;

  currentYear: number;
  mediumTypesList: ArrayStore;
  controlSystemTypesList: ArrayStore;
  technicalConditionsList: ArrayStore;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getFanCoolerControlTypes().subscribe((res: Array<FanCoolerControl>) => {
      this.controlSystemTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getFanCoolerRefrigeratedMediums().subscribe((res: Array<RefrigeratedMedium>) => {
      this.mediumTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getAreaTechnicalConditions().subscribe((res: Array<TechnicalCondition>) => {
      this.technicalConditionsList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
