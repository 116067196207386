import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { Department } from 'src/app/shared/models/department/department.model';
import { ProductionOverview } from 'src/app/shared/models/department/production-overview.model';
import { DepartmentFormService } from './department-form.service';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { TreeNavigationService } from 'src/app/shared/services/tree-navigation.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { AppConfig } from 'src/app/app.config';
import { Unit } from 'src/app/shared/models/unit.model';
import { DxDataGridComponent, DxFormComponent, DxValidationGroupComponent } from 'devextreme-angular';
import { map, takeUntil } from 'rxjs/operators';
import { ReportService } from '../../../../services/report.service';
import { FormMenuActions } from '../../../../models/FormMenuActions.enum';
import { AppSettings } from '../../../../../AppSettings';
import { ScreenService } from '../../../../services';
import { FormType, AuditTreeNavigation } from '../../../../models/audit/audit-tree-navigation.model';
import { Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Localizable } from '../../../../../locale/localizable';
import { AuthenticationService } from '../../../../../shared/services/authentication.service';
import { ErrorHandlerService } from '../../../../../shared/services/errorHandlerService';
import { AreaFormComponent } from '../../area-form/area-form/area-form.component';
import { CloseResult } from '../../../questionnaire/exit-confirmation-popup/exit-confirmation-popup.component';
import { AreaFormService } from '../../area-form/area-form/area-form.service';
import { EnergyBalanceSankey } from '../../../energy-balance-chart/energy-balance-chart/energy-balance-sankey.service';
import { GalleryService } from '../../../../services/gallery.service';
import { CanComponentDeactivate } from '../../../../../core/guards/can-deactivate.guard';

@Component({
  selector: 'app-department-form',
  templateUrl: './department-form.component.html',
  styleUrls: ['./department-form.component.scss'],
})
export class DepartmentFormComponent
  extends Localizable
  implements OnInit, AfterViewInit, OnDestroy, CanComponentDeactivate
{
  @ViewChild('departmentValidator', { static: false })
  validationGroup: DxValidationGroupComponent;
  @ViewChild(DxFormComponent, { static: false })
  departmentForm: DxFormComponent;

  @ViewChild('productionDataGrid', { static: false })
  dataGrid!: DxDataGridComponent;

  @Input() enterpriseId: string;
  @Input() enterpriseChangesIsSaved: boolean = true;
  @Input() existingForm: boolean = true;
  @Input() templateMode: boolean = false;

  @ViewChild('areaForm') areaForm!: AreaFormComponent;

  public createButtonOptions = {
    icon: 'fi fi-rr-plus',
    type: 'normal',
    stylingMode: 'outlined',
    text: this._('create-area'),
    onClick: () => this.onCreateArea(),
  };

  public createAndDisplayButtonOptions = {
    icon: 'fi fi-rr-plus',
    type: 'normal',
    text: this._('create-area-and-go'),
    onClick: () => this.onCreateAndDisplayArea(),
  };

  public saveProductionButtonOptions = {
    text: this._('production-breakdown-save-hint'),
    stylingMode: 'contained',
    onClick: () => {
      this.dataGrid.instance.saveEditData();
    },
  };

  private _departmentId: string = null;
  public isAuditFinishedFlag: boolean = false;
  activeIndex: number;

  public departmentId: string = null;

  public docVariableCode: string = null;

  @Output() departmentAdded: EventEmitter<boolean> = new EventEmitter<boolean>();

  createAreaPopupVisible = false;
  public energyTypes: Array<string>;
  public industryTypes: Array<string>;
  public unitTypes: Array<Unit>;
  public currentDepartment: Department;
  public currentDepartmentId: string;
  public productionFormat: string;
  public currentAuditId: string;
  public energyBalanceChart: boolean = false;

  public productionListDataSource: any;

  private _productionUnit: string = '* #.##';
  isGalleryVisible: boolean = false;
  deleteDepartmentPopup: boolean = false;
  questionnairePopupVisible: boolean = false;
  deleteDepartmentMessage: any;
  urlDBEnergyDatabase: string;

  saveText: string = '';
  galleryText: string = '';
  addAreaText: string = '';
  deleteDepartmentText: string = '';
  questionnaireText: string = '';
  public imagesNumber: number = 0;

  private savedDepartment: Department;

  public isChangingDisabled = false;

  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;

  private _authenticationService: AuthenticationService = inject(AuthenticationService);
  private _errorHandlerService: ErrorHandlerService = inject(ErrorHandlerService);

  private _isDeleted: boolean = false;

  phonePattern: any = /^\+?\d[\d\s]{6,20}$/;

  canBeDeactivated(): boolean {
    return this.triggerParentDeactivateCheck();
  }

  triggerParentDeactivateCheck(): boolean {
    if (this.currentDepartment && this._isDeleted === false) {
      return !this._departmentFormService.formChanged(this.currentDepartment);
    } else {
      return true;
    }
  }

  constructor(
    private _departmentFormService: DepartmentFormService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _apiRequestService: ApiRequestService,
    private _treeNavigationService: TreeNavigationService,
    private _config: AppConfig,
    private _reportService: ReportService,
    private _screenService: ScreenService,
    private _areaService: AreaFormService,
    private _energyBalanceSankey: EnergyBalanceSankey,
    private _galleryService: GalleryService,
    private ref: ChangeDetectorRef
  ) {
    super();
    this.productionFormat = this._productionUnit;
    this.urlDBEnergyDatabase = this._config.getConfig('DbEnergyDatabaseUrl');
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this._route.params.pipe(takeUntil(this.onDestroy)).subscribe((params) => {
      this.departmentId = params['departmentId'];
      if (this.currentDepartmentId) {
        // Department component after init, so fetch depratment from url change
        this.departmentFetched = false;
        this.fetchDataForDepartment(this.departmentId);
      }
    });

    this._route.firstChild?.url.subscribe((urlPath) => {
      const path = urlPath[0]?.path;
      this.setActiveTab(path);
    });

    this._route.parent.params.pipe(takeUntil(this.onDestroy)).subscribe((params) => {
      this.currentAuditId = params['auditId'];
    });

    this._departmentFormService.performAction.pipe(takeUntil(this.onDestroy)).subscribe((action) => {
      if (action) {
        this.selectedAction(action);
      }
    });

    this._screenService.changed.subscribe((screenSizeBreakPoint: BreakpointState) =>
      this.updateButtonsText(screenSizeBreakPoint)
    );

    if (!this.templateMode) {
      this.isAuditFinishedFlag = await this._treeNavigationService.isAuditFinished(this.currentAuditId);
    }

    if (this.existingForm) {
      this.departmentFetched = false;
      this.currentDepartmentId = this.departmentId;
      this.fetchDataForDepartment(this.departmentId); // First time init, no department fetched
    } else {
      this.currentDepartment = this._departmentFormService.getEmptyDepartment();
      this._departmentFormService.formLoaded(true);
      this.savedDepartment = this._treeNavigationService.deepCopy(this.currentDepartment);
      this.currentDepartment.enterpriseId = this.enterpriseId;
    }

    if (this.templateMode) {
      this._apiRequestService.getDepartmentDocVariableCode().subscribe((res: string) => {
        this.docVariableCode = res;
      });
    }

    this._treeNavigationService.checkFormData.pipe(takeUntil(this.onDestroy)).subscribe((result) => {
      if (result) {
        this._treeNavigationService.updateformDataSaved(
          this.currentDepartment,
          this.savedDepartment,
          this._departmentId
        );
      }
    });

    this._treeNavigationService.exitFormReturn.pipe(takeUntil(this.onDestroy)).subscribe((result) => {
      switch (result) {
        case CloseResult.Cancel:
          break;
        case CloseResult.Confirm:
          this._treeNavigationService.exitFormYesFn();
          break;
        case CloseResult.Special:
          this._treeNavigationService.exitFormSpecialFn();
          break;
      }
    });

    this._departmentFormService.questionnairePopupClosing.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.handleChange({ index: this.activeIndex });
    });
  }

  ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }

  public isAuditFinished(): boolean {
    return this.isAuditFinishedFlag;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._departmentFormService.formLoaded(false);
  }

  ngAfterViewInit(): void {
    this._treeNavigationService.setComponentRendered(this);
  }

  public fetchDataForDepartment(id: string) {
    this.fetchDeparment(id);
    this.fetchProductionList(id);
  }

  private fetchProductionList(departmentId: string): void {
    const urlDBEnergyDatabase = this._config.getConfig('DbEnergyDatabaseUrl');

    this.productionListDataSource = AspNetData.createStore({
      key: 'id',
      loadUrl: `${urlDBEnergyDatabase}api/departments/${departmentId}/productions`,
      insertUrl: `${urlDBEnergyDatabase}api/departments/${departmentId}/productions`,
      updateUrl: `${urlDBEnergyDatabase}api/departments/productions`,
      deleteUrl: `${urlDBEnergyDatabase}api/departments/productions`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        const token = localStorage.getItem(AppSettings.AUTH_TOKEN);
        ajaxOptions.headers = { Authorization: `Bearer ${token}` };
      },
      onAjaxError: (e) => {
        if (e.xhr.status === AppSettings.HTTP_STATUS_CODE_NOT_AUTHORIZED) {
          this._authenticationService.logout();
          notify(this._('not-authorized-logout'), 'error', AppSettings.NOTIFY_DURATION);
        } else {
          this._errorHandlerService.handleError(e.xhr);
        }
      },
    });
  }

  private fetchDeparment(id): void {
    this.isGalleryVisible = false;
    this._departmentFormService.getCurrentDepartment(id).subscribe((res) => {
      this.currentDepartment = res;
      this._departmentFormService.createCopy(this.currentDepartment);
      this._departmentFormService.formLoaded(true);
      this.currentDepartmentId = res.id;
      this.isGalleryVisible = true;
      this.savedDepartment = this._treeNavigationService.deepCopy(this.currentDepartment);
      this.getImagesNumberInGallery(this.currentDepartment.formGallery.id);
      this.departmentFetched = true;
    });
  }

  public departmentFetched = false;
  getUnitTypes(): void {
    this._apiRequestService.getUnitsForEnergyBalance().subscribe((res: Array<Unit>) => (this.unitTypes = res));
  }

  saveDepartment(e: any, department: Department): void {
    const validator: any = this.departmentForm.instance.validate();
    if (!validator.isValid) {
      validator.brokenRules[0].validator.focus();
    } else {
      this._departmentFormService
        .save(department)
        .pipe(
          map((response) => {
            this._treeNavigationService.expandAllParents(response.id.toUpperCase());
            this._treeNavigationService.getAuditNavigationTree(this.currentAuditId).subscribe();
          })
        )
        .subscribe({
          complete: () => {
            this.departmentAdded.emit(false);
            notify({
              message: this._('department-created'),
              type: 'success',
              displayTime: AppSettings.NOTIFY_DURATION,
              position: AppSettings.NOTIFY_TOP_POSTION,
            });
          },
        });
    }
  }

  saveDepartmentGo(e: any, department: Department): void {
    this._treeNavigationService.updateformDataSaved(this.currentDepartment, this.savedDepartment, this._departmentId);
    const validator = this.departmentForm.instance.validate();

    if (validator.isValid) {
      if (!this.enterpriseChangesIsSaved) {
        this._treeNavigationService.exitFormYesFn = () => {
          this.currentDepartment = this._treeNavigationService.deepCopy(this.savedDepartment);
          this.addDepartmentAndGo(department);
          return true;
        };
        this._treeNavigationService.exitFormSpecialFn = () => {
          this.saveForm();
          this.addDepartmentAndGo(department);
          return true;
        };
      } else {
        this.addDepartmentAndGo(department);
      }
    }
  }

  private addDepartmentAndGo(department: Department): void {
    this._departmentFormService
      .save(department)
      .pipe(
        map((response) => {
          this._treeNavigationService.getAuditNavigationTree(this.currentAuditId).subscribe(() => {
            this._treeNavigationService.expandAllParents(response.id.toUpperCase());
            const departmentNavigate: AuditTreeNavigation = {
              id: response.id,
              selectable: true,
              name: response.name,
              formType: FormType.Department,
              expanded: true,
              parentId: null,
            };
            this._treeNavigationService.setNavigationToFormId(departmentNavigate);
            this._departmentFormService.scrollToTop();
          });
        })
      )
      .subscribe({
        complete: () => {
          this.departmentAdded.emit(true);
          notify({
            message: this._('department-created'),
            type: 'success',
            displayTime: AppSettings.NOTIFY_DURATION,
            position: AppSettings.NOTIFY_TOP_POSTION,
          });
        },
      });
  }

  public saveForm() {
    this.updateDepartment(this.currentDepartment);
  }
  updateDepartment(department: Department): void {
    const validator: any = this.departmentForm.instance.validate();
    if (!validator.isValid) {
      validator.brokenRules[0].validator.focus();
    } else {
      this._apiRequestService.updateDepartment(department).subscribe((res: Department) => {
        this._treeNavigationService.getAuditNavigationTree(res.auditFormId).subscribe();
        this.currentDepartment = res;
        this._departmentFormService.createCopy(this.currentDepartment);
        this.savedDepartment = this._treeNavigationService.deepCopy(this.currentDepartment);
        notify({
          message: this._('changes-saved'),
          type: 'success',
          displayTime: AppSettings.NOTIFY_DURATION,
          position: AppSettings.NOTIFY_TOP_POSTION,
        });
        this._energyBalanceSankey.updateEnergyBalanceSankey(this.currentAuditId);
      });
    }
  }

  deleteDepartment(id: string): void {
    this._apiRequestService.canDepartmentBeDeleted(id).subscribe((response) => {
      if (!response.canDelete) {
        this.deleteDepartmentMessage = response;
        this.deleteDepartmentPopup = true;
      } else {
        this.deleteDepartmentPopupMessage = this.createDeleteConfirmationMessage(response);
        this.deleteDepartmentPopupVisible = true;
      }
    });
  }

  private deleteDepartmentRequest(id: string): void {
    this._apiRequestService.deleteDepartment(id).subscribe(() => {
      this._isDeleted = true;
      this._treeNavigationService.getAuditNavigationTree(this.currentDepartment.auditFormId).subscribe();
      const enterpriseNavigation: AuditTreeNavigation = {
        id: this.currentDepartment.enterpriseId,
        name: null,
        selectable: true,
        formType: FormType.Enterprise,
        expanded: true,
        parentId: null,
      };
      this._treeNavigationService.setNavigationToFormId(enterpriseNavigation);
      notify({
        message: this._('delete-department-notify-message'),
        type: 'success',
        displayTime: AppSettings.NOTIFY_DURATION,
        position: AppSettings.NOTIFY_TOP_POSTION,
      });
      this._energyBalanceSankey.updateEnergyBalanceSankey(this.currentAuditId);
    });
  }

  popupClosed(): void {
    this.deleteDepartmentPopup = false;
    this.deleteDepartmentMessage = {};
  }

  displayCreateAreaPopup(): void {
    this.createAreaPopupVisible = !this.createAreaPopupVisible;
  }

  addProductionOvreview(): void {
    const newProductionOverview: ProductionOverview = {
      id: 0,
      name: 'Test',
      quantity: 0,
      unit: 'Test',
    };
    this.currentDepartment.productionOverviews.unshift(newProductionOverview);
  }

  getImagesNumber() {
    let imagesNumberResult = this._galleryService.getImagesNumberByFormId(this.departmentId.toUpperCase());
    if (imagesNumberResult == null) {
      imagesNumberResult = this.imagesNumber;
    }
    return imagesNumberResult;
  }

  public getImagesNumberInGallery(galleryId: string): void {
    this._apiRequestService.getImagesNumberInGallery(galleryId).subscribe((res: number) => {
      this.imagesNumber = res;
    });
  }

  areaAdded(deleteUnsavedChanges: boolean): void {
    this.createAreaPopupVisible = false;
    if (deleteUnsavedChanges) {
      this.currentDepartment = this.savedDepartment;
    }
  }

  public generateReport(auditId: string): void {
    this._reportService.generateReport(auditId);
  }

  private checkChangesBeforeShowingAreaPopup() {
    const isChanged = this._departmentFormService.formChanged(this.currentDepartment);
    if (isChanged) {
      this._treeNavigationService.exitFormYesFn = () => {
        this.currentDepartment = this.savedDepartment;
        this.createAreaPopupVisible = true;
        return true;
      };
      this._treeNavigationService.exitFormSpecialFn = () => {
        this.saveForm();
        this.createAreaPopupVisible = true;
        return true;
      };
      this._treeNavigationService.addAndGoAskToSaveChanges();
    } else {
      this.createAreaPopupVisible = true;
    }
  }

  public async toggleFinishedAudit() {
    const audit = await this._treeNavigationService.toggleFinishedAudit(this.currentAuditId);
    this.isAuditFinishedFlag = audit.isFinished;
    this._departmentFormService.toggleFinishedAudit(this.isAuditFinishedFlag);
  }

  public selectedAction(e: any): void {
    switch (e.itemData.key) {
      case FormMenuActions.Save:
        this.saveForm();
        break;
      case FormMenuActions.Add:
        this.checkChangesBeforeShowingAreaPopup();
        break;
      case FormMenuActions.Delete:
        this.deleteDepartment(this.currentDepartment.id);
        break;
      case FormMenuActions.Gallery:
        this.showGallery();
        break;
      case FormMenuActions.Questionnaire:
        this.showQuestionnaire(true);
        break;
      case FormMenuActions.ToggleFinishedAudit:
        this.toggleFinishedAudit();
      default:
        break;
    }
  }

  setActiveTab(path: string): void {
    switch (path) {
      case AppSettings.FORM_TAB:
        this.activeIndex = 0;
        break;
      case AppSettings.GALLERY_TAB:
        this.activeIndex = 1;
        this.showGallery();
        break;
      case AppSettings.SANKEY_TAB:
        this.activeIndex = 2;
        this.showEnergyBalance();
        break;
      case AppSettings.SURVEY_TAB:
        this.activeIndex = 0;
        this.questionnairePopupVisible = true;
        break;
      default:
        this.activeIndex = 0;
    }
  }

  handleChange(e: any): void {
    const tabMap = [AppSettings.FORM_TAB, AppSettings.GALLERY_TAB, AppSettings.SANKEY_TAB];
    const path = tabMap[e.index];
    this._router.navigate([path], { relativeTo: this._route });
    this.setActiveTab(path);
  }

  public showEnergyBalance(): void {
    this.energyBalanceChart = true;
  }

  public showQuestionnaire(visible: boolean): void {
    if (visible) {
      this._router.navigate([AppSettings.SURVEY_TAB], { relativeTo: this._route });
    }
    this.questionnairePopupVisible = visible;
  }

  public showGallery(): void {
    this.isGalleryVisible = true;
  }

  public galleryClosed(imagesNumber: number): void {
    this.isGalleryVisible = false;
    this.imagesNumber = imagesNumber;
  }

  private updateButtonsText(screenSize: BreakpointState) {
    const isXSmall = screenSize.breakpoints[Breakpoints.XSmall];

    this.saveText = isXSmall ? '' : this._('button-save-text');
    this.galleryText = isXSmall ? '' : this._('button-gallery-text');
    this.addAreaText = isXSmall ? '' : this._('button-add-area-text');
    this.deleteDepartmentText = isXSmall ? '' : this._('button-delete-department-text');
    this.questionnaireText = isXSmall ? '' : this._('button-questionnaire-text');
  }

  public isChangesSaved(): boolean {
    return JSON.stringify(this.currentDepartment) === JSON.stringify(this.savedDepartment);
  }

  private createDeleteConfirmationMessage(response: any): string {
    let info = '';

    if (response.energyReceiversToDelete.length === 0 && response.childrenForms.length === 0) {
      info = this._('delete-department-no-areas-and-receivers-message');
      return info;
    }

    info = `<p>${this._('delete-department-warning-message')}</p>`;

    if (response.energyReceiversToDelete.length > 0) {
      const blockingEnergyReceiversListText = this._treeNavigationService.createHtmlList(
        response.energyReceiversToDelete
      );
      info += `<p>${this._('list-of-receivers')}</p> ${blockingEnergyReceiversListText}`;
    }

    if (response.childrenForms.length > 0) {
      const subformsListText = this._treeNavigationService.createHtmlList(response.childrenForms);
      info += `<p>${this._('list-of-subforms')}</p> ${subformsListText}`;
    }

    return info;
  }

  private onCreateArea(): void {
    this.areaForm.addAreaAndStay(this.areaForm.currentArea);
  }

  private onCreateAndDisplayArea(): void {
    this.areaForm.addAreaAndGo(this.areaForm.currentArea);
  }

  onCloseAreaCreation(e: CloseResult) {
    if (e === CloseResult.Confirm) {
      // exit
      this.createAreaPopupVisible = false;
      this.closeFromPopup = true;
    }

    this.createAreaExitPopupVisible = false;
  }

  onAreaCreationPopupHiding(e: any) {
    if (this.closeFromPopup === false) {
      const noChanges = this.areaForm.triggerParentDeactivateCheck();
      if (!noChanges) {
        e.cancel = true;
        this.createAreaExitPopupVisible = true;
      }
    }
    this.closeFromPopup = false;
  }

  onAreaCreationPopupShown(e: any) {
    this._areaService.createCopy(this.areaForm.currentArea);
  }

  private closeFromPopup = false;
  public createAreaExitPopupVisible = false;

  public onDxoPopupShowing(e: any) {
    e.component.content().parentNode.parentNode.classList.add('custom-popup'); //add class to overlay content
  }

  deleteDepartmentPopupVisible = false;
  deleteDepartmentPopupMessage: string;
  onDeleteDepartmentPopupClose(e: any) {
    if (e === CloseResult.Confirm) {
      this.deleteDepartmentRequest(this.currentDepartment.id);
    }

    this.deleteDepartmentPopupVisible = false;
  }
}
