<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-popup
  *ngIf="energyReceiver"
  [width]="(mobile ? popupWidth * 0.9 : 50) + '%'"
  [height]="mobile ? popupHeight * 0.9 + '%' : auto"
  [fullScreen]="false"
  [showTitle]="true"
  [title]="!editMode ? _('energy-balance-add-receiver') : _('energy-balance-edit-receiver')"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [visible]="true"
  [showCloseButton]="true"
  (onHidden)="formClosed()"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <div class="responsive-paddings">
    <dx-scroll-view
      width="100%"
      height="100%"
    >
      <dx-validation-group>
        <dx-form [showColonAfterLabel]="true">
          <dxi-item [dataField]="_('energy-balance-receiver-name')">
            <div *dxTemplate>
              <dx-text-box
                [(value)]="energyReceiver.name"
                stylingMode="outlined"
              >
                <dx-validator>
                  <dxi-validation-rule
                    type="required"
                    message="Pole nazwa obszaru nie może być puste."
                  ></dxi-validation-rule>
                </dx-validator>
              </dx-text-box>
            </div>
          </dxi-item>

          <dxi-item [dataField]="_('energy-balance-receiver-amount')">
            <div *dxTemplate>
              <dx-number-box
                step="0"
                [format]="fullNumberFormat"
                min="0"
                max="10000000"
                showClearButton="true"
                [(value)]="energyReceiver.energyAmount"
                stylingMode="outlined"
              >
                <dx-validator>
                  <dxi-validation-rule type="required"> </dxi-validation-rule>
                </dx-validator>
              </dx-number-box>
            </div>
          </dxi-item>

          <dxi-item [dataField]="_('energy-balance-receiver-unit')">
            <div *dxTemplate>
              <dx-select-box
                [items]="unitsList"
                displayExpr="shortName"
                valueExpr="id"
                [(value)]="energyReceiver.unitId"
                placeholder="Wybierz jednostkę"
                [searchEnabled]="true"
                searchMode="contains"
              >
                <dx-validator>
                  <dxi-validation-rule
                    type="required"
                    message="Pole jest wymagane"
                  >
                  </dxi-validation-rule>
                </dx-validator>
              </dx-select-box>
            </div>
          </dxi-item>

          <dxi-item [dataField]="_('energy-balance-receiver-energy-cost')">
            <div *dxTemplate>
              <dx-number-box
                step="0"
                min="0"
                max="10000000"
                showClearButton="true"
                [format]="currencyFormat"
                [(value)]="energyReceiver.energyCost"
                stylingMode="outlined"
              >
              </dx-number-box>
            </div>
          </dxi-item>

          <dxi-item>
            <div *dxTemplate>
              <dx-button
                [text]="editMode ? _('save-changes') : _('energy-balance-add-receiver')"
                width="100%"
                [icon]="editMode ? 'save' : 'add'"
                type="normal"
                (onClick)="
                  editMode
                    ? updateEnergyBalanceReceiver($event, energyReceiver)
                    : addNewReceiver($event, energyReceiver)
                "
                class="outlined-button-focus-hover"
                stylingMode="outlined"
              >
              </dx-button>
            </div>
          </dxi-item>
        </dx-form>
      </dx-validation-group>
    </dx-scroll-view>
  </div>
</dx-popup>
