import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  Output,
  EventEmitter,
} from '@angular/core';
import { QuestionnaireService } from './questionnaire.service';
import { Question } from './models/questions.models';
import notify from 'devextreme/ui/notify';
import { AppSettings } from '../../../AppSettings';
import { AppConfig } from '../../../app.config';
import { ScrollService } from '../../services/scroll.service';
import { takeUntil } from 'rxjs/operators';
import { formatMessage } from 'devextreme/localization';
import { Localizable } from '../../../locale/localizable';
import { QuestionnaireGroupService } from './questionnaireGroup.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent extends Localizable implements OnInit, AfterViewInit {
  @ViewChildren('questions') itemsElement: QueryList<ElementRef>;

  public lastQuestion: boolean = false;
  public validationError: boolean = false;
  public questionsList: Array<Question> = new Array<Question>();

  public title: string = this._('questionnaire-title');
  private urlToApi: string;

  @Input() departmentId = '';
  @Input() set questionnairePopupVisible(value) {
    this._popupVisible = value;
    if (value === false) {
      this.questionnairePopupVisibleChange.emit(value);
    }
  }
  get questionnairePopupVisible() {
    return this._popupVisible;
  }
  @Input() isAuditFinished: boolean = false;
  @Output() questionnairePopupVisibleChange = new EventEmitter<boolean>();

  private _popupVisible: boolean;

  constructor(
    private _questionnaireService: QuestionnaireService,
    private _questionnaireGroupService: QuestionnaireGroupService,
    private _config: AppConfig,
    private _scrollService: ScrollService
  ) {
    super();
    this.urlToApi = this._config.getConfig('DbEnergyDatabaseUrl');
  }

  ngOnInit(): void {
    this._questionnaireService.nextQuestion.pipe(takeUntil(this.onDestroy)).subscribe((question) => {
      const lastOrderedQuestionGuid = this._questionnaireService.linearQuestionGuids.slice(-1);
      if (this._questionnaireService.linearQuestionGuids.length > 0 && question.guid !== lastOrderedQuestionGuid[0]) {
        const indexOfQuestion = this._questionnaireService.linearQuestionGuids.findIndex((q) => q === question.guid);
        if (indexOfQuestion !== -1) {
          this.questionsList.splice(indexOfQuestion, 0, question);
        }
      } else {
        this.questionsList.push(question);
      }
    });

    this._questionnaireService.lastQuestion.pipe(takeUntil(this.onDestroy)).subscribe((last) => {
      this.lastQuestion = last;
      if (last) {
        setTimeout(() => {
          const elem = document.getElementById('lastQuestion');
          this.autoScrollToElement(elem);
        });
      }
    });

    this._questionnaireGroupService.clearQuestionsSubject.pipe(takeUntil(this.onDestroy)).subscribe((clear) => {
      if (clear) {
        this.questionsList = [];
      }
    });

    this._questionnaireService.removeQuestionGuid.pipe(takeUntil(this.onDestroy)).subscribe((guid) => {
      this.removeQuestion(guid);
    });

    this._questionnaireGroupService.closeQuestionnairePopupSubject
      .pipe(takeUntil(this.onDestroy))
      .subscribe((close) => {
        this.questionnairePopupVisible = close;
      });

    this._questionnaireGroupService.redownloadFirstGroupSubject
      .pipe(takeUntil(this.onDestroy))
      .subscribe((redownload) => {
        if (redownload) {
          this._questionnaireGroupService.getQuestionnaireFileByDepartmentId(this.departmentId);
        }
      });
  }

  ngAfterViewInit(): void {
    this.itemsElement.changes.subscribe((res) => {
      if (res.last !== undefined && res.last.nativeElement !== undefined) {
        const elem = res.last.nativeElement;
        this.autoScrollToElement(elem);
      }
    });

    this._questionnaireGroupService.findQuestion();
    this._questionnaireService.findRootQuestion();
    this._questionnaireService.fillTreeGraph();
  }

  public autoScrollToElement(elem): void {
    if (elem == null || elem.offsetParent == null) {
      return;
    }
    const parentClientHeight = elem.offsetParent.offsetParent.offsetParent.offsetParent.clientHeight;
    const offsetTop = elem.offsetTop;
    const elemHeight = elem.offsetHeight;
    const margin = 26;
    if (offsetTop + elemHeight + margin > parentClientHeight) {
      this._scrollService.updateScrollToBottom(elem);
    }
  }

  public disableSaveQuestionGroup(error: boolean) {
    this.validationError = error;
  }

  private removeQuestion(guid: string): void {
    const indexToRemove = this.questionsList.findIndex((a) => a.guid === guid);
    if (indexToRemove !== -1) {
      this.questionsList.splice(indexToRemove, 1);
    }
  }

  private errorLoadToast(text?: string): void {
    notify({
      message: text ? text : formatMessage('load-questionnaire-error'),
      type: 'error',
      displayTime: AppSettings.NOTIFY_DURATION,
      position: 'top center',
    });
  }
}
