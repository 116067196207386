<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="airCompressor.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('air-compressor-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="airCompressor.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('air-compressor-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="airCompressor.serialNumber"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('air-compressor-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="airCompressor.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-compressor-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="airCompressorTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="airCompressor.compressorType"
        [dataSource]="airCompressorTypesList"
        displayExpr="name"
        stylingMode="outlined"
        placeholder="Wybierz typ sprężarki"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('air-compressor-type')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="efficiencyRegulationTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="airCompressor.efficiencyRegulationType"
        [dataSource]="efficiencyRegulationTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('air-compressor-efficiency-regulation-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('air-compressor-efficiency-regulation')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="entityTypeAirCompressorsList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="airCompressor.entityTypeAirCompressor"
        [dataSource]="entityTypeAirCompressorsList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('air-compressor-unit-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('air-compressor-unit-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' bar'"
        [(value)]="airCompressor.ratedOperatingPressure"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-compressor-rated-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³/min'"
        [(value)]="airCompressor.ratedEfficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-compressor-rated-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="airCompressor.compressorEngineRatedPower"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-compressor-rated-engine-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="airCompressor.dryer"
        switchedOffText="Nie"
        switchedOnText="Tak"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('air-compressor-dryer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="airCompressor.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-compressor-working-hours')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="airCompressor.workingHoursUnderLoad"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-compressor-working-hours-under-load')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="airCompressor.workingHoursOnGear"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-compressor-working-hours-on-gear')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="airCompressor.workingHoursInAnalyzedPeriod"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-compressor-work-time-in-period')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="airCompressor.workingHoursOnIdleGear"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-compressor-working-hours-on-idle-gear-in-period')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="airCompressor.powerConsumptionLoad"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-compressor-power-consumption-load')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="airCompressor.powerConsumptionIdle"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-compressor-power-consumption-idle-gear')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³'"
        [(value)]="airCompressor.compressedAirProduction"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-compressor-air-production')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' MWh'"
        [(value)]="airCompressor.electricityConsumptionAnalyzedPeriod"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-compressor-electricity-consumption-in-period')"></dxo-label>
  </dxi-item>
</dx-form>
