import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { GalleryService } from '../../../services/gallery.service';
import { AppConfig } from '../../../../app.config';
import { AppSettings } from '../../../../AppSettings';
import { Localizable } from '../../../../locale/localizable';
import { UploadErrorEvent } from 'devextreme/ui/file_uploader';
import { ErrorHandlerService } from '../../../services/errorHandlerService';
import { AuthenticationService } from '../../../services/authentication.service';
import { AuditGalleryComponent } from '../audit-gallery/audit-gallery.component';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent extends Localizable implements OnInit, OnDestroy {
  @Output()
  public uploadedImage: EventEmitter<string> = new EventEmitter<string>();

  public uploadUrl: string;
  public maxImageSize: number = AppSettings.MAX_IMAGE_SIZE;

  private imageIdSubscription: Subscription;

  constructor(
    private _appConfig: AppConfig,
    private _galleryService: GalleryService,
    private _errorHandlerService: ErrorHandlerService,
    private _authenticationService: AuthenticationService
  ) {
    super(AuditGalleryComponent.localFile);
  }

  ngOnInit(): void {
    this.imageIdSubscription = this._galleryService.galleryId.subscribe((galleryId: string) => {
      this.uploadUrl = this._appConfig.getConfig('DbEnergyDatabaseUrl') + `api/galleries/${galleryId}/images`;
    });
  }

  ngOnDestroy(): void {
    this.imageIdSubscription.unsubscribe();
  }

  public get token(): string {
    return this._authenticationService.getBearerTokenHeader();
  }

  public handleUploadError(error: UploadErrorEvent) {
    this._errorHandlerService.handleError(error.error);
  }

  public uploadComplete(event: any): void {
    const id: string = event.request.response.toString();
    this.uploadedImage.emit(id.replace(/[^a-zA-Z0-9-]/g, ''));
  }
}
