import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { PowerGenerator } from 'src/app/shared/models/area/types/power-generator';
import { FuelType } from 'src/app/shared/models/area/types/water-heater.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { AppConfig } from '../../../../../../../app.config';
import { Localizable } from '../../../../../../../locale/localizable';

@Component({
  selector: 'app-power-generator',
  templateUrl: './power-generator.component.html',
  styleUrls: ['./power-generator.component.scss'],
})
export class PowerGeneratorComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  powerGenerator: PowerGenerator = null;

  @Input()
  templateMode: boolean = false;

  docVariableCode: string = null;

  public fuelTypesList: ArrayStore;
  public currentYear: number;

  private urlToDatabase: string;

  constructor(
    private _apiRequestService: ApiRequestService,
    private _httpClient: HttpClient,
    private _config: AppConfig
  ) {
    super();
    this.urlToDatabase = this._config.getConfig('DbEnergyDatabaseUrl');
  }

  ngOnInit(): void {
    if (this.templateMode) {
      this._apiRequestService.getPowerGeneratorDocVariableCode().subscribe((res) => {
        this.docVariableCode = res;
        this.powerGenerator = this.getNewEmptyPowerGenerator();
      });
    } else {
      this.currentYear = new Date().getFullYear();
      this._httpClient
        .get<Array<FuelType>>(this.urlToDatabase + `api/areas/power-generators/fuel-types`)
        .subscribe((res: Array<FuelType>) => {
          this.fuelTypesList = new ArrayStore({
            key: 'id',
            data: res,
          });
        });
    }
  }

  private getNewEmptyPowerGenerator(): PowerGenerator {
    return {
      name: '',
      description: '',
      conclusion: '',
      producer: '',
      model: '',
      serialNumber: '',
      productionYear: 1950,
      power: 0,
      fuelType: null,
    };
  }
}
