<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div>
  <div>
    <div class="credentials">
      <h3>{{ _('password-reset-confirmation-title') }}</h3>
      <form (keyup.enter)="FormPasswordManualConfirm()">
        <dx-form
          [formData]="DataPasswordManual"
          (onInitialized)="saveFormDataPasswordInstance($event)"
          [showColonAfterLabel]="true"
        >
          <dxi-item
            dataField="NewPassword"
            editorType="dxTextBox"
            [editorOptions]="oldPasswordButton"
          >
            <dxo-label
              class="field-label"
              text="{{ _('user-newPasswordLabel') }}"
            >
            </dxo-label>
            <dxi-validation-rule
              type="required"
              message="{{ _('user-newPasswordRequiredMessage') }}"
            >
            </dxi-validation-rule>
            <dxi-validation-rule
              type="pattern"
              [pattern]="passwordPattern"
              message="{{ _('user-passwordPatternValidationMessage') }}"
            >
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item
            dataField="ConfirmNewPassword"
            editorType="dxTextBox"
            [editorOptions]="oldPasswordButton"
          >
            <dxo-label
              class="field-label"
              text="{{ _('user-newPasswordConfirmLabel') }}"
            >
            </dxo-label>
            <dxi-validation-rule
              type="required"
              message="{{ _('user-newPasswordConfirmRequiredMessage') }}"
            >
            </dxi-validation-rule>
            <dxi-validation-rule
              type="compare"
              [comparisonTarget]="passwordComparison"
              message="{{ _('user-newPasswordConfirmValidationMessage') }}"
            >
            </dxi-validation-rule>
          </dxi-item>
          <dxi-item>
            <div class="center_button button_bottom_margin">
              <dx-button
                text="{{ _('user-resetPasswordButton') }}"
                icon="todo"
                (onClick)="FormPasswordManualConfirm()"
                stylingMode="outlined"
                class="outlined-button-focus-hover"
              >
              </dx-button>
            </div>
          </dxi-item>
        </dx-form>
      </form>
    </div>
  </div>
</div>
