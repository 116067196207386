import { Component } from '@angular/core';
import { AppInfoService } from '../../services';

@Component({
  selector: 'app-footer',
  template: `
    <footer class="content-block">
      Copyright © 2020-{{ appInfo.currentYear }} {{ appInfo.title }} Inc.
      <br />
      All trademarks or registered trademarks are property of their respective owners.
    </footer>
  `,
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(public appInfo: AppInfoService) {}
}
