import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { AuditType } from 'src/app/shared/models/audit/audit-type.model';
import ArrayStore from 'devextreme/data/array_store';
import { User } from 'src/app/shared/models/user.model';
import DataSource from 'devextreme/data/data_source';
import { Audit } from 'src/app/shared/models/audit/audit.model';
import { map, takeUntil } from 'rxjs/operators';
import { AuditService } from './audit.service';
import { ActivatedRoute, Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { Currency } from 'src/app/shared/models/audit/currency.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { TreeNavigationService } from 'src/app/shared/services/tree-navigation.service';
import { DxFormComponent } from 'devextreme-angular';
import { FormMenuActions } from '../../../models/FormMenuActions.enum';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../../../../AppSettings';
import * as saveAs from 'file-saver';
import { AppConfig } from '../../../../app.config';
import { ScreenService } from '../../../services';
import { FormType } from '../../../models/audit/audit-tree-navigation.model';
import { Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Localizable } from '../../../../locale/localizable';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { CloseResult } from '../../questionnaire/exit-confirmation-popup/exit-confirmation-popup.component';
import { AuditsCountService } from '../../../services/audits-count.service';
import { GalleryService } from '../../../services/gallery.service';
import { CanComponentDeactivate } from '../../../../core/guards/can-deactivate.guard';

@Component({
  selector: 'app-audit-form',
  templateUrl: './audit-form.component.html',
  styleUrls: ['./audit-form.component.scss'],
})
export class AuditFormComponent extends Localizable implements OnInit, AfterViewInit, CanComponentDeactivate {
  @ViewChild(DxFormComponent, { static: false }) auditForm: DxFormComponent;

  @Input() auditId: string;
  @Input() templateMode: boolean = false;

  @Output() auditNameEvent = new EventEmitter<string>();

  public dropDownOptions = {
    hideOnParentScroll: true,
  };

  public currentAudit: Audit;
  public currentUser: User;
  public tagBoxValueChangeInProgress: boolean = false;

  public screenWidth: string;
  public auditTypes: Array<AuditType>;
  public dataAuditTypes: ArrayStore;
  public currencies: ArrayStore;

  public users: Array<User>;
  public auditUsers: Array<string>;
  public sourceUsers: DataSource;
  public showMissedTemplates: boolean = false;
  public canGenerateReport: boolean = true;

  public docVariableCode: string = null;
  public missingTemplates: Array<string> = null;

  public isGalleryVisible: boolean = false;
  public isEnergyBalanceVisible: boolean = false;
  public isMobile: boolean = false;

  public saveText: string = '';
  public galleryText: string = '';
  public generateText: string = '';
  public deleteText: string = '';
  public sankeyText: string = '';

  public imagesNumber: number = 0;

  private urlDbEnergyDatabase: string = null;
  private savedAudit: Audit;
  public isChangingDisabled = false;

  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;

  public showAnimation: any;

  public sankeyPopupShowed: boolean = false;
  isAuditFinishedFlag: boolean = false;
  private _isDeleted: boolean = false;

  phonePattern: any = /^\+?\d[\d\s]{6,20}$/;

  phoneRules: any = {
    X: /[01-9]/,
  };

  triggerParentDeactivateCheck(): boolean {
    if (this.currentAudit && this._isDeleted === false) {
      return !this._auditService.formChanged(this.currentAudit);
    } else {
      return true;
    }
  }

  canBeDeactivated(): boolean {
    return this.triggerParentDeactivateCheck();
  }

  activeIndex: number = 0;
  private _auditCountService: AuditsCountService = inject(AuditsCountService);

  constructor(
    private _auditService: AuditService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _apiRequestService: ApiRequestService,
    private _treeNavigationService: TreeNavigationService,
    private _httpClient: HttpClient,
    private _config: AppConfig,
    private _screenService: ScreenService,
    private _authenticationService: AuthenticationService,
    private _galleryService: GalleryService
  ) {
    super('AuditsListComponent');

    this.urlDbEnergyDatabase = this._config.getConfig('DbEnergyDatabaseUrl');

    this.onTagBoxValueChanged = this.onTagBoxValueChanged.bind(this);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._route.params.pipe(takeUntil(this.onDestroy)).subscribe((params) => {
      this.auditId = params['auditId'];
    });

    this._route.firstChild?.url.subscribe((urlPath) => {
      const path = urlPath[0]?.path;
      this.setActiveTab(path);
    });

    this._auditService.performAction.pipe(takeUntil(this.onDestroy)).subscribe((action) => {
      if (action) {
        this.selectedAction(action);
      }
    });

    this._screenService.changed
      .pipe(takeUntil(this.onDestroy))
      .subscribe((screenSizeBreakPoint: BreakpointState) => this.updateButtonsText(screenSizeBreakPoint));
    this._screenService.isMobile
      .pipe(takeUntil(this.onDestroy))
      .subscribe((isMobile: boolean) => (this.isMobile = isMobile));

    this._auditService
      .getAuditTypes()
      .pipe(
        map((response) => {
          this.auditTypes = response;
          this.dataAuditTypes = new ArrayStore({
            key: 'id',
            data: this.auditTypes,
          });
        })
      )
      .subscribe();

    this._auditService
      .getCurrencies()
      .pipe(
        map((response) => {
          this.currencies = new ArrayStore({
            key: 'id',
            data: response,
          });
        })
      )
      .subscribe();

    this._auditService
      .getUsersList()
      .pipe(
        map((response: any) => {
          this.users = response.data;
          this.sourceUsers = new DataSource({
            store: new ArrayStore({
              key: 'id',
              data: this.users,
            }),
          });
        })
      )
      .subscribe();

    if (this.auditId != null) {
      this._auditService
        .getCurrentAudit(this.auditId)
        .pipe(
          map((response) => {
            this.currentAudit = response;
            this._auditService.createCopy(this.currentAudit);
            if (this.currentAudit) {
              this.savedAudit = this._treeNavigationService.deepCopy(this.currentAudit);
              this.isAuditFinishedFlag = this.currentAudit.isFinished;
              this._auditService.toggleFinishedAudit(this.isAuditFinishedFlag);
              this.auditNameEvent.emit(this.currentAudit.name);
              this._auditService.formLoaded(true);
              this.getImagesNumberInGallery(this.currentAudit.formGallery.id);
              this.assignAuditUsers(this.currentAudit.users);
              const currentUser = this._authenticationService.getDecodedCurrentUser();
              this.currentUser = this.currentAudit.users.find((user) => user.email === currentUser.email);
            }
          })
        )
        .subscribe();
    } else {
      this.currentAudit = this._auditService.getEmptyAudit();
      this._auditService.formLoaded(true);
      this.savedAudit = this._treeNavigationService.deepCopy(this.currentAudit);
    }

    if (this.templateMode) {
      this._apiRequestService.getAuditDocVariableCode().subscribe((res) => {
        this.docVariableCode = res;
      });
    }

    this._treeNavigationService.checkFormData.pipe(takeUntil(this.onDestroy)).subscribe((result) => {
      if (result) {
        this._treeNavigationService.updateformDataSaved(this.currentAudit, this.savedAudit, this.auditId);
      }
    });

    this._treeNavigationService.exitFormReturn.pipe(takeUntil(this.onDestroy)).subscribe((result) => {
      switch (result) {
        case CloseResult.Cancel:
          this._treeNavigationService.exitFormNoFn();
          break;
        case CloseResult.Confirm:
          this._treeNavigationService.exitFormYesFn();
          break;
        case CloseResult.Special:
          this._treeNavigationService.exitFormSpecialFn();
          break;
      }
    });
  }

  assignAuditUsers(users: User[]) {
    this.auditUsers = users.map((u) => u.id);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._auditService.formLoaded(false);
  }

  ngAfterViewInit(): void {
    this._treeNavigationService.setComponentRendered(this);
  }

  getImagesNumber() {
    let imagesNumberResult = this._galleryService.getImagesNumberByFormId(this.auditId.toUpperCase());
    if (imagesNumberResult == null) {
      imagesNumberResult = this.imagesNumber;
    }
    return imagesNumberResult;
  }

  createAudit(audit: Audit): void {
    const validator: any = this.auditForm.instance.validate();
    if (!validator.isValid) {
      validator.brokenRules[0].validator.focus();
    } else if (audit.fromDate > audit.toDate) {
      notify({
        message: this._('audit-create-validation-date-error'),
        type: 'error',
        displayTime: AppSettings.NOTIFY_DURATION,
        position: AppSettings.NOTIFY_TOP_POSTION,
      });
    } else {
      this._auditService
        .createAudit(audit)
        .pipe(
          map((response) => {
            this._treeNavigationService.changeForm(FormType.Audit, response.id.toUpperCase());
          })
        )
        .subscribe(() => {
          this._auditCountService.updateAuditCount();
        });
    }
  }

  createAuditAndGoToEnterprise(audit: Audit): void {
    const validator: any = this.auditForm.instance.validate();
    if (!validator.isValid) {
      validator.brokenRules[0].validator.focus();
    } else if (audit.fromDate > audit.toDate) {
      notify({
        message: this._('audit-create-validation-date-error'),
        type: 'error',
        displayTime: AppSettings.NOTIFY_DURATION,
        position: AppSettings.NOTIFY_TOP_POSTION,
      });
    } else {
      this._auditService
        .createAudit(audit)
        .pipe(
          map((response) => {
            this._treeNavigationService.addExpandedRowKey(response.id.toString().toUpperCase());
            this._router.navigate([`../pages/audit/${response.id}/enterprise/${response.enterprise.id}`]);
          })
        )
        .subscribe(() => {
          this._auditCountService.updateAuditCount();
        });
    }
  }

  private async checkBeforeToggleFinishedAudit(): Promise<void> {
    const isChanged = this._auditService.formChanged(this.currentAudit);
    if (isChanged) {
      this._treeNavigationService.exitFormYesFn = () => {
        this.currentAudit = this.savedAudit;
        this.toggleFinishedAudit();
        return true;
      };
      this._treeNavigationService.exitFormSpecialFn = () => {
        this.toggleFinishedAudit();
        return true;
      };
      this._treeNavigationService.exitFormNoFn = () => {
        return true;
      };
      this._treeNavigationService.setAuditCloseFormVisible(true);
    } else {
      this.toggleFinishedAudit();
    }
  }

  private toggleFinishedAudit() {
    this.currentAudit.isFinished = !this.currentAudit.isFinished;
    this.save(this.currentAudit, true);
    this.isAuditFinishedFlag = this.currentAudit.isFinished;
    this._auditService.toggleFinishedAudit(this.isAuditFinishedFlag);
  }

  isAuditFinished(): boolean {
    return this.isAuditFinishedFlag;
  }

  deleteAudit(id: string): void {
    this.deleteAuditPopupVisible = true;
  }

  save(audit: Audit, finishAudit?: boolean): void {
    const validator: any = this.auditForm.instance.validate();
    if (!validator.isValid) {
      validator.brokenRules[0].validator.focus();
    } else {
      this._apiRequestService.saveAudit(audit).subscribe((res: Audit) => {
        this.currentAudit = res;
        this._auditService.createCopy(this.currentAudit);
        this.savedAudit = this._treeNavigationService.deepCopy(this.currentAudit);
        this._treeNavigationService.getAuditNavigationTree(res.id).subscribe();
        this._auditService.formLoaded(true);

        if (!finishAudit) {
          notify({
            message: this._('changes-saved'),
            type: 'success',
            displayTime: AppSettings.NOTIFY_DURATION,
            position: AppSettings.NOTIFY_TOP_POSTION,
          });
        }
      });
    }
  }

  public report(id: string): void {
    this._httpClient
      .get(this.urlDbEnergyDatabase + `api/audits/${id}/check-report`)
      .subscribe((response: Array<string>) => {
        this.missingTemplates = response;
        if (response.length === 0) {
          this.generateReport(id);
        } else {
          if (this.missingTemplates.includes(AppSettings.AUDIT_MAIN_TEMPLATE)) {
            this.canGenerateReport = false;
            notify({
              message: this._('raport-generated-error'),
              type: 'error',
              displayTime: AppSettings.NOTIFY_DURATION,
              position: AppSettings.NOTIFY_TOP_POSTION,
            });
          } else {
            this.showMissedTemplates = true;
          }
        }
      });
  }

  public generateReport(id: string): void {
    this._httpClient
      .get(this.urlDbEnergyDatabase + `api/audits/${id}/report`, {
        responseType: 'blob',
      })
      .subscribe((res: Blob) => {
        saveAs(res, this._('report-name'));
        notify({
          message: this._('raport-generated'),
          type: 'success',
          displayTime: AppSettings.NOTIFY_DURATION,
          position: AppSettings.NOTIFY_TOP_POSTION,
        });
      });
  }

  public getImagesNumberInGallery(galleryId: string): void {
    this._httpClient
      .get(this.urlDbEnergyDatabase + `api/galleries/${galleryId}/images-number`)
      .subscribe((res: number) => {
        this.imagesNumber = res;
      });
  }

  public galleryClosed(e: boolean): void {
    this.isGalleryVisible = false;
    this.getImagesNumberInGallery(this.currentAudit.formGallery.id);
  }

  public saveForm() {
    this.save(this.currentAudit);
  }

  public selectedAction(e: any): void {
    if (e.itemData.key === FormMenuActions.Save) {
      this.saveForm();
    }
    if (e.itemData.key === FormMenuActions.Delete) {
      this.deleteAudit(this.currentAudit.id);
    }
    if (e.itemData.key === FormMenuActions.Report) {
      this.report(this.currentAudit.id);
    }
    if (e.itemData.key === FormMenuActions.Gallery) {
      this.showGallery();
    }
    if (e.itemData.key === FormMenuActions.Sankey) {
      this.showEnergyBalance();
    }
    if (e.itemData.key === FormMenuActions.ToggleFinishedAudit) {
      this.checkBeforeToggleFinishedAudit();
    }
  }

  setActiveTab(path: string): void {
    switch (path) {
      case AppSettings.FORM_TAB:
        this.activeIndex = 0;
        break;
      case AppSettings.GALLERY_TAB:
        this.activeIndex = 1;
        this.showGallery();
        break;
      case AppSettings.SANKEY_TAB:
        this.activeIndex = 2;
        this.showEnergyBalance();
        break;
      default:
        this.activeIndex = 0;
    }
  }

  handleChange(e: any): void {
    const tabMap = [AppSettings.FORM_TAB, AppSettings.GALLERY_TAB, AppSettings.SANKEY_TAB];
    const path = tabMap[e.index];
    this._router.navigate([path], { relativeTo: this._route });
    this.setActiveTab(path);
  }

  public showGallery(): void {
    this.isGalleryVisible = true;
  }

  public showEnergyBalance(): void {
    this.isEnergyBalanceVisible = true;
  }

  public getDisplayExpr(data: Currency): string {
    if (!data) {
      return '';
    }
    return `${data.short} (${data.name})`;
  }

  private updateButtonsText(screenSize: BreakpointState) {
    const isXSmall = screenSize.breakpoints[Breakpoints.XSmall];

    this.saveText = isXSmall ? '' : this._('button-save-text');
    this.galleryText = isXSmall ? '' : this._('button-gallery-text');
    this.generateText = isXSmall ? '' : this._('button-generate-raport-text');
    this.deleteText = isXSmall ? '' : this._('button-delete-text');
    this.sankeyText = isXSmall ? '' : this._('button-sankey-text');
  }

  public onTagBoxValueChanged(e) {
    if (this.tagBoxValueChangeInProgress) {
      this.tagBoxValueChangeInProgress = false;
      return;
    }

    if (!this.auditId && !this.templateMode) {
      return;
    }

    // prepare users based on auditUsers (string => user object)
    const usersArray = [];
    e.value.forEach((val) => {
      let usersUser = this.currentAudit.users.find((user) => user.id === val);
      if (usersUser === undefined) {
        usersUser = this.users.find((user) => user.id === val);
      }

      if (usersUser !== undefined) {
        usersArray.push(usersUser);
      }
    });

    const currentUser = JSON.parse(localStorage.getItem(AppSettings.AUTH_CURRENT_USER));
    if (currentUser.roleName === AppSettings.ADMIN_ROLE_NAME) {
      // update backend collection
      this.currentAudit.users = usersArray;
      return;
    }

    const userExists = usersArray.some((user) => user.email === currentUser.email);
    if (!userExists) {
      this.tagBoxValueChangeInProgress = true;
      if (e.value.length === 0 && e.previousValue.length > 1) {
        e.component.option('value', [e.previousValue.find((user) => user.id === this.currentUser.id)]);
      } else {
        e.component.option('value', e.previousValue);
        notify(this._('audit-select-users-email-valiation'), 'error');
      }
      return;
    }

    // update backend collection
    this.currentAudit.users = usersArray;
  }

  customUsersDisplayExpr(item) {
    return item && `${item.firstName} ${item.lastName}`;
  }

  deleteAuditPopupVisible = false;
  onDeleteAuditPopupClose(e: any) {
    if (e === CloseResult.Confirm) {
      this._apiRequestService.deleteAudit(this.currentAudit.id).subscribe(() => {
        this._isDeleted = true;
        this._auditCountService.updateAuditCount();
        this._router.navigate(['../pages/audits-list']);
      });
    }
    this.deleteAuditPopupVisible = false;
  }
}
