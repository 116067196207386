import { Component, Input, OnInit, inject } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { AppConfig } from 'src/app/app.config';
import {
  FleetOwnership,
  Transport,
  VehicleFuelType,
  VehicleType,
} from 'src/app/shared/models/area/types/transport.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { Localizable } from '../../../../../../../locale/localizable';
import { AuthenticationService } from '../../../../../../services/authentication.service';
import { AppSettings } from '../../../../../../../AppSettings';
import { ErrorHandlerService } from '../../../../../../services/errorHandlerService';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss'],
})
export class TransportComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  transport: Transport = null;

  @Input()
  templateMode: boolean = false;

  fleetOwnershipsList: ArrayStore;
  vehiclesList: any;
  vehiclesTypesList: Array<VehicleType>;
  fuelTypesList: Array<VehicleFuelType>;
  docVariableCode: string = '';

  constructor(
    private _apiRequestService: ApiRequestService,
    private _config: AppConfig,
    private _errorHandlerService: ErrorHandlerService,
    private _authenticationService: AuthenticationService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.templateMode) {
      this._apiRequestService.getTransportAreaDocVariableCode().subscribe((res) => {
        this.docVariableCode = res;
      });

      this.transport = this.getEmptyTransport();
      this.vehiclesList = this.transport.vehiclesList;
    } else {
      const urlDBEnergyDatabase = this._config.getConfig('DbEnergyDatabaseUrl');

      this._apiRequestService.getAreaTypeTransportFleetOwnerships().subscribe((res: Array<FleetOwnership>) => {
        this.fleetOwnershipsList = new ArrayStore({
          key: 'id',
          data: res,
        });
      });

      this._apiRequestService.getAreaTypeTransportVehicleTypes().subscribe((res: Array<VehicleType>) => {
        this.vehiclesTypesList = res;
      });

      this._apiRequestService.getAreaTypeTransportFuelTypes().subscribe((res: Array<VehicleFuelType>) => {
        this.fuelTypesList = res;
      });

      this.vehiclesList = AspNetData.createStore({
        key: 'id',
        loadUrl: `${urlDBEnergyDatabase}api/areas/transports/${this.transport.id}/vehicles`,
        insertUrl: `${urlDBEnergyDatabase}api/areas/transports/${this.transport.id}/vehicles`,
        updateUrl: `${urlDBEnergyDatabase}api/areas/transports/vehicles`,
        deleteUrl: `${urlDBEnergyDatabase}api/areas/transports/vehicles`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: true };
          const token = localStorage.getItem(AppSettings.AUTH_TOKEN);
          ajaxOptions.headers = { Authorization: `Bearer ${token}` };
        },
        onAjaxError: (e) => {
          if (e.xhr.status === AppSettings.HTTP_STATUS_CODE_NOT_AUTHORIZED) {
            this._authenticationService.logout();
            notify(this._('not-authorized-logout'), 'error', AppSettings.NOTIFY_DURATION);
          } else {
            this._errorHandlerService.handleError(e.xhr);
          }
        },
      });
    }
  }

  public getEmptyTransport(): Transport {
    return {
      fleetOwnership: null,
      vehiclesList: [
        {
          producer: 'Producent',
          model: 'Model',
          registrationNumber: 'Number rejestracyjny',
          power: 0,
          engineCapacity: 0,
          annualKm: 0,
          annualFuelConsumption: 0,
          vehicleType: { name: 'Rodzaj pojazdu' },
          vehicleFuelType: { name: 'Rodzaj paliwa' },
        },
      ],
      vehicleTrackingSystem: false,
      ecoDrivingTraining: false,
      ecoDrivingPrinciples: false,
      description: '',
      name: '',
      conclusion: '',
    };
  }
}
