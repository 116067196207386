import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Audit } from '../models/audit/audit.model';
import { User } from '../models/user.model';
import { Enterprise } from '../models/enterprise/enterprise.model';
import { AppConfig } from 'src/app/app.config';
import { AuditType } from '../models/audit/audit-type.model';
import { AuditTreeNavigation } from '../models/audit/audit-tree-navigation.model';
import { Department } from '../models/department/department.model';
import { Area, AreaType } from '../models/area/area.model';
import { Currency } from '../models/audit/currency.model';
import { IndustryType } from '../models/enterprise/industry-type.model';
import { BoilerRoom } from '../models/area/types/boiler-room.model';
import { Unit } from '../models/unit.model';
import {
  DtoEnergyBalanceReceiver,
  DtoEnergyBalanceSource,
  EnergyBalanceReceiver,
  EnergyBalanceSource,
  EnergyBalanceType,
} from '../models/energies/energy.model';
import { AuditTimeRange } from '../models/audit/audit-time-range.model';
import { ActionType } from '../models/area/action-type.model';
import { Boiler, Medium } from '../models/area/types/boiler.model';
import {
  OperatingPointVolatility,
  RegulationType,
  TechnicalCondition,
  WaterPump,
} from '../models/area/types/water-pump.model';
import { Fan, FanType } from '../models/area/types/fan.model';
import {
  HeatRecovery,
  HeatRecoveryPlace,
  HeatRecoveryType,
  RecoveryMediumType,
} from '../models/area/types/heat-recovery.model';
import {
  ConnectionGroupe,
  ElectricityConnection,
  EnergyDistributor,
  EnergySeller,
  MeasuringSystem,
  Tariff,
} from '../models/area/types/electricity-connection.model';
import { Photovoltaics } from '../models/area/types/photovoltaics.model';
import { AirConditioner } from '../models/area/types/air-conditioner.model';
import { RefrigerantType } from '../models/area/types/cooling-system.model';
import { Deaerator, DeaeratorSupplyType, DegassingType, WaterSupplyType } from '../models/area/types/deaerator.model';
import { ColdCompressor } from '../models/area/types/cold-compressor.model';
import { GassCogeneration, GassCogenerationSourceType } from '../models/area/types/gass-cogeneration.model';
import { AbsorptionAggregate, AbsorptionType, WorkFactor } from '../models/area/types/absorption-aggregate';
import { Blower, BlowerOperatingPointVolatility, BlowerRegulationType, BlowerType } from '../models/area/types/blower';
import { CompressedAirGenerator } from '../models/area/types/compressed-air-generator';
import { AirEnhancer, AirEnhancerType } from '../models/area/types/air-enhancer.model';
import { ElectricHeater, ElectricHeaterType } from '../models/area/types/electric-heater.model';
import {
  HeatPump,
  HeatPumpBottomSourceTemp,
  HeatPumpBottomSourceType,
  HeatPumpTopSourceType,
  HeatPumpType,
} from '../models/area/types/heat-pump.model';
import {
  AirCompressor,
  CompressorType,
  EfficiencyRegulationType,
  EntityTypeAirCompressor,
} from '../models/area/types/air-compressor';
import { FanCooler, FanCoolerControl, RefrigeratedMedium } from '../models/area/types/fan-cooler.model';
import { AirCurtain, AirCurtainType } from '../models/area/types/air-curtain.model';
import { Dehumidifier, DehumidifierType } from '../models/area/types/dehumidifier.model';
import { Building, BuildingType, ErectingBuildingTechnology } from '../models/area/types/building.model';
import { FuelType, WaterHeater, WaterHeaterType } from '../models/area/types/water-heater.model';
import { SteamGenerator, SteamGeneratorFuelType } from '../models/area/types/steam-generator.model';
import { FixtureType, Lighting, LightingControlSystem } from '../models/area/types/lighting.model';
import { RadiantHeater, RadiantHeaterSupplyType } from '../models/area/types/radiant-heater.model';
import { Armature, ArmatureInsulationMaterial } from '../models/area/types/armature.model';
import { VentilationCentral, VentilationCentralType } from '../models/area/types/ventilation-central.model';
import { Heater, PowerSupply } from '../models/area/types/heater.model';
import {
  InjectionMachine,
  InjectionMachineDriveType,
  InjectionMachinePumpType,
} from '../models/area/types/injection-machine.model';
import { Press, PressType } from '../models/area/types/press.model';
import { FleetOwnership, Transport, VehicleFuelType, VehicleType } from '../models/area/types/transport.model';
import { EfficiencyClass, ElectricDrive } from '../models/area/types/electric-drive.model';
import { VacuumPump } from '../models/area/types/vacuum-pump.model';
import { AppSettings } from '../../AppSettings';
import { EnergySavingType } from '../models/area/energy-saving-type';
import { PowerGenerator } from '../models/area/types/power-generator';
import { Localizable } from '../../locale/localizable';
import { Language } from '../models/language';

@Injectable({
  providedIn: 'root',
})
export class ApiRequestService extends Localizable {
  urlDBEnergyDatabase: string;

  constructor(
    private httpClient: HttpClient,
    private config: AppConfig
  ) {
    super();
    this.urlDBEnergyDatabase = this.config.getConfig('DbEnergyDatabaseUrl');
  }

  public getLanguages(): Observable<Language[]> {
    return this.httpClient.get<Language[]>(this.urlDBEnergyDatabase + 'api/languages');
  }

  public getImagesNumberInGallery(galleryId: string): Observable<number> {
    return this.httpClient.get<number>(this.urlDBEnergyDatabase + `api/galleries/${galleryId}/images-number`);
  }

  getUnitsForEnergyBalance(): Observable<Array<Unit>> {
    return this.httpClient.get<Array<Unit>>(this.urlDBEnergyDatabase + 'api/energyBalances/units');
  }

  getEnergyBalance(energyBalanceId: string) {
    return this.httpClient.get(`${this.urlDBEnergyDatabase}api/energyBalances/${energyBalanceId}`);
  }

  getEnergyBalanceTypes(): Observable<Array<EnergyBalanceType>> {
    return this.httpClient.get<Array<EnergyBalanceType>>(this.urlDBEnergyDatabase + 'api/energyBalances/types');
  }

  getEnergyBalanceSource(id: string): Observable<EnergyBalanceSource> {
    return this.httpClient
      .get<EnergyBalanceSource>(this.urlDBEnergyDatabase + `api/energyBalances/sources/${id}`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateEnergyBalanceSource(energyBalanceSource: DtoEnergyBalanceSource): Observable<EnergyBalanceSource> {
    return this.httpClient
      .put<EnergyBalanceSource>(this.urlDBEnergyDatabase + 'api/energyBalances/sources', energyBalanceSource)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateEnergyBalanceReceiver(energyBalanceReceiver: DtoEnergyBalanceReceiver): Observable<EnergyBalanceReceiver> {
    return this.httpClient
      .put<EnergyBalanceReceiver>(this.urlDBEnergyDatabase + 'api/energyBalances/receivers', energyBalanceReceiver)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypes(categoryId: number): Observable<Array<AreaType>> {
    return this.httpClient
      .get<Array<AreaType>>(this.urlDBEnergyDatabase + `api/areas/categories/${categoryId}/types`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaParentTypes(categoryId: number): Observable<Array<AreaType>> {
    return this.httpClient
      .get<Array<AreaType>>(this.urlDBEnergyDatabase + `api/areas/categories/${categoryId}/types/parents`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypesForType(typeId: string): Observable<Array<AreaType>> {
    return this.httpClient
      .get<Array<AreaType>>(this.urlDBEnergyDatabase + `api/areas/types/${typeId}`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getBoilerMediumList(): Observable<Array<Medium>> {
    return this.httpClient
      .get<Array<Medium>>(this.urlDBEnergyDatabase + `api/boilers/mediums`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaCategories(): Observable<Array<AuditTreeNavigation>> {
    return this.httpClient
      .get<Array<AuditTreeNavigation>>(this.urlDBEnergyDatabase + 'api/areas/categories')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  addEnergyBalanceSource(newEnergySource): any {
    return this.httpClient
      .post<any>(this.urlDBEnergyDatabase + 'api/energyBalances/sources', newEnergySource)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  addEnergyBalanceReceiver(newEnergyReceiver): any {
    return this.httpClient
      .post<any>(this.urlDBEnergyDatabase + 'api/energyBalances/receivers', newEnergyReceiver)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAuditTimeRange(auditId: string): Observable<AuditTimeRange> {
    return this.httpClient
      .get<AuditTimeRange>(this.urlDBEnergyDatabase + `api/audits/${auditId}/timeRange`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getBlowerDocVariableCode(): Observable<string> {
    return this.httpClient
      .get<string>(this.urlDBEnergyDatabase + `api/areas/blowers/doc-variables-code`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getPowerGeneratorDocVariableCode(): Observable<string> {
    return this.httpClient
      .get<string>(this.urlDBEnergyDatabase + `api/areas/power-generators/doc-variables-code`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getVentilationCentralDocVariableCode(): Observable<string> {
    return this.httpClient
      .get<string>(this.urlDBEnergyDatabase + `api/areas/ventilation-centrals/doc-variables-code`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getBuildingDocVariableCode(): Observable<string> {
    return this.httpClient
      .get<string>(this.urlDBEnergyDatabase + `api/areas/buildings/doc-variables-code`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAbsorptionAggregateDocVariableCode(): Observable<string> {
    return this.httpClient
      .get<string>(this.urlDBEnergyDatabase + `api/areas/absorption-aggregates/doc-variables-code`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAuditDocVariableCode(): Observable<string> {
    return this.httpClient
      .get<string>(this.urlDBEnergyDatabase + `api/audits/doc-variables-code`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getDepartmentDocVariableCode(): Observable<string> {
    return this.httpClient
      .get<string>(this.urlDBEnergyDatabase + `api/departments/doc-variables-code`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getTransportAreaDocVariableCode(): Observable<string> {
    return this.httpClient
      .get<string>(this.urlDBEnergyDatabase + `api/areas/transports/doc-variables-code`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  addArea(area: Area): Observable<Area> {
    return this.httpClient
      .post<Area>(this.urlDBEnergyDatabase + 'api/areas', area)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getArmatureDocVariableCode(): Observable<string> {
    return this.httpClient
      .get<string>(this.urlDBEnergyDatabase + `api/areas/armatures/doc-variables-code`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  deleteEnergyBalanceReceiver(energyBalanceReceiverId) {
    return this.httpClient
      .delete(this.urlDBEnergyDatabase + `api/energyBalances/receivers/${energyBalanceReceiverId}`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  deleteEnergyBalanceSource(energyBalanceSourceId) {
    return this.httpClient
      .delete(this.urlDBEnergyDatabase + `api/energyBalances/sources/${energyBalanceSourceId}`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getEnergyBalanceSourcesForAudit(auditId: string): Observable<Array<EnergyBalanceSource>> {
    return this.httpClient.get<Array<EnergyBalanceSource>>(
      this.urlDBEnergyDatabase + `api/energyBalances/audit/${auditId}/sources`
    );
  }

  saveUser(user: User): Observable<boolean> {
    return this.httpClient.post<boolean>(this.urlDBEnergyDatabase, user).pipe(retry(AppSettings.REQUEST_TRIES));
  }

  public updateUser(key: string, values: string): Observable<any> {
    const formData = new FormData();
    formData.append('key', key);
    formData.append('values', values);

    return this.httpClient.put<any>(this.urlDBEnergyDatabase + 'api/users', formData).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle error
        return throwError(error);
      })
    );
  }

  addEnterprise(enterprise: Enterprise): Observable<boolean> {
    return this.httpClient.post<any>(this.urlDBEnergyDatabase, enterprise).pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateEnterprise(enterprise: Enterprise): Observable<Enterprise> {
    return this.httpClient
      .put<Enterprise>(this.urlDBEnergyDatabase + 'api/enterprises/', enterprise)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  saveEnterprise(enterprise: Enterprise): Observable<boolean> {
    return this.httpClient.post<any>(this.urlDBEnergyDatabase, enterprise).pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getEnterprise(selectedEnterpriseId: string): Observable<Enterprise> {
    const currentEnterprise = this.httpClient.get<any>(
      this.urlDBEnergyDatabase + 'api/enterprises/' + selectedEnterpriseId
    );
    return currentEnterprise;
  }

  getIndustryTypes(): Observable<Array<IndustryType>> {
    return this.httpClient
      .get<Array<IndustryType>>(this.urlDBEnergyDatabase + 'api/enterprises/industryTypes')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  saveArea(area: Area): Observable<Area> {
    return this.httpClient
      .put<Area>(this.urlDBEnergyDatabase + `api/areas`, area)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getDepartment(departmentId: string): Observable<Department> {
    return this.httpClient.get<any>(`${this.urlDBEnergyDatabase}api/departments/${departmentId}`);
  }

  getDepartmentChildren(departmentId: string): Observable<Area[]> {
    return this.httpClient.get<Area[]>(`${this.urlDBEnergyDatabase}api/departments/${departmentId}/children`);
  }

  getAreaActionTypes(): Observable<Array<ActionType>> {
    return this.httpClient.get<Array<ActionType>>(this.urlDBEnergyDatabase + 'api/areas/actions/types');
  }

  getEnergySavingTypes(): Observable<Array<EnergySavingType>> {
    return this.httpClient.get<Array<EnergySavingType>>(this.urlDBEnergyDatabase + 'api/areas/energy-saving-types');
  }

  updateDepartment(department: Department): Observable<Department> {
    return this.httpClient
      .put<Department>(this.urlDBEnergyDatabase + 'api/departments', department)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  saveDepartment(department: Department): Observable<Department> {
    return this.httpClient
      .post<any>(this.urlDBEnergyDatabase + 'api/departments', department)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getCurrencies(): Observable<Array<Currency>> {
    return this.httpClient
      .get<Array<Currency>>(this.urlDBEnergyDatabase + 'api/audits/currencies')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getArea(areaId: string): Observable<Area> {
    return this.httpClient.get<Area>(`${this.urlDBEnergyDatabase}api/areas/${areaId}`);
  }

  getAreaChildren(areaId: string): Observable<Area[]> {
    return this.httpClient.get<Area[]>(`${this.urlDBEnergyDatabase}api/areas/${areaId}/children`);
  }

  getCategoryNamesForArea(selectedAreaId: string) {
    const currentCategoryNames = this.httpClient.get<any>(
      this.urlDBEnergyDatabase + 'api/area/categorynames' + selectedAreaId
    );
    return currentCategoryNames;
  }

  getActionListForArea(selectedAreaId: string) {
    const currentActionList = this.httpClient.get<any>(
      this.urlDBEnergyDatabase + 'api/area/actionList' + selectedAreaId
    );
    return currentActionList;
  }

  getActionTypesForArea(selectedAreaId: string) {
    const currentActionTypes = this.httpClient.get<any>(
      this.urlDBEnergyDatabase + 'api/area/actionTypes' + selectedAreaId
    );
    return currentActionTypes;
  }

  createAudit(audit: Audit): Observable<Audit> {
    return this.httpClient
      .post<Audit>(this.urlDBEnergyDatabase + 'api/audits', audit)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  saveAudit(audit: Audit): Observable<Audit> {
    return this.httpClient
      .put<Audit>(this.urlDBEnergyDatabase + 'api/audits', audit)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAudit(selectedAuditId: string): Observable<Audit> {
    return this.httpClient.get<any>(`${this.urlDBEnergyDatabase}api/audits/${selectedAuditId}`);
  }

  deleteAudit(id: string): Observable<void> {
    return this.httpClient
      .delete<void>(this.urlDBEnergyDatabase + `api/audits/${id}`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  deleteDepartment(id: string): Observable<void> {
    return this.httpClient
      .delete<void>(this.urlDBEnergyDatabase + `api/departments/${id}`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  canDepartmentBeDeleted(id: string): Observable<any> {
    return this.httpClient
      .get<any>(this.urlDBEnergyDatabase + `api/departments/${id}/can-delete`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAuditsList(): Observable<Audit[]> {
    return this.httpClient.get<any>(this.urlDBEnergyDatabase + 'api/auditsList');
  }

  getNumberOfAudits(): Observable<number> {
    return this.httpClient.get<number>(this.urlDBEnergyDatabase + `api/audits/number`);
  }

  getAuditTreeStructure(auditId: string): Observable<Array<AuditTreeNavigation>> {
    return this.httpClient
      .get<any>(this.urlDBEnergyDatabase + 'api/' + auditId + '/navigationTree')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getUsersList(): Observable<Array<User>> {
    return this.httpClient
      .get<Array<User>>(this.urlDBEnergyDatabase + 'api/users')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAuditTypes(): Observable<Array<AuditType>> {
    return this.httpClient
      .get<Array<AuditType>>(this.urlDBEnergyDatabase + 'api/audits/types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getNavigationTree(auditId: string): Observable<Array<AuditTreeNavigation>> {
    return this.httpClient
      .get<Array<AuditTreeNavigation>>(`${this.urlDBEnergyDatabase}api/audits/${auditId}/navigationTree`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  saveBoilerRoom(boilerRoom: BoilerRoom): Observable<void> {
    return this.httpClient
      .put<void>(this.urlDBEnergyDatabase + 'api/boilerRooms', boilerRoom)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateBoiler(boiler: Boiler): Observable<void> {
    return this.httpClient
      .put<void>(this.urlDBEnergyDatabase + 'api/boilers', boiler)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getBoilerRoom(selectedBoilerRoomId: string): Observable<BoilerRoom> {
    return this.httpClient.get<BoilerRoom>(`${this.urlDBEnergyDatabase}api/boilerRoom/${selectedBoilerRoomId}`);
  }

  getAuditCurrency(auditId: string): Observable<Currency> {
    return this.httpClient.get<Currency>(`${this.urlDBEnergyDatabase}api/audits/${auditId}/currencies`);
  }

  updateWaterPump(waterPump: WaterPump): Observable<void> {
    return this.httpClient
      .put<void>(this.urlDBEnergyDatabase + 'api/water-pumps', waterPump)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateFan(fan: Fan): Observable<void> {
    return this.httpClient.put<void>(this.urlDBEnergyDatabase + 'api/fans', fan).pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaPhotovoltaics(photovoltaics: Photovoltaics): Observable<void> {
    return this.httpClient
      .put<void>(this.urlDBEnergyDatabase + 'api/photovoltaics', photovoltaics)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTechnicalConditions(): Observable<Array<TechnicalCondition>> {
    return this.httpClient
      .get<Array<TechnicalCondition>>(this.urlDBEnergyDatabase + 'api/areas/technical-conditions')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getRegulationTypes(): Observable<Array<RegulationType>> {
    return this.httpClient
      .get<Array<TechnicalCondition>>(this.urlDBEnergyDatabase + 'api/areas/regulation-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getOperatingPointVolatilities(): Observable<Array<OperatingPointVolatility>> {
    return this.httpClient
      .get<Array<TechnicalCondition>>(this.urlDBEnergyDatabase + 'api/areas/operating-point-volatilities')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getFanAreaTypes(): Observable<Array<FanType>> {
    return this.httpClient
      .get<Array<FanType>>(this.urlDBEnergyDatabase + 'api/fans/types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getHeatRecoveryTypes(): Observable<Array<HeatRecoveryType>> {
    return this.httpClient
      .get<Array<HeatRecoveryType>>(this.urlDBEnergyDatabase + 'api/areas/heat-recoveries/types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getHeatRecoveryPlaces(): Observable<Array<HeatRecoveryPlace>> {
    return this.httpClient
      .get<Array<HeatRecoveryType>>(this.urlDBEnergyDatabase + 'api/areas/heat-recoveries/places')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getHeatRecoveryMediumTypes(): Observable<Array<RecoveryMediumType>> {
    return this.httpClient
      .get<Array<HeatRecoveryType>>(this.urlDBEnergyDatabase + 'api/areas/heat-recoveries/medium-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateHeatRecovery(heatRecovery: HeatRecovery): Observable<void> {
    return this.httpClient
      .put<void>(this.urlDBEnergyDatabase + 'api/areas/heat-recoveries', heatRecovery)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getConnectionGroupes(): Observable<Array<ConnectionGroupe>> {
    return this.httpClient
      .get<Array<ConnectionGroupe>>(this.urlDBEnergyDatabase + 'api/areas/electricity-connections/connection-groupes')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getMeasuringSystems(): Observable<Array<MeasuringSystem>> {
    return this.httpClient
      .get<Array<MeasuringSystem>>(this.urlDBEnergyDatabase + 'api/areas/electricity-connections/measuring-systems')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getEnergySellers(): Observable<Array<EnergySeller>> {
    return this.httpClient
      .get<Array<EnergySeller>>(this.urlDBEnergyDatabase + 'api/areas/electricity-connections/energy-sellers')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getEnergyDistributors(): Observable<Array<EnergyDistributor>> {
    return this.httpClient
      .get<Array<EnergyDistributor>>(this.urlDBEnergyDatabase + 'api/areas/electricity-connections/energy-distributors')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getTariffs(): Observable<Array<Tariff>> {
    return this.httpClient
      .get<Array<Tariff>>(this.urlDBEnergyDatabase + 'api/areas/electricity-connections/tariffs')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateElectrictyConnection(electricityConnection: ElectricityConnection): Observable<void> {
    return this.httpClient
      .put<void>(this.urlDBEnergyDatabase + 'api/areas/electricity-connections', electricityConnection)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAirConditioner(airConditioner: AirConditioner): Observable<void> {
    return this.httpClient
      .put<void>(this.urlDBEnergyDatabase + 'api/areas/air-conditioners', airConditioner)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateTransformerArea(transformer: Transformer): Observable<void> {
    return this.httpClient
      .put<void>(this.urlDBEnergyDatabase + 'api/areas/transformers', transformer)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  deleteArea(id: string): Observable<void> {
    return this.httpClient
      .delete<void>(this.urlDBEnergyDatabase + `api/areas/${id}`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  canAreaBeDeleted(id: string): Observable<any> {
    return this.httpClient
      .get<any>(this.urlDBEnergyDatabase + `api/areas/${id}/can-delete`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getRefrigerantTypes(): Observable<Array<RefrigerantType>> {
    return this.httpClient
      .get<Array<RefrigerantType>>(this.urlDBEnergyDatabase + 'api/areas/refigerant-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateDeaeratorArea(deaerator: Deaerator): Observable<void> {
    return this.httpClient
      .put<void>(this.urlDBEnergyDatabase + 'api/areas/deaerators', deaerator)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateColdCompressorArea(coldCompressor: ColdCompressor): Observable<void> {
    return this.httpClient
      .put<void>(this.urlDBEnergyDatabase + 'api/areas/cold-compressors', coldCompressor)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getDeaeratorDegassingTypes(): Observable<Array<DegassingType>> {
    return this.httpClient
      .get<Array<DegassingType>>(this.urlDBEnergyDatabase + 'api/areas/deaerators/degassing-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getDeaeratorSupplyTypes(): Observable<Array<DeaeratorSupplyType>> {
    return this.httpClient
      .get<Array<DeaeratorSupplyType>>(this.urlDBEnergyDatabase + 'api/areas/deaerators/deaerator-supply-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getDeaeratorWaterSupplyTypes(): Observable<Array<WaterSupplyType>> {
    return this.httpClient
      .get<Array<WaterSupplyType>>(this.urlDBEnergyDatabase + 'api/areas/deaerators/water-supply-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getGassCogenerationModel(): Observable<Array<GassCogenerationSourceType>> {
    return this.httpClient
      .get<Array<GassCogenerationSourceType>>(this.urlDBEnergyDatabase + 'api/areas/gass-cogenerations/source-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateGassCogeneration(gassCogeneration: GassCogeneration): Observable<GassCogeneration> {
    return this.httpClient
      .put<GassCogeneration>(this.urlDBEnergyDatabase + 'api/areas/gass-cogenerations', gassCogeneration)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAbsorptionTypes(): Observable<Array<AbsorptionType>> {
    return this.httpClient
      .get<Array<AbsorptionType>>(this.urlDBEnergyDatabase + 'api/areas/absorption-aggregates/absorption-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getWorkFactorTypes(): Observable<Array<WorkFactor>> {
    return this.httpClient
      .get<Array<WorkFactor>>(this.urlDBEnergyDatabase + 'api/areas/absorption-aggregates/work-factors')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAbsorptionAggregate(absorptionAggregate: AbsorptionAggregate): Observable<AbsorptionAggregate> {
    return this.httpClient
      .put<AbsorptionAggregate>(this.urlDBEnergyDatabase + 'api/areas/absorption-aggregates', absorptionAggregate)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getBlowerRegularionTypes(): Observable<Array<BlowerRegulationType>> {
    return this.httpClient
      .get<Array<BlowerRegulationType>>(this.urlDBEnergyDatabase + 'api/areas/blowers/blower-regulation-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getBlowerTypes(): Observable<Array<BlowerType>> {
    return this.httpClient
      .get<Array<BlowerType>>(this.urlDBEnergyDatabase + 'api/areas/blowers/blower-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getBlowerOperatingPointVolatilities(): Observable<Array<BlowerOperatingPointVolatility>> {
    return this.httpClient
      .get<
        Array<BlowerOperatingPointVolatility>
      >(this.urlDBEnergyDatabase + 'api/areas/blowers/blower-operating-point-volatilities')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeBlower(blower: Blower): Observable<Blower> {
    return this.httpClient
      .put<Blower>(this.urlDBEnergyDatabase + 'api/areas/blowers', blower)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeCompressedAirGenerator(
    compressedAirGenerator: CompressedAirGenerator
  ): Observable<CompressedAirGenerator> {
    return this.httpClient
      .put<CompressedAirGenerator>(
        this.urlDBEnergyDatabase + 'api/areas/compressed-air-generators',
        compressedAirGenerator
      )
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeAirCompressor(airCompressor: AirCompressor): Observable<AirCompressor> {
    return this.httpClient
      .put<AirCompressor>(this.urlDBEnergyDatabase + 'api/areas/air-compressors', airCompressor)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAirCompressorTypes(): Observable<Array<CompressorType>> {
    return this.httpClient
      .get<Array<CompressorType>>(this.urlDBEnergyDatabase + 'api/areas/air-compressors/compressor-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAirCompressorEfficiencyRegulationTypes(): Observable<Array<EfficiencyRegulationType>> {
    return this.httpClient
      .get<
        Array<EfficiencyRegulationType>
      >(this.urlDBEnergyDatabase + 'api/areas/air-compressors/efficiency-regulation-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAirCompressorEntityTypeAirCompressor(): Observable<Array<EntityTypeAirCompressor>> {
    return this.httpClient
      .get<
        Array<EntityTypeAirCompressor>
      >(this.urlDBEnergyDatabase + 'api/areas/air-compressors/entity-type-air-compressor')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeAirCurtain(airCurtain: AirCurtain): Observable<AirCurtain> {
    return this.httpClient
      .put<AirCurtain>(this.urlDBEnergyDatabase + 'api/areas/air-curtains', airCurtain)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeAirEnhancer(airEnhancer: AirEnhancer): Observable<AirEnhancer> {
    return this.httpClient.put<AirEnhancer>(this.urlDBEnergyDatabase + 'api/areas/air-enhancers', airEnhancer);
  }

  updateAreaTypeElectricHeater(electricHeater: ElectricHeater): Observable<ElectricHeater> {
    return this.httpClient
      .put<ElectricHeater>(this.urlDBEnergyDatabase + 'api/areas/electric-heaters', electricHeater)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeDehumidifier(dehumidifier: Dehumidifier): Observable<Dehumidifier> {
    return this.httpClient
      .put<Dehumidifier>(this.urlDBEnergyDatabase + 'api/areas/dehumidifiers', dehumidifier)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAirCurtainTypes(): Observable<Array<AirCurtainType>> {
    return this.httpClient
      .get<Array<AirCurtainType>>(this.urlDBEnergyDatabase + 'api/areas/air-curtains/types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getElectricHeaterTypes(): Observable<Array<ElectricHeaterType>> {
    return this.httpClient.get<Array<ElectricHeaterType>>(
      this.urlDBEnergyDatabase + 'api/areas/electric-heaters/types'
    );
  }

  updateAreaTypeFanCooler(fanCooler: FanCooler): Observable<FanCooler> {
    return this.httpClient
      .put<FanCooler>(this.urlDBEnergyDatabase + 'api/areas/fan-coolers', fanCooler)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getDehumidifierTypes(): Observable<Array<DehumidifierType>> {
    return this.httpClient
      .get<Array<DehumidifierType>>(this.urlDBEnergyDatabase + 'api/areas/dehumidifiers/types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeHeatPump(heatPump: HeatPump): Observable<HeatPump> {
    return this.httpClient
      .put<HeatPump>(this.urlDBEnergyDatabase + 'api/areas/heat-pumps', heatPump)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getPumpTypesForHeatPump(): Observable<Array<HeatPumpType>> {
    return this.httpClient
      .get<Array<HeatPumpType>>(this.urlDBEnergyDatabase + 'api/areas/heat-pumps/pump-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getBottomSourceTypesForHeatPump(): Observable<Array<HeatPumpBottomSourceType>> {
    return this.httpClient
      .get<Array<HeatPumpBottomSourceType>>(this.urlDBEnergyDatabase + 'api/areas/heat-pumps/bottom-source-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getBottomSourceTempsForHeatPump(): Observable<Array<HeatPumpBottomSourceTemp>> {
    return this.httpClient
      .get<Array<HeatPumpBottomSourceTemp>>(this.urlDBEnergyDatabase + 'api/areas/heat-pumps/bottom-source-temps')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getTopSourceTypesForHeatPump(): Observable<Array<HeatPumpTopSourceType>> {
    return this.httpClient
      .get<Array<HeatPumpTopSourceType>>(this.urlDBEnergyDatabase + 'api/areas/heat-pumps/top-source-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeBuilding(building: Building): Observable<Building> {
    return this.httpClient
      .put<Building>(this.urlDBEnergyDatabase + 'api/areas/buildings', building)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeBuildingTypes(): Observable<Array<BuildingType>> {
    return this.httpClient
      .get<Array<BuildingType>>(this.urlDBEnergyDatabase + 'api/areas/buildings/types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeBuildingErectingTechnologies(): Observable<Array<ErectingBuildingTechnology>> {
    return this.httpClient
      .get<Array<ErectingBuildingTechnology>>(this.urlDBEnergyDatabase + 'api/areas/buildings/erecting-technologies')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeAirEnhancerTypes(): Observable<Array<AirEnhancerType>> {
    return this.httpClient.get<Array<AirEnhancerType>>(this.urlDBEnergyDatabase + 'api/areas/air-enhancers/types');
  }

  getFanCoolerRefrigeratedMediums(): Observable<Array<RefrigeratedMedium>> {
    return this.httpClient
      .get<Array<RefrigeratedMedium>>(this.urlDBEnergyDatabase + 'api/areas/fan-coolers/mediums')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getFanCoolerControlTypes(): Observable<Array<FanCoolerControl>> {
    return this.httpClient
      .get<Array<FanCoolerControl>>(this.urlDBEnergyDatabase + 'api/areas/fan-coolers/control-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeWaterHeater(waterHeater: WaterHeater): Observable<WaterHeater> {
    return this.httpClient
      .put<WaterHeater>(this.urlDBEnergyDatabase + 'api/areas/water-heaters', waterHeater)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypewaterHeaterTypes(): Observable<Array<WaterHeaterType>> {
    return this.httpClient
      .get<Array<WaterHeaterType>>(this.urlDBEnergyDatabase + 'api/areas/water-heaters/types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypewaterHeaterFuelTypes(): Observable<Array<FuelType>> {
    return this.httpClient
      .get<Array<FuelType>>(this.urlDBEnergyDatabase + 'api/areas/water-heaters/fuels')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeSteamGenerator(steamGenerator: SteamGenerator): Observable<SteamGenerator> {
    return this.httpClient
      .put<SteamGenerator>(this.urlDBEnergyDatabase + 'api/areas/steam-generators', steamGenerator)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeSteamGeneratorFuelTypes(): Observable<Array<SteamGeneratorFuelType>> {
    return this.httpClient
      .get<Array<SteamGeneratorFuelType>>(this.urlDBEnergyDatabase + 'api/areas/steam-generators/fuels')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeLighting(lighting: Lighting): Observable<Lighting> {
    return this.httpClient
      .put<Lighting>(this.urlDBEnergyDatabase + 'api/areas/lightings', lighting)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeLightingControlSystems(): Observable<Array<LightingControlSystem>> {
    return this.httpClient
      .get<Array<LightingControlSystem>>(this.urlDBEnergyDatabase + 'api/areas/lightings/control-systems')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeLightingFixtureTypes(): Observable<Array<FixtureType>> {
    return this.httpClient
      .get<Array<FixtureType>>(this.urlDBEnergyDatabase + 'api/areas/lightings/fixture-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeRadiantHeater(radiantHeater: RadiantHeater): Observable<RadiantHeater> {
    return this.httpClient
      .put<RadiantHeater>(this.urlDBEnergyDatabase + 'api/areas/radiant-heaters', radiantHeater)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeRadiantHeaterSupplyTypes(): Observable<Array<RadiantHeaterSupplyType>> {
    return this.httpClient
      .get<Array<RadiantHeaterSupplyType>>(this.urlDBEnergyDatabase + 'api/areas/radiant-heaters/supply-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeArmature(armature: Armature): Observable<Armature> {
    return this.httpClient
      .put<Armature>(this.urlDBEnergyDatabase + 'api/areas/armatures', armature)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeArmatureInsulationMaterials(): Observable<Array<ArmatureInsulationMaterial>> {
    return this.httpClient
      .get<Array<ArmatureInsulationMaterial>>(this.urlDBEnergyDatabase + 'api/areas/armatures/insulation-materials')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeVentilationCentral(ventilationCentral: VentilationCentral): Observable<VentilationCentral> {
    return this.httpClient
      .put<VentilationCentral>(this.urlDBEnergyDatabase + 'api/areas/ventilation-centrals', ventilationCentral)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeVentilationCentralTypes(): Observable<Array<VentilationCentralType>> {
    return this.httpClient
      .get<Array<VentilationCentralType>>(this.urlDBEnergyDatabase + 'api/areas/ventilation-centrals/types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeHeater(heater: Heater): Observable<Heater> {
    return this.httpClient
      .put<Heater>(this.urlDBEnergyDatabase + 'api/areas/heaters', heater)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeHeaterPowerSupplies(): Observable<Array<PowerSupply>> {
    return this.httpClient
      .get<Array<PowerSupply>>(this.urlDBEnergyDatabase + 'api/areas/heaters/power-supplies')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeInjectionMachine(injectionMachine: InjectionMachine): Observable<InjectionMachine> {
    return this.httpClient
      .put<InjectionMachine>(this.urlDBEnergyDatabase + 'api/areas/injection-machines', injectionMachine)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeInjectionMachineDriveTypes(): Observable<Array<InjectionMachineDriveType>> {
    return this.httpClient
      .get<Array<InjectionMachineDriveType>>(this.urlDBEnergyDatabase + 'api/areas/injection-machines/drive-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeInjectionMachinePumpTypes(): Observable<Array<InjectionMachinePumpType>> {
    return this.httpClient
      .get<Array<InjectionMachinePumpType>>(this.urlDBEnergyDatabase + 'api/areas/injection-machines/pump-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypePress(press: Press): Observable<Press> {
    return this.httpClient
      .put<Press>(this.urlDBEnergyDatabase + 'api/areas/presses', press)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypePressTypes(): Observable<Array<PressType>> {
    return this.httpClient
      .get<Array<PressType>>(this.urlDBEnergyDatabase + 'api/areas/presses/types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeTransport(transport: Transport): Observable<Transport> {
    return this.httpClient
      .put<Transport>(this.urlDBEnergyDatabase + 'api/areas/transports', transport)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeTransportFleetOwnerships(): Observable<Array<FleetOwnership>> {
    return this.httpClient
      .get<Array<FleetOwnership>>(this.urlDBEnergyDatabase + 'api/areas/transports/fleet-ownerships')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeTransportFuelTypes(): Observable<Array<VehicleFuelType>> {
    return this.httpClient
      .get<Array<VehicleFuelType>>(this.urlDBEnergyDatabase + 'api/areas/transports/fuel-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeTransportVehicleTypes(): Observable<Array<VehicleType>> {
    return this.httpClient
      .get<Array<VehicleType>>(this.urlDBEnergyDatabase + 'api/areas/transports/vehicle-types')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeElectricDrive(electricDrive: ElectricDrive): Observable<ElectricDrive> {
    return this.httpClient
      .put<ElectricDrive>(this.urlDBEnergyDatabase + 'api/areas/electric-drives', electricDrive)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getAreaTypeElectricDriveEfficiencyClasses(): Observable<Array<EfficiencyClass>> {
    return this.httpClient
      .get<Array<EfficiencyClass>>(this.urlDBEnergyDatabase + 'api/areas/electric-drives/efficiency-classes')
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypeVacuumPump(vacuumPump: VacuumPump): Observable<VacuumPump> {
    return this.httpClient
      .put<VacuumPump>(this.urlDBEnergyDatabase + 'api/areas/vacuum-pumps', vacuumPump)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  updateAreaTypePowerGenerator(powerGenerator: PowerGenerator): Observable<PowerGenerator> {
    return this.httpClient
      .put<PowerGenerator>(this.urlDBEnergyDatabase + 'api/areas/power-generators', powerGenerator)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  getTemplateList(formTypeCode: string, auditTypeId: string): Observable<Array<string>> {
    return this.httpClient
      .get<Array<string>>(this.urlDBEnergyDatabase + `api/templates/${formTypeCode}/files/${auditTypeId}`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }

  deleteTemplate(id: string): Observable<string> {
    return this.httpClient
      .delete<string>(this.urlDBEnergyDatabase + `api/templates/${id}`)
      .pipe(retry(AppSettings.REQUEST_TRIES));
  }
}
