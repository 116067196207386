<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<header>
  <dx-toolbar class="header-toolbar">
    <dxi-item
      *ngIf="menuToggleEnabled && isSmall"
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu,
      }"
    >
    </dxi-item>
    <dxi-item
      location="before"
      cssClass="header-title"
      *ngIf="title"
      [text]="title"
    >
    </dxi-item>

    <dxi-item [location]="isSmall ? 'center' : 'before'">
      <img
        class="logo"
        src="../../../../assets/icons/dbenergy-icon.svg"
        alt="DbEnergy logo"
        height="56px"
        width="56px"
      />
    </dxi-item>

    <dxi-item location="after">
      <div *dxTemplate>
        <app-account></app-account>
      </div>
    </dxi-item>
  </dx-toolbar>
</header>
