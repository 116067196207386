import { Component, Input, OnInit } from '@angular/core';
import { Photovoltaics } from 'src/app/shared/models/area/types/photovoltaics.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { Localizable } from '../../../../../../../locale/localizable';

@Component({
  selector: 'app-photovoltaics',
  templateUrl: './photovoltaics.component.html',
  styleUrls: ['./photovoltaics.component.scss'],
})
export class PhotovoltaicsComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  photovoltaics: Photovoltaics = null;
  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
}
