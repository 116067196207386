<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div
  class="dx-card responsive-paddings outer-container energy-balance-chart-max-width"
  #outerContainer
>
  <div>
    <h2 class="headerStyle georama-font">{{ _('sankey-chart') }}</h2>
    <div class="button-container">
      <dx-button
        stylingMode="outlined"
        icon="plus"
        type="normal"
        (onClick)="zoomIn()"
      >
      </dx-button>
      <dx-button
        stylingMode="outlined"
        icon="minus"
        type="normal"
        (onClick)="zoomOut()"
      >
      </dx-button>

      <dx-drop-down-button
        class="export-button"
        [text]="_('energy-balance-chart-export-button-text')"
        icon="menu"
        [items]="exportFileTypes"
        (onItemClick)="exportButtonClick($event)"
      ></dx-drop-down-button>

      <dx-drop-down-button
        class="unit-buttom"
        text="{{ _('energy-balance-chart-unit-button-text') }}: {{ this.unit }}"
        [items]="eneryBalanceUnits"
        selectedItem="MWh"
        (onSelectionChanged)="changeUnit($event)"
      ></dx-drop-down-button>
    </div>
  </div>
  <div
    #sankeyContainer
    class="container"
    [style.width.px]="sankeyContainerWidthPx"
  >
    <dx-sankey
      *ngIf="showSankey"
      [ngClass]="loadingVisible ? 'invisible' : ''"
      #sankey
      id="sankey"
      [(dataSource)]="data"
      hoverEnabled="true"
      sourceField="source"
      targetField="target"
      weightField="weight"
    >
      <dxo-size
        [height]="sankeyHeightPx"
        [width]="sankeyWidthPx"
      >
      </dxo-size>
      <dxo-link colorMode="gradient"> </dxo-link>
      <dxo-node
        [width]="8"
        [padding]="30"
      >
      </dxo-node>
      <dxo-tooltip
        [enabled]="true"
        [customizeLinkTooltip]="customizeLinkTooltip"
        [customizeNodeTooltip]="customizeNodeTooltip"
        [zIndex]="1700"
      ></dxo-tooltip>
    </dx-sankey>
    <div
      class="loading-container"
      *ngIf="loadingVisible"
    >
      <h3 class="center-loading-text">
        {{ this._('Loading') }}
      </h3>
    </div>
  </div>
</div>
