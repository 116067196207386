import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { Heater, PowerSupply } from 'src/app/shared/models/area/types/heater.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-heater',
  templateUrl: './heater.component.html',
  styleUrls: ['./heater.component.scss'],
})
export class HeaterComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  heater: Heater = null;

  powerSuppliesList: ArrayStore;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this._apiRequestService.getAreaTypeHeaterPowerSupplies().subscribe((res: Array<PowerSupply>) => {
      this.powerSuppliesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
