<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-popup
  [title]="_('gallery-title')"
  [fullScreen]="false"
  [width]="popupWidth + '%'"
  [height]="popupHeight + '%'"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [visible]="true"
  (onHidden)="popupClosed()"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <dx-scroll-view
    width="100%"
    height="100%"
  >
    <div class="images-container">
      <div
        class="image"
        *ngFor="let item of images"
      >
        <app-picture
          [image]="item"
          (deletedImageId)="removeImage($event)"
        ></app-picture>
      </div>
    </div>
    <div class="buttons-container">
      <app-upload-image (uploadedImage)="addImage($event)"></app-upload-image>
      <app-take-picture *ngIf="false"></app-take-picture>
      <div
        *ngIf="isMobile"
        class="take-picture-container"
      >
        <dx-button
          class="take-picture-btn"
          [text]="_('gallery-popup-take-picture-button-text')"
          icon="image"
          (onClick)="takePicture.click()"
          class="outlined-button-focus-hover"
          stylingMode="outlined"
        ></dx-button>
        <label
          for="takePicture"
          aria-label="take picture"
        ></label>
        <input
          #takePicture
          id="takePicture"
          type="file"
          accept="image/*"
          capture="environment"
          (change)="uploadImage($event)"
        />
      </div>
    </div>
  </dx-scroll-view>
</dx-popup>
