import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { retry } from 'rxjs/operators';
import { AppConfig } from '../../../app.config';
import { DocVariable } from '../../models/dov-variable.model';

@Component({
  selector: 'app-doc-variables-list',
  templateUrl: './doc-variables-list.component.html',
  styleUrls: ['./doc-variables-list.component.scss'],
})
export class DocVariablesListComponent implements OnInit {
  docVariablesList: Array<DocVariable> = null;

  constructor(
    private _httpClient: HttpClient,
    private _config: AppConfig
  ) {}

  ngOnInit(): void {
    const urlToDatabase = this._config.getConfig('DbEnergyDatabaseUrl');

    this._httpClient
      .get<Array<DocVariable>>(urlToDatabase + 'api/templates/doc-variables')
      .pipe(retry(3))
      .subscribe((res) => (this.docVariablesList = res));
  }
}
