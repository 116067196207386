import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { map, tap } from 'rxjs/operators';
import { AuditTreeNavigation, FormType } from '../models/audit/audit-tree-navigation.model';
import { ApiRequestService } from './api-request.service';
import { UtilsService } from './utils.service';
import { Localizable } from '../../locale/localizable';
import { CloseResult } from '../components/questionnaire/exit-confirmation-popup/exit-confirmation-popup.component';
import { AuditCreatorVersion } from '../../pages/home/home.component';

@Injectable({
  providedIn: 'root',
})
export class TreeNavigationService extends Localizable {
  private _selectedFormId = new BehaviorSubject<string>('');
  private _displayedFormId = new BehaviorSubject<string>('');
  private _navigateToFormId = new Subject<AuditTreeNavigation>();
  public _navigationTreeData = new BehaviorSubject<Array<AuditTreeNavigation>>([]);
  private _expandedRowsKeys = new BehaviorSubject<Array<string>>([]);
  private _clearSelection = new BehaviorSubject<boolean>(false);

  private _exitFormVisible = new Subject<boolean>();
  private _exitGuardFormVisible = new Subject<boolean>();
  private _exitFormSaveComponents = new Subject<FormType>();
  private _exitFormReturn = new Subject<CloseResult>();
  private _toggleAuditClosePopupVisible = new Subject<boolean>();
  private _toggleAuditClosePopupReturn = new Subject<CloseResult>();
  private _showCreator = new Subject<AuditCreatorVersion>();
  private _componentRendered = new Subject<any>();

  public selectedFormId = this._selectedFormId.asObservable();
  public displayedFormId = this._displayedFormId.asObservable();
  public navigateToFormId = this._navigateToFormId.asObservable();
  public navigationTreeData = this._navigationTreeData.asObservable();
  public expandedRowsKeys = this._expandedRowsKeys.asObservable();
  public clearSelection = this._clearSelection.asObservable();
  public showCreator = this._showCreator.asObservable();

  private _collapseNavSideMenu$ = new BehaviorSubject<boolean>(false);
  public collapseNavSideMenu$ = this._collapseNavSideMenu$.asObservable();

  public exitFormVisible = this._exitFormVisible.asObservable();
  public exitGuardFormVisible = this._exitGuardFormVisible.asObservable();
  public exitFormReturn = this._exitFormReturn.asObservable();
  public exitFormSaveComponents = this._exitFormSaveComponents.asObservable();
  public toggleAuditClosePopupVisible = this._toggleAuditClosePopupVisible.asObservable();
  public toggleAuditClosePopupReturn = this._toggleAuditClosePopupReturn.asObservable();
  public componentRendered = this._componentRendered.asObservable();
  private _doNotShowCreatorHint: boolean = false;

  public setComponentRendered(form: any) {
    this._componentRendered.next(form);
  }

  public setGuardExitFormVisible(visible: boolean) {
    this._exitGuardFormVisible.next(visible);
  }

  public setExitFormVisible(visible: boolean) {
    this._exitFormVisible.next(visible);
  }

  public setExitFormReturn(result: CloseResult) {
    this._exitFormReturn.next(result);
  }

  public setExitFormSaveComponents(formtype: FormType) {
    this._exitFormSaveComponents.next(formtype);
  }

  public setAuditCloseFormVisible(visible: boolean) {
    this._toggleAuditClosePopupVisible.next(visible);
  }

  public setAuditCloseFormReturn(result: CloseResult) {
    this._toggleAuditClosePopupReturn.next(result);
  }

  public setShowCreator(value: AuditCreatorVersion) {
    this._showCreator.next(value);
  }

  private _formDataUnsaved = new ReplaySubject<{
    isSaved: boolean;
    id: string;
  }>();
  public formDataUnsaved = this._formDataUnsaved.asObservable();

  private _checkFormData = new BehaviorSubject<boolean>(false);
  public checkFormData = this._checkFormData.asObservable();

  public updateformDataSaved(current: any, saved: any, id: string): void {
    if (current !== undefined && saved !== undefined) {
      const isSaved = JSON.stringify(current) === JSON.stringify(saved);
      this._formDataUnsaved.next({ isSaved, id });
    } else if (current === undefined && saved === undefined) {
      this._formDataUnsaved.next({ isSaved: true, id });
    }
  }

  public updateCheckFormData(isToCheck: boolean): void {
    this._checkFormData.next(isToCheck);
  }

  constructor(
    private _apiRequestService: ApiRequestService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _utilsService: UtilsService
  ) {
    super('dictionary');
  }

  public clearNavigationTree(): void {
    this._doNotShowCreatorHint = false;
    this._navigationTreeData.next([]);
    this._selectedFormId.next(null);
    this._navigationTreeData.next(null);
    this._expandedRowsKeys.next([]);
  }

  public getAuditNavigationTree(auditId: string): Observable<void> {
    return this._apiRequestService.getNavigationTree(auditId).pipe(
      map((res) => {
        this._navigationTreeData.next(res);
      }),
      tap(() => {
        let currentSelectedComponent = this._navigationTreeData
          .getValue()
          .find((ntd) => ntd.id === this._displayedFormId.getValue());
        if (currentSelectedComponent === undefined) {
          currentSelectedComponent = this._navigationTreeData
            .getValue()
            .find((ntd) => ntd.id === this._selectedFormId.getValue());
          if (currentSelectedComponent !== undefined) {
            this.setShowCreatorOnAuditEnterprise(currentSelectedComponent.formType);
          }
        } else {
          this.setShowCreatorOnAuditEnterprise(currentSelectedComponent.formType);
        }
        this.expandNode(this._navigationTreeData.getValue(), this._selectedFormId.getValue());
      })
    );
  }

  public getNavigationItems(formType: FormType): Array<AuditTreeNavigation> {
    const navigationItems = this.getLastNavigationTreeData();
    if (navigationItems === null) {
      return [];
    }
    switch (formType) {
      case FormType.Audit:
        return navigationItems.filter((ni) => ni.formType === FormType.Audit);
      case FormType.Enterprise:
        return navigationItems.filter((ni) => ni.formType === FormType.Enterprise);
      case FormType.Department:
        return navigationItems.filter((ni) => ni.formType === FormType.Department);
      case FormType.Area:
        return navigationItems.filter((ni) => ni.formType === FormType.Area);
      default:
        return [];
    }
  }

  public getLastNavigationTreeData(): Array<AuditTreeNavigation> {
    return this._navigationTreeData.getValue();
  }

  public addExpandedRowKey(key: string): void {
    this._expandedRowsKeys.next(this._expandedRowsKeys.getValue().concat(key));
  }

  public removeExpandedRowKey(key: string): void {
    this._expandedRowsKeys.next(this._expandedRowsKeys.getValue().filter((x) => x !== key.toUpperCase()));
  }

  public setSelectedRowKey(key: string): void {
    key = key.toUpperCase();
    this._selectedFormId.next(key);
  }

  public setDisplayedRowKey(key: string): void {
    key = key.toUpperCase();
    this._displayedFormId.next(key);
    if (this._navigationTreeData.getValue() !== null) {
      const currentSelectedComponent = this._navigationTreeData
        .getValue()
        .find((ntd) => ntd.id === this._displayedFormId.getValue());
      this.setShowCreatorOnAuditEnterprise(currentSelectedComponent.formType);
    }
  }

  public setNavigationToFormId(selectedForm: AuditTreeNavigation): void {
    this._navigateToFormId.next(selectedForm);
  }

  public expandParentNode(key: string): void {
    const node = this._navigationTreeData.getValue().find((x: AuditTreeNavigation) => x.id === key.toUpperCase());
    this.addExpandedRowKey(node.parentId);
  }

  // This was to recreate tree expanded nodes, but after home component rerenfed it's impossible
  public expandAllNodesFrom(key: string): void {
    const node = this._navigationTreeData.getValue().find((x: AuditTreeNavigation) => x.id === key.toUpperCase());
    this.recursiveExpandParentNode(node);
  }

  private recursiveExpandParentNode(node: AuditTreeNavigation): void {
    if (node.parentId !== '-1') {
      this.addExpandedRowKey(node.id);
      const parentNode = this._navigationTreeData
        .getValue()
        .find((x: AuditTreeNavigation) => x.id === node.parentId.toUpperCase());
      this.recursiveExpandParentNode(parentNode);
    } else {
      this.addExpandedRowKey(node.id);
    }
  }

  public getSelectedRowKey(): Array<string> {
    return [this._selectedFormId.getValue()];
  }

  public expandAreaItem(id: string): void {
    this.navigationTreeData.subscribe((res) => {
      const area = res.find((x) => x.id.localeCompare(id, undefined, { sensitivity: 'base' }));
      this.expandSelectedRow();
      this.addExpandedRowKey(area.parentId);
      this.addExpandedRowKey(area.id);
    });
  }
  public setHideCreatorHint(value: boolean) {
    this._doNotShowCreatorHint = value;
  }

  private setShowCreatorOnAuditEnterprise(formType: FormType) {
    if (!this._doNotShowCreatorHint && (FormType.Audit === formType || FormType.Enterprise === formType)) {
      const departments = this.getNavigationItems(FormType.Department);
      if (departments.length > 1) {
        this.setShowCreator(AuditCreatorVersion.FewDepartments);
      } else if (departments.length === 1) {
        this.setShowCreator(AuditCreatorVersion.Default);
      } else {
        this.setShowCreator(AuditCreatorVersion.NoDepartments);
      }
    } else {
      this.setShowCreator(AuditCreatorVersion.Hide);
    }
  }

  public changeForm(formType: FormType, id: string) {
    this.setShowCreatorOnAuditEnterprise(formType);
    let wasSuccessRouted;
    if (formType === FormType.Audit) {
      wasSuccessRouted = this._router.navigate([`../pages/audit/${id}`]);
    }
    wasSuccessRouted.then((result) => {
      if (result) {
        this.getAuditNavigationTree(id).subscribe(() => {
          this.setDisplayedRowKey(id);
          this.setSelectedRowKey(id);
        });
      }
    });
  }

  public expandSelectedRow(): void {
    this.addExpandedRowKey(this.getSelectedRowKey()[0]);
  }

  expandAllParents(key: string): void {
    this.expandNode(this._navigationTreeData.getValue(), key);
  }

  expandNode(nodes, key) {
    nodes.forEach((node) => {
      if (node.id === key) {
        this.expandNode(nodes, node.parentId);
        this.addExpandedRowKey(key);
      }
    });
  }

  public updateCollapseNavSideMenu(isCollapsed: boolean) {
    this._collapseNavSideMenu$.next(isCollapsed);
  }

  public getNameById(id: string): string {
    if (this._navigationTreeData.getValue() !== null) {
      const form = this._navigationTreeData.getValue().find((x) => x.id.toUpperCase() === id.toUpperCase());
      if (form !== undefined && form !== null) {
        return form.name;
      }
    }
    return '';
  }

  public deepCopy(obj: any): any {
    return this._utilsService.deepCopy(obj);
  }

  public addAndGoAskToSaveChanges() {
    this.showConfirmDialogBeforeGo();
  }

  public exitFormYesFn: () => boolean;
  public exitFormNoFn: () => boolean;
  public exitFormSpecialFn: () => boolean;

  public showConfirmDialogBeforeGo() {
    this.setExitFormVisible(true);
  }

  public createHtmlList(names: string[]): string {
    let htmlList = '';

    if (names.length === 0) {
      return htmlList;
    }

    names.forEach((name: string) => {
      htmlList += `<li>${name}</li>`;
    });

    return `<ul>${htmlList}</ul>`;
  }

  public async isAuditFinished(auditId: string): Promise<boolean> {
    const audit = await this._apiRequestService.getAudit(auditId).toPromise();
    return audit.isFinished;
  }

  public async toggleFinishedAudit(auditId: string): Promise<any> {
    return this._apiRequestService
      .getAudit(auditId)
      .toPromise()
      .then((audit) => {
        const auditResult = audit;
        audit.isFinished = !audit.isFinished;
        return this._apiRequestService.saveAudit(auditResult).toPromise();
      });
  }
}
