import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Department } from 'src/app/shared/models/department/department.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { ScrollService } from '../../../../../shared/services/scroll.service';
import { UtilsService } from '../../../../services/utils.service';
import { FormType } from '../../../../models/audit/audit-tree-navigation.model';

@Injectable({
  providedIn: 'root',
})
export class DepartmentFormService {
  private departmentCopy: Department;

  public _isFinished = new Subject<boolean>();
  public isFinished = this._isFinished.asObservable();

  private _performAction = new Subject<any>();
  public performAction = this._performAction.asObservable();

  private _isLoaded = new BehaviorSubject<{ value: boolean; formtype: FormType }>({
    value: false,
    formtype: FormType.Audit,
  });
  public isLoaded = this._isLoaded.asObservable();

  private _questionnairePopupClosing = new Subject<boolean>();
  public questionnairePopupClosing = this._questionnairePopupClosing.asObservable();
  public questionnairePopupClosed() {
    this._questionnairePopupClosing.next(true);
  }

  constructor(
    private _apiRequestService: ApiRequestService,
    private scrollService: ScrollService,
    private _utilsService: UtilsService
  ) {}

  public getEmptyDepartment(): Department {
    const department: Department = {
      parentFormId: '1',
      conclusion: '',
      name: '',
      description: '',
      contactPerson: {
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        position: null,
      },
      monitoringSystemDescription: '',
      address: {
        country: null,
        zipCode: null,
        city: null,
        address: null,
      },
      productionOverviews: [],
    };
    return department;
  }

  public createCopy(department: Department): void {
    this.departmentCopy = this._utilsService.deepCopy(department);
  }

  public formChanged(department: Department): boolean {
    return !this._utilsService.areObjectsEqual(department, this.departmentCopy);
  }

  public save(department: Department): Observable<Department> {
    return this._apiRequestService.saveDepartment(department);
  }

  public update(department: Department): Observable<Department> {
    return this._apiRequestService.updateDepartment(department);
  }

  public getCurrentDepartment(departmentId: string): Observable<Department> {
    return this._apiRequestService.getDepartment(departmentId);
  }

  public scrollToTop(): void {
    this.scrollService.updateScrollToTop(true);
  }

  public formLoaded(value: boolean): void {
    this._isLoaded.next({ value, formtype: FormType.Department });
  }

  public selectedAction(e: any): void {
    this._performAction.next(e);
  }

  public toggleFinishedAudit(isFinished: boolean): void {
    this._isFinished.next(isFinished);
  }
}
