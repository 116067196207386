import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import {
  InjectionMachine,
  InjectionMachineDriveType,
  InjectionMachinePumpType,
} from 'src/app/shared/models/area/types/injection-machine.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { Localizable } from '../../../../../../../locale/localizable';

@Component({
  selector: 'app-injection-machine',
  templateUrl: './injection-machine.component.html',
  styleUrls: ['./injection-machine.component.scss'],
})
export class InjectionMachineComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  injectionMachine: InjectionMachine = null;

  injectionMachineDriveTypesList: ArrayStore;
  injectionMachinePumpTypesList: ArrayStore;

  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getAreaTypeInjectionMachinePumpTypes().subscribe((res: Array<InjectionMachinePumpType>) => {
      this.injectionMachinePumpTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService
      .getAreaTypeInjectionMachineDriveTypes()
      .subscribe((res: Array<InjectionMachineDriveType>) => {
        this.injectionMachineDriveTypesList = new ArrayStore({
          key: 'id',
          data: res,
        });
      });
  }
}
