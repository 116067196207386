import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppConfig } from '../../../../app.config';
import { Localizable } from '../../../../locale/localizable';
import { HttpClient } from '@angular/common/http';
import { retry } from 'rxjs/operators';
import { AppSettings } from '../../../../AppSettings';
import { UploadErrorEvent } from 'devextreme/ui/file_uploader';
import { ErrorHandlerService } from '../../../../shared/services/errorHandlerService';
import notify from 'devextreme/ui/notify';
import { AuthenticationService } from '../../../services/authentication.service';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-version-creator',
  templateUrl: './version-creator.component.html',
  styleUrls: ['./version-creator.component.scss'],
})
export class VersionCreatorComponent extends Localizable {
  public uploadUrl: string;
  public fileName = 'file';
  public versionText = '';

  questionnaireVersionInfo: QuestionnaireVersionDto = { versionName: '', versionNumber: -1, fileGroups: [] };
  acceptVersionButtonDisabled: boolean = true;
  acceptButtonOptions: { icon: string; stylingMode: string; type: string; text: string; onClick: () => void };
  @Input() popupWidth: any;
  @Input() popupHeight: any;

  private _popupVisible: boolean;
  @Input() set versionCreatorPopupVisible(value) {
    this._popupVisible = value;
    if (value === false) {
      this.versionCreatorPopupVisibleChange.emit(value);
    }
  }
  get versionCreatorPopupVisible() {
    return this._popupVisible;
  }

  @Output() versionCreatorPopupVisibleChange = new EventEmitter<boolean>();
  public currentVersionCreatorPopupVisible = true;

  constructor(
    private _appConfig: AppConfig,
    private _httpClient: HttpClient,
    private _errorHandlerService: ErrorHandlerService,
    private authenticationService: AuthenticationService
  ) {
    super();

    this.uploadUrl = this._appConfig.getConfig('DbEnergyDatabaseUrl') + `api/questionnaires/files/add`;
  }

  ngOnInit(): void {
    this.requestVersionFileList();
    this.acceptButtonOptions = {
      icon: 'fi fi-rr-check',
      stylingMode: 'contained',
      type: 'normal',
      text: this._('version-creator-accept-version'),
      onClick: () => {
        this.acceptVersion();
      },
    };
  }

  public showVersionCreator(visible: boolean): void {
    this.versionCreatorPopupVisible = visible;
  }

  versionCanBeAccepted() {
    if (
      this.questionnaireVersionInfo.fileGroups &&
      this.questionnaireVersionInfo.fileGroups.length > 0 &&
      this.versionText
    ) {
      this.acceptVersionButtonDisabled = false;
    } else {
      this.acceptVersionButtonDisabled = true;
    }
  }

  private requestVersionFileList() {
    this._httpClient
      .get(this._appConfig.getConfig('DbEnergyDatabaseUrl') + 'api/questionnaires/files/newVersion', {
        responseType: 'json',
      })
      .pipe(retry(AppSettings.REQUEST_TRIES))
      .subscribe(
        (data: QuestionnaireVersionDto) => {
          this.questionnaireVersionInfo = data;
          this.versionCanBeAccepted();
        },
        (error) => {
          console.log(error);
          this._errorHandlerService.handleErrorAdvanced(error, AppSettings.NOTIFY_DURATION, true);
        }
      );
  }

  public handleUploadError(error: UploadErrorEvent) {
    let textError = error.error.responseText;
    if (textError === undefined && error.error !== undefined) {
      textError = error.error;
    }
    const err = { error: textError };
    this._errorHandlerService.handleErrorAdvanced(err, AppSettings.NOTIFY_LONG_DURATION, false);
    this.requestVersionFileList();
  }

  public handleFilesUploaded(event: any) {
    this.requestVersionFileList();
  }

  versionTextChanged(event: any) {
    this.versionCanBeAccepted();
  }

  public acceptVersion() {
    this._httpClient
      .post(
        `${this._appConfig.getConfig('DbEnergyDatabaseUrl')}api/questionnaires/files/acceptversion/${this.versionText}`,
        null,
        { responseType: 'text' }
      )
      .pipe(retry(AppSettings.REQUEST_TRIES))
      .subscribe(
        (data) => {
          this.requestVersionFileList();
          this.loadToast(data);
          return;
        },
        (error) => {
          this.requestVersionFileList();
          this.handleUploadError(error);
        }
      );
  }

  onFileDeleted(event: any) {
    this._httpClient
      .delete(`${this._appConfig.getConfig('DbEnergyDatabaseUrl')}api/questionnaires/file/${event.itemData.fileName}`, {
        responseType: 'text',
      })
      .subscribe(() => this.requestVersionFileList());
  }

  public get token(): string {
    return this.authenticationService.getBearerTokenHeader();
  }

  loadToast(text: string) {
    notify(text, 'success', AppSettings.NOTIFY_DURATION);
  }
}

export class QuestionnaireVersionDto {
  versionNumber: number;
  versionName: string;
  fileGroups: Array<FileGroup>;
}

export class FileGroup {
  fileName: string;
  groups: Array<string>;
}
