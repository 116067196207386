<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="airEnhancer.numberOfDevices"
        stylingMode="outlined"
        [min]="0"
        [max]="100"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-enhancer-number-of-devices')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="airEnhancer.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('air-enhancer-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="airEnhancer.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('air-enhancer-model')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="airEnhancerTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="airEnhancer.airEnhancerType"
        [dataSource]="airEnhancerTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('air-enhancer-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('air-enhancer-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="airEnhancer.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-enhancer-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="airEnhancer.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-enhancer-working-hours-in-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' V'"
        [(value)]="airEnhancer.supplyVoltageValue"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-enhancer-supply-voltage-value')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="airEnhancer.ratedEnginePower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-enhancer-rated-engine-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' bar'"
        [(value)]="airEnhancer.inletPressure"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-enhancer-inlet-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' bar'"
        [(value)]="airEnhancer.outletPressure"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-enhancer-outlet-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³/h'"
        [(value)]="airEnhancer.efficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('air-enhancer-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="airEnhancer.performanceRegulation"
        switchedOffText="Nie"
        switchedOnText="Tak"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('air-enhancer-performance-regulation')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="airEnhancer.overridingControl"
        switchedOffText="Nie"
        switchedOnText="Tak"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('air-enhancer-overriding-control')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="technicalConditionsList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="airEnhancer.technicalCondition"
        [dataSource]="technicalConditionsList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('air-enhancer-technical-condition-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('air-enhancer-technical-condition')"></dxo-label>
  </dxi-item>
</dx-form>
