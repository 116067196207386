import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FormDetectionChangesService {
  public formChanged: Subject<boolean> = new Subject<boolean>();

  private formstatusChanged(changed: boolean): void {
    this.formChanged.next(changed);
  }

  public formChange(): void {
    this.formstatusChanged(true);
  }

  public formSame(): void {
    this.formstatusChanged(false);
  }
}
