import { Component, Input, OnInit } from '@angular/core';
import { CoolingSystem } from 'src/app/shared/models/area/types/cooling-system.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { Localizable } from '../../../../../../../locale/localizable';

@Component({
  selector: 'app-cooling-system',
  templateUrl: './cooling-system.component.html',
  styleUrls: ['./cooling-system.component.scss'],
})
export class CoolingSystemComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  coolingSystem: CoolingSystem = null;

  public currencyFormat: string;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {}
}
