import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { RefrigerantType } from 'src/app/shared/models/area/types/cooling-system.model';
import { Dehumidifier, DehumidifierType } from 'src/app/shared/models/area/types/dehumidifier.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-dehumidifier',
  templateUrl: './dehumidifier.component.html',
  styleUrls: ['./dehumidifier.component.scss'],
})
export class DehumidifierComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  dehumidifier: Dehumidifier = null;

  currentYear: number;
  refrigerantTypes: ArrayStore;
  dehumidifierTypes: ArrayStore;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getDehumidifierTypes().subscribe((res: Array<DehumidifierType>) => {
      this.dehumidifierTypes = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getRefrigerantTypes().subscribe((res: Array<RefrigerantType>) => {
      this.refrigerantTypes = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
