import { Component } from '@angular/core';
import { AppConstant } from './constant/constant.model';
import { Localizable } from '../../../locale/localizable';
@Component({
  selector: 'app-global-constant-list',
  templateUrl: './global-constant-list.component.html',
  styleUrls: ['./global-constant-list.component.scss'],
})
export class GlobalConstantListComponent extends Localizable {
  constantsList: Array<AppConstant> = [
    {
      name: this._('constants-list-audit-type-name'),
      apiUrl: 'api/constants/AuditType',
      titleBarText: this._('constants-list-audit-type-title-bar-text'),
      gridColumnName: this._('constants-list-audit-type-grid-column-name'),
    },
    {
      name: this._('constants-list-energy-balance-type-name'),
      apiUrl: 'api/constants/EnergyBalanceType',
      titleBarText: this._('constants-list-energy-balance-type-title-bar-text'),
      gridColumnName: this._('constants-list-energy-balance-type-grid-column-name'),
    },
    {
      name: this._('constants-list-currency-name'),
      apiUrl: 'api/constants/Currency',
      titleBarText: this._('constants-list-currency-title-bar-text'),
      gridColumnName: this._('constants-list-currency-grid-column-name'),
      gridSecondColumnName: this._('constants-list-currency-grid-second-column-name'),
      shortName: this._('constants-list-currency-short-name'),
    },
    {
      name: this._('constants-list-industry-type-name'),
      apiUrl: 'api/constants/IndustryType',
      titleBarText: this._('constants-list-industry-type-title-bar-text'),
      gridColumnName: this._('constants-list-industry-type-grid-column-name'),
    },
    {
      name: this._('constants-list-unit-name'),
      apiUrl: 'api/constants/Unit',
      titleBarText: this._('constants-list-unit-title-bar-text'),
      gridColumnName: this._('constants-list-unit-grid-column-name'),
      gridSecondColumnName: this._('constants-list-unit-grid-second-column-name'),
      shortName: this._('constants-list-unit-short-name'),
    },
    {
      name: this._('constants-list-action-type-name'),
      apiUrl: 'api/constants/ActionType',
      titleBarText: this._('constants-list-action-type-title-bar-text'),
      gridColumnName: this._('constants-list-action-type-grid-column-name'),
    },
    {
      name: this._('constants-list-refrigerant-type-name'),
      apiUrl: 'api/constants/RefrigerantType',
      titleBarText: this._('constants-list-refrigerant-type-title-bar-text'),
      gridColumnName: this._('constants-list-refrigerant-type-grid-column-name'),
    },
    {
      name: this._('constants-list-compressor-type-name'),
      apiUrl: 'api/constants/CompressorType',
      titleBarText: this._('constants-list-compressor-type-title-bar-text'),
      gridColumnName: this._('constants-list-compressor-type-grid-column-name'),
    },
    {
      name: this._('constants-list-efficiency-regulation-type-name'),
      apiUrl: 'api/constants/EfficiencyRegulationType',
      titleBarText: this._('constants-list-efficiency-regulation-type-title-bar-text'),
      gridColumnName: this._('constants-list-efficiency-regulation-type-grid-column-name'),
    },
    {
      name: this._('constants-list-medium-name'),
      apiUrl: 'api/constants/Medium',
      titleBarText: this._('constants-list-medium-title-bar-text'),
      gridColumnName: this._('constants-list-medium-grid-column-name'),
    },
    {
      name: this._('constants-list-technical-condition-name'),
      apiUrl: 'api/constants/TechnicalCondition',
      titleBarText: this._('constants-list-technical-condition-title-bar-text'),
      gridColumnName: this._('constants-list-technical-condition-grid-column-name'),
    },
    {
      name: this._('constants-list-energy-saving-type-name'),
      apiUrl: 'api/constants/EnergySavingType',
      titleBarText: this._('constants-list-energy-saving-type-title-bar-text'),
      gridColumnName: this._('constants-list-energy-saving-type-grid-column-name'),
    },
  ];

  constantsListEnergyConnection: Array<AppConstant> = [
    {
      name: this._('constants-list-energy-connection-name'),
      apiUrl: 'api/constants/ConnectionGroupe',
      titleBarText: this._('constants-list-energy-connection-title-bar-text'),
      gridColumnName: this._('constants-list-energy-connection-grid-column-name'),
    },
    {
      name: this._('constants-list-energy-connection-measuring-system-name'),
      apiUrl: 'api/constants/MeasuringSystem',
      titleBarText: this._('constants-list-energy-connection-measuring-system-title-bar-text'),
      gridColumnName: this._('constants-list-energy-connection-measuring-system-grid-column-name'),
    },
    {
      name: this._('constants-list-energy-connection-energy-seller-name'),
      apiUrl: 'api/constants/EnergySeller',
      titleBarText: this._('constants-list-energy-connection-energy-seller-title-bar-text'),
      gridColumnName: this._('constants-list-energy-connection-energy-seller-grid-column-name'),
    },
    {
      name: this._('constants-list-energy-connection-energy-distributor-name'),
      apiUrl: 'api/constants/EnergyDistributor',
      titleBarText: this._('constants-list-energy-connection-energy-distributor-title-bar-text'),
      gridColumnName: this._('constants-list-energy-connection-energy-distributor-grid-column-name'),
    },
    {
      name: this._('constants-list-energy-connection-tariff-name'),
      apiUrl: 'api/constants/Tariff',
      titleBarText: this._('constants-list-energy-connection-tariff-title-bar-text'),
      gridColumnName: this._('constants-list-energy-connection-tariff-grid-column-name'),
    },
  ];

  constantsListTransport: Array<AppConstant> = [
    {
      name: this._('constants-list-transport-fleet-ownership-name'),
      apiUrl: 'api/constants/FleetOwnership',
      titleBarText: this._('constants-list-transport-fleet-ownership-title-bar-text'),
      gridColumnName: this._('constants-list-transport-fleet-ownership-grid-column-name'),
    },
    {
      name: this._('constants-list-transport-vehicle-type-name'),
      apiUrl: 'api/constants/VehicleType',
      titleBarText: this._('constants-list-transport-vehicle-type-title-bar-text'),
      gridColumnName: this._('constants-list-transport-vehicle-type-grid-column-name'),
    },
    {
      name: this._('constants-list-transport-vehicle-fuel-type-name'),
      apiUrl: 'api/constants/VehicleFuelType',
      titleBarText: this._('constants-list-transport-vehicle-fuel-type-title-bar-text'),
      gridColumnName: this._('constants-list-transport-vehicle-fuel-type-grid-column-name'),
    },
  ];

  constantsListManufacturingProcess: Array<AppConstant> = [
    {
      name: this._('constants-list-manufacturing-process-efficiency-class-name'),
      apiUrl: 'api/constants/EfficiencyClass',
      titleBarText: this._('constants-list-manufacturing-process-efficiency-class-title-bar-text'),
      gridColumnName: this._('constants-list-manufacturing-process-efficiency-class-grid-column-name'),
    },
  ];

  constantsListInjectionMolding: Array<AppConstant> = [
    {
      name: this._('constants-list-injection-molding-injection-machine-drive-type-name'),
      apiUrl: 'api/constants/InjectionMachineDriveType',
      titleBarText: this._('constants-list-injection-molding-injection-machine-drive-type-title-bar-text'),
      gridColumnName: this._('constants-list-injection-molding-injection-machine-drive-type-grid-column-name'),
    },
    {
      name: this._('constants-list-injection-molding-injection-machine-pump-type-name'),
      apiUrl: 'api/constants/InjectionMachinePumpType',
      titleBarText: this._('constants-list-injection-molding-injection-machine-pump-type-title-bar-text'),
      gridColumnName: this._('constants-list-injection-molding-injection-machine-pump-type-grid-column-name'),
    },
  ];

  constantsListArmature: Array<AppConstant> = [
    {
      name: this._('constants-list-armature-armature-insulation-material-name'),
      apiUrl: 'api/constants/ArmatureInsulationMaterial',
      titleBarText: this._('constants-list-armature-armature-insulation-material-title-bar-text'),
      gridColumnName: this._('constants-list-armature-armature-insulation-material-grid-column-name'),
    },
  ];

  constatntsListLighting: Array<AppConstant> = [
    {
      name: this._('constants-list-lighting-lighting-control-system-name'),
      apiUrl: 'api/constants/LightingControlSystem',
      titleBarText: this._('constants-list-lighting-lighting-control-system-title-bar-text'),
      gridColumnName: this._('constants-list-lighting-lighting-control-system-grid-column-name'),
    },
  ];

  constatntsListHeating: Array<AppConstant> = [
    {
      name: this._('constants-list-heating-radiant-heater-supply-type-name'),
      apiUrl: 'api/constants/RadiantHeaterSupplyType',
      titleBarText: this._('constants-list-heating-radiant-heater-supply-type-title-bar-text'),
      gridColumnName: this._('constants-list-heating-radiant-heater-supply-type-grid-column-name'),
    },
  ];

  constructor() {
    super();
  }
}
