import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { EfficiencyClass, ElectricDrive } from 'src/app/shared/models/area/types/electric-drive.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-electric-drive',
  templateUrl: './electric-drive.component.html',
  styleUrls: ['./electric-drive.component.scss'],
})
export class ElectricDriveComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  electricDrive: ElectricDrive = null;

  efficiencyClassesList: ArrayStore;
  public rotationUnit: string = this._('electric-drive-rotation-unit');

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this._apiRequestService.getAreaTypeElectricDriveEfficiencyClasses().subscribe((res: Array<EfficiencyClass>) => {
      this.efficiencyClassesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
