<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div
  class="image"
  [ngClass]="{ 'image-error-flex': imageFileUnavailable }"
>
  <dx-button
    [id]="'image' + image.id"
    *ngIf="!imageSelection && !isAuditFinished && !imageEntityError"
    class="menu-button"
    stylingMode="outlined"
    icon="overflow"
    [visible]="!loadingImage"
  >
  </dx-button>

  <dx-context-menu
    showEvent="dxclick"
    [dataSource]="items"
    [target]="'#image' + image.id"
    [width]="100"
    (onItemClick)="menuItemClick($event)"
  >
    <div *dxTemplate="let itemData of 'item'">
      <div class="item-template-container">
        <span
          *ngIf="itemData.icon"
          [ngClass]="itemData.icon"
          class="dx-icon"
        ></span>
        <span class="dx-menu-item-text">{{ itemData.text }}</span>
        <span
          *ngIf="itemData.items"
          class="dx-icon-spinright dx-icon"
        ></span>
      </div>
    </div>
  </dx-context-menu>

  <dx-button
    *ngIf="imageSelection && selected && !imageEntityError"
    class="image-selected"
    stylingMode="contained"
    icon="fi fi-rr-check"
    [hoverStateEnabled]="false"
    [focusStateEnabled]="false"
    [activeStateEnabled]="false"
    (onClick)="selectImage()"
    [visible]="!loadingImage"
  >
  </dx-button>

  <dx-button
    *ngIf="imageSelection && !selected && !imageEntityError"
    class="image-not-selected"
    stylingMode="contained"
    [hoverStateEnabled]="false"
    [focusStateEnabled]="false"
    [activeStateEnabled]="false"
    (onClick)="selectImage()"
    [visible]="!loadingImage"
  >
  </dx-button>

  <dx-button
    *ngIf="image.include"
    class="in-raport-indicator border"
    stylingMode="contained"
    icon="fi fi-rr-document"
    [hoverStateEnabled]="false"
    [focusStateEnabled]="false"
    [activeStateEnabled]="false"
    (onClick)="selectImage()"
    [visible]="!loadingImage"
  >
  </dx-button>
  <dx-load-indicator
    class="image-load-indicator"
    [(visible)]="loadingImage"
    [height]="loadIndicatorHeight"
    [width]="loadIndicatorWidth"
  >
  </dx-load-indicator>
  <img
    *ngIf="!imageFileUnavailable && !imageEntityError"
    class="img-class"
    #imageElement
    id="imageElement{{ image.id }}"
    (click)="selectImage()"
    onkeypress="selectImage()"
    width="100%"
    height="100%"
    alt="{{ image.fileName }}"
    loading="lazy"
    [style.visibility]="loadingImage ? 'hidden' : 'visible'"
  />
  <div *ngIf="imageFileUnavailable">
    <p>
      {{ _('gallery-image-unavailable') }}
    </p>
  </div>
</div>

<app-images-form-selector
  *ngIf="showMoveImagePopup"
  [showMoveImagePopup]="showMoveImagePopup"
  (onImagesMoved)="showMenuButtons = false"
  (onPopupHiding)="onPopupHiding()"
  (onImagesMoved)="onImagesMoved()"
  [imagesIds]="[this.image.id]"
></app-images-form-selector>

<dx-popup
  *ngIf="showImageDetailsPopup"
  [title]="_('audit-gallery-edit-image-title')"
  [width]="(mobile ? popupWidth * 0.9 : 50) + '%'"
  [height]="(mobile ? popupHeight * 0.9 : 80) + '%'"
  [fullScreen]="false"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="showImageDetailsPopup"
  (onHiding)="checkChanges()"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <dxi-toolbar-item
    toolbar="bottom"
    widget="dxSwitch"
    location="before"
    [options]="switchOptions"
    [disabled]="isAuditFinished"
  ></dxi-toolbar-item>
  <dxi-toolbar-item
    toolbar="bottom"
    location="before"
  >
    {{ _('gallery-picture-include-in-report') }}
  </dxi-toolbar-item>
  <dxi-toolbar-item
    toolbar="bottom"
    widget="dxButton"
    location="after"
    [options]="downloadButtonOptions"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    toolbar="bottom"
    widget="dxButton"
    location="after"
    [options]="deleteButtonOptions"
    [disabled]="isAuditFinished"
  >
  </dxi-toolbar-item>
  <app-picture
    #picture
    [isAuditFinished]="isAuditFinished"
    [image]="image"
    (deletedImageId)="afterImageRemove()"
    (editedImageId)="afterImageEdit($event)"
    (canZoomOutChange)="handleAbilityToZoomOut($event)"
  >
  </app-picture>
</dx-popup>

<app-exit-confirmation-popup
  [popupVisible]="showImageDeleteConfirmationPopup"
  [confirmationNo]="_('No')"
  [confirmationYes]="_('Yes')"
  [title]="_('audit-gallery-delete-image-confirm-title')"
  [message]="_('audit-gallery-delete-image-confirm-message')"
  (closeEvent)="delete($event, image.id)"
></app-exit-confirmation-popup>
