import { Component, Input, inject } from '@angular/core';
import { FixtureType, Lighting, LightingControlSystem } from 'src/app/shared/models/area/types/lighting.model';
import ArrayStore from 'devextreme/data/array_store';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { AppConfig } from 'src/app/app.config';
import { Localizable } from '../../../../../../../locale/localizable';
import { AppSettings } from '../../../../../../../AppSettings';
import { AuthenticationService } from '../../../../../../../shared/services/authentication.service';
import { ErrorHandlerService } from '../../../../../../../shared/services/errorHandlerService';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-lighting',
  templateUrl: './lighting.component.html',
  styleUrls: ['./lighting.component.scss'],
})
export class LightingComponent extends Localizable {
  @Input()
  auditId: string = '';

  @Input()
  lighting: Lighting = null;

  lightingControlSystemsList: ArrayStore;
  fixturesList: any;
  fixtureTypesList: Array<FixtureType>;

  constructor(
    private _apiRequestService: ApiRequestService,
    private _config: AppConfig,
    private _authenticationService: AuthenticationService,
    private _errorHandlerService: ErrorHandlerService
  ) {
    super();
  }

  ngOnInit(): void {
    const urlDBEnergyDatabase = this._config.getConfig('DbEnergyDatabaseUrl');

    this._apiRequestService.getAreaTypeLightingControlSystems().subscribe((res: Array<LightingControlSystem>) => {
      this.lightingControlSystemsList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getAreaTypeLightingFixtureTypes().subscribe((res: Array<FixtureType>) => {
      this.fixtureTypesList = res;
    });

    this.fixturesList = AspNetData.createStore({
      key: 'id',
      loadUrl: `${urlDBEnergyDatabase}api/areas/lightings/${this.lighting.id}/fixtures`,
      insertUrl: `${urlDBEnergyDatabase}api/areas/lightings/${this.lighting.id}/fixtures`,
      updateUrl: `${urlDBEnergyDatabase}api/areas/lightings/fixtures`,
      deleteUrl: `${urlDBEnergyDatabase}api/areas/lightings/fixtures`,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.xhrFields = { withCredentials: true };
        const token = localStorage.getItem(AppSettings.AUTH_TOKEN);
        ajaxOptions.headers = { Authorization: `Bearer ${token}` };
      },
      onAjaxError: (e) => {
        if (e.xhr.status === AppSettings.HTTP_STATUS_CODE_NOT_AUTHORIZED) {
          this._authenticationService.logout();
          notify(this._('not-authorized-logout'), 'error', AppSettings.NOTIFY_DURATION);
        } else {
          this._errorHandlerService.handleError(e.xhr);
        }
      },
    });
  }
}
