<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form>
  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [format]="fullNumberFormat + ' bar'"
        [(value)]="compressedAirGenerator.workingPressureInNetwork"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('compressed-air-generator-working-pressure-in-network')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="compressedAirGenerator.masterControlSystem"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('compressed-air-generator-master-control-system')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="compressedAirGenerator.numberOfCompressors"
        stylingMode="outlined"
        [min]="0"
        [max]="20"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('compressed-air-generator-number-of-compressors')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="compressedAirGenerator.numberOfCompressorsTwo"
        stylingMode="outlined"
        [min]="0"
        [max]="20"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('compressed-air-generator-number-of-compressors-two')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="compressedAirGenerator.subPeakSystem"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('compressed-air-generator-sub-peak-system')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³'"
        [(value)]="compressedAirGenerator.compressedAirProductionInAnalyzedPeriod"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('compressed-air-generator-compressed-air-production-in-analyzed-period')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' MWh'"
        [(value)]="compressedAirGenerator.electricityConsumptionInAnalyzedPriod"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('compressed-air-generator-electricity-consumption-in-analyzed-priod')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="compressedAirGenerator.airTreatmentSystem"
        switchedOffText="Nie"
        switchedOnText="Tak"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('compressed-air-generator-air-treatment-system')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="compressedAirGenerator.compressedAirPurityClass"
        stylingMode="outlined"
        [min]="1"
        [max]="6"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('compressed-air-generator-compressed-air-purity-class')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="compressedAirGenerator.bufferTanks"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('compressed-air-generator-buffer-tanks')"></dxo-label>
  </dxi-item>
</dx-form>
