import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { Localizable } from '../../../app/locale/localizable';
import { AuthenticationService } from './authentication.service';
import { AppSettings } from '../../AppSettings';
import notify from 'devextreme/ui/notify';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends Localizable {
  constructor(private authenticationService: AuthenticationService) {
    super('dictionary');
  }

  handleError(error: any) {
    return this.handleErrorAdvanced(error, AppSettings.NOTIFY_DURATION, false);
  }

  handleErrorAdvanced(error: any, duration: number, onlyAuthentication: boolean) {
    let errorMessage: string;
    console.log(error);
    if (error.status === AppSettings.HTTP_STATUS_CODE_NOT_AUTHORIZED) {
      this.authenticationService.logout();
      errorMessage = this._('not-authorized-logout');
      notify(errorMessage, 'error', AppSettings.NOTIFY_DURATION);
      return throwError(errorMessage);
    }
    if (error.status === AppSettings.HTTP_STATUS_CODE_FORBIDDEN) {
      this.authenticationService.logout();
      errorMessage = this._('forbidden-logout');
      notify(errorMessage, 'error', AppSettings.NOTIFY_DURATION);
      return throwError(errorMessage);
    }

    if (onlyAuthentication) {
      return throwError(errorMessage);
    }

    if (error.error instanceof ErrorEvent || typeof error.error === 'string') {
      errorMessage = `${this._('Error')}: ${error.error}`;
    } else {
      errorMessage = this._('internal-server-error');
    }

    notify(errorMessage, 'error', duration);
    return throwError(errorMessage);
  }
}
