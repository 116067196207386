import { Component, NgModule, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';

import { Router } from '@angular/router';
import { DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';
import { AuthenticationService } from '../../services/authentication.service';
import { ScreenService } from '../../services';
import { AccountModule } from '../account/account.component';
import { AppSettings } from '../../../AppSettings';
@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title: string;

  public isSmall;

  constructor(
    private screen: ScreenService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.screen.changed.subscribe(() => this.updateDrawer());
  }

  updateDrawer() {
    this.isSmall = this.screen.sizes['screen-small'] || this.screen.sizes['screen-x-small'];
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  };

  login(): void {
    this.router.navigateByUrl('/login');
  }

  logout(): void {
    this.authenticationService.logout();
    this.router.navigateByUrl('/login');
  }

  isLoggedIn(): boolean {
    return localStorage.getItem(AppSettings.AUTH_TOKEN) ? true : false;
  }
}

@NgModule({
  imports: [CommonModule, DxButtonModule, DxToolbarModule, DxSelectBoxModule, DxTextBoxModule, AccountModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
