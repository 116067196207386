<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<ng-container>
  <app-side-nav-outer-toolbar
    *ngIf="showMenu"
    [title]="showTitle ? appInfo.title : ''"
  >
    <router-outlet></router-outlet>
  </app-side-nav-outer-toolbar>

  <app-login *ngIf="!showMenu"></app-login>
</ng-container>
