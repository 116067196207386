<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="electricHeater.numberOfDevices"
        stylingMode="outlined"
        [min]="0"
        [max]="1000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('electric-heater-number-of-devices')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="electricHeater.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('electric-heater-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="electricHeater.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('electric-heater-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="electricHeater.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('electric-heater-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="electricHeaterTypesLIst">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="electricHeater.electricHeaterType"
        [dataSource]="electricHeaterTypesLIst"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('electric-heater-electric-heater-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('electric-heater-electric-heater-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="electricHeater.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('electric-heater-working-hours-in-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="electricHeater.ratedElectricPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('electric-heater-rated-electric-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="electricHeater.ratedThermalPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('electric-heater-rated-thermal-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' %'"
        [(value)]="electricHeater.efficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('electric-heater-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="electricHeater.thermostaticHead"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('electric-heater-thermostatic-head')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="electricHeater.controlSystem"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('electric-heater-control-system')"></dxo-label>
  </dxi-item>
</dx-form>
