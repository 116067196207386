import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppSettings } from '../../../AppSettings';
import { Localizable } from '../../../locale/localizable';
import { FormType } from '../../models/audit/audit-tree-navigation.model';

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss'],
})
export class CustomPopupComponent extends Localizable {
  private static readonly localFile = 'ApiRequestService';

  @Input()
  public message: any;
  @Input()
  public name = '';
  @Input()
  public type = '';
  @Input()
  public receiversToDeleteList = new Array<string>();
  @Input()
  public receiversBlockingDeletionList = new Array<string>();
  @Input()
  public formsList = new Array<string>();

  @Output()
  public popupClose = new EventEmitter<void>();

  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;

  public get FormTypesEnum() {
    return FormType;
  }

  constructor() {
    super(CustomPopupComponent.localFile);
  }

  popupClosed() {
    this.popupClose.emit();
  }
}
