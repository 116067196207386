import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { DtoEnergyBalanceSource, EnergyBalanceSource } from 'src/app/shared/models/energies/energy.model';
import { Unit } from 'src/app/shared/models/unit.model';
import { AppSettings } from '../../../../AppSettings';
import { ScreenService } from '../../../services';
import { EnergyBalanceService } from '../energy-balance.service';
import { Localizable } from '../../../../locale/localizable';

@Component({
  selector: 'app-energy-source-popup',
  templateUrl: './energy-source-popup.component.html',
  styleUrls: ['./energy-source-popup.component.scss'],
})
export class EnergySourcePopupComponent extends Localizable implements OnInit {
  @Input() editMode: boolean = false;
  @Input() editEnergySource: EnergyBalanceSource;
  @Input() energySource: DtoEnergyBalanceSource;
  @Input() currencyFormat: string = '';

  @Output() addEnergySource = new EventEmitter<EnergyBalanceSource>();
  @Output() updateEnergySourced = new EventEmitter<EnergyBalanceSource>();

  public unitsList: Array<Unit>;
  public mobile: boolean = false;

  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;

  constructor(
    private _energyBalanceService: EnergyBalanceService,
    private _screenService: ScreenService
  ) {
    super('EnergyBalanceComponent');
  }

  ngOnInit(): void {
    this._energyBalanceService.getUnitsListForEnergyBalance().subscribe((res) => (this.unitsList = res));

    this._screenService.changed.subscribe(() => {
      this.setPopUpFullScreen();
    });

    if (this.editMode) {
      this.energySource = {
        id: this.editEnergySource.id,
        name: this.editEnergySource.name,
        energyAmount: this.editEnergySource.energyAmount,
        unitId: this.editEnergySource.unit.id,
        energyCost: this.editEnergySource.energyCost,
      };
    }
  }

  formClosed(): void {
    this.addEnergySource.emit(null);
  }

  getUnitsForEnergyBalance(): void {
    this._energyBalanceService.getUnitsListForEnergyBalance().subscribe((res) => (this.unitsList = res));
  }

  updateEnergyBalanceSource(e: any, energySource: DtoEnergyBalanceSource): void {
    if (e.validationGroup.validate().isValid) {
      this._energyBalanceService.updateEnergyBalanceSource(energySource).subscribe((res: EnergyBalanceSource) => {
        this.updateEnergySourced.emit(res);
        notify({
          message: `Zmiany zapisane`,
          type: 'success',
          displayTime: AppSettings.NOTIFY_DURATION,
          position: 'top center',
        });
      });
    }
  }

  addNewSource(e: any, energySource: DtoEnergyBalanceSource): void {
    if (e.validationGroup.validate().isValid) {
      this._energyBalanceService.addNewEnergyBalanceSource(energySource).subscribe((res: EnergyBalanceSource) => {
        this.addEnergySource.emit(res);
        notify({
          message: `Źródło dodane`,
          type: 'success',
          displayTime: AppSettings.NOTIFY_DURATION,
          position: 'top center',
        });
      });
    }
  }

  public setPopUpFullScreen(): void {
    if (this._screenService.sizes['screen-x-small'] || this._screenService.sizes['screen-small']) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }
}
