import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { AppSettings } from '../../../AppSettings';

@Component({
  selector: 'app-missing-templates',
  templateUrl: './missing-templates.component.html',
  styleUrls: ['./missing-templates.component.scss'],
})
export class MissingTemplatesComponent implements OnInit {
  @Input() missingTemplatesList: Array<string> = null;
  @Input() canGenerate: boolean = false;
  @Output() generateReport = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  emitGenerateReport(): void {
    this.generateReport.emit(true);
  }
}
