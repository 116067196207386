<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="container">
  <dx-file-uploader
    title="_('gallery-upload-image-title')"
    type="normal"
    width="100%"
    [(uploadUrl)]="uploadUrl"
    accept=".jpg,.png,jpeg."
    labelText=""
    [selectButtonText]="_('gallery-upload-image-select-button-text')"
    uploadMode="instantly"
    [multiple]="false"
    [invalidFileExtensionMessage]="_('gallery-upload-image-invalid-extension-message')"
    [invalidMaxFileSizeMessage]="_('gallery-upload-image-invalid-max-size-message')"
    [uploadAbortedMessage]="_('gallery-upload-image-upload-aborted-message')"
    [uploadFailedMessage]="_('gallery-upload-image-upload-failed-message')"
    [maxFileSize]="maxImageSize"
    (onUploaded)="uploadComplete($event)"
    (onUploadError)="handleUploadError($event)"
    [uploadHeaders]="{
      Authorization: token,
    }"
  >
  </dx-file-uploader>
</div>
