<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div *ngIf="currentEnterprise">
  <h1
    *ngIf="currentEnterprise && currentEnterpriseId"
    class="form-header-margin-top form-header-margin-bottom"
  >
    {{ currentEnterprise.name }}
  </h1>

  <p-tabView
    id="tabview"
    [activeIndex]="activeIndex"
    (onChange)="handleChange($event)"
    *ngIf="currentEnterprise && currentEnterpriseId"
  >
    <p-tabPanel [header]="_('button-form-text')">
      <ng-template [ngTemplateOutlet]="form"></ng-template>
    </p-tabPanel>
    <p-tabPanel header="{{ _('button-gallery-text') }} ({{ getImagesNumber() }})">
      <app-audit-gallery
        [formId]="currentEnterpriseId"
        [auditId]="currentAuditId"
        *ngIf="isGalleryVisible"
        [isAuditFinished]="isAuditFinishedFlag"
      ></app-audit-gallery>
    </p-tabPanel>
    <p-tabPanel [header]="_('button-sankey-text')">
      <app-energy-balance-chart
        *ngIf="energyBalanceChart"
        [auditId]="currentAuditId"
      ></app-energy-balance-chart>
      <app-energy-balance
        *ngIf="currentEnterprise"
        [auditId]="currentAuditId"
        [energyBalanceId]="this.currentEnterprise.energyBalance.id"
        [assignedToFormId]="this.currentEnterpriseId"
        [isEnergyBalanceDisabled]="isAuditFinishedFlag"
      >
      </app-energy-balance>
    </p-tabPanel>
  </p-tabView>
</div>

<ng-template
  *ngIf="currentEnterprise && !currentEnterpriseId"
  [ngTemplateOutlet]="form"
></ng-template>
<div *ngIf="currentEnterprise && enterpriseId">
  <dx-popup
    *ngIf="createDepartmentPopupVisible"
    [title]="_('create-department')"
    [fullScreen]="false"
    maxWidth="70rem"
    [height]="popupHeight + '%'"
    [showTitle]="true"
    [dragEnabled]="false"
    [hideOnOutsideClick]="false"
    [showCloseButton]="true"
    [(visible)]="createDepartmentPopupVisible"
    (onHiding)="onDepartmentCreationPopupHiding($event)"
    (onShown)="onDepartmentCreationPopupShown($event)"
    [wrapperAttr]="{ class: 'custom-popup' }"
  >
    <dx-scroll-view
      width="100%"
      height="100%"
    >
      <div class="forms">
        <app-department-form
          #departmentForm
          [existingForm]="false"
          [enterpriseId]="this.currentEnterpriseId"
          [enterpriseChangesIsSaved]="isChangesSaved()"
          (departmentAdded)="closeDepartmentForm($event)"
        ></app-department-form>
      </div>
    </dx-scroll-view>
    <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      cssClass="button-rounded-regular toolbar-button-focus-border"
      [options]="createButtonOptions"
    >
    </dxi-toolbar-item>
    <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      cssClass="toolbar-contained-button-focus-border button-contained button-case-sensitive"
      [options]="createAndDisplayButtonOptions"
    >
    </dxi-toolbar-item>
  </dx-popup>
</div>

<app-exit-confirmation-popup
  [popupVisible]="createDepartmentExitPopupVisible"
  [confirmationNo]="_('No')"
  [confirmationYes]="_('Yes')"
  [title]="_('unsaved-changes-title')"
  [message]="_('unsaved-changes-message')"
  (closeEvent)="onCloseDepartmentCreation($event)"
></app-exit-confirmation-popup>

<ng-template #form>
  <dx-form
    *ngIf="currentEnterprise"
    id="enterprise-form"
    [showColonAfterLabel]="true"
    [disabled]="isChangingDisabled || isAuditFinished()"
  >
    <dxi-item>
      <h3 class="form-header-margin">{{ _('general') }}</h3>
    </dxi-item>
    <dxi-item>
      <dxi-validation-rule
        type="required"
        [message]="_('enterprise-name-required')"
      >
        <div *dxTemplate>
          <dx-text-box
            [(value)]="currentEnterprise.name"
            stylingMode="outlined"
            [disabled]="templateMode"
          >
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'Name'"></app-field-doc-variable>
          </div>
        </div>
      </dxi-validation-rule>
      <dxo-label [text]="_('enterprise-name')"></dxo-label>
    </dxi-item>

    <dxi-item>
      <div *dxTemplate>
        <dx-text-area
          height="100"
          [(value)]="currentEnterprise.description"
          stylingMode="outlined"
          [disabled]="templateMode"
        >
        </dx-text-area>
        <div *ngIf="templateMode">
          <app-field-doc-variable [text]="this.docVariableCode + 'Description'"></app-field-doc-variable>
        </div>
      </div>
      <dxo-label [text]="_('enterprise-description')"></dxo-label>
    </dxi-item>

    <dxi-item *ngIf="industryTypes">
      <div *dxTemplate>
        <dx-select-box
          [dataSource]="industryTypes"
          [(value)]="currentEnterprise.industryType"
          displayExpr="name"
          showClearButton="true"
          [placeholder]="_('enterprise-industry-select-placeholder')"
          stylingMode="outlined"
          [disabled]="templateMode"
          [searchEnabled]="true"
          searchMode="contains"
        >
        </dx-select-box>
        <div *ngIf="templateMode">
          <app-field-doc-variable [text]="this.docVariableCode + 'IndustryType'"></app-field-doc-variable>
        </div>
      </div>
      <dxo-label [text]="_('enterprise-industry')"></dxo-label>
    </dxi-item>
    <dxi-item
      itemType="group"
      cssClass="georama-font"
      [caption]="_('contact-details')"
    >
      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            [(value)]="currentEnterprise.address.country"
            stylingMode="outlined"
            [disabled]="templateMode"
          >
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'Country'"></app-field-doc-variable>
          </div>
        </div>
        <dxo-label [text]="_('enterprise-country')"></dxo-label>
      </dxi-item>

      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            [(value)]="currentEnterprise.address.city"
            stylingMode="outlined"
            [disabled]="templateMode"
          >
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'City'"></app-field-doc-variable>
          </div>
        </div>
        <dxo-label [text]="_('enterprise-city')"></dxo-label>
      </dxi-item>

      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            #zipCodeTextBox
            [(value)]="currentEnterprise.address.zipCode"
            [disabled]="templateMode"
            stylingMode="outlined"
          >
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'ZipCode'"></app-field-doc-variable>
          </div>
        </div>
        <dxo-label [text]="_('enterprise-post-code')"></dxo-label>
      </dxi-item>

      <dxi-item>
        <div *dxTemplate>
          <dx-text-box
            [(value)]="currentEnterprise.address.address"
            stylingMode="outlined"
            [disabled]="templateMode"
          >
          </dx-text-box>
          <div *ngIf="templateMode">
            <app-field-doc-variable [text]="this.docVariableCode + 'Address'"></app-field-doc-variable>
          </div>
        </div>
        <dxo-label [text]="_('enterprise-address')"></dxo-label>
      </dxi-item>
    </dxi-item>

      <dxi-item
        [disabled]="templateMode"
        cssClass="georama-font"
        itemType="group"
        [caption]="_('department-list')"
      >


        <div *dxTemplate>
          <dx-data-grid
            id="gridContainer"
            [dataSource]="currentEnterprise.departments"
            [allowColumnReordering]="true"
            [columnAutoWidth]="false"
            [showBorders]="true"
          >
            <dxo-paging [enabled]="true"></dxo-paging>

            <dxo-toolbar>
              <dxi-item location="after">
                <dx-button

                  icon="add"
                  class="text-button-focus-hover button-rounded-regular"
                  (onClick)="onAddDepartmentButtonClick()"
                  stylingMode="text"
                  [disabled]="isAuditFinishedFlag"
                >
                </dx-button>
              </dxi-item>
            </dxo-toolbar>

            <dxi-column
              dataField="name"
              [caption]="_('name')"
            ></dxi-column>
            <dxi-column
              dataField="description"
              [caption]="_('description')"
            ></dxi-column>
          </dx-data-grid>
        </div>
        </dxi-item>

    <dxi-item *ngIf="docVariableCode">
      <div *dxTemplate>
        <app-add-template [areaTypeCode]="this.docVariableCode"></app-add-template>
      </div>
    </dxi-item>
  </dx-form>
</ng-template>
