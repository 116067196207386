import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { retry } from 'rxjs/operators';
import { TemplateService } from 'src/app/shared/services/template.service';
import { AppConfig } from '../../../app.config';
import { AppSettings } from '../../../AppSettings';
import { AreaTypes } from '../../../shared/models/area/area-types';
import { Localizable } from '../../../locale/localizable';

@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss'],
})
export class TemplateFormComponent extends Localizable implements OnInit {
  selectedFormType: string = null;
  urlToApi: string = null;
  categoryCode: string = null;

  public get AreaTypesEnum(): typeof AreaTypes {
    return AreaTypes;
  }

  constructor(
    private _templateService: TemplateService,
    private _httpClient: HttpClient,
    private _config: AppConfig
  ) {
    super('TemplateFileListComponent');
    this.urlToApi = this._config.getConfig('DbEnergyDatabaseUrl');
  }

  ngOnInit(): void {
    this._templateService.formTypeUpdated.subscribe((res) => (this.selectedFormType = res));
  }

  getElectroEnergeticCode(): any {
    this._httpClient
      .get<string>(this.urlToApi + 'api/areas/categories/electro-energetics/doc-variables')
      .pipe(retry(AppSettings.REQUEST_TRIES))
      .subscribe((res) => (this.categoryCode = res));
  }

  getBuildingsCode(): any {
    this._httpClient
      .get<string>(this.urlToApi + 'api/areas/categories/buildings/doc-variables')
      .pipe(retry(AppSettings.REQUEST_TRIES))
      .subscribe((res) => (this.categoryCode = res));
  }

  getTechnologicalProcesCode(): any {
    this._httpClient
      .get<string>(this.urlToApi + 'api/areas/categories/technological-processes/doc-variables')
      .pipe(retry(AppSettings.REQUEST_TRIES))
      .subscribe((res) => (this.categoryCode = res));
  }

  getTransportCode(): any {
    this._httpClient
      .get<string>(this.urlToApi + 'api/areas/categories/transports/doc-variables')
      .pipe(retry(AppSettings.REQUEST_TRIES))
      .subscribe((res) => (this.categoryCode = res));
  }

  getEnergySourceCode(): any {
    this._httpClient
      .get<string>(this.urlToApi + 'api/areas/categories/energy-sources/doc-variables')
      .pipe(retry(AppSettings.REQUEST_TRIES))
      .subscribe((res) => (this.categoryCode = res));
  }
}
