<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-button
  [text]="constant.name"
  stylingMode="outlined"
  class="outlined-button-focus-hover"
  (onClick)="openPopup()"
></dx-button>

<dx-popup
  [title]="constant.titleBarText"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="popupVisible"
  (onHidden)="cleanStore()"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <dx-data-grid
    *ngIf="popupVisible"
    id="gridContainer"
    [showBorders]="true"
    [dataSource]="definedConstantsList"
  >
    <dxo-paging [enabled]="true"></dxo-paging>
    <dxo-editing
      mode="cell"
      [allowAdding]="true"
      [allowUpdating]="true"
      [allowDeleting]="true"
    >
      <dxo-texts
        [confirmDeleteTitle]="_('constant-delete-title')"
        [confirmDeleteMessage]="_('constant-delete-message')"
      >
      </dxo-texts>
    </dxo-editing>

    <dxi-column
      dataField="name"
      [caption]="constant.gridColumnName"
    >
      <dxi-validation-rule
        type="required"
        [message]="_('constant-name-required-validation-message')"
      ></dxi-validation-rule>
      <dxi-validation-rule
        type="stringLength"
        [min]="1"
        [message]="_('constant-name-length-validation-message')"
      ></dxi-validation-rule>
    </dxi-column>
    <dxi-column
      *ngIf="constant.gridSecondColumnName"
      [dataField]="constant.shortName"
      [caption]="constant.gridSecondColumnName"
    >
      <dxi-validation-rule
        type="required"
        [message]="_('constant-short-required-validation-message')"
      ></dxi-validation-rule>
    </dxi-column>
  </dx-data-grid>
</dx-popup>
