import { Injectable } from '@angular/core';
import { locale } from 'devextreme/localization';
import { AppSettings } from '../../AppSettings';
import { ApiRequestService } from './api-request.service';
import { AuthenticationService } from './authentication.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  languages: Language[] = null;
  public currentUser;

  languageIcons: { [key: string]: string } = {
    'pl-PL': 'assets/img/poland.svg',
    'en-US': 'assets/img/united-kingdom.svg',
  };
  languageId: string;
  languageIdSubject: Subject<string> = new Subject<string>();

  constructor(private apiRequestService: ApiRequestService, private authenticationService: AuthenticationService) {}

  public setLanguage(): void {
    this.apiRequestService.getLanguages().subscribe((res) => {
      this.languages = res.map((l) => {
        return {
          id: l.id,
          name: l.name,
          icon: this.languageIcons[l.name],
        };
      });
      this.currentUser = this.authenticationService.getDecodedCurrentUser();
      const userSettings = localStorage.getItem(AppSettings.USER_LOCALE);
      if (!userSettings) {
        const dashPosition = navigator.language.toLowerCase().indexOf('-');
        const navigatorLanguage =
          dashPosition === -1
            ? navigator.language.toLowerCase()
            : navigator.language.toLowerCase().substring(0, dashPosition);

        if (navigatorLanguage === 'pl') {
          this.languageId = this._getLanguageID('pl');
        } else if (navigatorLanguage === 'en') {
          this.languageId = this._getLanguageID('en');
        }
      } else {
        this.languageId = this._getLanguageID(userSettings);
      }
      this.languageIdSubject.next(this.languageId);
    });
  }

  onLanguageChange({ itemData: language }: { itemData: Language }): void {
    this.languageId = language.id;
    const userId = this.authenticationService.getDecodedCurrentUser().id;
    const values = JSON.stringify({ languageId: this.languageId });
    this.apiRequestService.updateUser(userId, values).subscribe((data) => {
      localStorage.setItem(AppSettings.AUTH_CURRENT_USER, JSON.stringify(data));
    });
    if (language.name === 'en-US') {
      this._switchToEnglish();
    }
    if (language.name === 'pl-PL') {
      this._switchToPolish();
    }
    this.reloadPage(true);
  }

  reloadPage(reload: boolean): void {
    if (reload) {
      document.location.reload();
    }
  }

  private _switchToPolish(): void {
    localStorage.setItem(AppSettings.USER_LOCALE, 'pl');
  }

  private _switchToEnglish(): void {
    localStorage.setItem(AppSettings.USER_LOCALE, 'en');
  }

  private _switchToDefualtLanguage(): void {
    this._switchToEnglish();
  }

  private _getLanguageID(language: string): string {
    let selectedLanguage = this.languages.find(
      (item) => item.name.toLowerCase().substring(0, AppSettings.NUMBER_OF_LANGUAGE_RELEVANT_CHARS) === language
    );
    if (!selectedLanguage) {
      selectedLanguage = this.languages[0];
      this._switchToDefualtLanguage();
      locale(selectedLanguage.name.toLowerCase());
      this.reloadPage(true);
    }
    return selectedLanguage.id;
  }
}

interface Language {
  id: string;
  name: string;
  icon?: string;
}
