import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ImageMenuActions } from '../../../../models/image-menu-actions.enum';
import { Image } from '../../../../models/image.model';
import { GalleryService } from '../../../../services/gallery.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Localizable } from '../../../../../locale/localizable';
import { AppSettings } from '../../../../../AppSettings';
import { ScreenService } from '../../../../services';
import notify from 'devextreme/ui/notify';
import { UtilsService } from '../../../../services/utils.service';
import { CloseResult } from '../../../questionnaire/exit-confirmation-popup/exit-confirmation-popup.component';
import { AuditGalleryComponent } from '../audit-gallery.component';
import { PictureComponent } from '../../picture/picture.component';

@Component({
  selector: 'app-audit-photo',
  templateUrl: './audit-photo.component.html',
  styleUrls: ['./audit-photo.component.scss'],
})
export class AuditPhotoComponent extends Localizable implements OnInit {
  @ViewChild('imageElement', { read: ElementRef })
  imageDocElement: ElementRef<HTMLElement>;

  @ViewChild(PictureComponent) picture: PictureComponent;

  @Input()
  image: Image = null;

  @Input()
  formId: string = '';

  @Input() public isAuditFinished: boolean = false;

  @Input() public imageSelection: boolean = false;

  @Output()
  public imageDeleted: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public selectedImageId: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public deSelectedImageId: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public imageEdited: EventEmitter<void> = new EventEmitter<void>();

  private loadedFormData: Image;

  public selected: boolean = false;

  public componentDestroyed$: Subject<boolean> = new Subject();

  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;

  public loadIndicatorWidth = AppSettings.LOAD_IMAGE_INDICATOR_WIDTH;
  public loadIndicatorHeight = AppSettings.LOAD_IMAGE_INDICATOR_HEIGHT;

  public loadingImage = true;

  public mobile: boolean = false;
  public showImageDeleteConfirmationPopup: boolean = false;
  public showMenuButtons = false;
  public showMoveImagePopup = false;
  public showImageDetailsPopup = false;
  public menuButtons = [
    {
      key: ImageMenuActions.Move,
      icon: 'fi fi-rr-move-to-folder',
      hint: this._('audit-gallery-move-hint'),
    },
    {
      key: ImageMenuActions.ShowDetails,
      icon: 'fi fi-rr-pencil',
      hint: this._('audit-gallery-edit-hint'),
    },
  ];

  public downloadButtonOptions = {
    icon: 'fi fi-rr-download',
    stylingMode: 'outlined',
    type: 'normal',
    onClick: () => this.downloadOrginal(this.image),
  };

  public deleteButtonOptions = {
    type: 'danger',
    icon: 'fi fi-rr-trash',
    stylingMode: 'outlined',
    onClick: () => this.triggerImageDeleteConfirmation(),
  };

  public switchOptions = {};
  items: Record<any, unknown>[];
  imageFileUnavailable: boolean = false;
  imageEntityError: boolean = false;

  constructor(
    private _galleryService: GalleryService,
    private _screenService: ScreenService,
    private utilsService: UtilsService
  ) {
    super(AuditGalleryComponent.localFile);
    this.items = [
      {
        text: this._('gallery-edit'),
        icon: 'fi fi-rr-pencil',
        index: ImageMenuActions.ShowDetails,
      },
      { text: this._('gallery-move'), icon: 'fi fi-rr-move-to-folder', index: ImageMenuActions.Move },
      { text: this._('gallery-delete'), icon: 'fi fi-rr-trash', index: ImageMenuActions.Delete },
    ];

    this._galleryService.selectedImagesChanged.subscribe((ids: Array<string>) => {
      const imgId = ids.find((id) => id === this.image.id);
      if (imgId) {
        this.selected = true;
      } else {
        this.selected = false;
      }
    });
  }

  menuItemClick(event: any) {
    if (event) {
      switch (event.itemData.index) {
        case ImageMenuActions.ShowDetails:
          this.showImageDetailsPopup = true;
          break;
        case ImageMenuActions.Move:
          this.showMoveImagePopup = true;
          break;
        case ImageMenuActions.Delete:
          this.triggerImageDeleteConfirmation();
          break;
        default:
          break;
      }
    }
  }

  ngOnInit(): void {
    this.getImage(this.image.id);
    this.switchOptions = {
      value: this.image.include,
      switchedOffText: this._('No'),
      switchOnText: this._('Yes'),
      onValueChanged: (e) => this.updateImageInclude(e.value),
    };
    this.loadedFormData = this.utilsService.deepCopy(this.image);
    this._galleryService.deselectAllImages.pipe(takeUntil(this.componentDestroyed$)).subscribe((deselect: boolean) => {
      if (deselect) {
        this.selected = deselect;
        this.handleSelectionOfImage();
      }
    });
    this._screenService.isMobile.subscribe((isMobile: boolean) => (this.mobile = isMobile));
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  public getImage(id: string) {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === AppSettings.HTTP_STATUS_CODE_200_OK) {
        const img = <HTMLImageElement>document.getElementById(`imageElement${this.image.id}`);
        if (img) {
          img.src = URL.createObjectURL(xhr.response);
          this.loadingImage = false;
        }
      }
      if (
        xhr.readyState === XMLHttpRequest.HEADERS_RECEIVED &&
        (xhr.status === AppSettings.HTTP_STATUS_CODE_NOT_FOUND ||
          xhr.status === AppSettings.HTTP_STATUS_CODE_BAD_REQUEST)
      ) {
        this.loadingImage = false;
        this.imageFileUnavailable = true;
      }

      if (
        xhr.readyState === XMLHttpRequest.HEADERS_RECEIVED &&
        xhr.status === AppSettings.HTTP_STATUS_CODE_BAD_REQUEST
      ) {
        this.loadingImage = false;
        this.imageEntityError = true;
      }
    };
    xhr.open('GET', this._galleryService.createImageUrlById(id), true);
    xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem(AppSettings.AUTH_TOKEN)}`);
    xhr.send();
  }

  public selectImage(): void {
    if (this.isAuditFinished || !this.imageSelection) {
      this.showImageDetailsPopup = true;
      return;
    }
    this.handleSelectionOfImage();
  }

  public handleSelectionOfImage() {
    this.selected = !this.selected;
    if (this.selected) {
      this.selectedImageId.emit(this.image.id);
    } else {
      this.deSelectedImageId.emit(this.image.id);
    }
  }

  public afterImageRemove(): void {
    this.imageDeleted.emit();
    this.showImageDetailsPopup = false;
  }

  public afterImageEdit(image: Image): void {
    this._galleryService.updateImage(image).subscribe(() => {
      this.imageEdited.emit();
    });
  }

  public onPopupHiding(): void {
    this.showMoveImagePopup = false;
  }

  public onImagesMoved(): void {
    this._galleryService.fetchAuditAndChildrenGalleries(this._galleryService.getAuditId());
    this.showSuccessNotify(this._('audit-gallery-move-image-message'));
    this.showMoveImagePopup = false;
  }

  public showSuccessNotify(text: string): void {
    notify({
      message: text,
      type: 'success',
      displayTime: AppSettings.NOTIFY_DURATION,
      position: 'top center',
    });
  }

  public downloadOrginal(image: Image): void {
    this._galleryService.downloadOrginal(image);
  }

  public triggerImageDeleteConfirmation(): void {
    this.showImageDeleteConfirmationPopup = true;
  }

  public delete(event: CloseResult, id: string): void {
    if (event === CloseResult.Confirm) {
      this._galleryService.deleteImage(id).subscribe(() => {
        this.afterImageRemove();
        notify({
          message: this._('gallery-picture-successfully-deleted-message'),
          type: 'success',
          displayTime: AppSettings.NOTIFY_DURATION,
          position: 'top center',
        });
      });
    }
    this.showImageDeleteConfirmationPopup = false;
  }

  public checkChanges() {
    this.showMenuButtons = false;
  }

  updateImageInclude(newValue: boolean): void {
    this.image.include = newValue;
    this.picture.image.include = newValue;
  }
}
