<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="gassCogeneration.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="gassCogeneration.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="gassCogeneration.serialNumber"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="gassCogeneration.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="gassCogeneration.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-working-hours-in-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="gassCogeneration.engineProducer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-engine-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="gassCogeneration.engineModel"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-engine-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="gassCogeneration.generatorProducer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-generator-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kVA'"
        [(value)]="gassCogeneration.generatorPower"
        [min]="0"
        [max]="10000000"
        stylingMode="outlined"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-generator-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kV'"
        [(value)]="gassCogeneration.generatorVoltageLevel"
        [min]="0"
        [max]="10000000"
        stylingMode="outlined"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-generator-voltage-level')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="sourceTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="gassCogeneration.sourceType"
        [dataSource]="sourceTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('gass-cogeneration-source-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-source-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="gassCogeneration.waterTemp"
        [min]="0"
        [max]="10000000"
        stylingMode="outlined"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-water-temp')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' bar'"
        [(value)]="gassCogeneration.waterPressure"
        [min]="0"
        [max]="10000000"
        stylingMode="outlined"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-water-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="gassCogeneration.steamTemp"
        [min]="0"
        [max]="10000000"
        stylingMode="outlined"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-steam-temp')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' bar'"
        [(value)]="gassCogeneration.steamPressure"
        [min]="0"
        [max]="10000000"
        stylingMode="outlined"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-steam-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="gassCogeneration.electricRatedPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-electric-rated-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="gassCogeneration.heatedRatedPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-heated-rated-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kWh'"
        [(value)]="gassCogeneration.ratedGasConsumption"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-rated-gas-consumption')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="gassCogeneration.workingHours"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-working-hours')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' MWh'"
        [(value)]="gassCogeneration.grossElectricityProduction"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-gross-electricity-production')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' MWh'"
        [(value)]="gassCogeneration.netElectricityProduction"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-net-electricity-production')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' MWh'"
        [(value)]="gassCogeneration.heatEnergyProduction"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-heat-energy-production')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' MWh'"
        [(value)]="gassCogeneration.gasConsumption"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('gass-cogeneration-gas-consumption')"></dxo-label>
  </dxi-item>
</dx-form>
