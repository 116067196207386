<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="heatPump.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('heat-pump-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="heatPump.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('heat-pump-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="heatPump.serialNumber"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('heat-pump-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="heatPump.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-pump-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="pumpTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="heatPump.heatPumpType"
        [dataSource]="pumpTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('heat-pump-heat-pump-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('heat-pump-heat-pump-type')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="bottomSourceTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="heatPump.bottomSourceType"
        [dataSource]="bottomSourceTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('heat-pump-bottom-source-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('heat-pump-bottom-source-type')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="bottomSourceTempsList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="heatPump.bottomSourceTemp"
        [dataSource]="bottomSourceTempsList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('heat-pump-temperature-bottom-source-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('heat-pump-temperature-bottom-source')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="heatPump.maxWaterTemp"
        stylingMode="outlined"
        [min]="0"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-pump-max-water-temp')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="topSourceTypesList">
    <div *dxTemplate>
      <dx-tag-box
        [dataSource]="topSourceTypesList"
        [(value)]="heatPump.topSourceType"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('heat-pump-top-source-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      ></dx-tag-box>
    </div>
    <dxo-label [text]="_('heat-pump-top-source-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="heatPump.ratedHeatingCapacity"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-pump-rated-heating-capacity')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat"
        [(value)]="heatPump.cop"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-pump-cop')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat"
        [(value)]="heatPump.scop"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-pump-scop')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="heatPump.ratedCoolingCapacity"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-pump-rated-cooling-capacity')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat"
        [(value)]="heatPump.eer"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-pump-eer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat"
        [(value)]="heatPump.seer"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-pump-seer')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="refrigerantTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="heatPump.refrigerantType"
        [dataSource]="refrigerantTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('heat-pump-refrigerant-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('heat-pump-refrigerant-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' bar'"
        [(value)]="heatPump.suctionPressure"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-pump-suction-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' bar'"
        [(value)]="heatPump.dischargePressure"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-pump-discharge-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="heatPump.electricRatedPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-pump-electric-rated-power-heating')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="heatPump.ratedCoolingCapacityInCooling"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-pump-electric-rated-power-cooling')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³'"
        [(value)]="heatPump.outletTankCapacity"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('heat-pump-outlet-tank-capacity')"></dxo-label>
  </dxi-item>
</dx-form>
