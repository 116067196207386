import { Component, HostBinding, HostListener } from '@angular/core';
import { ScreenService, AppInfoService } from './shared/services';
import { Router } from '@angular/router';
import { AuthenticationService } from './shared/services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  locale: string;

  @HostBinding('class') get getClass() {
    return Object.keys(this._screenService.sizes)
      .filter((cl) => this._screenService.sizes[cl])
      .join(' ');
  }

  public showTitle: boolean = false;

  @HostListener('window:popstate')
  canDeactivate() {
    if (window.location.href.includes('/audit/')) {
      return false;
    }
    return true;
  }

  public showMenu = false;

  constructor(
    private _screenService: ScreenService,
    public appInfo: AppInfoService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.showTitle = !this._screenService.isMobile;
    // @ts-ignore: private option not yet exposed for public use
    router.canceledNavigationResolution = 'computed';

    this.authenticationService.isUserAuthorized$.subscribe((isLoggedIn) => {
      if (isLoggedIn !== null) {
        this.showMenu = isLoggedIn;
      } else if (this.authenticationService.getDecodedUser() !== null) {
        this.showMenu = this.authenticationService.getDecodedUser() !== null;
      }
    });
  }

  @HostListener('window:beforeunload')
  beforeunloadHandler(): boolean {
    return false;
  }
}
