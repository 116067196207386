import { Injectable } from '@angular/core';
import * as argon2 from 'argon2-browser/dist/argon2-bundled.min.js';
import { AppSettings } from '../../AppSettings';

@Injectable({
  providedIn: 'root',
})
export class SaltingService {
  private paralleism = AppSettings.ARGON2_PARALLELISM;
  private memory = AppSettings.ARGON2_MEMORY;
  private iterations = AppSettings.ARGON2_ITERATIONS;
  private hashLength = AppSettings.ARGON2_HASH_LENGTH;
  private type = argon2.ArgonType.Argon2i;

  constructor() {}

  public async hash(password: string, salt: string): Promise<string> {
    try {
      const h = await argon2.hash({
        pass: password,
        salt,
        parallelism: this.paralleism,
        mem: this.memory,
        time: this.iterations,
        hashLen: this.hashLength,
        type: this.type,
      });
      return this.getLastHashFromArgon2Hash(h.encoded);
    } catch (e) {
      console.error(e.message, e.code);
      throw e;
    }
  }

  public getLastHashFromArgon2Hash(argon2Hash: string): string {
    const parts = argon2Hash.split('$');
    if (parts.length >= 1) {
      return parts[parts.length - 1];
    } else {
      return '';
    }
  }

  public generateRandomString(length: number): string {
    const charset = 'abcdef0123456789';
    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      randomString += charset.charAt(randomIndex);
    }
    return randomString;
  }
}
