<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div class="center-padding">
  <div>
    <div class="top-title-container">
      <div class="flex-title">
        <h1
          data-testid="audits-header"
          class="form-header-margin-top form-header-margin-bottom"
        >
          {{ _('audits') }}
        </h1>
        <dx-button
          class="contained-button-focus-hover button-contained button-case-sensitive"
          text="{{ _('audit-cards-add-new-audit') }}"
          icon="fi fi-rr-plus"
          (onClick)="displayCreateAuditPopup()"
        >
        </dx-button>
      </div>
    </div>
  </div>
  <div>
    <p-tabView
      id="tabview"
      (onChange)="handleChange($event)"
    >
      <p-tabPanel header="{{ _('audits-cards-all') }}"> </p-tabPanel>
      <p-tabPanel header="{{ _('audits-cards-finished') }}"> </p-tabPanel>
      <p-tabPanel header="{{ _('audits-cards-in-progress') }}"> </p-tabPanel>
    </p-tabView>

    <div class="filter-container">
      <div class="filter">
        <dx-text-box
          class="filter-field search-filter"
          [(value)]="name.filter"
          valueChangeEvent="input"
          label="{{ _('audit-name') }}"
          (onValueChanged)="filterNewValue()"
          stylingMode="outlined"
          labelMode="floating"
          [showClearButton]="true"
        >
          <dxi-button
            name="search-button"
            location="before"
            [options]="searchButton"
          ></dxi-button>
          <dxi-button name="clear"></dxi-button>
          <dxi-button
            name="search-sort-button"
            location="after"
            [options]="sortNameButton"
          ></dxi-button>
        </dx-text-box>
      </div>
      <div class="card flex justify-content-center">
        <p-toggleButton
          id="toggle-button"
          onIcon="fi-rr-filter-slash"
          offIcon="fi-rr-filter"
          [(ngModel)]="isFilterExpanded"
          [onLabel]="_('audit-cards-filter-off')"
          [offLabel]="_('audit-cards-filter')"
        >
        </p-toggleButton>
      </div>
    </div>
    <div
      class="filter-container"
      *ngIf="isFilterExpanded"
    >
      <div class="filter">
        <dx-select-box
          [items]="auditTypes"
          displayExpr="name"
          valueExpr="name"
          class="filter-field other-filter-textbox"
          [(value)]="auditType.filter"
          [searchEnabled]="true"
          searchMode="contains"
          label="{{ _('audit-type') }}"
          (onValueChanged)="filterNewValue()"
          stylingMode="outlined"
          labelMode="floating"
          [showClearButton]="true"
        >
          <dxi-button name="clear"></dxi-button>
          <dxi-button
            name="type-sort-button"
            location="after"
            [options]="sortTypeButton"
          ></dxi-button>
        </dx-select-box>
        <dx-date-range-box
          openOnFieldClick="true"
          startDateLabel="{{ _('audit-from') }}:"
          endDateLabel="{{ _('audit-to') }}:"
          class="filter-field daterange-filter-box"
          labelMode="floating"
          stylingMode="outlined"
          [min]="minDate"
          [(startDate)]="fromDate"
          [(endDate)]="toDate"
          [max]="currentDate"
          (onValueChanged)="filterNewValue()"
          displayFormat="yyyy-MM-dd"
          [showClearButton]="true"
          openOnFieldClick="true"
          dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
          invalidStartDateMessage="{{ _('invalid-format-message', [_('audit-from'), _('date-format')]) }}"
          invalidEndDateMessage="{{ _('invalid-format-message', [_('audit-to'), _('date-format')]) }}"
          startDateOutOfRangeMessage="{{ _('out-of-range', _('audit-from')) }}"
          endDateOutOfRangeMessage="{{ _('out-of-range', _('audit-to')) }}"
          [dropDownOptions]="dropDownOptions"
        >
          <dxi-button name="clear"></dxi-button>
          <dxi-button
            name="type-sort-button"
            location="after"
            [options]="sortDateButton"
          ></dxi-button>
        </dx-date-range-box>
      </div>
    </div>
    <div class="audit-container">
      <app-audit-card
        *ngFor="let audit of auditsArray"
        [audit]="audit"
        (deletedAudit)="deleteAudit(audit)"
        class="audit-card"
      ></app-audit-card>
      <div
        *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7]"
        class="child-filler"
      ></div>
    </div>
  </div>
</div>
<dx-popup
  title="{{ _('create-audit') }}"
  [fullScreen]="false"
  maxWidth="70rem"
  [height]="popupHeight + '%'"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="createAuditPopupVisible"
  (onHiding)="onAuditCreationPopupHiding($event)"
  (onShown)="onAuditCreationPopupShown($event)"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <dx-scroll-view
    width="100%"
    height="100%"
  >
    <div class="forms">
      <app-audit-form #auditForm></app-audit-form>
    </div>
  </dx-scroll-view>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    cssClass="button-rounded-regular toolbar-button-focus-border"
    [options]="createButtonOptions"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    cssClass="toolbar-contained-button-focus-border button-contained button-case-sensitive"
    [options]="createAndDisplayButtonOptions"
  >
  </dxi-toolbar-item>
</dx-popup>
<app-exit-confirmation-popup
  [popupVisible]="createAuditExitPopupVisible"
  [confirmationNo]="_('No')"
  [confirmationYes]="_('Yes')"
  [title]="_('unsaved-changes-title')"
  [message]="_('unsaved-changes-message')"
  (closeEvent)="onCloseAuditCreation($event)"
></app-exit-confirmation-popup>
