<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form
  [showColonAfterLabel]="true"
  *ngIf="absorptionAggregate"
>
  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="absorptionAggregate.producer"
        stylingMode="outlined"
        [disabled]="templateMode"
      >
      </dx-text-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'Producer'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="absorptionAggregate.model"
        stylingMode="outlined"
        [disabled]="templateMode"
      >
      </dx-text-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'Model'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="absorptionAggregate.serialNumber"
        stylingMode="outlined"
        [disabled]="templateMode"
      >
      </dx-text-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'SerialNumber'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        step="0"
        [format]="integerNumberFormat"
        [(value)]="absorptionAggregate.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'ProductionYear'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-select-box
        [(value)]="absorptionAggregate.absorptionType"
        [dataSource]="absorptionTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('absorption-aggregate-choose-absorber-type')"
        [disabled]="templateMode"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'AbsorptionType'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-select-box
        [(value)]="absorptionAggregate.workFactor"
        [dataSource]="workFactorTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('absorption-aggregate-choose-work-factor')"
        [disabled]="templateMode"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'WorkFactor'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-work-factor')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [format]="fullNumberFormat + ' %'"
        [(value)]="absorptionAggregate.ratedEfficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
        [step]="0"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'RatedEfficiency'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-rated-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="absorptionAggregate.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'WorkingHoursInYear'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-working-hours')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="absorptionAggregate.ratedCoolingCapacity"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'RatedCoolingCapacity'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-rated-cooling-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="absorptionAggregate.ratedHeatDemand"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'RatedHeatDemand'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-rated-heat-demand')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="absorptionAggregate.inputChilledWaterTemp"
        stylingMode="outlined"
        [min]="0"
        [max]="10"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'InputChilledWaterTemp'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-inlet-chilled-water-temperature')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="absorptionAggregate.outputChilledWaterTemp"
        stylingMode="outlined"
        [min]="0"
        [max]="10"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'OutputChilledWaterTemp'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-outlet-chilled-water-temperature')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³/h'"
        [(value)]="absorptionAggregate.chilledWaterFlow"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'ChilledWaterFlow'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-chilled-water-flow-rate')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="absorptionAggregate.inputHeatSourceTemp"
        stylingMode="outlined"
        [min]="0"
        [max]="10000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'InputHeatSourceTemp'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-inlet-heat-source-temperature')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="absorptionAggregate.outputHeatSourceTemp"
        stylingMode="outlined"
        [min]="0"
        [max]="10000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'OutputHeatSourceTemp'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-outlet-heat-source-temperature')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³/h'"
        [(value)]="absorptionAggregate.heatSourceFlow"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'HeatSourceFlow'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-heat-source-flow-rate')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="absorptionAggregate.workingHoursInAnalyzedPeriod"
        stylingMode="outlined"
        [min]="0"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'WorkingHoursInAnalyzedPeriod'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-work-time-in-period')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' MWh'"
        [(value)]="absorptionAggregate.coolingEnergyInAnalyzedPeriod"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable
          [text]="this.docVariableCode + 'CoolingEnergyInAnalyzedPeriod'"
        ></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-cooling-energy-production')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' MWh'"
        [(value)]="absorptionAggregate.electricEnergyConsumptionInAnalyzedPeriod"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable
          [text]="this.docVariableCode + 'ElectricEnergyConsumptionInAnalyzedPeriod'"
        ></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-energy-consumption')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' MWh'"
        [(value)]="absorptionAggregate.heatEnergyConsumptionInAnalyzedPeriod"
        stylingMode="outlined"
        [min]="0"
        [max]="100000000"
        [disabled]="templateMode"
      >
      </dx-number-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable
          [text]="this.docVariableCode + 'HeatEnergyConsumptionInAnalyzedPeriod'"
        ></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-thermal-energy-consumption')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-select-box
        [(value)]="absorptionAggregate.technicalCondition"
        [dataSource]="technicalConditions"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('absorption-aggregate-technical-condition-placeholder')"
        [disabled]="templateMode"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
      <div *ngIf="templateMode">
        <app-field-doc-variable [text]="this.docVariableCode + 'TechnicalCondition'"></app-field-doc-variable>
      </div>
    </div>
    <dxo-label [text]="_('absorption-aggregate-technical-condition')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="templateMode">
    <div *dxTemplate>
      <app-add-template [areaTypeCode]="this.docVariableCode"></app-add-template>
    </div>
  </dxi-item>
</dx-form>
