import { Medium } from './boiler.model';

export interface WaterPump {
  id: number;
  producer: string;
  model: string;
  serialNumber: string;
  productionYear: number;
  ratedPower: number;
  ratedEfficiency: number;
  ratedCapacity: number;
  rasingHeight: number;
  medium: Medium;
  mediumTemp: number;
  suctionPressure: number;
  dischargePressure: number;
  averageLoad: number;
  averageEfficiency: number;
  technicalCondition: TechnicalCondition;
  workingHoursInYear: number;
  sampleCardAvailable: number;
  regulationType: RegulationType;
  operatingPointVolatility: OperatingPointVolatility;
}

export class TechnicalCondition {
  id: number;
  name: string;
}

export interface RegulationType {
  id: number;
  name: string;
}

export interface OperatingPointVolatility {
  id: number;
  name: string;
}
