<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-tab-panel
  id="boilerRoomTabPanel"
  [loop]="false"
  [swipeEnabled]="true"
  itemTitleTemplate="title"
>
  <div *dxTemplate="let content of 'title'">
    <div class="tabsTitle">{{ content.title }}</div>
  </div>
  <dxi-item [title]="_('boiler-general-information')">
    <div *dxTemplate>
      <dx-form [showColonAfterLabel]="true">
        <dxi-item>
          <div *dxTemplate>
            <dx-text-box
              [(value)]="boiler.producer"
              stylingMode="outlined"
            >
            </dx-text-box>
          </div>
          <dxo-label [text]="_('boiler-producer')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-text-box
              [(value)]="boiler.model"
              stylingMode="outlined"
            >
            </dx-text-box>
          </div>
          <dxo-label [text]="_('boiler-model')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-text-box
              [(value)]="boiler.serialNumber"
              stylingMode="outlined"
            >
            </dx-text-box>
          </div>
          <dxo-label [text]="_('boiler-serial-number')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="integerNumberFormat"
              [(value)]="boiler.productionYear"
              stylingMode="outlined"
              min="1950"
              [max]="currentYear"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-production-year')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat + ' kW'"
              [(value)]="boiler.ratedPower"
              stylingMode="outlined"
              min="0"
              max="10000000"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-rated-power')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat + ' bar'"
              [(value)]="boiler.pressure"
              stylingMode="outlined"
              min="0"
              max="10000000"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-pressure')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat + ' Kg/h'"
              [(value)]="boiler.efficiency"
              stylingMode="outlined"
              min="0"
              max="100"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-efficiency')"></dxo-label>
        </dxi-item>

        <dxi-item *ngIf="mediumList">
          <div *dxTemplate>
            <dx-select-box
              [(value)]="boiler.medium"
              [dataSource]="mediumList"
              displayExpr="name"
              stylingMode="outlined"
              [searchEnabled]="true"
              searchMode="contains"
            >
            </dx-select-box>
          </div>
          <dxo-label [text]="_('boiler-medium')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat + ' °C'"
              [(value)]="boiler.mediumTemp"
              stylingMode="outlined"
              min="0"
              max="100"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-medium-temperature')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat + ' °C'"
              [(value)]="boiler.condensateTemp"
              stylingMode="outlined"
              min="0"
              max="100"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-condensate-temperature')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat + ' °C'"
              [(value)]="boiler.exhaustGasTemp"
              stylingMode="outlined"
              min="0"
              max="100"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-exhaust-gas-temp')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat + ' °C'"
              [(value)]="boiler.exhaustGasFlow"
              stylingMode="outlined"
              min="0"
              max="100"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-exhaust-gas-flow')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat + ' %'"
              [(value)]="boiler.averageLoad"
              stylingMode="outlined"
              [min]="0"
              [max]="100"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-average-load')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat + ' %'"
              [(value)]="boiler.averageEfficiency"
              stylingMode="outlined"
              [min]="0"
              [max]="1"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-average-efficiency')"></dxo-label>
        </dxi-item>

        <dxi-item *ngIf="technicalConditions">
          <div *dxTemplate>
            <dx-select-box
              [(value)]="boiler.technicalCondition"
              [dataSource]="technicalConditions"
              displayExpr="name"
              stylingMode="outlined"
              [placeHolder]="_('boiler-technical-condition-placeholder')"
              [searchEnabled]="true"
              searchMode="contains"
            >
            </dx-select-box>
          </div>
          <dxo-label [text]="_('boiler-technical-condition')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-switch
              [(value)]="boiler.dedustingInstallation"
              [switchedOffText]="_('No')"
              [switchedOnText]="_('Yes')"
            ></dx-switch>
          </div>
          <dxo-label [text]="_('boiler-dedusting-instalation')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-switch
              [(value)]="boiler.denitrificationInstallation"
              [switchedOffText]="_('No')"
              [switchedOnText]="_('Yes')"
            ></dx-switch>
          </div>
          <dxo-label [text]="_('boiler-denitrification-instalation')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-switch
              [(value)]="boiler.environmentalExamination"
              [switchedOffText]="_('No')"
              [switchedOnText]="_('Yes')"
            ></dx-switch>
          </div>
          <dxo-label [text]="_('boiler-environmental-examination')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-switch
              [(value)]="boiler.boilerPass"
              [switchedOffText]="_('No')"
              [switchedOnText]="_('Yes')"
            ></dx-switch>
          </div>
          <dxo-label [text]="_('boiler-passport')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-switch
              [(value)]="boiler.congenerationSystem"
              [switchedOffText]="_('No')"
              [switchedOnText]="_('Yes')"
            ></dx-switch>
          </div>
          <dxo-label [text]="_('boiler-congeneration-system')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-switch
              [(value)]="boiler.waterSlagging"
              [switchedOffText]="_('No')"
              [switchedOnText]="_('Yes')"
            ></dx-switch>
          </div>
          <dxo-label [text]="_('boiler-water-slagging')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-switch
              [(value)]="boiler.airLeaks"
              [switchedOffText]="_('No')"
              [switchedOnText]="_('Yes')"
            ></dx-switch>
          </div>
          <dxo-label [text]="_('boiler-air-leaks')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-switch
              [(value)]="boiler.ashInDeslagger"
              [switchedOffText]="_('No')"
              [switchedOnText]="_('Yes')"
            ></dx-switch>
          </div>
          <dxo-label [text]="_('boiler-ash-in-deslagger')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-switch
              [(value)]="boiler.automaticSystem"
              [switchedOffText]="_('No')"
              [switchedOnText]="_('Yes')"
            ></dx-switch>
          </div>
          <dxo-label [text]="_('boiler-automatic-system')"></dxo-label>
        </dxi-item>
      </dx-form>
    </div>
  </dxi-item>
  <dxi-item [title]="_('boiler-fuel-consumption')">
    <div *dxTemplate>
      <dx-form [showColonAfterLabel]="true">
        <dxi-item>
          <div *dxTemplate>
            <div class="dateDescription">
              {{ _('boiler-fuel-consumption-date-description') }}
              {{ timeRange?.fromDate | date: 'shortDate' }} -
              {{ timeRange?.toDate | date: 'shortDate' }}
            </div>
          </div>
        </dxi-item>
        <dxi-item dataField="Nazwa">
          <div *dxTemplate>
            <dx-text-box
              [(value)]="boiler.fuelConsumption.name"
              stylingMode="outlined"
            >
            </dx-text-box>
          </div>
          <dxo-label [text]="_('boiler-fuel-consumption-name')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat"
              [(value)]="boiler.fuelConsumption.amount"
              min="0"
              max="10000000"
              stylingMode="outlined"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-fuel-consumption-amount')"></dxo-label>
        </dxi-item>

        <dxi-item *ngIf="unitList">
          <div *dxTemplate>
            <dx-select-box
              [dataSource]="unitList"
              [(value)]="boiler.fuelConsumption.unit"
              displayExpr="name"
              stylingMode="outlined"
              [searchEnabled]="true"
              searchMode="contains"
            >
            </dx-select-box>
          </div>
          <dxo-label [text]="_('boiler-fuel-consumption-unit')"></dxo-label>
        </dxi-item>

        <dxi-item>
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [format]="fullNumberFormat"
              [(value)]="boiler.fuelConsumption.calorificValue"
              min="0"
              max="10000000"
              stylingMode="outlined"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-fuel-consumption-calorific-value')"></dxo-label>
        </dxi-item>

        <dxi-item
          *ngIf="currencyFormat"
          dataField="Koszt"
        >
          <div *dxTemplate>
            <dx-number-box
              [step]="0"
              [(value)]="boiler.fuelConsumption.cost"
              min="0"
              max="10000000"
              [(format)]="currencyFormat"
              [showClearButton]="true"
              stylingMode="outlined"
            >
            </dx-number-box>
          </div>
          <dxo-label [text]="_('boiler-fuel-consumption-cost')"></dxo-label>
        </dxi-item>
      </dx-form>
    </div>
  </dxi-item>
</dx-tab-panel>
