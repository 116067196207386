<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form
  [showColonAfterLabel]="true"
  *ngIf="ventilationCentral"
>
  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="ventilationCentral.producer"
        stylingMode="outlined"
        [disabled]="templateMode"
      ></dx-text-box>
    </div>
    <dxo-label [text]="_('ventilation-central-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="ventilationCentral.model"
        stylingMode="outlined"
        [disabled]="templateMode"
      ></dx-text-box>
    </div>
    <dxo-label [text]="_('ventilation-central-model')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="ventilationCentralTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="ventilationCentral.ventilationCentralType"
        [dataSource]="ventilationCentralTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('ventilation-central-type-placeholder')"
        [disabled]="templateMode"
        [searchEnabled]="true"
        searchMode="contains"
      ></dx-select-box>
    </div>
    <dxo-label [text]="_('ventilation-central-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="ventilationCentral.serialNumber"
        stylingMode="outlined"
        [disabled]="templateMode"
      ></dx-text-box>
    </div>
    <dxo-label [text]="_('ventilation-central-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="ventilationCentral.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
        [disabled]="templateMode"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('ventilation-central-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="ventilationCentral.fansPowerInput"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('ventilation-central-fans-power-input')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³/h'"
        [(value)]="ventilationCentral.airFlowInput"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('ventilation-central-air-flow-input')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="ventilationCentral.fansPowerOutput"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('ventilation-central-fans-power-output')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³/h'"
        [(value)]="ventilationCentral.airFlowOutput"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('ventilation-central-air-flow-output')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="ventilationCentral.heaterThermalPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('ventilation-central-heater-thermal-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="ventilationCentral.radiatorHeatOutput"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('ventilation-central-radiator-heat-output')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' ℃'"
        [(value)]="ventilationCentral.targetTemp"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
        [disabled]="templateMode"
      ></dx-number-box>
    </div>
    <dxo-label [text]="_('ventilation-central-target-temp')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="ventilationCentral.recuperation"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
        [disabled]="templateMode"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('ventilation-central-recuperation')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="ventilationCentral.inverters"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
        [disabled]="templateMode"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('ventilation-central-inverters')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="ventilationCentral.ductsInsulated"
        [switchedOffText]="_('No')"
        [switchedOnText]="_('Yes')"
        [disabled]="templateMode"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('ventilation-central-ducts-insulated')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="technicalConditionsList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="ventilationCentral.technicalCondition"
        [dataSource]="technicalConditionsList"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('ventilation-central-technical-condition-placeholder')"
        [disabled]="templateMode"
        [searchEnabled]="true"
        searchMode="contains"
      ></dx-select-box>
    </div>
    <dxo-label [text]="_('ventilation-central-technical-condition')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="templateMode">
    <div *dxTemplate>
      <app-add-template [areaTypeCode]="this.docVariableCode"></app-add-template>
    </div>
  </dxi-item>
</dx-form>
