import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GalleryService } from '../../../services/gallery.service';
import { GalleryAccordionComponent } from './gallery-accordion/gallery-accordion.component';
import { Localizable } from '../../../../locale/localizable';

@Component({
  selector: 'app-audit-gallery',
  templateUrl: './audit-gallery.component.html',
  styleUrls: ['./audit-gallery.component.scss'],
})
export class AuditGalleryComponent extends Localizable implements OnInit {
  public static readonly localFile = 'AuditGalleryComponent';
  @ViewChild(GalleryAccordionComponent, { read: ElementRef }) private auditPhotoElementRef: ElementRef;

  @Input()
  public formId: string;

  @Input()
  public auditId: string;

  @Input()
  public isAuditFinished: boolean = false;

  constructor(private _galleryService: GalleryService) {
    super();
  }

  ngOnInit(): void {
    this._galleryService.fetchAuditAndChildrenGalleries(this.auditId);
  }
}
