import { Component } from '@angular/core';
import { Localizable } from '../../locale/localizable';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
})
export class AdminPanelComponent extends Localizable {
  private static readonly localFile = 'GlobalConstantListComponent';
  constructor() {
    super(AdminPanelComponent.localFile);
  }
}
