import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { Enterprise } from 'src/app/shared/models/enterprise/enterprise.model';
import { EnterpriseFormService } from './enterprise-form.service';
import notify from 'devextreme/ui/notify';
import ArrayStore from 'devextreme/data/array_store';
import { DxFormComponent } from 'devextreme-angular';
import { ReportService } from '../../../services/report.service';
import { TreeNavigationService } from '../../../services/tree-navigation.service';
import { FormMenuActions } from '../../../models/FormMenuActions.enum';
import { AppSettings } from '../../../../AppSettings';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../../app.config';
import { ScreenService } from '../../../services';
import { Subscription } from 'rxjs';
import { Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Localizable } from '../../../../locale/localizable';
import { DepartmentFormComponent } from '../department-form/department-form/department-form.component';
import { CloseResult } from '../../questionnaire/exit-confirmation-popup/exit-confirmation-popup.component';
import { DepartmentFormService } from '../department-form/department-form/department-form.service';
import { EnergyBalanceSankey } from '../../energy-balance-chart/energy-balance-chart/energy-balance-sankey.service';
import { GalleryService } from '../../../services/gallery.service';
import { CanComponentDeactivate } from '../../../../core/guards/can-deactivate.guard';

@Component({
  selector: 'app-enterprise-form',
  templateUrl: './enterprise-form.component.html',
  styleUrls: ['./enterprise-form.component.scss'],
})
export class EnterpriseFormComponent
  extends Localizable
  implements OnInit, AfterViewInit, OnDestroy, CanComponentDeactivate
{
  @Input() enterpriseId: string = null;
  @Input() templateMode: boolean = false;
  @ViewChild(DxFormComponent, { static: false })
  enterpriseForm: DxFormComponent;

  energyTypes: string[];
  industryTypes: ArrayStore;
  unitTypes: string[];
  createDepartmentPopupVisible = false;

  @ViewChild('departmentForm') departmentFormPopup!: DepartmentFormComponent;

  public createButtonOptions = {
    icon: 'fi fi-rr-plus',
    type: 'normal',
    stylingMode: 'outlined',
    text: this._('create-department'),
    onClick: () => this.onCreateDepartment(),
  };

  public createAndDisplayButtonOptions = {
    icon: 'fi fi-rr-plus',
    type: 'normal',
    text: this._('create-department-and-go'),
    onClick: () => this.onCreateAndDisplayDepartment(),
  };

  urlDBEnergyDatabase: string;
  docVariableCode: string;
  currentEnterprise: Enterprise;
  currentEnterpriseId: string;
  currentAuditId: string;
  isGalleryVisible: boolean = false;
  energyBalanceChart: boolean = false;

  saveText: string = '';
  galleryText: string = '';
  addDepartmentText: string = '';

  public imagesNumber: number = 0;

  private savedEnterprise: Enterprise;
  private checkChangesSubscription: Subscription;

  public isChangingDisabled = false;

  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;
  isAuditFinishedFlag: boolean = false;

  private exitFormReturnSubscription: Subscription;
  activeIndex: number;

  constructor(
    private _enterpriseFormService: EnterpriseFormService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _reportService: ReportService,
    private _treeNavigationService: TreeNavigationService,
    private _httpClient: HttpClient,
    private _appConfig: AppConfig,
    private _screenService: ScreenService,
    private _departmentService: DepartmentFormService,
    private _energyBalanceSankey: EnergyBalanceSankey,
    private _galleryService: GalleryService
  ) {
    super();
    this.urlDBEnergyDatabase = this._appConfig.getConfig('DbEnergyDatabaseUrl');
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this._route.params.pipe(takeUntil(this.onDestroy)).subscribe((params) => {
      this.enterpriseId = params['enterpriseId'];
    });

    this._route.parent.params.pipe(takeUntil(this.onDestroy)).subscribe((params) => {
      this.currentAuditId = params['auditId'];
    });
    this._route.firstChild?.url.subscribe((urlPath) => {
      const path = urlPath[0]?.path;
      this.setActiveTab(path);
    });

    this._enterpriseFormService.performAction.pipe(takeUntil(this.onDestroy)).subscribe((action) => {
      if (action) {
        this.selectedAction(action);
      }
    });

    this._screenService.changed.subscribe((screenSizeBreakPoint: BreakpointState) =>
      this.updateButtonsText(screenSizeBreakPoint)
    );

    this.currentEnterpriseId = this.enterpriseId;

    if (this._route.snapshot.paramMap.get('enterpriseId') != null) {
      this.currentEnterpriseId = this._route.snapshot.paramMap.get('enterpriseId');
    }

    if (!this.templateMode) {
      this.isAuditFinishedFlag = await this._treeNavigationService.isAuditFinished(this.currentAuditId);
    }

    this._enterpriseFormService
      .getIndustryTypes()
      .pipe(
        map(
          (response) =>
            (this.industryTypes = new ArrayStore({
              data: response,
              key: 'id',
            }))
        )
      )
      .subscribe();

    if (this.templateMode) {
      this._httpClient
        .get<string>(this.urlDBEnergyDatabase + `api/enterprises/doc-variables-code`)
        .subscribe((res: string) => (this.docVariableCode = res));
    }

    if (this.currentEnterpriseId != null) {
      this.fetchEnterprise(this.currentEnterpriseId);
    } else {
      this.currentEnterprise = this._enterpriseFormService.getEmptyEnterprise();
      this._enterpriseFormService.formLoaded(true);
      this.savedEnterprise = this._treeNavigationService.deepCopy(this.currentEnterprise);
    }

    this._treeNavigationService.checkFormData.pipe(takeUntil(this.onDestroy)).subscribe((result) => {
      if (result) {
        this._treeNavigationService.updateformDataSaved(
          this.currentEnterprise,
          this.savedEnterprise,
          this.currentEnterpriseId
        );
      }
    });

    this._treeNavigationService.exitFormReturn.pipe(takeUntil(this.onDestroy)).subscribe((result) => {
      switch (result) {
        case CloseResult.Cancel:
          break;
        case CloseResult.Confirm:
          this._treeNavigationService.exitFormYesFn();
          break;
        case CloseResult.Special:
          this._treeNavigationService.exitFormSpecialFn();
          break;
      }
    });
  }

  public isAuditFinished(): boolean {
    return this.isAuditFinishedFlag;
  }

  getImagesNumber() {
    let imagesNumberResult = this._galleryService.getImagesNumberByFormId(this.enterpriseId.toUpperCase());
    if (imagesNumberResult == null) {
      imagesNumberResult = this.imagesNumber;
    }
    return imagesNumberResult;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._enterpriseFormService.formLoaded(false);
  }

  ngAfterViewInit(): void {
    this._treeNavigationService.setComponentRendered(this);
  }

  canBeDeactivated(): boolean {
    return this.triggerParentDeactivateCheck();
  }

  triggerParentDeactivateCheck(): boolean {
    if (this.currentEnterprise) {
      return !this._enterpriseFormService.formChanged(this.currentEnterprise);
    } else {
      return true;
    }
  }

  public fetchEnterprise(id: string) {
    this.isGalleryVisible = false;
    this._enterpriseFormService
      .getCurrentEnterprise(id.toUpperCase())
      .pipe(
        map((response: Enterprise) => {
          this.currentEnterprise = response;
          this._enterpriseFormService.createCopy(this.currentEnterprise);
          this._enterpriseFormService.formLoaded(true);
          this.isGalleryVisible = true;
          this.savedEnterprise = this._treeNavigationService.deepCopy(this.currentEnterprise);
          this.getImagesNumberInGallery(this.currentEnterprise.formGallery.id);
        })
      )
      .subscribe();
  }

  public updateEnterprise(enterprise: Enterprise): void {
    const validator: any = this.enterpriseForm.instance.validate();
    if (!validator.isValid) {
      validator.brokenRules[0].validator.focus();
    } else {
      this._enterpriseFormService
        .update(enterprise)
        .pipe(
          map((response) => {
            this.currentEnterprise = response;
            this._enterpriseFormService.createCopy(this.currentEnterprise);
            this.savedEnterprise = this._treeNavigationService.deepCopy(this.currentEnterprise);
            this._treeNavigationService.getAuditNavigationTree(response.auditFormId).subscribe();
            notify({
              message: this._('changes-saved'),
              type: 'success',
              displayTime: AppSettings.NOTIFY_DURATION,
              position: AppSettings.NOTIFY_TOP_POSTION,
            });
            this._energyBalanceSankey.updateEnergyBalanceSankey(this.currentAuditId);
          })
        )
        .subscribe();
    }
  }

  onAddDepartmentButtonClick() {
    this.displayCreateDepartmentPopup();
  }

  public getImagesNumberInGallery(galleryId: string): void {
    this._httpClient
      .get(this.urlDBEnergyDatabase + `api/galleries/${galleryId}/images-number`)
      .subscribe((res: number) => {
        this.imagesNumber = res;
      });
  }

  closeDepartmentForm(deleteEnterpriseFormChanges: boolean): void {
    this.createDepartmentPopupVisible = false;
    if (deleteEnterpriseFormChanges) {
      this.currentEnterprise = this.savedEnterprise;
    } else {
      this.fetchEnterprise(this.currentEnterpriseId);
    }
  }

  displayCreateDepartmentPopup(): void {
    this.createDepartmentPopupVisible = !this.createDepartmentPopupVisible;
  }

  private checkEnterpriseChangesBeforeAddingDepartment() {
    const isChanged = this._enterpriseFormService.formChanged(this.currentEnterprise);
    if (isChanged) {
      this._treeNavigationService.exitFormYesFn = () => {
        this.currentEnterprise = this.savedEnterprise;
        this.createDepartmentPopupVisible = true;
        return true;
      };
      this._treeNavigationService.exitFormSpecialFn = () => {
        this.saveForm();
        this.createDepartmentPopupVisible = true;
        return true;
      };
      this._treeNavigationService.addAndGoAskToSaveChanges();
    } else {
      this.createDepartmentPopupVisible = true;
    }
  }

  public async toggleFinishedAudit() {
    const audit = await this._treeNavigationService.toggleFinishedAudit(this.currentAuditId);
    this.isAuditFinishedFlag = audit.isFinished;
    this._enterpriseFormService.toggleFinishedAudit(this.isAuditFinishedFlag);
  }

  public saveForm() {
    this.updateEnterprise(this.currentEnterprise);
  }

  public selectedAction(e: any): void {
    switch (e.itemData.key) {
      case FormMenuActions.Save:
        this.saveForm();
        break;
      case FormMenuActions.Add:
        this.checkEnterpriseChangesBeforeAddingDepartment();
        break;
      case FormMenuActions.Gallery:
        this.showGallery();
        break;
      case FormMenuActions.ToggleFinishedAudit:
        this.toggleFinishedAudit();
      default:
        break;
    }
  }

  setActiveTab(path: string): void {
    switch (path) {
      case AppSettings.FORM_TAB:
        this.activeIndex = 0;
        break;
      case AppSettings.GALLERY_TAB:
        this.activeIndex = 1;
        this.showGallery();
        break;
      case AppSettings.SANKEY_TAB:
        this.activeIndex = 2;
        this.showEnergyBalance();
        break;
      default:
        this.activeIndex = 0;
    }
  }

  handleChange(e: any): void {
    const tabMap = [AppSettings.FORM_TAB, AppSettings.GALLERY_TAB, AppSettings.SANKEY_TAB];
    const path = tabMap[e.index];
    this._router.navigate([path], { relativeTo: this._route });
    this.setActiveTab(path);
  }

  public showGallery(): void {
    this.isGalleryVisible = true;
  }

  public showEnergyBalance(): void {
    this.energyBalanceChart = true;
  }

  public galleryClosed(imagesNumber: number): void {
    this.isGalleryVisible = false;
    this.imagesNumber = imagesNumber;
  }

  public generateReport(auditId: string): void {
    this._reportService.generateReport(auditId);
  }

  private updateButtonsText(screenSize: BreakpointState) {
    const isXSmall = screenSize.breakpoints[Breakpoints.XSmall];

    this.saveText = isXSmall ? '' : this._('button-save-text');
    this.galleryText = isXSmall ? '' : this._('button-gallery-text');
    this.addDepartmentText = isXSmall ? '' : this._('button-add-department');
  }

  public isChangesSaved(): boolean {
    return JSON.stringify(this.currentEnterprise) === JSON.stringify(this.savedEnterprise);
  }

  private onCreateDepartment(): void {
    this.departmentFormPopup.saveDepartment({}, this.departmentFormPopup.currentDepartment);
  }

  private onCreateAndDisplayDepartment(): void {
    this.departmentFormPopup.saveDepartmentGo({}, this.departmentFormPopup.currentDepartment);
  }

  onCloseDepartmentCreation(e: CloseResult) {
    if (e === CloseResult.Confirm) {
      // exit
      this.createDepartmentPopupVisible = false;
      this.closeFromPopup = true;
    }

    this.createDepartmentExitPopupVisible = false;
  }

  onDepartmentCreationPopupHiding(e: any) {
    if (this.closeFromPopup === false) {
      const noChanges = this.departmentFormPopup.triggerParentDeactivateCheck();
      if (!noChanges) {
        e.cancel = true;
        this.createDepartmentExitPopupVisible = true;
      }
    }
    this.closeFromPopup = false;
  }

  onDepartmentCreationPopupShown(e: any) {
    this._departmentService.createCopy(this.departmentFormPopup.currentDepartment);
  }

  private closeFromPopup = false;
  public createDepartmentExitPopupVisible = false;
}
