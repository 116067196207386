<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<ng-content></ng-content>

<div class="menu-container georama-component">
  <dx-tree-view
    [items]="items"
    keyExpr="path"
    selectionMode="single"
    (onItemClick)="onItemClick($event)"
    (onContentReady)="setDefaultSelectedItem()"
    width="100%"
    itemTemplate="itemTemplate"
  >
    <div *dxTemplate="let item of 'itemTemplate'">
      <div [data-testid]="item.textKey">
        <div class="dx-treeview-item-content">
          <span class="{{ item.icon }} dx-icon">
            <span
              *ngIf="item.textKey === navigationAuditName && numberOfAudits"
              class="dx-list-item-badge dx-badge"
            >
              {{ numberOfAudits }}
            </span>
          </span>
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
  </dx-tree-view>
</div>
