import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Image } from '../../models/image.model';
import { ScreenService } from '../../services';
import { GalleryService } from '../../services/gallery.service';
import { AppSettings } from '../../../AppSettings';
import { Localizable } from '../../../locale/localizable';
import { AuditGalleryComponent } from './audit-gallery/audit-gallery.component';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent extends Localizable implements OnInit {
  @Input()
  public formId: string = '';

  @Output()
  public imagesNumber = new EventEmitter<number>();

  @ViewChild('takePicture', { static: false }) inputImage: Input;

  public images: Array<Image> = [];
  public isMobile: boolean;

  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;
  constructor(
    private _galleryService: GalleryService,
    private _screenService: ScreenService
  ) {
    super(AuditGalleryComponent.localFile);
  }

  ngOnInit(): void {
    this._galleryService.getGalleryObserver(this.formId).subscribe((images: Array<Image>) => {
      this.images = images;
    });

    //TODO: Check if device has access to camera -
    this._screenService.isMobile.subscribe((isMobile: boolean) => (this.isMobile = isMobile));
  }

  public popupClosed(): void {
    this.imagesNumber.emit(this.images.length);
  }

  public addImage(id: string): void {
    this._galleryService.addImageToCollection(id, this.images);
  }

  public removeImage(id: string): void {
    this._galleryService.removeImage(id, this.images);
  }

  public uploadImage(e: any): void {
    this._galleryService.uploadImage(e, this.images);
  }
}
