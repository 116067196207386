import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { RefrigerantType } from 'src/app/shared/models/area/types/cooling-system.model';
import {
  HeatPump,
  HeatPumpBottomSourceTemp,
  HeatPumpBottomSourceType,
  HeatPumpTopSourceType,
  HeatPumpType,
} from 'src/app/shared/models/area/types/heat-pump.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';
import { Localizable } from '../../../../../../../locale/localizable';

@Component({
  selector: 'app-heat-pump',
  templateUrl: './heat-pump.component.html',
  styleUrls: ['./heat-pump.component.scss'],
})
export class HeatPumpComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  heatPump: HeatPump = null;

  currentYear: number;
  pumpTypesList: ArrayStore;
  bottomSourceTypesList: ArrayStore;
  bottomSourceTempsList: ArrayStore;
  topSourceTypesList: ArrayStore;
  refrigerantTypesList: ArrayStore;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getPumpTypesForHeatPump().subscribe((res: Array<HeatPumpType>) => {
      this.pumpTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getBottomSourceTypesForHeatPump().subscribe((res: Array<HeatPumpBottomSourceType>) => {
      this.bottomSourceTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getBottomSourceTempsForHeatPump().subscribe((res: Array<HeatPumpBottomSourceTemp>) => {
      this.bottomSourceTempsList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getTopSourceTypesForHeatPump().subscribe((res: Array<HeatPumpTopSourceType>) => {
      this.topSourceTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });

    this._apiRequestService.getRefrigerantTypes().subscribe((res: Array<RefrigerantType>) => {
      this.refrigerantTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
