<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="fan.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('fan-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="fan.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('fan-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="fan.serialNumber"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('fan-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="fan.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat"
        [(value)]="fan.compressionRatio"
        stylingMode="outlined"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-compression-ratio')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="typeList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="fan.fanType"
        [dataSource]="typeList"
        displayExpr="name"
        stylingMode="outlined"
        [searchEnabled]="true"
        searchMode="contains"
        [placeholder]="_('fan-fan-type-placeholder')"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('fan-fan-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kW'"
        [(value)]="fan.ratedPower"
        stylingMode="outlined"
        [min]="0"
        [max]="10000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-rated-power')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' %'"
        [(value)]="fan.ratedEfficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-rated-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' %'"
        [(value)]="fan.ratedCapacity"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-rated-capacity')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="operatinPointsVolatilityList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="fan.operatingPointVolatility"
        [dataSource]="operatinPointsVolatilityList"
        displayExpr="name"
        stylingMode="outlined"
        [searchEnabled]="true"
        searchMode="contains"
        [placeholder]="_('fan-operating-point-volatility-placeholder')"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('fan-operating-point-volatility')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="regulationTypesList">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="fan.regulationType"
        [dataSource]="regulationTypesList"
        displayExpr="name"
        stylingMode="outlined"
        [searchEnabled]="true"
        searchMode="contains"
        [placeholder]="_('fan-regulation-type-placeholder')"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('fan-regulation-type')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="fan.airTemp"
        stylingMode="outlined"
        [min]="0"
        [max]="1000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-air-temp')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kPa'"
        [(value)]="fan.suctionPressure"
        stylingMode="outlined"
        [min]="0"
        [max]="1000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-suction-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kPa'"
        [(value)]="fan.dischargePressure"
        stylingMode="outlined"
        [min]="0"
        [max]="1000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-discharge-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' %'"
        [(value)]="fan.averageLoad"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-average-load')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' %'"
        [(value)]="fan.averageEfficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-average-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat + ' h'"
        [(value)]="fan.workingHoursInYear"
        stylingMode="outlined"
        [min]="0"
        [max]="8784"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('fan-working-hours-in-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-switch
        [(value)]="fan.sampleCardAvailable"
        switchedOffText="Nie"
        switchedOnText="Tak"
      ></dx-switch>
    </div>
    <dxo-label [text]="_('fan-sample-card-available')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="technicalCondition">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="fan.technicalCondition"
        [dataSource]="technicalCondition"
        displayExpr="name"
        stylingMode="outlined"
        [searchEnabled]="true"
        searchMode="contains"
        [placeholder]="_('fan-technical-condition-placeholder')"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('fan-technical-condition')"></dxo-label>
  </dxi-item>
</dx-form>
