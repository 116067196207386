import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SingleCardModule } from './layouts';
import { ScreenService, AppInfoService } from './shared/services';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfig } from './app.config';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollService } from './shared/services/scroll.service';
import {
  CustomInterceptor,
  ErrorInterceptor,
  HeaderInterceptor,
} from './core/audit-http-interceptor/audit-http-interceptor';
import { HeaderModule } from './shared/components/header/header.component';
import { LoginModule } from './pages/login/login.component';
import { ButtonModule } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuditsCountService } from './shared/services/audits-count.service';

// tslint:disable-next-line: typedef
export function initConfig(config: AppConfig) {
  return () => config.load();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SideNavOuterToolbarModule,
    SingleCardModule,
    AppRoutingModule,
    HttpClientModule,
    DragDropModule,
    HeaderModule,
    LoginModule,
    ButtonModule,
    TabViewModule,
    CardModule,
    ToggleButtonModule,
    InputTextModule,
    CalendarModule,
    BrowserAnimationsModule,
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    ScrollService,
    ScreenService,
    AppInfoService,
    AuditsCountService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
