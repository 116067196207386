import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { GassCogeneration, GassCogenerationSourceType } from 'src/app/shared/models/area/types/gass-cogeneration.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-gass-cogeneration',
  templateUrl: './gass-cogeneration.component.html',
  styleUrls: ['./gass-cogeneration.component.scss'],
})
export class GassCogenerationComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  gassCogeneration: GassCogeneration = null;

  sourceTypesList: ArrayStore;
  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getGassCogenerationModel().subscribe((res: Array<GassCogenerationSourceType>) => {
      this.sourceTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
