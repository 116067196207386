import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { SteamGenerator, SteamGeneratorFuelType } from 'src/app/shared/models/area/types/steam-generator.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-steam-generator',
  templateUrl: './steam-generator.component.html',
  styleUrls: ['./steam-generator.component.scss'],
})
export class SteamGeneratorComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  steamGenerator: SteamGenerator = null;

  fuelTypesList: ArrayStore;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this._apiRequestService.getAreaTypeSteamGeneratorFuelTypes().subscribe((res: Array<SteamGeneratorFuelType>) => {
      this.fuelTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
