<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-popup
  id="questionnairePopup"
  *ngIf="questionnairePopupVisible && entepriseName !== ''"
  [fullScreen]="false"
  [showTitle]="true"
  [width]="popupWidth + '%'"
  [height]="popupHeight + '%'"
  [dragEnabled]="false"
  [visible]="currentQuestionnairePopupVisible"
  titleTemplate="title"
  (onHiding)="onHidingHandler($event)"
  [wrapperAttr]="{ class: 'custom-popup' }"
>
  <div
    *dxTemplate="let data of 'title'"
    class="questionnaire-popup-title"
  >
    <div
      id="questionnaireTitle"
      class="dx-widget dx-visibility-change-handler dx-collection dx-popup-title dx-has-close-button"
      role="toolbar"
    >
      <div>
        <div class="toolbar-before-questionnaire-padding">
          <h1 class="h1-no-margin">{{ _('button-questionnaire-text') }}</h1>
          <h3 class="gray-text">{{ entepriseName }}/{{ currentDepartmentName }}</h3>
          <dx-drop-down-button
            [text]="localizeQuestionnaireGroupName(currentlySelectedGroup)"
            [items]="translatedCurrentlyAvailableGroups"
            [dropDownOptions]="{
              minWidth: '15rem',
              maxWidth: '30rem',
              width: '40vw',
              maxHeight: '50vh',
            }"
            class="button-case-sensitive dropdown-button-focus-border"
            keyExpr="_id"
            displayExpr="_name"
            (onItemClick)="onGroupSelected($event)"
            itemTemplate="groupsTemplate"
          >
            <div *dxTemplate="let data of 'groupsTemplate'">
              <span class="{{ data._icon }} drop-down-button-icon"></span>
              {{ data._name }}
            </div>
          </dx-drop-down-button>

          <dx-progress-bar
            id="progress-bar-status"
            class="questionnaire-progress-bar"
            [min]="0"
            [max]="totalProgress"
            [value]="currentProgress"
            [showStatus]="false"
          ></dx-progress-bar>

          <dx-progress-bar
            id="progress-bar-status-group"
            class="questionnaire-progress-bar"
            [min]="0"
            [max]="totalGroupProgress"
            [value]="currentGroupProgress"
            [showStatus]="false"
          ></dx-progress-bar>
        </div>
        <div class="dx-toolbar-after toolbar-after-padding">
          <dx-button
            class="outlined-button-focus-hover button-rounded-regular button-rounded-regular-margin"
            stylingMode="outlined"
            icon="fi fi-rr-file-upload"
            [text]="_('update-questionnaire-button')"
            type="normal"
            (onClick)="showUpdateQuestionnairePopup()"
            [visible]="updateButtonVisible"
            [disabled]="isAuditFinished"
          ></dx-button>
          <dx-button
            class="outlined-button-focus-hover button-rounded-regular button-rounded-regular-margin"
            stylingMode="outlined"
            icon="fi fi-rr-trash"
            [text]="_('clear-questionnaire-button')"
            type="normal"
            (onClick)="clearAnswers()"
            [disabled]="isAuditFinished"
          ></dx-button>
          <dx-button
            id="download-csv-margin"
            stylingMode="outlined"
            icon="fi fi-rr-file-csv"
            class="outlined-button-focus-hover button-rounded-regular button-rounded-regular-margin"
            [text]="_('download-questionnaire-button')"
            (onClick)="onClickDowloadCSVButton()"
          >
          </dx-button>
          <dx-button
            id="close-questionnaire-button"
            icon="fi fi-rr-cross-small"
            (onClick)="showQuestionnaire(false)"
          ></dx-button>
        </div>
      </div>
    </div>
  </div>

  <dx-scroll-view
    id="questionnaireScrollView"
    width="100%"
    height="100%"
  >
    <app-questionnaire
      *ngIf="currentQuestionnairePopupVisible"
      [departmentId]="currentDepartmentId"
      [(questionnairePopupVisible)]="questionnairePopupVisible"
      [isAuditFinished]="isAuditFinished"
    ></app-questionnaire>
  </dx-scroll-view>

  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    cssClass="button-rounded-regular toolbar-button-focus-border"
    [options]="prevGroupButtonOptions"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    cssClass="button-rounded-regular toolbar-button-focus-border"
    [options]="nextGroupButtonOptions"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    cssClass="toolbar-contained-button-focus-border button-contained button-case-sensitive"
    [options]="saveButtonOptions"
    [disabled]="isAuditFinished"
  >
  </dxi-toolbar-item>
</dx-popup>

<app-exit-confirmation-popup
  [popupVisible]="exitPopupVisible"
  [confirmationNo]="_('exit-confirmation-no')"
  [confirmationYes]="_('exit-confirmation-yes')"
  [title]="_('exit-confirmation-title')"
  [message]="_('exit-confirmation-message')"
  (closeEvent)="onCloseQuestionnaire($event)"
></app-exit-confirmation-popup>

<app-exit-confirmation-popup
  [popupVisible]="discardPopupVisible"
  [confirmationNo]="_('discard-questionnaire-confirmation-no')"
  [confirmationYes]="_('discard-questionnaire-confirmation-yes')"
  [title]="_('discard-questionnaire-confirmation-title')"
  [message]="_('discard-questionnaire-confirmation-message')"
  (closeEvent)="onDiscardQuestionnaire($event)"
></app-exit-confirmation-popup>

<app-exit-confirmation-popup
  [popupVisible]="updateQuestionnairePopupVisible"
  [confirmationNo]="_('update-questionnaire-discard-cancel')"
  [confirmationYes]="_('update-questionnaire-discard-confirm')"
  [title]="_('update-questionnaire-discard-title')"
  [message]="_('update-questionnaire-discard-message')"
  (closeEvent)="onUpdateQuestionnaire($event)"
  customContent="true"
>
  <ng-template appExitConfirmationPopupContent>
    <ul>
      <div *ngFor="let group of groupsToBeDiscarded">
        <li>
          {{ _('update-questionnaire-discard-group') }} {{ group._id }} -
          {{ group._name }}
        </li>
      </div>
    </ul>
  </ng-template>
</app-exit-confirmation-popup>

<app-exit-confirmation-popup
  [popupVisible]="orphanedNotesPopupVisible"
  [confirmationNo]="_('orphaned-notes-questionnaire-confirmation-no')"
  [confirmationYes]="_('orphaned-notes-questionnaire-confirmation-yes')"
  [title]="_('orphaned-notes-questionnaire-confirmation-title')"
  [message]="_('orphaned-notes-questionnaire-confirmation-message')"
  (closeEvent)="onOrphanedNotesAction($event)"
></app-exit-confirmation-popup>

<dx-popup
  [fullScreen]="false"
  [showTitle]="true"
  [dragEnabled]="false"
  height="50%"
  width="30%"
  [title]="_('loader-questionnaire-title')"
  [visible]="questionnaireLoading"
  [wrapperAttr]="{ class: 'loading-popup custom-popup' }"
>
  <div class="center-container">
    <dx-load-indicator
      *ngIf="true"
      [height]="loadIndicatorWidth"
      [width]="loadIndicatorWidth"
    ></dx-load-indicator
    ><br />
    <span>{{ _('loader-questionnaire-waiting-text') }}</span
    ><br />
    <span>{{ _('loader-questionnaire-prepare-survey-text') }}</span>
  </div>
</dx-popup>
