import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnergyBalanceSource } from 'src/app/shared/models/energies/energy.model';
import { confirm } from 'devextreme/ui/dialog';
import { AppSettings } from '../../../../AppSettings';

@Component({
  selector: 'app-energy-source-delete-popup',
  templateUrl: './energy-source-delete-popup.component.html',
  styleUrls: ['./energy-source-delete-popup.component.scss'],
})
export class EnergySourceDeletePopupComponent implements OnInit {
  @Input() energyBalanceSource: EnergyBalanceSource = null;
  @Input() currencyFormat: string = '';

  @Output() deleteSourceWithReceivers = new EventEmitter<EnergyBalanceSource>();
  @Output() popupClosed = new EventEmitter<boolean>();

  public popupWidth = AppSettings.POPUP_WIDTH;
  public popupHeight = AppSettings.POPUP_HEIGHT;
  constructor() {}

  ngOnInit(): void {}

  deleteSource(): void {
    const result = confirm('<i>Jest pewny? Spowoduje to także usunięcie odbiorników?</i>', 'Usunięcie odbiornika');
    result.then((dialogResult) => {
      if (dialogResult) {
        this.deleteSourceWithReceivers.emit(this.energyBalanceSource);
        this.popupClosed.emit(true);
      }
    });
  }

  formClosed(): void {
    this.popupClosed.emit(true);
  }
}
