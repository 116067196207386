<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-form [showColonAfterLabel]="true">
  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="deaerator.producer"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('deaerator-producer')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="deaerator.model"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('deaerator-model')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-text-box
        [(value)]="deaerator.serialNumber"
        stylingMode="outlined"
      >
      </dx-text-box>
    </div>
    <dxo-label [text]="_('deaerator-serial-number')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="integerNumberFormat"
        [(value)]="deaerator.productionYear"
        stylingMode="outlined"
        [min]="1950"
        [max]="currentYear"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('deaerator-production-year')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kPa'"
        [(value)]="deaerator.pressure"
        stylingMode="outlined"
        [min]="0"
        [max]="1000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('deaerator-pressure')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' %'"
        [(value)]="deaerator.efficiency"
        stylingMode="outlined"
        [min]="0"
        [max]="1"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('deaerator-efficiency')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="deaerator.outputWaterTemp"
        stylingMode="outlined"
        min="0"
        max="100"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('deaerator-output-water-temp')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³'"
        [(value)]="deaerator.generatingCapacity"
        stylingMode="outlined"
        min="0"
        max="1000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('deaerator-generating-capacity')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' m³'"
        [(value)]="deaerator.degassingCapacity"
        stylingMode="outlined"
        min="0"
        max="1000000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('deaerator-degassing-capacity')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="deaerator.makeupWaterTemp"
        stylingMode="outlined"
        min="0"
        max="100"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('deaerator-makeup-water-temp')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' kg/s'"
        [(value)]="deaerator.outputFlow"
        stylingMode="outlined"
        min="0"
        max="100000"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('deaerator-output-flow')"></dxo-label>
  </dxi-item>

  <dxi-item>
    <div *dxTemplate>
      <dx-number-box
        [step]="0"
        [format]="fullNumberFormat + ' °C'"
        [(value)]="deaerator.outputTemp"
        stylingMode="outlined"
        min="0"
        max="100"
      >
      </dx-number-box>
    </div>
    <dxo-label [text]="_('deaerator-output-temp')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="degassingTypes">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="deaerator.degassingType"
        [dataSource]="degassingTypes"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('deaerator-degassing-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('deaerator-degassing-type')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="deaeratorSupplyTypes">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="deaerator.deaeratorSupplyType"
        [dataSource]="deaeratorSupplyTypes"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('deaerator-supply-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('deaerator-supply-type')"></dxo-label>
  </dxi-item>

  <dxi-item *ngIf="waterSupplyTypes">
    <div *dxTemplate>
      <dx-select-box
        [(value)]="deaerator.waterSupplyType"
        [dataSource]="waterSupplyTypes"
        displayExpr="name"
        stylingMode="outlined"
        [placeholder]="_('deaerator-water-supply-type-placeholder')"
        [searchEnabled]="true"
        searchMode="contains"
      >
      </dx-select-box>
    </div>
    <dxo-label [text]="_('deaerator-water-supply-type')"></dxo-label>
  </dxi-item>
</dx-form>
