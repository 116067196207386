import { Component, OnInit } from '@angular/core';
import { ScrollService } from '../../services/scroll.service';

@Component({
  selector: 'app-scroll-to-top-button',
  templateUrl: './scroll-to-top-button.component.html',
  styleUrls: ['./scroll-to-top-button.component.scss'],
})
export class ScrollToTopButtonComponent implements OnInit {
  windowScrolled = false;

  constructor(private scrollService: ScrollService) {}

  ngOnInit(): void {
    this.scrollService.windowScrolled$.subscribe((result) => (this.windowScrolled = result));
  }

  public scrollTop() {
    this.scrollService.updateScrollToTop(true);
  }
}
