import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Answer, CheckBoxAnswer, OpenAnswer, SelectAnswer } from './models/answers.models';
import { Question } from './models/questions.models';
import TreeModel from 'tree-model';
import { HttpClient } from '@angular/common/http';
import { AnswerDto, QuestionDto, QuestionnaireGroupDto } from './questionnaire.model';
import { AppConfig } from '../../../app.config';
import { Localizable } from '../../../locale/localizable';
import {
  QuestionnairePathNode,
  QuestionGroup,
  QuestionnaireTags,
  QuestionnaireAnswer,
  QuestionType,
  QuestionnairePathNodeCheckBox,
} from './models/questionnaire.models';
type TreeNode = TreeModel.Node<QuestionnairePathNode>;

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService extends Localizable {
  public nextQuestion: Subject<Question> = new Subject<Question>();
  public lastQuestion: Subject<boolean> = new Subject<boolean>();
  public currentGroupProgress: Subject<number> = new Subject<number>();
  public totalGroupProgress: Subject<number> = new Subject<number>();
  private totalPossibleQuestionsCount: number = 0;
  public removeQuestionGuid: Subject<string> = new Subject<string>();

  public questionList: Array<Question> = new Array<Question>();
  private questionnaireTree: Array<QuestionnairePathNode> = new Array<QuestionnairePathNode>();

  public treeRootNode: TreeModel.Node<QuestionnairePathNode>;
  private treeModel: TreeModel;
  private apiUrl: string;
  public questionnaireGroupDto: QuestionnaireGroupDto;
  public currentGroup: QuestionGroup;
  public currentXMLGroup: QuestionGroup;

  private treeGraph: Array<{ id: string; next: Array<string> }> = [];

  public parsedXml: any;
  private readonly rootEndAnswerLength = 2;

  // We would like to save answers every time except if already saved answers were not changed.
  public isDirty: boolean = true;

  constructor(
    private _httpClient: HttpClient,
    private _config: AppConfig
  ) {
    super('Questionnaire');
    this.treeModel = new TreeModel({});
    this.apiUrl = this._config.getConfig('DbEnergyDatabaseUrl');
  }

  public findRootQuestion(): void {
    const rootQuestion = this.questionList.find((q) => q._id === QuestionnaireTags.RootTag);
    let question = JSON.parse(JSON.stringify(rootQuestion));

    const rootQuestionDto = this.questionnaireGroupDto.questions.find((q) => q.id === QuestionnaireTags.RootTag);
    if (rootQuestionDto != null && this.questionnaireGroupDto.id === this.currentGroup._id) {
      question = { ...question, guid: rootQuestionDto.guid };
    }

    question = new Question(question);
    const rootNode = new QuestionnairePathNode(question);
    this.treeRootNode = new TreeModel(QuestionnairePathNode).parse<QuestionnairePathNode>(rootNode);
    this.setNextQuestion(question);
    this.addAnswerToLastUpdatedQuestion(question);
  }

  private findNextQuestion(nodeAnswer: QuestionnaireAnswer): Question {
    return this.questionList.find((question) => question._id === nodeAnswer.nextQuestionId);
  }

  private findNextQuestionInDTO(nodeAnswer: QuestionnaireAnswer): QuestionDto {
    let nextQuestionInDtoGuid = '';
    const currentQuestionNode = this.findNode(nodeAnswer.questionGuid);
    const currentQuestionInDto = this.questionnaireGroupDto.questions.find(
      (question) => question.guid === currentQuestionNode.model.guid
    );

    if (currentQuestionInDto == null) {
      return null;
    }

    const currentQuestionInDtoAnswer = currentQuestionInDto.answers;
    if (Array.isArray(currentQuestionInDtoAnswer) && currentQuestionInDtoAnswer.length > 0) {
      nextQuestionInDtoGuid = currentQuestionInDtoAnswer[0].nextQuestionGuid;
    }

    return this.questionnaireGroupDto.questions.find((question) => question.guid === nextQuestionInDtoGuid);
  }

  public setQuestionList(questions: Array<Question>): void {
    this.questionList = questions;
  }

  private getAllFilledAndSkippedQuestions() {
    const questions = this.treeRootNode.all((node) => {
      if (node.model.answer && Array.isArray(node.model.answer) && node.model.answer.length !== 0) {
        return true;
      }
      if (node.model.answer && !Array.isArray(node.model.answer)) {
        return true;
      }
      return false;
    });
    return Array.from(new Set(questions.map((q) => q.model.guid))).map((id) =>
      questions.find((q) => q.model.guid === id)
    );
  }

  public setUserAnswer(answer: Answer, skipAddingQuestion: boolean): void {
    const node = this.findNode(answer.questionGuid);
    const nodeAnswer: QuestionnaireAnswer = new QuestionnaireAnswer(answer);

    if (this.nodeHasAnswer(node.model)) {
      // can be checkbox - single or multiple choice question, or any other type
      node.model.setAnswer(nodeAnswer, node.model.multiplechoice);
      this.isDirty = true;
    } else {
      node.model.setAnswer(nodeAnswer);
      if (answer._next === QuestionnaireTags.EndTag) {
        this.isDirty = true;
        this.lastQuestion.next(true);
      }

      if (skipAddingQuestion) {
        // just skip adding questions
        return;
      }

      if (node.model.pathChildGuid != null) {
        // We must now make sure that this pathChildGuid (possible child) is the direct descendant of this question (can be skipped)
        const questionNode = this.findNode(node.model.pathChildGuid);
        if (answer._next === questionNode.model.id) {
          // this is literally next question that already exists in tree, return
          this.updateProgressGroup(node);
          return;
        }
        // we set answer after skipped question and there are next questions that were skipped in this route
      }

      // findNextQuestion by Id from parsed XML question list
      let question = this.findNextQuestion(nodeAnswer);
      if (question) {
        // We found question template and would like to convert it to next question node.
        // We should check with dto if such question exists.
        const questionDto = this.findNextQuestionInDTO(nodeAnswer);
        if (
          questionDto != null &&
          questionDto.guid === answer.nextQuestionGuid &&
          this.questionnaireGroupDto.id === this.currentGroup._id
        ) {
          // We are working on dto set.
          question = { ...question, guid: questionDto.guid };
        } else {
          this.isDirty = true;
        }
        this.addQuestion(question, node);
      }
    }

    this.updateProgressGroup(node);
  }

  // this method splits survey of all passed questions (real & skipped) by loop
  private splitFilledAndSkippedQuestionsByCycle(
    questions: TreeModel.Node<QuestionnairePathNode>[]
  ): TreeModel.Node<QuestionnairePathNode>[][] {
    const result: TreeModel.Node<QuestionnairePathNode>[][] = [];
    const currentDevice: TreeModel.Node<QuestionnairePathNode>[] = [];
    const seenQuestionsIds = new Set<string>();
    let insideSurvey = false;

    questions.forEach((question) => {
      if (question.model.id === QuestionnaireTags.RootTag) {
        insideSurvey = this.handleRoot(question, currentDevice, seenQuestionsIds);
      } else if (question.model.id === QuestionnaireTags.EndTag) {
        insideSurvey = this.handleEnd(insideSurvey, currentDevice, result);
      } else if (insideSurvey) {
        this.handleQuestion(question, currentDevice, seenQuestionsIds, result);
      }
    });

    this.finalizeDevice(currentDevice, result);

    return result;
  }

  private handleRoot(
    question: TreeModel.Node<QuestionnairePathNode>,
    currentDevice: TreeModel.Node<QuestionnairePathNode>[],
    seenQuestionsIds: Set<string>
  ): boolean {
    currentDevice.push(question);
    seenQuestionsIds.add(question.model.id);
    return true;
  }

  private handleEnd(
    insideSurvey: boolean,
    currentDevice: TreeModel.Node<QuestionnairePathNode>[],
    result: TreeModel.Node<QuestionnairePathNode>[][]
  ): boolean {
    if (insideSurvey && currentDevice.length > 0) {
      result.push([...currentDevice]);
      currentDevice.length = 0;
    }
    return false;
  }

  private handleQuestion(
    question: TreeModel.Node<QuestionnairePathNode>,
    currentDevice: TreeModel.Node<QuestionnairePathNode>[],
    seenQuestionsIds: Set<string>,
    result: TreeModel.Node<QuestionnairePathNode>[][]
  ): void {
    if (seenQuestionsIds.has(question.model.id)) {
      if (currentDevice.length > 0) {
        result.push([...currentDevice]);
        currentDevice.length = 0;
      }
      seenQuestionsIds.clear();
    }
    currentDevice.push(question);
    seenQuestionsIds.add(question.model.id);
  }

  private finalizeDevice(
    currentDevice: TreeModel.Node<QuestionnairePathNode>[],
    result: TreeModel.Node<QuestionnairePathNode>[][]
  ): void {
    if (currentDevice.length > 0) {
      result.push([...currentDevice]);
    }
  }

  private getNodeModelAnswerId(node: TreeModel.Node<QuestionnairePathNode>) {
    if (Array.isArray(node.model.answer)) {
      return node.model.answer[0].id;
    } else {
      return node.model.answer.id;
    }
  }

  private filterRealAnsweredQuestions(
    subSurvey: TreeModel.Node<QuestionnairePathNode>[]
  ): TreeModel.Node<QuestionnairePathNode>[] {
    return subSurvey.filter((n) => {
      if (this.getNodeModelAnswerId(n) !== QuestionnaireTags.SkipTag) {
        return true;
      } else {
        return false;
      }
    });
  }

  public updateProgressGroup(lastModifiedQuestion: TreeNode = null) {
    const allFilledAndSkippedQuestions = this.getAllFilledAndSkippedQuestions();
    const allFilledAndSkippedQuestionsLength = allFilledAndSkippedQuestions.length;
    // first group showup
    if (allFilledAndSkippedQuestionsLength === 0) {
      this.currentGroupProgress.next(0);
      this.totalGroupProgress.next(1);
      return;
    }

    // if tree graph is empy we do not scan
    if (this.treeGraph.length === 0) {
      this.currentGroupProgress.next(0);
      this.totalGroupProgress.next(1);
      return;
    }

    const splitAllQuestions = this.splitFilledAndSkippedQuestionsByCycle(allFilledAndSkippedQuestions);

    let sumOfRealAnswerProgress = 0;
    let sumOfTotalProgress = 0;

    // for each subSurvey count the progress
    splitAllQuestions.forEach((subSurvey) => {
      // get real answered
      const realAnsweredQuestionsSubSurvey = this.filterRealAnsweredQuestions(subSurvey);

      const currentRealProgress = realAnsweredQuestionsSubSurvey.length;

      let longestPossibePath = [];
      if (lastModifiedQuestion) {
        longestPossibePath = this.getLongestRoutePrePathExact(
          subSurvey[0].model.id,
          realAnsweredQuestionsSubSurvey,
          subSurvey,
          lastModifiedQuestion
        );

        if (longestPossibePath == null) {
          longestPossibePath = this.getLongestRoutePrePath(subSurvey[0].model.id, subSurvey, lastModifiedQuestion);
        }
      } else {
        const haveTheSameElements = subSurvey.every((item) => realAnsweredQuestionsSubSurvey.includes(item));
        if (haveTheSameElements) {
          longestPossibePath = this.getLongestRoutePrePathExact(
            subSurvey[0].model.id,
            realAnsweredQuestionsSubSurvey,
            subSurvey,
            lastModifiedQuestion
          );
        } else {
          longestPossibePath = this.getLongestRoutePrePath(
            subSurvey[0].model.id,
            realAnsweredQuestionsSubSurvey,
            lastModifiedQuestion
          );
        }
      }
      if (!longestPossibePath) {
        return;
      }
      sumOfRealAnswerProgress += currentRealProgress;
      sumOfTotalProgress += longestPossibePath.length - 1; // -1 because we do not count "END"
    });

    this.totalPossibleQuestionsCount = sumOfTotalProgress;
    this.currentGroupProgress.next(sumOfRealAnswerProgress);
    this.totalGroupProgress.next(this.totalPossibleQuestionsCount);
  }

  public dropDependentNodes(node: TreeNode, dropSelf: boolean) {
    // Delete all dependent nodes till lastQuestion skipToId
    let nodeIterator = node;
    const treeNodeList: TreeNode[] = [];

    if (dropSelf) {
      treeNodeList.push(nodeIterator);
    }

    while (nodeIterator != null && nodeIterator.model.pathChildGuid != null) {
      nodeIterator = this.findNode(nodeIterator.model.pathChildGuid);
      if (nodeIterator != null) {
        if (nodeIterator.model.id === node.model.skipToId) {
          if (node.children.length > 0) {
            const nodeChild = JSON.stringify(node.children[0].model);
            const parentChild = JSON.stringify(nodeIterator.parent.children[0].model);
            if (nodeChild !== parentChild) {
              // reassign children of nodeiterator to main node
              this.addChildNode(node, nodeIterator.parent.children[0]);
            }
          }

          // Add nodes until skipToId (meaning next independent question)
          nodeIterator = null;
        } else {
          treeNodeList.push(nodeIterator);
        }
      }
    }

    if (treeNodeList.length > 0) {
      treeNodeList.forEach((tn) => {
        this.removeQuestionGuid.next(tn.model.guid);
        tn.drop();
      });
    }
  }

  public questionChangeAnswer(answer: Answer, dropNodes: boolean): void {
    this.isDirty = true;
    const node = this.findNode(answer.questionGuid);
    const nodeAnswer: QuestionnaireAnswer = new QuestionnaireAnswer(answer);
    if (this.nodeHasAnswer(node.model)) {
      if (dropNodes) {
        // only to next skippable question
        this.dropDependentNodes(node, false);
      }

      // Change answer
      node.model.setAnswer(nodeAnswer);

      if (!dropNodes) {
        this.updateProgressGroup(node);
        return;
      }

      const question = this.findNextQuestion(nodeAnswer);
      if (question) {
        if (node.children.length > 0 && node.children[0].model.id === question._id) {
          this.updateProgressGroup(node);
          return;
        }
        this.changeQuestion(question, node);
        this.lastQuestion.next(false);
      } else {
        this.lastQuestion.next(true);
      }
    } else {
      this.setUserAnswer(answer, false);
    }
    this.updateProgressGroup(node);
  }

  public changeQuestion(question: Question, parent: TreeNode): void {
    // Do not use dto to fill values in case of back-forth answer change.
    // We treat it as a new answers.
    this.questionnaireGroupDto.questions = [];
    const newQuestion = new Question(question);
    const nodeQuestion = this.newNodeFromQuestion(newQuestion);
    const node = this.treeModel.parse(nodeQuestion);

    if (!this.findNode(node.model.guid)) {
      this.moveParentChildrenToNode(parent, node);
      this.addChildNode(parent, node);
      this.setNextQuestion(newQuestion);
    }
  }

  public addChildNode(parent: TreeNode, child: TreeNode): void {
    parent.addChild(child);
    parent.model.setPathGuid(child.model.guid);
  }

  public addQuestion(question: Question, parent: TreeNode): void {
    const newQuestion = new Question(question);
    const nodeQuestion = this.newNodeFromQuestion(newQuestion);
    const node = this.treeModel.parse(nodeQuestion);

    if (!this.findNode(node.model.guid)) {
      this.moveParentChildrenToNode(parent, node);
      this.addChildNode(parent, node);

      this.setNextQuestion(newQuestion);
      this.addAnswerToLastUpdatedQuestion(newQuestion);
    }
  }

  public linearQuestionGuids: Array<string> = [];
  private moveParentChildrenToNode(parent: TreeNode, node: TreeModel.Node<QuestionnairePathNode>) {
    if (parent.children.length > 0) {
      // change parent instead of remove
      // suppose we have this question group: Q1A2S2 Q2A3A4S5 Q3(...) Q4(...) Q5(...)
      // if we skipped from 2 -> 5 and answered 5 and ongoing, but then we want to answer 2
      // then we add eg. Q3 but pin next question (skipped to Q5) from 2 to freshly created 3
      // that way we have following answers: 1 -> 2 -> 3(no answer) -> 5
      this.addChildNode(node, parent.children[0]);
      parent.children = [];
    }
  }

  private setNextQuestion(question: Question) {
    this.getLinearTreeStructure();
    this.nextQuestion.next(question);
  }

  public fillTreeGraph() {
    this.treeGraph = [];
    this.questionList.forEach((question) => {
      const nextQuestions = [];
      //add skip to have skip routes when searching longest
      if (question._skipToId) {
        nextQuestions.push(question._skipToId);
      }

      if (Array.isArray(question.answer)) {
        question.answer.forEach((answer) => {
          if (!nextQuestions.some((ans) => ans === answer._next)) {
            // Only unique next questions
            nextQuestions.push(answer._next);
          }
        });
      } else {
        if (question._skipToId !== question.answer._next) {
          nextQuestions.push(question.answer._next);
        }
      }

      this.treeGraph.push({ id: question._id, next: nextQuestions });
    });
  }

  public getLongestRoutePrePath(
    questionId: string = QuestionnaireTags.RootTag,
    prePath: Array<any>,
    lastModifiedQuestion: TreeNode = null
  ) {
    const { prePathArray } = this.preparePathForSearching(questionId, prePath, lastModifiedQuestion);
    const routes = this.generateRoutesFromQuestion(questionId);

    const filteredRoutes = routes.filter((route) => this.isSubsequence(prePathArray, route));
    return this.findLongestArray(filteredRoutes);
  }

  private getNodeAnswerNextQuestionId(node: TreeModel.Node<QuestionnairePathNode>) {
    if (Array.isArray(node.model.answer)) {
      return node.model.answer[0].nextQuestionId;
    } else {
      return node.model.answer.nextQuestionId;
    }
  }

  private preparePathForSearching(
    questionId: string = QuestionnaireTags.RootTag,
    prePath: Array<any>,
    lastModifiedQuestion: TreeNode = null
  ) {
    const questionIndex = prePath.indexOf(lastModifiedQuestion);

    const prePathArray = [];
    prePath.forEach((node) => {
      const nodeId = node.model.id;
      const nextNodeId = node.model.answer.nextQuestionId;
      if (nodeId && !prePathArray.includes(nodeId)) {
        prePathArray.push(nodeId);
      }
      if (nextNodeId && !prePathArray.includes(nextNodeId)) {
        prePathArray.push(nextNodeId);
      }
    });

    // if first question answer is "No" and next is "END"
    if (
      prePathArray.length === this.rootEndAnswerLength &&
      prePathArray[0] === QuestionnaireTags.RootTag &&
      prePathArray[1] === QuestionnaireTags.EndTag
    ) {
      return { prePathArray };
    }

    let nextQuestionId = null;
    if (lastModifiedQuestion) {
      nextQuestionId = this.getNodeAnswerNextQuestionId(lastModifiedQuestion);
      if (
        questionIndex !== -1 &&
        !prePathArray.includes(nextQuestionId) &&
        nextQuestionId !== QuestionnaireTags.EndTag
      ) {
        prePathArray.splice(questionIndex + 1, 0, nextQuestionId);
      }
    }
    return { prePathArray, nextQuestionId };
  }

  public getLongestRoutePrePathExact(
    questionId: string = QuestionnaireTags.RootTag,
    prePath: Array<any>,
    filledAndSkipped: TreeModel.Node<QuestionnairePathNode>[],
    lastModifiedQuestion: TreeNode = null
  ) {
    const { prePathArray, nextQuestionId } = this.preparePathForSearching(questionId, prePath, lastModifiedQuestion);
    const routes = this.generateRoutesFromQuestion(questionId);

    const nextQuestionIndex = prePathArray.indexOf(nextQuestionId);
    //taking the question that answer point to into the longest route finding
    if (
      nextQuestionIndex === -1 &&
      lastModifiedQuestion &&
      this.getNodeModelAnswerId(lastModifiedQuestion) === QuestionnaireTags.SkipTag
    ) {
      const isParentInPrePath = prePath.filter((node) => node.model.id === lastModifiedQuestion.parent?.model.id);
      if (isParentInPrePath.length > 0) {
        const parentNextQuestionId = this.getNodeAnswerNextQuestionId(lastModifiedQuestion.parent);
        if (parentNextQuestionId === lastModifiedQuestion.model.id) {
          return this.getLongestRoutePrePathExact(questionId, prePath, filledAndSkipped, lastModifiedQuestion.parent);
        }
      }
    }

    const allFilledAndSkipped = filledAndSkipped.map((node) => node.model.id);
    const isRealTheSameAsFilled = allFilledAndSkipped.every((item) => prePathArray.includes(item));
    let filteredRoutes = null;
    if (isRealTheSameAsFilled) {
      filteredRoutes = routes.filter((route) => this.isExactSubsequence(prePathArray, route));
      return this.findLongestArray(filteredRoutes);
    }

    const prePathStart = prePathArray.slice(0, nextQuestionIndex + 1);
    const prePathEnd = prePathArray.slice(nextQuestionIndex + 1);

    filteredRoutes = routes.filter((route) => this.isExactSubsequence(prePathStart, route));
    filteredRoutes = filteredRoutes.filter((route) => this.isExactSubsequence(prePathEnd, route));
    return this.findLongestArray(filteredRoutes);
  }

  private isSubsequence(subsequence: string[], sequence: string[]): boolean {
    let subIndex = 0;
    for (let seqIndex = 0; seqIndex < sequence.length && subIndex < subsequence.length; seqIndex++) {
      if (subsequence[subIndex] === sequence[seqIndex]) {
        subIndex++;
      }
    }
    return subIndex === subsequence.length;
  }

  private isExactSubsequence(subsequence: string[], sequence: string[]): boolean {
    for (let i = 0; i <= sequence.length - subsequence.length; i++) {
      let match = true;
      for (let j = 0; j < subsequence.length; j++) {
        if (sequence[i + j] !== subsequence[j]) {
          match = false;
          break;
        }
      }
      if (match) {
        return true;
      }
    }
    return false;
  }

  public generateRoutesFromQuestion(questionId: string) {
    const outcomesWithSkip = this.generateRoutesForCurrentID(this.treeGraph, questionId);
    const outcomesFromRoot = this.generateRoutesForCurrentID(this.treeGraph, QuestionnaireTags.RootTag);
    return outcomesWithSkip.filter((pathWithSkip) =>
      outcomesFromRoot.some((longestPath) => this.isSubsequence(pathWithSkip, longestPath))
    );
  }

  public getAllRoutes(): string[][] {
    const routes: string[][] = [];

    this.treeGraph.forEach((node) => {
      routes.push(...this.generateRoutesForCurrentID(this.treeGraph, node.id));
    });

    return routes;
  }

  private generateRoutesForCurrentID(
    objects: Array<{ id: string; next: Array<string> }>,
    currentID: string
  ): string[][] {
    function trackRoute(id: string, path: string[], visited: Set<string>): string[][] {
      if (id === QuestionnaireTags.EndTag) {
        return [path];
      }

      const outcomes: string[][] = [];
      const currentObject = objects.find((obj) => obj.id === id);
      if (currentObject == null) {
        return [path];
      }

      currentObject.next.forEach((nextId) => {
        if (visited.has(nextId)) {
          // Avoid revisiting the same node to prevent cycles
          outcomes.push([...path]);
        } else {
          const newPath = [...path, nextId];
          const newVisited = new Set(visited).add(nextId);

          if (nextId === QuestionnaireTags.EndTag) {
            outcomes.push(newPath);
          } else {
            outcomes.push(...trackRoute(nextId, newPath, newVisited));
          }
        }
      });

      return outcomes;
    }

    return trackRoute(currentID, [currentID], new Set([currentID]));
  }

  private findShortestArray(arrays: string[][]): string[] | null {
    if (arrays.length === 0) {
      return null;
    }

    let shortestArray = arrays[0];
    for (let i = 1; i < arrays.length; i++) {
      if (arrays[i].length < shortestArray.length) {
        shortestArray = arrays[i];
      }
    }
    return shortestArray;
  }

  private findLongestArray(arrays: string[][]): string[] | null {
    if (arrays.length === 0) {
      return null;
    }

    let longestArray = arrays[0];
    for (let i = 1; i < arrays.length; i++) {
      if (arrays[i].length > longestArray.length) {
        longestArray = arrays[i];
      }
    }
    return longestArray;
  }

  private getLinearTreeStructure() {
    // walks from root to end in tree dependent order.
    // fills array with tree ordered guids
    this.linearQuestionGuids = [];
    this.treeRootNode.walk((x) => this.addNodeGuidToQuestionGuids(x));
  }

  private addNodeGuidToQuestionGuids(node: TreeNode): any {
    this.linearQuestionGuids.push(node.model.guid);
  }

  public findNode(guid: string): TreeNode {
    return this.treeRootNode.first({ strategy: 'post' }, (node) => {
      if (node.model.guid === guid) {
        return true;
      }
      return false;
    });
  }

  private addAnswerToLastUpdatedQuestion(currentQuestion: Question): void {
    const foundQuestionDto = this.questionnaireGroupDto.questions.find(
      (element) => element.guid === currentQuestion.guid
    );
    if (foundQuestionDto == null) {
      return;
    }

    const currentQuestionDto = foundQuestionDto;
    const emptyGuid = '00000000-0000-0000-0000-000000000000';
    // Find element in DTO
    if (currentQuestionDto != null) {
      if (currentQuestionDto.type.toUpperCase() === QuestionType.Closed.toUpperCase()) {
        const currentQuestionAnswerDto = currentQuestionDto.answers[0];
        const ans: Answer = {
          _id: currentQuestionAnswerDto.id,
          _next: currentQuestionAnswerDto.nextQuestionId,
          _questionId: currentQuestionDto.id,
          questionGuid: currentQuestionDto.guid,

          type: null,

          guid: currentQuestionAnswerDto.guid,
          nextQuestionGuid: currentQuestionAnswerDto.nextQuestionGuid,
        };
        // Set answer from dto
        if (ans.nextQuestionGuid === emptyGuid) {
          ans.nextQuestionGuid = null;
        }
        this.setUserAnswer(ans, false);
      } else if (currentQuestionDto.type.toUpperCase() === QuestionType.Open.toUpperCase()) {
        const currentQuestionAnswerDto = currentQuestionDto.answers[0];
        const ans: OpenAnswer = {
          _id: currentQuestionAnswerDto.id,
          _next: currentQuestionAnswerDto.nextQuestionId,
          _questionId: currentQuestionDto.id,
          questionGuid: currentQuestionDto.guid,

          type: currentQuestionAnswerDto.stringValue != null ? 'TextBox' : 'NumberBox',
          unit: currentQuestionAnswerDto.unit,
          units: [],
          isText: currentQuestionAnswerDto.stringValue != null ? true : false,
          stringValue: currentQuestionAnswerDto.stringValue,
          numberValue: currentQuestionAnswerDto.numberValue,

          guid: currentQuestionAnswerDto.guid,
          nextQuestionGuid: currentQuestionAnswerDto.nextQuestionGuid,
        };
        if (ans.nextQuestionGuid === emptyGuid) {
          ans.nextQuestionGuid = null;
        }
        this.setUserAnswer(ans, false);
      } else if (currentQuestionDto.type.toUpperCase() === QuestionType.CheckBox.toUpperCase()) {
        if (currentQuestion.multiplechoice === true) {
          currentQuestionDto.answers.forEach((answer) => {
            const currentQuestionAnswerDto = answer;
            const ans: CheckBoxAnswer = {
              _id: currentQuestionAnswerDto.id,
              _next: currentQuestionAnswerDto.nextQuestionId,
              _questionId: currentQuestionDto.id,
              questionGuid: currentQuestionDto.guid,
              type: null,
              booleanValue: currentQuestionAnswerDto.booleanValue,
              text: '',
              guid: currentQuestionAnswerDto.guid,
              nextQuestionGuid: currentQuestionAnswerDto.nextQuestionGuid,
            };
            if (ans.nextQuestionGuid === emptyGuid) {
              ans.nextQuestionGuid = null;
            }
            this.setUserAnswer(ans, false);
          });
        } else {
          const currentQuestionAnswerDto = currentQuestionDto.answers[0];
          const ans: CheckBoxAnswer = {
            _id: currentQuestionAnswerDto.id,
            _next: currentQuestionAnswerDto.nextQuestionId,
            _questionId: currentQuestionDto.id,
            questionGuid: currentQuestionDto.guid,
            type: null,
            booleanValue: currentQuestionAnswerDto.booleanValue,
            text: '',
            guid: currentQuestionAnswerDto.guid,
            nextQuestionGuid: currentQuestionAnswerDto.nextQuestionGuid,
          };
          if (ans.nextQuestionGuid === emptyGuid) {
            ans.nextQuestionGuid = null;
          }
          this.setUserAnswer(ans, false);
        }
      }

      // we have to find corresponding node:
      if (currentQuestionDto.note !== '') {
        currentQuestion.note = currentQuestionDto.note;
        this.addQuestionNote(currentQuestion);
      }
      // We only applied already answered questions.
      this.isDirty = false;
    }
  }

  addQuestionNote(question: Question) {
    if (question != null) {
      const questionOnList = this.questionList.find((q) => q._id === question._id);
      if (questionOnList != null) {
        const questionNode = this.findNode(question.guid);
        if (questionNode != null) {
          questionNode.model.note = question.note;
          this.isDirty = true;
        }
      }
    }
  }
  removeQuestionNote(question: Question) {
    if (question != null) {
      const questionOnList = this.questionList.find((q) => q._id === question._id);
      if (questionOnList != null) {
        const questionNode = this.findNode(question.guid);
        if (questionNode != null) {
          questionNode.model.note = '';
          this.isDirty = true;
        }
      }
    }
  }

  skipQuestion(questionToSkip: Question) {
    if (questionToSkip != null) {
      const question = this.questionList.find((q) => q._id === questionToSkip._skipToId);
      if (question != null) {
        // set mock answer and move on
        if (questionToSkip.type === QuestionType.Open && (questionToSkip.answer as OpenAnswer).type === 'NumberBox') {
          const ans = {
            _id: QuestionnaireTags.SkipTag,
            _questionId: questionToSkip._id,
            _next: questionToSkip._skipToId,
            type: QuestionnaireTags.SkipTag,
            questionGuid: questionToSkip.guid,
            unit: (questionToSkip.answer as OpenAnswer).units[0],
          };
          this.setUserAnswer(ans, false);
        } else {
          this.setUserAnswer(
            {
              _id: QuestionnaireTags.SkipTag,
              _questionId: questionToSkip._id,
              _next: questionToSkip._skipToId,
              type: QuestionnaireTags.SkipTag,
              questionGuid: questionToSkip.guid,
            },
            false
          );
        }
        this.isDirty = true;
      }
    }
  }

  canQuestionBeSkipped(question: Question): boolean {
    if (question._skipToId === undefined) {
      return false;
    }

    if (this.isSkippingToEndQuestion(question)) {
      return false;
    }

    // if child nodes in tree do not exist we can skip
    const questionNode = this.findNode(question.guid);
    if (questionNode != null) {
      if (questionNode.model.answer != null && !Array.isArray(questionNode.model.answer)) {
        return false;
      } else if (questionNode.model.answer != null && Array.isArray(questionNode.model.answer)) {
        if (questionNode.model.answer.length > 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
    // weird scenario - we want to skip question that does not exists in the tree O_o
    return false;
  }

  isSkippingToEndQuestion(question: Question) {
    let answers: Array<SelectAnswer | OpenAnswer>;
    if (Array.isArray(question.answer)) {
      answers = question.answer;
    } else {
      answers = [question.answer];
    }

    if (answers.some((a) => a._next.toLocaleUpperCase() === QuestionnaireTags.EndTag)) {
      return true;
    }

    return false;
  }

  isLastQuestion(question: Question) {
    let answers: Array<SelectAnswer | OpenAnswer>;
    if (Array.isArray(question.answer)) {
      answers = question.answer;
    } else {
      answers = [question.answer];
    }

    const foundAnswer = answers.find((answer) => answer._next.toLocaleUpperCase() !== QuestionnaireTags.EndTag);
    if (foundAnswer !== undefined) {
      return false;
    }

    return true;
  }

  private nodeHasAnswer(node: QuestionnairePathNode): boolean {
    if (Array.isArray(node.answer)) {
      if (node.answer.length) {
        return true;
      }
    } else {
      if (node.answer) {
        return true;
      }
    }
    return false;
  }

  private findNodeForAnswer(answer: Answer): QuestionnairePathNode {
    return this.questionnaireTree.find((n) => n.guid === answer.questionGuid);
  }

  public newNodeFromQuestion(question: Question): QuestionnairePathNode {
    if (question.type === QuestionType.CheckBox) {
      return new QuestionnairePathNodeCheckBox(question);
    } else {
      return new QuestionnairePathNode(question);
    }
  }

  public saveCurrentQuestionsObservable(): Observable<any> {
    // We already used dto - clear it before posting new answers
    this.questionnaireGroupDto.questions = [];
    this.treeRootNode.walk((x) => this.createQuestionAnswerDTOForNode(x.model, this.questionnaireGroupDto));
    this.isDirty = false;
    return this._httpClient.post(this.apiUrl + 'api/questionnaires/groups', this.questionnaireGroupDto);
  }

  private questionsWithNotesWithoutAnswers: Array<{ questionText: string; questionNote: string }>;
  public getNodesWithNotesWithoutHavingAnswerProvided(): any {
    this.questionsWithNotesWithoutAnswers = [];
    this.treeRootNode.walk((x) => this.checkNodeForAnswersAndNotes(x.model));
    return this.questionsWithNotesWithoutAnswers;
  }

  private checkNodeForAnswersAndNotes(questionNode: QuestionnairePathNode): any {
    if (questionNode.answer == null && questionNode.note != null) {
      const question = this.questionList.find((q) => q._id === questionNode.id);
      if (question != null) {
        this.questionsWithNotesWithoutAnswers.push({ questionNote: questionNode.note, questionText: question.text });
      } else {
        this.questionsWithNotesWithoutAnswers.push({ questionNote: questionNode.note, questionText: '' });
      }
    }
  }

  public downloadCSV() {
    this._httpClient
      .get(`${this.apiUrl}api/questionnaires/CSV/${this.questionnaireGroupDto.questionnaireId}`, {
        responseType: 'blob' as 'text',
      })
      .subscribe((response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        const filename = this._('questionnaire-csv-name');

        if (filename) {
          downloadLink.setAttribute('download', filename);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.parentNode.removeChild(downloadLink);
      });
  }

  private createQuestionAnswerDTOForNode(
    question: QuestionnairePathNode,
    questionnaireGroup: QuestionnaireGroupDto
  ): any {
    if (questionnaireGroup.questions.some((q) => q.guid === question.guid)) {
      // do not add already added question
      return;
    }

    if (question.answer == null) {
      const questionNode = this.findNode(question.guid);
      if (questionNode.children.length > 0) {
        if (question.type === QuestionType.Open) {
          const ans = {
            _id: QuestionnaireTags.SkipTag,
            _questionId: questionNode.model.id,
            _next: questionNode.children[0].model.id,
            type: QuestionnaireTags.SkipTag,
            questionGuid: questionNode.model.guid,
            unit: (question.answer as QuestionnaireAnswer).unit,
          };
          this.setUserAnswer(ans, false);
        } else {
          this.setUserAnswer(
            {
              _id: QuestionnaireTags.SkipTag,
              _questionId: questionNode.model.id,
              _next: questionNode.children[0].model.id,
              type: QuestionnaireTags.SkipTag,
              questionGuid: questionNode.model.guid,
            },
            false
          );
        }
      } else {
        return;
      }
    }

    const questionDto = this.createNewQuestionDto(question);
    if (Array.isArray(question.answer)) {
      if (question.answer.length === 0) {
        // this can happen when question is created but not answered.
        return;
      }
      question.answer.forEach((answer) =>
        questionDto.answers.push(this.createNewAnswerDto(answer, question.pathChildGuid))
      );
    } else {
      const answerDto = this.createNewAnswerDto(question.answer, question.pathChildGuid);
      questionDto.answers.push(answerDto);
    }
    questionnaireGroup.questions.push(questionDto);
  }

  private createNewQuestionDto(question: QuestionnairePathNode): QuestionDto {
    return {
      id: question.id,
      guid: question.guid,
      type: question.type,
      root: question.root || false,
      note: question.note,
      answers: new Array<AnswerDto>(),
    };
  }

  private createNewAnswerDto(answer: QuestionnaireAnswer, nextGuid: string): AnswerDto {
    return {
      id: answer.id,
      guid: answer.guid,
      nextQuestionGuid: nextGuid,
      nextQuestionId: answer.nextQuestionId,
      stringValue: answer.stringValue,
      numberValue: answer.numberValue,
      booleanValue: answer.booleanValue,
      unit: answer.unit,
      isText: answer.isText,
    };
  }
}

export function enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value) ? (value as unknown as T) : undefined;
}
