import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormType } from '../../../models/audit/audit-tree-navigation.model';
import { Audit } from '../../../models/audit/audit.model';
import { TreeNavigationService } from '../../../services/tree-navigation.service';
import { AuditCardsService } from '../audit-cards.service';
import { Localizable } from '../../../../locale/localizable';
import { CloseResult } from '../../questionnaire/exit-confirmation-popup/exit-confirmation-popup.component';

@Component({
  selector: 'app-audit-card',
  templateUrl: './audit-card.component.html',
  styleUrls: ['./audit-card.component.scss'],
})
export class AuditCardComponent extends Localizable implements OnInit {
  private static readonly localFile = 'AuditsListComponent';
  @Input() audit: Audit = null;

  @Output() deletedAudit: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private _treeNavigationService: TreeNavigationService,
    private _auditCardsService: AuditCardsService
  ) {
    super(AuditCardComponent.localFile);
  }

  ngOnInit(): void {}

  public deleteAuditPopupVisible = false;
  public deleteAudit(): void {
    // dialog here
    this.deleteAuditPopupVisible = true;
  }

  public onDeleteAudit(event: KeyboardEvent, id: string): void {
    this.deleteEvent = event;
    this.deleteId = id;
    this.deleteAudit();
    event.stopPropagation();
  }

  onDeleteAuditPopupClose(e: any) {
    if (e === CloseResult.Confirm) {
      // exit
      this.deletedAudit.emit();
      this._auditCardsService.deleteAudit(this.deleteId);
      this.deleteAuditPopupVisible = false;
      this.deleteId = null;
    }

    this.deleteEvent.stopPropagation();
    this.deleteEvent = null;
    this.deleteAuditPopupVisible = false;
  }

  public openAudit(id: string): void {
    this._treeNavigationService.setHideCreatorHint(false);
    this._treeNavigationService.changeForm(FormType.Audit, id);
  }

  public handleKeyDownOpen(event: KeyboardEvent, id: string): void {
    if (event.code === 'Enter') {
      event.preventDefault();
      this.openAudit(id);
    }
  }
  private deleteEvent: KeyboardEvent;
  private deleteId: string;
  public handleKeyDownDelete(event: KeyboardEvent, id: string): void {
    if (event.code === 'Enter') {
      this.onDeleteAudit(event, id);
    }
  }
}
