import { Component, Input, OnInit } from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import { Localizable } from '../../../../../../../locale/localizable';
import { AirCurtain, AirCurtainType } from 'src/app/shared/models/area/types/air-curtain.model';
import { ApiRequestService } from 'src/app/shared/services/api-request.service';

@Component({
  selector: 'app-air-curtain',
  templateUrl: './air-curtain.component.html',
  styleUrls: ['./air-curtain.component.scss'],
})
export class AirCurtainComponent extends Localizable implements OnInit {
  @Input()
  auditId: string = '';

  @Input()
  airCurtain: AirCurtain = null;
  airCurtainTypesList: ArrayStore;
  currentYear: number;

  constructor(private _apiRequestService: ApiRequestService) {
    super();
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

    this._apiRequestService.getAirCurtainTypes().subscribe((res: Array<AirCurtainType>) => {
      this.airCurtainTypesList = new ArrayStore({
        key: 'id',
        data: res,
      });
    });
  }
}
