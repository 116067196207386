import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuditTreeNavigation, FormType } from '../../../../models/audit/audit-tree-navigation.model';
import { TreeNavigationService } from '../../../../services/tree-navigation.service';
import { Image } from '../../../../models/image.model';
import { GalleryService } from '../../../../services/gallery.service';
import { Gallery } from '../../../../models/gallery.model';
import { Subscription } from 'rxjs';
import { ScreenService } from '../../../../services';
import { AppConfig } from '../../../../../app.config';
import notify from 'devextreme/ui/notify';
import { AppSettings } from '../../../../../AppSettings';
import { Localizable } from '../../../../../locale/localizable';
import { UploadErrorEvent } from 'devextreme/ui/file_uploader';
import { ErrorHandlerService } from '../../../../services/errorHandlerService';
import { AuthenticationService } from '../../../../services/authentication.service';
import DataSource from 'devextreme/data/data_source';
import { CloseResult } from '../../../questionnaire/exit-confirmation-popup/exit-confirmation-popup.component';
import { AuditGalleryComponent } from '../audit-gallery.component';

@Component({
  selector: 'app-gallery-accordion',
  templateUrl: './gallery-accordion.component.html',
  styleUrls: ['./gallery-accordion.component.scss'],
})
export class GalleryAccordionComponent extends Localizable implements OnInit {
  @Input() formData: AuditTreeNavigation;
  @Input() startNode: boolean = false;
  @Input() isAuditFinished: boolean = false;
  @Input() formId: string;
  public childrenForms: Array<AuditTreeNavigation> = [];
  public selectedChildrenForms: Array<AuditTreeNavigation> = [];
  public images: Array<Image> = [];
  public imgView: Array<Image> = [];
  private galleries: Array<Gallery> = [];
  private galleryId = '';

  public showDeleteConfirmationPopup: boolean = false;

  private imagesSubscription: Subscription;
  public isCollapsible = true;
  public isMobile = true;

  public name = '';

  public imgDataSource: DataSource;
  private filter: Array<any> = [['caption', 'contains', this.name]];
  searchButton = {
    icon: 'fi fi-rr-search',
    stylingMode: 'text',
  };

  public selectedImagesIdList: Array<string> = [];
  public showMoveImagePopup = false;

  public imageSelection = false;

  @Output()
  public imageDeleted: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public uploadedImage: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public image: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(GalleryAccordionComponent)
  galleryAccordion: GalleryAccordionComponent;
  @ViewChild('takePicture', { static: false }) inputImage: Input;
  private _navigationTreeDataSubscription: Subscription;

  constructor(
    private _treeNaviagtionService: TreeNavigationService,
    private _galleryService: GalleryService,
    private _appConfig: AppConfig,
    private _screenService: ScreenService,
    private _errorHandlerService: ErrorHandlerService,
    private _authenticationService: AuthenticationService
  ) {
    super(AuditGalleryComponent.localFile);
    this._galleryService.deletedImagesIdsList.subscribe((res: Array<string>) => {
      res.forEach((id: string) => {
        const removeIndex = this.images.map((image) => image.id).indexOf(id);
        ~removeIndex && this.images.splice(removeIndex, 1);
      });
    });

    this._galleryService.selectedImagesChanged.subscribe((ids: Array<string>) => {
      this.selectedImagesIdList = ids;
    });
  }

  ngOnInit(): void {
    this._navigationTreeDataSubscription = this._treeNaviagtionService.navigationTreeData.subscribe(
      (treeNaviagtionData) => {
        let id = '-1';
        if (this.formId) {
          id = this.formId;
        }
        if (!this.startNode) {
          id = this.formData.id;
          this.childrenForms = treeNaviagtionData?.filter((formData) => formData.parentId === id.toUpperCase());
        } else {
          this.childrenForms = treeNaviagtionData?.filter((formData) => formData.id === id.toUpperCase());
        }
        this.fetchFormImages(id);

        this.formData = treeNaviagtionData?.find((formData) => formData.id === id.toUpperCase());

        if (this.startNode) {
          this.selectedChildrenForms = this.childrenForms;
        }
      }
    );

    this._treeNaviagtionService.navigateToFormId.subscribe((selectedForm: AuditTreeNavigation) => {
      this.fetchFormImages(selectedForm.id);
    });

    this._galleryService.auditGalleries.subscribe((galleries) => {
      this.galleries = galleries;
      const formGallery = galleries.filter((gallery) => gallery.formId.toUpperCase() === this.formData?.id)[0];
      this.galleryId = formGallery?.id;
      if (this.formData?.id && this.formData?.selectable) {
        this.setImages(formGallery?.images);
      }
    });

    //TODO: Check if device has access to camera -
    this._screenService.isMobile.subscribe((isMobile: boolean) => (this.isMobile = isMobile));
  }

  ngOnDestroy() {
    this._navigationTreeDataSubscription.unsubscribe();
  }

  public filterNewValue(): void {
    this.filter = [['caption', 'contains', this.name]];
    this.imgDataSource.filter(this.filter);
    this.imgDataSource.load();
    this.imgView = this.imgDataSource.items();
  }

  public fetchFormImages(fromId: string): void {
    this._galleryService.getGalleryObserver(fromId).subscribe((images) => {
      this.setImages(images);
    });
  }

  public get token(): string {
    return this._authenticationService.getBearerTokenHeader();
  }

  public uploadComplete(): void {
    this._galleryService.fetchAuditAndChildrenGalleries(this._galleryService.getAuditId());
    this._galleryService.showSuccessNotify(this._('audit-gallery-successfully-added-image'));
    this._galleryService.deselect();
  }

  public getImagesNumber(childId: string): number {
    const images = this.galleries.filter((gallery) => gallery.formId.toUpperCase() === childId)[0]?.images;
    if (images !== undefined) {
      return images.length;
    }
    return 0;
  }

  public onImageDeleted(): void {
    this.updateImages();
  }

  public onImageEdited(): void {
    this.updateImages();
  }

  public getUploadUrl(formId: string): string {
    const formGallery = this.galleries.filter((gallery) => gallery.formId.toUpperCase() === formId)[0];
    const galleryId = formGallery?.id;
    return this._appConfig.getConfig('DbEnergyDatabaseUrl') + `api/galleries/${galleryId}/images`;
  }

  public fetchImages(galleryId: string): void {
    if (this.galleryId === galleryId) {
      this.updateImages();
    }
  }

  private updateImages(): void {
    if (this.imagesSubscription !== undefined) {
      this.imagesSubscription.unsubscribe();
    }
    this.imagesSubscription = this._galleryService.fetchImages(this.galleryId).subscribe((images) => {
      this.setImages(images);
    });
    this._galleryService.fetchAuditAndChildrenGalleries(this._galleryService.getAuditId());
  }

  private setImages(images: Image[]): void {
    if (images !== undefined) {
      this.images = images;
      this.imgDataSource = new DataSource({
        store: images,
      });
      this.imgDataSource.filter(this.filter);
      this.imgDataSource.load();
      this.imgView = this.imgDataSource.items();
    }
  }

  public isExpanded(formId: string): boolean {
    return this.selectedChildrenForms.some((x) => {
      if (!x.selectable) {
        return false;
      } else {
        return x.id === formId;
      }
    });
  }

  public onImageSelected(id: string): void {
    this._galleryService.onImageSelected(id);
  }

  public onImageDeSelected(id: string): void {
    this._galleryService.onImageDeSelected(id);
  }

  public isFormType(): boolean {
    return Object.values(FormType).includes(this.formData.formType);
  }

  public isImageDropped(imageId: string): boolean {
    return imageId != null && imageId !== '';
  }

  public isMovedToAnotherForm(imageFormId: string) {
    return this.formData?.id?.toLocaleUpperCase() !== imageFormId.toUpperCase();
  }

  public onDragStart(event, imageId: string, imageFormId: string): void {
    if (this.isAuditFinished) {
      return;
    }

    const draggedImagesIds = [imageId, ...this._galleryService.selectedImagesIdsList];
    const uniqueDraggedImagesIds = Array.from(new Set(draggedImagesIds));
    event.dataTransfer?.setData('imagesIds', uniqueDraggedImagesIds);
    event.dataTransfer?.setData('imageFormId', imageFormId);
  }

  public onDragOver(event): void {
    if (this.isAuditFinished) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
  }

  public onDrop(event): void {
    if (this.isAuditFinished) {
      return;
    }
    const imageId = event.dataTransfer.getData('imageId');
    const imageFormId = event.dataTransfer.getData('imageFormId');
    if (this.isImageDropped(imageId) && this.isFormType() && this.isMovedToAnotherForm(imageFormId)) {
      this._galleryService.changeImageLocation([imageId], this.formData?.id).subscribe(() => {
        this._galleryService.fetchAuditAndChildrenGalleries(this._galleryService.getAuditId());

        notify({
          message: this._('audit-gallery-image-dragged-and-dropped-message'),
          type: 'success',
          displayTime: AppSettings.NOTIFY_DURATION,
          position: 'top center',
        });
      });
    }
  }
  public handleUploadError(error: UploadErrorEvent) {
    this._errorHandlerService.handleError(error.error);
  }

  public deleteSelected(event: CloseResult, idsList: Array<string>): void {
    if (event === CloseResult.Confirm) {
      this._galleryService.deleteSelectedImaged(idsList).subscribe((res: Array<string>) => {
        this._galleryService.removeImagesFromView(res);
        this._galleryService.showSuccessNotify(this._('audit-gallery-delete-message'));
        this.deselect();
      });
    }
    this.showDeleteConfirmationPopup = false;
  }

  public setSelectedImagesIncludeFlag(idsList: Array<string>, includeFlag: boolean): void {
    const succesNote = includeFlag
      ? this._('audit-gallery-added-to-report-message')
      : this._('audit-gallery-removed-from-report-message');

    this._galleryService.updateSelectedImagesIncludeInReportStatus(idsList, includeFlag).subscribe(() => {
      this._galleryService.fetchAuditAndChildrenGalleries(this._galleryService.getAuditId());
      this.deselect();
      this._galleryService.showSuccessNotify(succesNote);
    });
  }

  public onImagesMoved(): void {
    this._galleryService.fetchAuditAndChildrenGalleries(this._galleryService.getAuditId());
    this._galleryService.showSuccessNotify(this._('audit-gallery-images-form-updated-message'));
    this.deselect();
    this.showMoveImagePopup = false;
  }

  public onPopupHiding(): void {
    this.showMoveImagePopup = false;
  }

  public turnOnSelection(): void {
    this.imageSelection = true;
  }

  public deselect(): void {
    this.imageSelection = false;
    this._galleryService.deselect();
  }

  public triggerImageDeleteConfirmation(): void {
    this.showDeleteConfirmationPopup = true;
  }

  public reverseSelection(): void {
    const selectedIds = this.selectedImagesIdList;
    const viewIds = this.imgView.map((image) => image.id);

    const reversedIds = viewIds.filter((id) => !selectedIds.includes(id));

    this._galleryService.deselect();
    this.selectedImagesIdList = [];
    reversedIds.forEach((id) => {
      this._galleryService.onImageSelected(id);
    });
  }
}
