<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<div>
  <div>
    <app-gallery-accordion
      [formId]="formId"
      [startNode]="true"
      [isAuditFinished]="isAuditFinished"
    >
    </app-gallery-accordion>
  </div>
</div>
