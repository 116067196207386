<!-- Copyright C Alfa-Net. All Rights Reserved. -->
<dx-data-grid
  id="gridContainer"
  [dataSource]="dataAuditsList"
  [remoteOperations]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  columnResizingMode="widget"
  [showBorders]="true"
  [showColumnLines]="true"
  [showRowLines]="false"
  [rowAlternationEnabled]="true"
  (onCellClick)="onCellClick($event)"
>
  <dxo-selection
    mode="single"
    selectAllMode="allPages"
  >
  </dxo-selection>
  <dxo-paging [pageSize]="10"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[5, 10, 20]"
    [showInfo]="true"
  >
  </dxo-pager>

  <dxo-editing
    mode="row"
    [allowDeleting]="true"
    [useIcons]="true"
  >
    <dxo-texts
      deleteRow="Usuń audyt"
      confirmDeleteMessage="Na pewno chcesz usunąć wybrany audyt?"
    >
    </dxo-texts>
  </dxo-editing>

  <dxo-filter-row
    [visible]="true"
    applyFilter="auto"
    [showOperationChooser]="false"
  >
  </dxo-filter-row>

  <dxo-header-filter visible="true"> </dxo-header-filter>

  <dxi-column
    caption="Nazwa audytu"
    alignment="left"
    cellTemplate="auditNameCellTemplate"
    dataField="name"
    [allowFiltering]="true"
    [allowHeaderFiltering]="false"
  ></dxi-column>
  <div *dxTemplate="let cell of 'auditNameCellTemplate'">
    <div class="audit-mame-cell">{{ cell.text }}</div>
  </div>

  <dxi-column
    caption="Typ audytu"
    alignment="left"
    dataField="auditType.name"
  ></dxi-column>

  <dxi-column
    dataField="date"
    alignment="left"
    dataType="date"
    caption="Data wykonania"
  ></dxi-column>

  <dxi-column
    type="buttons"
    [width]="110"
  >
    <dxi-button
      name="delete"
      hint="Usuń audyt"
      icon="fi fi-rr-trash"
    >
    </dxi-button>
  </dxi-column>
</dx-data-grid>
